import React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import classnames from 'classnames';

const style = {
  position: 'absolute',
  background: 'red',
  color: '#fff',
  fontSize: 17,
  padding: 10,
  left: '43vw',
  top: 0,
  zIndex: 100000,
};

/* global window */

function isDevelopmentVersion() {
  if (!window || !window.location || !window.location.href) {
    return false;
  }
  if (window.location.href.indexOf('stage') !== -1) {
    return 'Entwicklungsversion';
  }
  // if (window.location.href.indexOf('localhost:9000') !== -1) {
  //     return 'Development-Version';
  // }
  return false;
}


class ApplicationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      landscape: false,
    };
    this.idDev = false;//isDevelopmentVersion();
    this.setLandscape = this.setLandscape.bind(this);
  }
  componentDidMount() {
    window.addEventListener('optimizedResize', this.setLandscape);
    this.setLandscape();
  }
  componentWillUnmount() {
    window.removeEventListener('optimizedResize', this.setLandscape);
  }
  setLandscape() {
    this.setState({ landscape: window.innerWidth > window.innerHeight });
  }
  render() {
    const { landscape } = this.state;
    return (
      <DndProvider backend={HTML5Backend}>
        <div className={classnames({ landscape, portrait: !landscape })}>
          {this.props.children}
          {this.idDev ? (<div style={style}>{this.idDev}</div>) : null}
        </div>
      </DndProvider>
    );
  }
}

// export default DndContext(HTML5Backend)(ApplicationComponent);
export default ApplicationComponent;
