import { createAction, handleActions } from 'redux-actions';
import api from 'actions/api';

export const CHECKED = 'CHECKED';
export const CHECKED_NOT_LOGGED_IN = 'CHECKED_NOT_LOGGED_IN';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const AUTH_UPDATED = 'AUTH_UPDATED';

export const checked = createAction(CHECKED);
export const checkedLoginNotLoggedIn = createAction(CHECKED_NOT_LOGGED_IN);
export const loggedIn = createAction(LOGIN);
export const loggedOut = createAction(LOGOUT);
export const updated = createAction(AUTH_UPDATED);

export const check = () => api.get({ url: '/api/auth/' });
export const login = data => api.post({ url: '/api/auth/', data });
export const login2 = data => api._post2({ url: '/api/auth/', data });
export const updateSettings = data => api.put({ url: '/api/auth/', data });
export const updateSettings2 = data => api._put2({ url: '/api/auth/', data });
export const changePassword = data => api.put({ url: '/api/auth/password/', data });
export const changePassword2 = data => api._put2({ url: '/api/auth/password/', data });

export const requestPasswordReset = data => api.post({ url: '/api/reset_password/request/', data });
export const requestPasswordReset2 = data => api._post2({ url: '/api/reset_password/request/', data });

const emptyState = {
  user: null,
  checkedLogin: false,
};

export const authReducer = handleActions({
  [CHECKED]: (state, { payload: user }) => ({
    ...emptyState,
    user,
    checkedLogin: true,
  }),
  [CHECKED_NOT_LOGGED_IN]: state => ({
    ...state,
    user: null,
    checkedLogin: true,
  }),
  [LOGIN]: (state, { payload: user }) => ({
    ...emptyState,
    user,
    checkedLogin: true,
  }),
  [LOGOUT]: () => {
    api.removeAuthToken();
    return {
      ...emptyState,
      checkedLogin: true,
    };
  },
  [AUTH_UPDATED]: (state, { payload: user }) => ({
    ...state,
    user,
  }),
}, { ...emptyState });
