import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router';
import moment from 'moment';

const messages = defineMessages({
  NO_USER_FOUND: {
    id: 'CompanyUserList.NO_USER_FOUND',
    defaultMessage: 'Es wurden keine Benutzer gefunden.',
  },
  TABLE_NAME: {
    id: 'CompanyUserList.TABLE_NAME',
    defaultMessage: 'Name',
  },
  TABLE_EMAIL: {
    id: 'CompanyUserList.TABLE_EMAIL',
    defaultMessage: 'E-Mail-Adresse',
  },
  TABLE_PHONE: {
    id: 'CompanyUserList.TABLE_PHONE',
    defaultMessage: 'Telefonnummer',
  },
  TABLE_CREATED: {
    id: 'CompanyUserList.TABLE_CREATED',
    defaultMessage: 'Erstellt',
  },
  TABLE_LAST_LOGIN: {
    id: 'CompanyUserList.TABLE_LAST_LOGIN',
    defaultMessage: 'Letze Anmeldung',
  },
  OWNER: {
    id: 'CompanyUserList.OWNER',
    defaultMessage: 'Besitzer',
  },
  ADMIN: {
    id: 'CompanyUserList.ADMIN',
    defaultMessage: 'Administrator',
  },
  DEACTIVATED: {
    id: 'CompanyUserList.DEACTIVATED',
    defaultMessage: 'Deaktiviert',
  },
});

function CompanyUserList(props) {
  const { items, style, intl: { formatMessage } } = props;
  if (!(items && items.length)) {
    return <p style={style}>{formatMessage(messages.NO_USER_FOUND)}</p>;
  }

  return (
    <div className="table-responsive" style={style}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>{formatMessage(messages.TABLE_NAME)}</th>
            <th>{formatMessage(messages.TABLE_EMAIL)}</th>
            <th>{formatMessage(messages.TABLE_PHONE)}</th>
            <th>{formatMessage(messages.TABLE_CREATED)}</th>
            <th>{formatMessage(messages.TABLE_LAST_LOGIN)}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {items.map(companyUser => (
            <tr key={companyUser.id}>
              <td>
                <Link
                  to={`/company/${companyUser.company_id}/user/${companyUser.user_id}/`}
                  style={{ maxWidth: '450px', display: 'block' }}
                  className="text-overflow"
                  title={companyUser.user.name}
                >
                  {companyUser.user.name}
                </Link>
              </td>
              <td>{companyUser.user.email}</td>
              <td>{companyUser.user.phone}</td>
              <td>
              {companyUser.user.created ?
                moment(companyUser.user.created, 'YYYY-MM-Do h:mm:ss').fromNow() : ''
              }
              </td>
              <td>
              {companyUser.user.last_login ?
                moment(companyUser.user.last_login, 'YYYY-MM-Do h:mm:ss').fromNow() : ''
              }
              </td>
              <td>
                {companyUser.is_owner ? (
                  <span className="label label-primary" style={{ color: '#fff' }}>
                    {formatMessage(messages.OWNER)}
                  </span>
                ) : null}
                {companyUser.is_admin ? (
                  <span className="label label-info" style={{ color: '#fff' }}>
                    {formatMessage(messages.ADMIN)}
                  </span>
                ) : null}
                {companyUser.is_inactive ? (
                  <span className="label label-danger" style={{ color: '#fff' }}>
                    {formatMessage(messages.DEACTIVATED)}
                  </span>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default injectIntl(CompanyUserList);
