import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import classnames from 'classnames';

import { DATE_FORMAT } from '../../../../config';
import { eventEditStart, eventEditStop } from '../../../../actions/eventEdit';
import Capitals from '../Capitals';
import EventNoteForm from './EventNoteForm';
import ImageGallery from './ImageGallery';

const messages = defineMessages({
  FINAL_NOTE: {
    id: 'EventNote.FINAL_NOTE',
    defaultMessage: 'Abschlussnotiz',
  },
  IMAGE: {
    id: 'EventNote.IMAGE',
    defaultMessage: 'Bild',
  },
  CONVERSATION_NOTE: {
    id: 'EventNote.CONVERSATION_NOTE',
    defaultMessage: 'Gesprächsnotiz',
  },
  NOTE: {
    id: 'EventNote.NOTE',
    defaultMessage: 'Notiz',
  },
  CLASSIFY_PROJECT: {
    id: 'EventNote.CLASSIFY_PROJECT',
    defaultMessage: 'Eingeordnet im Projekt',
  },
  CANCEL_EDIT: {
    id: 'EventNote.CANCEL_EDIT',
    defaultMessage: 'Bearbeiten Abbrechen',
  },
  EDIT: {
    id: 'EventNote.EDIT',
    defaultMessage: 'Bearbeiten',
  },
  DELETE: {
    id: 'EventNote.DELETE',
    defaultMessage: 'Löschen',
  },
  ASSIGN_PROJECT: {
    id: 'EventNote.ASSIGN_PROJECT',
    defaultMessage: 'Zu Projekt zuordnen',
  },
});

function EventNote(props) {
  const {
    evt,
    editEvt,
    customer,
    call,
    orders,
    connectDragSource,
    intl: { formatMessage },
  } = props;

  let text = null;

  // insert the order-in span into the last paragraph
  if (evt.text) {
    text = evt.text.split('\n');
    // const count = text.length;
    text = text.map((part, index) => (
      <p key={index} className="not-hidden">
        {part}
      </p>
      ));
  }
  const activeOrders = orders ? orders.filter(order => order.active).filter(order => order.id !== -1) : [];
  return (
    <div
      className={classnames('event-row', {
        'has-order': !!evt.order_name,
      })}
    >
      <div className="event-row-left-col">
        <Capitals customer={{ name: evt.user_name }} />
      </div>
      <div className="event-row-right-col">
        <div className="event-row-header">
          {props.endNote ? (
              <p className="event-row-text">{formatMessage(messages.FINAL_NOTE)}</p>
            ) : (
                evt.pictures && evt.pictures.length > 0 ? (
                  <p className="event-row-text">{formatMessage(messages.IMAGE)}</p>
                ) : (
                    call ? (
                      <p className="event-row-text">{formatMessage(messages.CONVERSATION_NOTE)}</p>
                    ) : (
                      <p className="event-row-text">{formatMessage(messages.NOTE)}</p>
                    )
                )
            )
          }
          {!editEvt
            && (evt.order_name
              && (
                <span className="ordered-in">
                  <span className="menu-item-responsive">
                    <span>{formatMessage(messages.CLASSIFY_PROJECT)}</span> <i className="fa fa-bookmark" />
                  </span>
                  <Link
                    title={evt.order_name}
                    className="text-overflow max400"
                    to={`/company/${customer.company_id}/customer/${customer.id}/order/${evt.order_id}`}
                  >
                    {evt.order_name}
                  </Link>
                </span>
              )
            )
          }
          <DropdownButton pullRight title="" id={evt.id}>
            {editEvt ?
              (
                <MenuItem eventKey="1" onClick={() => props.stopEdit()}>{formatMessage(messages.CANCEL_EDIT)}</MenuItem>
              ) : (
                <MenuItem eventKey="1" onClick={() => props.editNote()}>{formatMessage(messages.EDIT)}</MenuItem>
              )
            }
            <MenuItem divider />
            <MenuItem
              eventKey="3"
              onClick={() => props.deleteEvent(evt)}
            >{formatMessage(messages.DELETE)}</MenuItem>
            {activeOrders && activeOrders.length && typeof props.addNoteToOrder === 'function' ?
              (
                <MenuItem header id="Zu Projekt zuordnen">{formatMessage(messages.ASSIGN_PROJECT)}</MenuItem>
              )
              : null
            }
            {activeOrders && activeOrders.length && typeof props.addNoteToOrder === 'function' ?
              (
                activeOrders.map(order => (
                  <MenuItem
                    key={order.id}
                    eventKey={order.id}
                    onClick={() => props.addNoteToOrder(evt, order)}
                  >
                    {order.name}
                  </MenuItem>
                ))
              )
              : null
            }
          </DropdownButton>
          {typeof connectDragSource === 'function' && (
                      connectDragSource(<i className="drag-handle note-drag-handle fa fa-th" />)
                  )}
          <div className="event-row-header-date-time">
            {`${moment(evt.created || call.created, DATE_FORMAT).format('DD.MM.YY HH:mm')} Uhr`}
          </div>
        </div>
        <div className="event-row-body">
          <div className="event-row-text no-flex">
            {evt.pictures && evt.pictures.length ? (
              <ImageGallery
                images={evt.pictures.map(pic => ({ src: pic.path, thumbnail: pic.path }))}
              />
            ) : null}
            {editEvt ? (
              <EventNoteForm
                evt={evt}
                onSubmit={(formData) => {
                  const event = { ...evt };
                  if (call && call.order_id !== 0) {
                    event.order_id = call.order_id;
                  }
                  props.updateEvent(event, formData);
                }}
                cancelEdit={props.stopEdit}
                initialValues={{ text: evt.text }}
              />
            ) : text}
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state, props) => ({
    editEvt: props.evt ? state.eventEdit[props.evt.id] || null : null,
  }),
  (dispatch, props) => ({
    editNote: () => dispatch(eventEditStart(props.evt)),
    stopEdit: () => dispatch(eventEditStop(props.evt.id)),
  })
)(injectIntl(EventNote));
