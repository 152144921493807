import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';

import FormGroup2 from '../form/FormGroup';
import FormError from '../form/FormError';
import FormSubmit from '../form/FormSubmit';
import TextField from '../form/TextField';

const messages = defineMessages({
  PLACEHOLDER_COMPANY_NAME: {
    id: 'CompanyUpdateComponent.PLACEHOLDER_COMPANY_NAME',
    defaultMessage: 'Unternehmensname',
  },
  LABEL_APPLY_COMPANY: {
    id: 'CompanyUpdateComponent.LABEL_APPLY_COMPANY',
    defaultMessage: 'Unternehmen anlegen',
  },
  VALIDATION_FIELD_REQUIRED: {
    id: 'CompanyUpdateComponent.VALIDATION_FIELD_REQUIRED',
    defaultMessage: 'Dieses Feld ist erforderlich.',
  },
});

function CompanyCreate(props) {
  const {
    onSubmit,
    intl: { formatMessage },
  } = props;
  return (
    <Form
      onSubmit={onSubmit}
      validate={values => {
        const errors = {};
        if (!values.name) {
         errors.name = formatMessage(messages.VALIDATION_FIELD_REQUIRED);
       }
        return errors;
      }}
    >
      {({ submitError, handleSubmit, submitting }) =>
        <form onSubmit={handleSubmit}>
          <Field
            name="name"
          >
            {({ input, meta }) => (
              <FormGroup2 input={input} meta={meta}>
                <TextField
                  {...input}
                  autoFocus
                  autoComplete="off"
                  tabIndex="1"
                  placeholder={formatMessage(messages.PLACEHOLDER_COMPANY_NAME)}
                />
              </FormGroup2>
            )}
          </Field>
          <FormError error={submitError} />
          <FormSubmit
            btnClass="lh-btn lh-btn-primary"
            btnProps={{ tabIndex: 2 }}
            submitting={submitting}
            label={formatMessage(messages.LABEL_APPLY_COMPANY)}
          />
        </form>
      }
    </Form>
  );
}

export default injectIntl(CompanyCreate);
