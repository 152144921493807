import ReactDOM from 'react-dom';

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';


import { browserHistory } from 'react-router';
import { routerMiddleware } from 'react-router-redux';

import promiseMiddleware from 'redux-promise';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const batchedUpdatesMiddleware = () => next => action => ReactDOM.unstable_batchedUpdates(() => next(action));

const middlewares = [
  thunkMiddleware,
  promiseMiddleware,
  sagaMiddleware,
  routerMiddleware(browserHistory),
  batchedUpdatesMiddleware,
];

if (process.env.NODE_ENV !== 'production') {
  /* eslint-disable import/no-extraneous-dependencies */
  /* eslint-disable global-require */
  const createLoggerMiddleware = require('redux-logger');
  const loggerMiddleware = createLoggerMiddleware({
    duration: true,
    collapsed: true,
    predicate: (getState, action) => [
      'AUDIO_VOLUME',
      'REMOTE_AUDIO_VOLUME',
      'OFFLINE_STATUS',
      'HEART_BEAT',
    ].indexOf(action && action.type) === -1,
  });
  middlewares.push(loggerMiddleware);
}

export default (reducer, initialState) => {
  const composeEnhancers = composeWithDevTools({
    actionsBlacklist: [
      'AUDIO_VOLUME',
      'REMOTE_AUDIO_VOLUME',
      'OFFLINE_STATUS',
      'HEART_BEAT',
    ],
  });
  const store = createStore(
      reducer,
      initialState,
      composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);
  return store;
};
