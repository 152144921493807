import React, { PureComponent } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';

import FormGroup2 from 'components/form/FormGroup';
import FormError from 'components/form/FormError';
import FormSubmit from 'components/form/FormSubmit';
import TextField from 'components/form/TextField';
import CheckBoxField from 'components/form/CheckBoxField';
import { connect } from 'react-redux';
import _ from 'lodash';

const messages = defineMessages({
  PLACEHOLDER_NAME: {
    id: 'CompanyUserUpdateForm.PLACEHOLDER_NAME',
    defaultMessage: 'Name',
  },
  PLACEHOLDER_EMAIL: {
    id: 'CompanyUserUpdateForm.PLACEHOLDER_EMAIL',
    defaultMessage: 'E-Mail-Adresse',
  },
  PLACEHOLDER_PHONE: {
    id: 'CompanyUserUpdateForm.PLACEHOLDER_PHONE',
    defaultMessage: 'Telefonnummer',
  },
  LABEL_OWNER: {
    id: 'CompanyUserUpdateForm.LABEL_OWNER',
    defaultMessage: 'Dieser Benutzer ist Besitzer des Unternehmens',
  },
  LABEL_ADMIN: {
    id: 'CompanyUserUpdateForm.LABEL_ADMIN',
    defaultMessage: 'Dieser Benutzer ist Administrator des Unternehmens',
  },
  LABEL_INACTIVE: {
    id: 'CompanyUserUpdateForm.LABEL_INACTIVE',
    defaultMessage: 'Dieser Benutzer ist inaktiv und darf sich nicht einloggen',
  },
  LABEL_SAVE_CHANGES: {
    id: 'CompanyUserUpdateForm.LABEL_SAVE_CHANGES',
    defaultMessage: 'Änderungen speichern',
  },
  VALIDATION_FIELD_REQUIRED: {
    id: 'CompanyUserUpdateForm.VALIDATION_FIELD_REQUIRED',
    defaultMessage: 'Dieses Feld ist erforderlich.',
  },
  VALIDATION_EMAIL_OR_PHONE: {
    id: 'CompanyUserUpdateForm.VALIDATION_EMAIL_OR_PHONE',
    defaultMessage: 'E-Mail oder Telefonnummer ist erforderlich.',
  },
});

/*
@injectIntl
@connect(state => state.auth.user)
@reduxForm({
    form: 'companyUserUpdate',
    fields: [
        'name',
        'email',
        'phone',
        'is_owner',
        'is_admin',
        'is_inactive',
    ],
    validate: (values, { intl: { formatMessage } }) => ({
        name: values.name ? null : formatMessage(messages.VALIDATION_FIELD_REQUIRED),
        _error: values.email || values.phone ? null : formatMessage(messages.VALIDATION_EMAIL_OR_PHONE),
    }),
    touchOnBlur: false,
})
 */

class CompanyUserUpdateForm extends PureComponent {
    render() {
        const {
            is_admin: showIsOwnerField,  // global admin can set is_owner field
            intl: { formatMessage },
        } = this.props;

        //console.log(this.props);

        return (
          <Form
            onSubmit={this.props.onSubmit}
            validate={values => {
              const errors = {};
              if (!values.name) {
                errors.name = formatMessage(messages.VALIDATION_FIELD_REQUIRED);
              }
              if (!values.email && !values.phone) {
                errors.phone = formatMessage(messages.VALIDATION_EMAIL_OR_PHONE);
              }

              return errors;
            }}
            initialValues={{
              ...this.props
            }}
          >
            {({ handleSubmit, submitError, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="name"
                >
                  {({ input, meta }) => {
                    return (
                      <FormGroup2 input={input} meta={meta}>
                        <TextField
                          {...input}
                          tabIndex="1"
                          autoFocus
                          autoComplete="off"
                          placeholder={formatMessage(messages.PLACEHOLDER_NAME)}
                        />
                      </FormGroup2>
                    )
                  }}
                </Field>
                <Field
                  name="email"
                >
                  {({ input, meta }) => {
                    return (
                      <FormGroup2 input={input} meta={meta}>
                        <TextField
                          {...input}
                          tabIndex="2"
                          autoComplete="off"
                          autoCapitalize="off"
                          autoCorrect="off"
                          placeholder={formatMessage(messages.PLACEHOLDER_EMAIL)}
                        />
                      </FormGroup2>
                    )
                  }}
                </Field>
                <Field
                  name="phone"
                >
                  {({ input, meta }) => {
                    return (
                      <FormGroup2 input={input} meta={meta}>
                        <TextField
                          {...input}
                          tabIndex="3"
                          autoComplete="off"
                          placeholder={formatMessage(messages.PLACEHOLDER_PHONE)}
                        />
                      </FormGroup2>
                    )
                  }}
                </Field>
                {showIsOwnerField ? (
                  <Field
                    name="is_owner"
                    type="checkbox"
                  >
                    {({ input, meta }) => {
                      return (
                        <FormGroup2 input={input} meta={meta}>
                            <CheckBoxField
                                {...input}
                                tabIndex="4"
                                label={formatMessage(messages.LABEL_OWNER)}
                            />
                        </FormGroup2>
                      )
                    }}
                  </Field>
                ) : null}
                <Field
                  name="is_admin"
                  type="checkbox"
                >
                  {({ input, meta }) => {
                    return (
                      <FormGroup2 input={input} meta={meta}>
                          <CheckBoxField
                              {...input}
                              tabIndex="5"
                              label={formatMessage(messages.LABEL_ADMIN)}
                          />
                      </FormGroup2>
                    )
                  }}
                </Field>
                <Field
                  name="is_inactive"
                  type="checkbox"
                >
                  {({ input, meta }) => {
                    return (
                      <FormGroup2 input={input} meta={meta}>
                          <CheckBoxField
                              {...input}
                              tabIndex="5"
                              label={formatMessage(messages.LABEL_INACTIVE)}
                          />
                      </FormGroup2>
                    )
                  }}
                </Field>
                <FormError error={submitError} />
                <FormSubmit
                    btnClass="lh-btn lh-btn-primary lh-btn-block"
                    btnProps={{ tabIndex: 6 }}
                    submitting={submitting}
                    label={formatMessage(messages.LABEL_SAVE_CHANGES)}
                />
              </form>
            )}
          </Form>
        );
    }
}

export default connect(
  state => state.auth.user
)(injectIntl(CompanyUserUpdateForm));
