import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Link } from 'react-router';
import { FORM_ERROR } from 'final-form';

import { updateSettings2, updated } from '../../actions/auth';
import NavBarConfig from '../../components/NavBarConfig';
import SettingsForm from '../../components/forms/SettingsForm';

const messages = defineMessages({
  PROPERTIES: {
    id: 'SettingsPage.PROPERTIES',
    defaultMessage: 'Einstellungen',
  },
  CHANGE_PASSWORD: {
    id: 'SettingsPage.CHANGE_PASSWORD',
    defaultMessage: 'Passwort ändern',
  },
});

function SettingsPage({ user, onSubmit, route, intl: { formatMessage } }) {
  return (
    <div>
      <NavBarConfig currentRouteName={route.name} />
      <div className="m40 w-xs">
        <h3>{formatMessage(messages.PROPERTIES)}</h3>
        <Link
          to="/settings/password/"
          className="lh-btn lh-btn-primary lh-btn-block"
          style={{ marginBottom: '10px' }}
        >
          {formatMessage(messages.CHANGE_PASSWORD)}
        </Link>
        <SettingsForm
          onSubmit={onSubmit}
          initialValues={{
            name: user.name || '',
            email: user.email || '',
            phone: user.phone || '',
          }}
        />
      </div>
    </div>
  );
}

export default connect(null,
  dispatch => ({
    onSubmit: async formData => {
      const data = await updateSettings2({
          ...formData,
          email: formData.email || undefined,
          phone: formData.phone || undefined,
        }
      );
      if (data.response) {
        const user = data.response;
        dispatch(updated(user));
        dispatch(push('/'));
      } else {
        return { [FORM_ERROR]: data.error[Object.keys(data.error)[0]] };
      }
    },
  })
)(injectIntl(SettingsPage));
