import React from 'react';

import { connect } from 'react-redux';
import { replace } from 'react-router-redux';


@connect(null,
    dispatch => ({
        redirect: path => dispatch(replace(path)),
    })
)

export default class CompanySinglePage extends React.PureComponent {
    componentWillMount() {
        const { company, companyMembership, redirect } = this.props;
        if (!company || !companyMembership) { return; }
        // if (!companyMembership.is_owner && !companyMembership.is_admin) {
        redirect(`/company/${company.id}/customer/`);
        // }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.company && nextProps.companyMembership) {
            nextProps.redirect(`/company/${nextProps.company.id}/customer/`);
        }
    }
    render() {
        return null;
    }
}
