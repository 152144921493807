import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { classnames } from 'classnames';

import { sendSignal } from '../../../actions/ot_signaling';

const messages = defineMessages({
  REQUEST_UNLOCK_SOUND: {
    id: 'AudioPrompt.REQUEST_UNLOCK_SOUND',
    defaultMessage: 'Ihr Gesprächspartner würde gern den Ton freischalten.',
  },
  ASSENT: {
    id: 'AudioPrompt.ASSENT',
    defaultMessage: 'Wenn Sie einverstanden sind, klicken Sie auf "Einverstanden", um den Ton freizuschalten.',
  },
  UNLOCK_SOUND: {
    id: 'AudioPrompt.UNLOCK_SOUND',
    defaultMessage: 'Ton freischalten',
  },
  DECLINE_AUDIO: {
    id: 'AudioPrompt.DECLINE_AUDIO',
    defaultMessage: 'Ablehnen',
  },
  MODAL_CONTENT_LABEL: {
    id: 'AudioPrompt.MODAL_CONTENT_LABEL',
    defaultMessage: 'Freischaltanfrage für Tonverbindung',
  },
});

export function AudioPrompt(props) {
  const {
    requested,
    intl: { formatMessage }
  } = props;

  if (!requested) {
    return null;
  }

  return (
    <div className="audio-request-modal">
      <div className="content">
        <i
          className="fa fa-close close-icon"
          onClick={() => sendSignal('audio-declined')}
        />

        <h3>{formatMessage(messages.REQUEST_UNLOCK_SOUND)}</h3>

        <p>
          {formatMessage(messages.ASSENT)}
        </p>

        <div className="buttons">
          <button
            className="lh-btn lh-btn-primary"
            onClick={() => sendSignal('audio-accepted')}
          >
            {formatMessage(messages.UNLOCK_SOUND)}
          </button>

          <button
            className="lh-btn lh-btn-default"
            onClick={() => sendSignal('audio-declined')}
          >
            {formatMessage(messages.DECLINE_AUDIO)}
          </button>
        </div>
      </div>
    </div>
  );
}

export default connect(state => (
  {
    //requested: state.audio.remote.requested
    requested: state.video.room.self.audio.requested
  }
  ))(
  injectIntl(AudioPrompt)
);
