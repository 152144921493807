import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { Link } from 'react-router';
import { push } from 'react-router-redux';
import { createCompany, companyCreated } from 'actions/company';

import NotFound from 'components/NotFound';
import NavBarConfig from 'components/NavBarConfig';
import CompanyCreate from 'components/company/CompanyCreate';

const messages = defineMessages({
  BACK: {
    id: 'CompanyCreatePage.BACK',
    defaultMessage: 'Zurück',
  },
  CREATE_COMPANY: {
    id: 'CompanyCreatePage.CREATE_COMPANY',
    defaultMessage: 'Neues Unternehmen anlegen',
  },
});

@injectIntl
@connect(
    state => ({ locale: state.intl.locale }),
    dispatch => ({
        submit: formData => createCompany(formData).then(company => {
            dispatch(companyCreated(company));
            dispatch(push(`/company/${company.id}/`));
        }),
    })
)

export default class CompanyCreatePage extends React.PureComponent {
    render() {
        const { locale, user, intl: { formatMessage } } = this.props;
        if (!user.is_admin && !user.is_staff) {
            return <NotFound />;
        }
        return (
            <div>
                <NavBarConfig
                  companyList
                  createPage
                  currentRouteName={this.props.routes[this.props.routes.length - 1].name}
                />
                <div className="m40 w-xs">
                    <Link to="/">{formatMessage(messages.BACK)}</Link>
                    <h3>{formatMessage(messages.CREATE_COMPANY)}</h3>
                    <CompanyCreate
                      initialValues={{ locale }}
                      onSubmit={this.props.submit}
                    />
                </div>
            </div>
        );
    }
}
