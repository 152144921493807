import { createAction, handleActions } from 'redux-actions';

const ORDEREDIT_START = 'ORDEREDIT_START';
const ORDEREDIT_STOP = 'ORDEREDIT_STOP';

export const orderEditStart = createAction(ORDEREDIT_START);
export const orderEditStop = createAction(ORDEREDIT_STOP);

const orderEdit = handleActions({
  [ORDEREDIT_START]: (state, action) => ({
    ...state,
    [action.payload.id]: action.payload,
  }),
  [ORDEREDIT_STOP]: (state, action) => {
    const newState = { ...state };
    delete newState[action.payload];
    return newState;
  },
}, {});

export default orderEdit;
