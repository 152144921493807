import { createAction, handleActions } from 'redux-actions';

const EVENTEDIT_START = 'EVENTEDIT_START';
const EVENTEDIT_STOP = 'EVENTEDIT_STOP';

export const eventEditStart = createAction(EVENTEDIT_START);
export const eventEditStop = createAction(EVENTEDIT_STOP);

const eventEdit = handleActions({
  [EVENTEDIT_START]: (state, action) => ({
    ...state,
    [action.payload.id]: action.payload,
  }),
  [EVENTEDIT_STOP]: (state, action) => {
    const newState = { ...state };
    delete newState[action.payload];
    return newState;
  },
}, {});

export default eventEdit;
