import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';

import { availableLocales } from 'actions/intl';
import FormGroup2 from '../../../components/form/FormGroup';
import FormError from '../../../components/form/FormError';
import FormSubmit from '../../../components/form/FormSubmit';
import classNames from "classnames";

const messages = defineMessages({
  FIELD_REQUIRED: {
    id: 'CustomerCreateForm.FIELD_REQUIRED',
    defaultMessage: 'Dieses Feld ist erforderlich.',
  },
  PLACEHOLDER_CUSTOMER_NAME: {
    id: 'CustomerCreateForm.PLACEHOLDER_CUSTOMER_NAME',
    defaultMessage: 'Kundenname',
  },
  INDICATE_PHONE_NUMBER: {
    id: 'CustomerCreateForm.INDICATE_PHONE_NUMBER',
    defaultMessage: 'Bitte geben sie hier die mobile Telefonnummer des neuen Kunden an (Optional). ' +
      'Standardmäßig wird der Kunde zu einem Videogespräch per SMS eingeladen.',
  },
  COUNTRY_CODE: {
    id: 'CustomerCreateForm.COUNTRY_CODE',
    defaultMessage: 'Die mobile Telefonnummer wird ohne Ländervorwahl als deutsche Telefonnummer interpretiert.',
  },
  PLACEHOLDER_PHONE_NUMBER: {
    id: 'CustomerCreateForm.PLACEHOLDER_PHONE_NUMBER',
    defaultMessage: 'Mobile Telefonnummer',
  },
  LABEL_APPLY_CUSTOMER: {
    id: 'CustomerCreateForm.LABEL_APPLY_CUSTOMER',
    defaultMessage: 'Kunde neu anlegen',
  },
  LABEL_LANGUAGE: {
    id: 'CustomerCreateForm.LABEL_LANGUAGE',
    defaultMessage: 'Sprache des Kunden',
  },
  LOCALE_DE: {
    id: 'CustomerCreateForm.LOCALE_DE',
    defaultMessage: 'Deutsch',
  },
  LOCALE_EN: {
    id: 'CustomerCreateForm.LOCALE_EN',
    defaultMessage: 'Englisch',
  },
});

function CustomerCreateForm(props) {
  const {
    intl: { formatMessage },
  } = props;

  const locales = availableLocales.map(locale => ({
    value: locale,
    label: formatMessage(messages[`LOCALE_${locale.toUpperCase()}`]),
  }));

  const onSubmitLocal = (data) => {
    const dataSubmit = {
      name: data.name,
      phone: data.phone,
      locale: data.locale.value
    }
    return props.onSubmit(dataSubmit);
  }

  return (<Form
    onSubmit={onSubmitLocal}
    validate={values => {
      const errors = {};
      if (!values.name) {
        errors.name = formatMessage(messages.FIELD_REQUIRED);
      }

      return errors;
    }}
  >
    {({ submitError, handleSubmit, pristine, submitting }) => (
      <form onSubmit={handleSubmit} >
        <Field name="name">
          {({ input, meta }) => {
            return (
              <FormGroup2 input={input} meta={meta}>
                <input
                  {...input}
                  id={props.name}
                  className="form-control"
                  tabIndex="1"
                  placeholder={formatMessage(messages.PLACEHOLDER_CUSTOMER_NAME)}
                  autoFocus
                  autoComplete="off"
                />
              </FormGroup2>
          )
          }}
        </Field>
        <p className="m40 w-xs">
          {formatMessage(messages.INDICATE_PHONE_NUMBER)}
          <br />
          <br />
          {formatMessage(messages.COUNTRY_CODE)}
        </p>
        <Field name="phone">
          {({ input, meta }) => {
            return (
              <FormGroup2 input={input} meta={meta}>
                <input
                  {...input}
                  id={props.name}
                  className="form-control"
                  tabIndex="2"
                  placeholder={formatMessage(messages.PLACEHOLDER_PHONE_NUMBER)}
                  autoCapitalize="off"
                  autoCorrect="off"
                  autoComplete="off"
                />
              </FormGroup2>
            )
          }}
        </Field>
        <span>{formatMessage(messages.LABEL_LANGUAGE)}</span>
        <Field
          name="locale"
          initialValue={locales[0]}
        >
          {({ input, meta }) => {
            return (
              <FormGroup2 input={input} meta={meta}>
                <Select
                  {...input}
                  name="form-field-locale"
                  value={input.value}
                  options={locales}
                  searchable={false}
                  clearable={false}
                  tabIndex="3"
                />
              </FormGroup2>
            )
          } }
        </Field>
        {(submitError) &&
        <div className={classNames('form-group', { 'has-error': (submitError) })}>
          <div className={classNames({ 'col-sm-8': false, 'col-sm-offset-4': false })}>
                <span className="help-block">{submitError}</span>
          </div>
        </div>
        }
        <FormSubmit
          btnClass="lh-btn lh-btn-primary"
          btnProps={{ tabIndex: 4 }}
          submitting={submitting || pristine}
          label={formatMessage(messages.LABEL_APPLY_CUSTOMER)}
        />
      </form>
    )
    }
  </Form>
  );
}
// <!--<FormError error={submitError} />-->
export default injectIntl(CustomerCreateForm);
