import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';

import FormError from 'components/form/FormError';

const messages = defineMessages({
  PLACEHOLDER_WRITE_NOTE: {
    id: 'ChatInput.PLACEHOLDER_WRITE_NOTE',
    defaultMessage: 'Schreiben Sie eine Notiz',
  },
  VALUE_CREATE_NOTE: {
    id: 'ChatInput.VALUE_CREATE_NOTE',
    defaultMessage: 'Notiz erstellen',
  },
  VALIDATION_FIELD_REQUIRED: {
    id: 'ChatInput.VALIDATION_FIELD_REQUIRED',
    defaultMessage: 'Dieses Feld ist erforderlich.',
  },
});

function ChatInputComponent(props) {
  const {
    intl: { formatMessage },
  } = props;

  const onSubmitLocal = (data, form) => {
    //console.log(data);
    //console.log(form);
    return props.onSubmit(data, form);
  }

  return (
    <div className="chat-input">
      <Form
        onSubmit={onSubmitLocal}
        validate={values => {
          const errors = {};
          if (!values.text) {
            errors.text = formatMessage(messages.VALIDATION_FIELD_REQUIRED);
          }

          return errors;
        }
        }
      >
        {({ submitError, handleSubmit}) => (
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <FormError error={submitError} />
              <Field name="text">
                {({ input, meta }) => {
                  return (
                    <input
                      {...input}
                      className="form-control"
                      type="text"
                      placeholder={formatMessage(messages.PLACEHOLDER_WRITE_NOTE)}
                      autoComplete="off"
                    />
                  )
                }}
              </Field>
              <div className="input-group-btn">
                <input
                  className="btn btn-default"
                  type="submit"
                  value={formatMessage(messages.VALUE_CREATE_NOTE)}
                />
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
}

export default injectIntl(ChatInputComponent);
