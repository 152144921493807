import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  APP: {
    id: 'IOSAppPage.APP',
    defaultMessage: 'Haben Sie die tele-LOOK App noch nicht?',
  },
  QUESTIONS: {
    id: 'IOSAppPage.QUESTIONS',
    defaultMessage: 'Bei Fragen kontaktieren Sie bitte',
  },
});

/* global localStorage */

class IOSAppPage extends React.Component {
  componentWillMount() {
    this.props.saveRoom(this.props.routeParams.roomName);
  }

  render() {
    const { intl: { formatMessage } } = this.props;

    return (
      <div className="flex">
        <div style={{ background: '#fff', maxWidth: 480 }} className="content-block home-content">
          <h4 style={{ margin: 0 }}>
            {formatMessage(messages.APP)}
          </h4>
          <div className="text-center" style={{ margin: '40px 0' }}>
            <a className="lh-btn lh-btn-primary" href="https://itunes.apple.com/us/app/tele-look/id1136692572?l=de&ls=1&mt=8">tele-LOOK installieren</a>
          </div>
          <div>
            {formatMessage(messages.QUESTIONS)} <a href="mailto:support@tele-look.com">support@tele-look.com</a>.
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  () => ({
    saveRoom: (room) => {
      try {
        localStorage.setItem('ios-slug', room);
        return true;
      } catch (e) {
        return false;
        // local storage is not available in private mode, this is to prevent errors
      }
    },
  })
)(injectIntl(IOSAppPage));
