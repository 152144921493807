/* global window */
/* global document */
import React from 'react';
import moment from 'moment';
import { defineMessages, injectIntl } from 'react-intl';

import { getAuthToken } from 'actions/api';

const messages = defineMessages({
  EMPTY_MESSAGE: {
    id: 'BillingDownloadList.EMPTY_MESSAGE',
    defaultMessage: 'Es liegen noch keine Einzelverbindungsnachweise zum Download bereit. Die Verbindungen des aktuellen Monats können Sie unten einsehen.',
  },
  MONTH: {
    id: 'BillingDownloadList.MONTH',
    defaultMessage: 'Monat',
  },
  PDF: {
    id: 'BillingDownloadList.PDF',
    defaultMessage: 'PDF',
  },
});

function BillingDownloadList({
  downloads,
  companyId,
  intl: { formatMessage },
}) {
  const token = `Bearer ${getAuthToken()}`;

  if (downloads.length === 0) {
    return <p>{formatMessage(messages.EMPTY_MESSAGE)}</p>;
  }

  return (
    <table className="table table-condensed table-striped">
      <thead>
        <tr>
          <th>{formatMessage(messages.MONTH)}</th>
          <th>{formatMessage(messages.PDF)}</th>
        </tr>
      </thead>
      <tbody>
        {downloads.map(download => (
          <tr key={download.month}>
            <td>
              {moment(download.month).format('MMMM YYYY')}
            </td>
            <td>
              <a
                onClick={() => {
                  document.cookie = `Authorization=${token}; path=/api/`;
                  window.location = `/api/company/${companyId}/billing/${download.id}`;
                }}
              >
                {formatMessage(messages.PDF)}
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default injectIntl(BillingDownloadList);
