import React from 'react';
import classnames from 'classnames';

export default function VideoState({
    videoActive,
    videoPaused,
}) {
  const active = videoActive;
  const paused = videoActive && videoPaused;
  return (
    <div className={classnames('video-state', { active, paused })}>
      <span className="fa-stack fa-lg">
        <span className="fa fa-video-camera fa-stack-1x" />
        <span className="fa fa-ban fa-stack-2x" />
      </span>
    </div>
  );
}
