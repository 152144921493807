import React, { PureComponent } from 'react';
import Error from '../components/Error';
import NotFound from '../components/NotFound';
import Loading from '../components/Loader';

import { get } from './api';


export default config => Component => class Container extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      error: null,
      responses: null,
    };
  }

  componentWillMount() {
    this.setState({ isFetching: true });
  }

  componentDidMount() {
    let cnf = config;
    if (typeof config === 'function') {
      cnf = cnf(this.props);
    }

    Promise.all(Object.values(cnf).map(get)).then(
      responses => this.setState({
        isFetching: false,
        responses: Object.keys(cnf).reduce((o, c, i) => ({ ...o, [c]: responses[i] }), {}),
      }),
      error => this.setState({ isFetching: false, error }),
    );
  }

  render() {
    if (this.state.isFetching) {
      return <Loading />;
    }

    if (this.state.error) {
      if (this.state.error._error) {
        return <Error error={this.state._error} />;
      }
      return <NotFound />;
    }

    return <Component {...this.props} {...this.state.responses} />;
  }
};
