import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Link } from 'react-router';

import { createCustomer2, createCustomer, customerCreated } from '../../actions/company';
import NavBarConfig from '../../components/NavBarConfig';
import CustomerCreate from './components/CustomerCreate';

const messages = defineMessages({
  BACK: {
    id: 'CustomerCreatePage.BACK',
    defaultMessage: 'Zurück',
  },
  APPLY_CUSTOMER: {
    id: 'CustomerCreatePage.APPLY_CUSTOMER',
    defaultMessage: 'Kunde anlegen',
  },
});

function CustomerCreatePage({
  company,
  companyMembership,
  onSubmit,
  routes,
  intl: { formatMessage },
}) {
  if (!company || !companyMembership) {
    return null;
  }
  return (
    <div>
      <NavBarConfig
        createPage
        currentRouteName={routes[routes.length - 1].name}
      />
      <div className="m40 w-xs">
        <Link to={`/company/${company.id}/customer/`}>
          {formatMessage(messages.BACK)}
        </Link>
        <h3>{formatMessage(messages.APPLY_CUSTOMER)}</h3>
        <CustomerCreate onSubmit={onSubmit} initialValues={{ locale: 'de' }} />
      </div>
    </div>
  );
}

export default connect(null, (dispatch, props) => ({
  onSubmit: formData =>
    createCustomer2(props.params.companyId, formData)
      .then(response => {
        if(response.error == null){
          const customer = response.response;
          //console.log('create customer');
          //console.log(customer);
          const url = `/company/${customer.company_id}/customer/${customer.id}/`;
          //console.log(url);
          dispatch(customerCreated(customer));
          dispatch(
            push(`/company/${customer.company_id}/customer/${customer.id}/`)
          );
        }
    }),
}))(injectIntl(CustomerCreatePage));
