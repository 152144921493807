import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { companyUserList } from 'actions/company';

import { Link } from 'react-router';
import NotFound from 'components/NotFound';
import NavBarConfig from 'components/NavBarConfig';
import CompanyUserList from 'components/companyUser/CompanyUserList';

const messages = defineMessages({
  BACK: {
    id: 'CompanyUserListPage.BACK',
    defaultMessage: 'Zurück',
  },
  USER: {
    id: 'CompanyUserListPage.USER',
    defaultMessage: 'Benutzer',
  },
  APPLY_USER: {
    id: 'CompanyUserListPage.APPLY_USER',
    defaultMessage: 'Benutzer anlegen',
  },
});

@injectIntl
@connect(
    state => state.companyUserList,
    (dispatch, props) => ({
        load: () => dispatch(companyUserList.load(props.params.companyId)),
    })
)

export default class CompanyUserListPage extends React.PureComponent {
    componentWillMount() {
        this.props.load();
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.params.companyId !== nextProps.params.companyId) {
            nextProps.load();
        }
    }
    render() {
        const { user, company, companyMembership, results, routes, intl: { formatMessage } } = this.props;
        if (!company || !companyMembership) { return null; }
        if (!user.is_admin && !user.is_staff &&
          !companyMembership.is_owner && !companyMembership.is_admin) {
          return <NotFound />;
        }
        return (
          <div>
            <NavBarConfig companyUserList currentRouteName={routes[routes.length - 1].name} />
            <div className="m40">
              <Link to={`/company/${company.id}/`}>{formatMessage(messages.BACK)}</Link>
              <h3>{formatMessage(messages.USER)}</h3>
              <Link className="lh-btn lh-btn-primary top" to={`/company/${company.id}/user/create/`}>
                <span className="fa fa-user-plus" />
                {' '}{formatMessage(messages.APPLY_USER)}
              </Link>
              <CompanyUserList style={{ marginTop: 20 }} items={results} />
            </div>
          </div>
        );
    }
}
