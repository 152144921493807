/* global localStorage */
import { createAction, handleActions } from 'redux-actions';
//import { addLocaleData } from 'react-intl';
import moment from 'moment';

//import localeDataDe from 'react-intl/locale-data/de';
//import localeDataEn from 'react-intl/locale-data/en';

import messagesDe from '../lang/de-DE.json';
import messagesEn from '../lang/en.json';

const MESSAGES = {
  de: messagesDe,
  en: messagesEn,
};

//addLocaleData(localeDataDe);
//addLocaleData(localeDataEn);

function readNavigatorLocale() {
  /* global navigator */
  if (navigator.languages) {
    const languages = navigator.languages.map(language =>
      language.substring(0, 2)
    );
    return languages.find(language => MESSAGES[language] !== undefined);
  } else if (navigator.browserLanguage) {
    const language = navigator.browserLanguage.substring(0, 2);
    if (MESSAGES[language]) {
      return language;
    }
  }
}

function readUserLocale() {
  try {
    return localStorage.getItem('userLocale');
  } catch (e) {
    return null;
  }
}

function determineLocale() {
  return readUserLocale() || readNavigatorLocale() || 'en';
}

function getStateForLocale(locale) {
  return {
    locale,
    key: locale, // work-around to trigger rerendering
    messages: MESSAGES[locale] || messagesEn,
  };
}

const SET_LOCALE = 'SET_LOCALE';
const setLocale = createAction(SET_LOCALE);

export const availableLocales = Object.keys(MESSAGES);

export function changeLanguage(locale) {
  return dispatch => {
    dispatch(setLocale(locale));
    moment.locale(locale);
    try {
      localStorage.setItem('userLocale', locale);
    } catch (e) {
      // needed for safari
    }
  };
}

export default handleActions(
  {
    [SET_LOCALE]: (state, { payload }) => getStateForLocale(payload),
  },
  getStateForLocale(determineLocale())
);
