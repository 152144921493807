/* global window */
/* global document */
/* global Blob */
import { createAction, handleActions } from 'redux-actions';
import { post } from './api';

import { RESET_VIDEO } from './constants';
import { eventCreated } from './company';
import { getPublisher, getSubscription, sendSignal } from './ot_signaling';


const SCREENSHOT_REQUESTED = 'SCREENSHOT_REQUESTED';
const SCREENSHOT_TAKEN = 'SCREENSHOT_TAKEN';
const SCREENSHOT_UPLOADED = 'SCREENSHOT_UPLOADED';
const SCREENSHOT_FAILED = 'SCREENSHOT_FAILED';
const SCREENSHOT_NOT_SUPPORTED = 'SCREENSHOT_NOT_SUPPORTED';
const SCREENSHOT_RESET = 'SCREENSHOT_RESET';

const REMOTE_SCREENSHOT_REQUESTED = 'REMOTE_SCREENSHOT_REQUESTED';
const REMOTE_SCREENSHOT_TAKEN = 'REMOTE_SCREENSHOT_TAKEN';
const REMOTE_SCREENSHOT_UPLOADED = 'REMOTE_SCREENSHOT_UPLOADED';
const REMOTE_SCREENSHOT_FAILED = 'REMOTE_SCREENSHOT_FAILED';
const REMOTE_SCREENSHOT_NOT_SUPPORTED = 'REMOTE_SCREENSHOT_NOT_SUPPORTED';
const REMOTE_SCREENSHOT_RESET = 'REMOTE_SCREENSHOT_RESET';


export const screenshotRequested = createAction(SCREENSHOT_REQUESTED);
export const screenshotTaken = createAction(SCREENSHOT_TAKEN);
export const screenshotUploaded = createAction(SCREENSHOT_UPLOADED);
export const screenshotFailed = createAction(SCREENSHOT_FAILED);
export const screenshotNotSupported = createAction(SCREENSHOT_NOT_SUPPORTED);
export const screenshotReset = createAction(SCREENSHOT_RESET);

export const remoteScreenshotRequested = createAction(REMOTE_SCREENSHOT_REQUESTED);
export const remoteScreenshotTaken = createAction(REMOTE_SCREENSHOT_TAKEN);
export const remoteScreenshotUploaded = createAction(REMOTE_SCREENSHOT_UPLOADED);
export const remoteScreenshotFailed = createAction(REMOTE_SCREENSHOT_FAILED);
export const remoteScreenshotNotSupported = createAction(REMOTE_SCREENSHOT_NOT_SUPPORTED);
export const remoteScreenshotReset = createAction(REMOTE_SCREENSHOT_RESET);

export function screenshotResetWithTimeout() {
  return dispatch => {
    setTimeout(() => {
      dispatch(screenshotReset());
      dispatch(remoteScreenshotReset());
    }, 5000);
  };
}

const screenshotEmptyState = '';
export const screenshotReducer = handleActions({
  [SCREENSHOT_REQUESTED]: () => 'requested',
  [SCREENSHOT_TAKEN]: () => 'taken',
  [SCREENSHOT_UPLOADED]: () => 'uploaded',
  [SCREENSHOT_FAILED]: () => 'failed',
  [SCREENSHOT_NOT_SUPPORTED]: () => 'not_supported',
  [SCREENSHOT_RESET]: () => screenshotEmptyState,
  [RESET_VIDEO]: () => screenshotEmptyState,
}, screenshotEmptyState);

const remoteScreenshotEmptyState = '';
export const remoteScreenshotReducer = handleActions({
  [REMOTE_SCREENSHOT_REQUESTED]: () => 'requested',
  [REMOTE_SCREENSHOT_TAKEN]: () => 'taken',
  [REMOTE_SCREENSHOT_UPLOADED]: () => 'uploaded',
  [REMOTE_SCREENSHOT_FAILED]: () => 'failed',
  [REMOTE_SCREENSHOT_NOT_SUPPORTED]: () => 'not_supported',
  [REMOTE_SCREENSHOT_RESET]: () => remoteScreenshotEmptyState,
  [RESET_VIDEO]: () => remoteScreenshotEmptyState,
}, '');

const createPicture = (companyId, customerId, callId, image) => post({
  url: `/api/company/${companyId}/customer/${customerId}/call/${callId}/picture/`,
  attachments: { image },
});

export const createPictureCustomer = (room, file, progressCallback) => post({
  url: `/api/room/${room}/picture/`,
  attachments: { file },
}, progressCallback);


export function convertDataUriToImage(dataUri) {
  const data = dataUri.split(',')[1];
  const mimeType = dataUri.split(';')[0].slice(5);

  const bytes = window.atob(data);
  const buf = new ArrayBuffer(bytes.length);
  const arr = new Uint8Array(buf);
  for (let i = 0; i < bytes.length; i++) {
    arr[i] = bytes.charCodeAt(i);
  }

  return new Blob([arr], { type: mimeType });
}
/*
export function captureScreenshot(companyId, customerId) {
  return (dispatch, getState) => {
    const subscription = getSubscription();
    if (!subscription) {
      // TODO --> Meldung an Benutzer zeigen?
      return;
    }
    const state = getState();
    const canvas = document.createElement('canvas');
    const vWidth = subscription.videoWidth();
    const vHeight = subscription.videoHeight();
    const video = subscription.videoElement();
    canvas.setAttribute('width', vWidth);
    canvas.setAttribute('height', vHeight);
    canvas.width = vWidth;
    canvas.height = vHeight;
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, vWidth, vHeight);
    const blob = convertDataUriToImage(canvas.toDataURL('image/png'));
    const { video: { activeCall: { call: { id } } } } = state;

    createPicture(companyId, customerId, id, blob).then((event) => {
      dispatch(eventCreated(event));
    });

    video.style.display = 'none';
    setTimeout(() => { video.style.display = ''; }, 100);
  };
}
*/
// called by publisher
export function takePublisherScreenshot(slug) {
  const publisher = getPublisher();
  const image = convertDataUriToImage(`data:image/png;base64,${publisher.getImgData()}`);
  sendSignal('screenshot-taken');
  createPictureCustomer(slug, image)
    .then(() => sendSignal('screenshot-uploaded'))
    .catch(() => sendSignal('screenshot-failed'));
}

// called by subscriber
export function requestScreenshot() {
  return () => sendSignal('screenshot-request');
}
