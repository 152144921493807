import { Children } from 'react';
import withSideEffect from 'react-side-effect';
import { store } from '../main';
import { setNavbarConfig } from '../actions/navbar';

function NavBarConfig(props) {
  return props.children ? Children.only(props.children) : null;
}

function reducePropsToState(propsList) {
  return propsList.reduce((props, item) => ({ ...props, ...item, children: undefined }), {});
}

function handleStateChangeOnClient(props) {
  store.dispatch(setNavbarConfig(props));
}

export default withSideEffect(reducePropsToState, handleStateChangeOnClient)(NavBarConfig);
