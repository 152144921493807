import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router';

const messages = defineMessages({
  PASSWORD_CHANGED: {
    id: 'RequestPasswordResetInfo.PASSWORD_CHANGED',
    defaultMessage: 'Ihr Passwort wurde geändert',
  },
  USE_NEW_PASSWORD: {
    id: 'RequestPasswordResetInfo.USE_NEW_PASSWORD',
    defaultMessage: 'Bitte verwenden Sie in Zukunft Ihr neues Passwort.',
  },
  BACK: {
    id: 'RequestPasswordResetInfo.BACK',
    defaultMessage: 'Zurück',
  },
});

class RequestPasswordResetInfo extends React.PureComponent {

    render() {
      const { intl: { formatMessage } } = this.props;

        return (
            <div className="flex">
                  <div className="flex-box home-content">
                    <div className="m40 w-xs">
                      <h3>{formatMessage(messages.PASSWORD_CHANGED)}</h3>
                    </div>
                    <p className="alert alert-info">
                      {formatMessage(messages.USE_NEW_PASSWORD)}
                    </p>
                    <Link to="/">{formatMessage(messages.BACK)}</Link>
                </div>
           </div>
        );
    }
}

export default injectIntl(RequestPasswordResetInfo);
