import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router';

const messages = defineMessages({
  PASSWORD_TOKEN: {
    id: 'RequestPasswordResetFailed.PASSWORD_TOKEN',
    defaultMessage: 'Ihr Passworttoken ist abgelaufen',
  },
  TEXT_RESET_PASSWORD: {
    id: 'RequestPasswordResetFailed.TEXT_RESET_PASSWORD',
    defaultMessage: 'Der Link um das Passwort zurückzusetzen ist nur einmal gültig. '
      + 'Bitte generieren Sie einen neuen Link falls sie Ihr Passwort erneut ändern möchten.',
  },
  LINK_REQUEST_PASSWORD: {
    id: 'RequestPasswordResetFailed.LINK_REQUEST_PASSWORD',
    defaultMessage: 'Passwortlink beantragen',
  },
  BACK_TO_LOGIN: {
    id: 'RequestPasswordResetFailed.BACK_TO_LOGIN',
    defaultMessage: 'Zurück zum Login',
  },
});

class RequestPasswordResetFailed extends React.PureComponent {
    render() {
      const { intl: { formatMessage } } = this.props;

        return (
            <div className="flex">
                <div className="flex-box home-content">
                    <h1>{formatMessage(messages.PASSWORD_TOKEN)}</h1>
                    <p className="alert alert-danger">
                      {formatMessage(messages.TEXT_RESET_PASSWORD)}
                    </p>
                    <div className="text-center" style={{ marginTop: '15px' }}>
                        <Link to="/requestPasswortReset">
                          {formatMessage(messages.LINK_REQUEST_PASSWORD)}
                        </Link>
                    </div>
                    <div className="text-center" style={{ marginTop: '15px' }}>
                        <Link to="/login">
                          {formatMessage(messages.BACK_TO_LOGIN)}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(RequestPasswordResetFailed);
