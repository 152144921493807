import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form } from 'react-final-form';

import FormError from '../../../components/form/FormError';
import FormSubmit from '../../../components/form/FormSubmit';

const messages = defineMessages({
  LABEL_DELETE_CUSTOMER: {
    id: 'CustomerDelete.LABEL_DELETE_CUSTOMER',
    defaultMessage: 'Kunde unwiderruflich löschen',
  },
});

class CustomerDelete extends React.Component {
  render() {
    const {
        intl: { formatMessage },
    } = this.props;
    return (
      <Form
        onSubmit={this.props.onSubmit}
      >
        {({ submitError, handleSubmit, submitting }) => (
          <form style={this.props.style} onSubmit={handleSubmit}>
            <FormError error={submitError} />
            <FormSubmit
              btnClass="btn-lg btn-danger btn-block"
              btnProps={{ tabIndex: 1 }}
              submitting={submitting}
              icon="fa fa-trash"
              label={formatMessage(messages.LABEL_DELETE_CUSTOMER)}
            />
          </form>
        )}
      </Form>
    );
  }
}

export default injectIntl(CustomerDelete);
