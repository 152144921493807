import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { resetPasswordSendSms, resetPasswordSendEmail } from 'actions/company';

import { Link } from 'react-router';
import NavBarConfig from 'components/NavBarConfig';
import CompanyUserSingle from 'components/companyUser/CompanyUserSingle';
import { push } from 'react-router-redux';

const messages = defineMessages({
  BACK: {
    id: 'CompanyUserSinglePage.BACK',
    defaultMessage: 'Zurück',
  },
  USER: {
    id: 'CompanyUserSinglePage.USER',
    defaultMessage: 'Benutzer',
  },
  BUTTON_RESET_PASSWORT_SMS: {
    id: 'CompanyUserSinglePage.BUTTON_RESET_PASSWORT_SMS',
    defaultMessage: 'Passwort per SMS zurücksetzen',
  },
  BUTTON_RESET_PASSWORT_EMAIL: {
    id: 'CompanyUserSinglePage.BUTTON_RESET_PASSWORT_EMAIL',
    defaultMessage: 'Passwort per E-mail zurücksetzen',
  },
  EDIT_USER: {
    id: 'CompanyUserSinglePage.EDIT_USER',
    defaultMessage: 'Benutzer bearbeiten',
  },
  DELETE_USER: {
    id: 'CompanyUserSinglePage.DELETE_USER',
    defaultMessage: 'Benutzer löschen',
  },
});

function CompanyUserSinglePage({
  user,
  company,
  companyUser,
  onResetPasswordSendSms,
  onResetPasswordSendEmail,
  routes,
  intl: { formatMessage },
}) {
  if (!companyUser) { return null; }
  return (
    <div>
      <NavBarConfig currentRouteName={routes[routes.length - 1].name} />
      <div className="m40 w-xs">
        <Link to={`/company/${company.id}/user/`}>{formatMessage(messages.BACK)}</Link>
        <h3>{formatMessage(messages.USER)}</h3>
        <Link
            style={{ marginBottom: 10 }}
            className="lh-btn lh-btn-primary lh-btn-block"
            to={`/company/${companyUser.company_id}/user/${companyUser.user_id}/update/`}
        >
            <span className="fa fa-pencil" />
            {' '}{formatMessage(messages.EDIT_USER)}
        </Link>
        <div className="list-group" style={{ marginTop: 0 }}>
            <div className="list-group-item">
                <CompanyUserSingle companyUser={companyUser} />
            </div>
        </div>
        {user.is_admin || user.is_staff || !companyUser.is_owner ? (
            <div>
                {companyUser.user.phone ? (
                    <button
                        className="lh-btn lh-btn-default lh-btn-block"
                        type="button"
                        style={{ marginTop: '15px' }}
                        onClick={onResetPasswordSendSms}
                    >
                        <span className="fa fa-mobile" /> {formatMessage(messages.BUTTON_RESET_PASSWORT_SMS)}
                    </button>
                ) : null}
                {companyUser.user.email ? (
                    <button
                        className="lh-btn lh-btn-default lh-btn-block"
                        type="button"
                        style={{ marginTop: '15px' }}
                        onClick={onResetPasswordSendEmail}
                    >
                        <span className="fa fa-envelope-o" /> {formatMessage(messages.BUTTON_RESET_PASSWORT_EMAIL)}
                    </button>
                ) : null}
                <Link
                    className="lh-btn lh-btn-warning lh-btn-block"
                    style={{ marginTop: '30px' }}
                    to={`/company/${companyUser.company_id}/user/${companyUser.user_id}/delete/`}
                >
                  <span className="fa fa-trash" />
                  {' '}{formatMessage(messages.DELETE_USER)}
                </Link>
            </div>
        ) : null}
      </div>
    </div>
  );
}

export default connect(null,
  (dispatch, props) => ({
    onResetPasswordSendSms: () => resetPasswordSendSms(props.params.companyId, props.params.userId).then(() => {
      const { companyId, userId } = props.params;
      dispatch(push(`/company/${companyId}/user/${userId}/success/`));
    }),
    onResetPasswordSendEmail: () => resetPasswordSendEmail(props.params.companyId, props.params.userId).then(() => {
      const { companyId, userId } = props.params;
      dispatch(push(`/company/${companyId}/user/${userId}/success/`));
    }),
  }),
)(injectIntl(CompanyUserSinglePage));
