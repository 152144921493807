import React from 'react';
import classNames from 'classnames';

export default function FormGroup2(props) {
  const { label, input, meta, children, horizontal, className, style } = props;
  const error = meta
                && meta.touched
                && meta.invalid
                && meta.error;

  const groupClassName = classNames('form-group', className, { 'has-error': error });
  const labelClassName = classNames('control-label', { 'col-sm-4': horizontal });
  const inputClassName = classNames({
    'col-sm-8': horizontal,
    'col-sm-offset-4': horizontal && !label,
  });

  let errors;
  if (error) {
    errors = Array.isArray(error) ? (
      error.map((err, i) => (
        <span key={i} className="help-block">{err}</span>
      ))
    ) : (
      <span className="help-block">{error}</span>
    );
  }

  return (
    <div className={groupClassName} style={style}>
      {label ? (
        <label className={labelClassName} htmlFor={input.name}>
          {label}
        </label>
      ) : null}
      <div className={inputClassName}>
        {children}
        {errors}
      </div>
    </div>
  );
}

export function FormGroup(props) {
    const { label, field, children, horizontal, className, style } = props;

    const error = field && field.touched && field.invalid && field.error;

    const groupClassName = classNames('form-group', className, { 'has-error': error });
    const labelClassName = classNames('control-label', { 'col-sm-4': horizontal });
    const inputClassName = classNames({
        'col-sm-8': horizontal,
        'col-sm-offset-4': horizontal && !label,
    });

    let errors;
    if (error) {
        errors = Array.isArray(error) ? (
            error.map((err, i) => (
                <span key={i} className="help-block">{err}</span>
            ))
        ) : (
            <span className="help-block">{error}</span>
        );
    }

    return (
        <div className={groupClassName} style={style}>
            {label ? (
                <label className={labelClassName} htmlFor={field.name}>
                    {label}
                </label>
            ) : null}
            <div className={inputClassName}>
                {children}
                {errors}
            </div>
        </div>
    );
}
/*
FormGroup.propTypes = {
    label: React.PropTypes.string,
    field: React.PropTypes.object,
    children: React.PropTypes.node,
    horizontal: React.PropTypes.bool,
    className: React.PropTypes.string,
    style: React.PropTypes.object,
};
*/
FormGroup.defaultProps = {
    horizontal: false,
};
