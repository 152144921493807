import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { companyList } from 'actions/company';

import { Link } from 'react-router';
import NotFound from 'components/NotFound';
import NavBarConfig from '../../components/NavBarConfig';
import CompanyList from '../../components/company/CompanyList';

import Logo from '../../components/Logo';

const messages = defineMessages({
  ALL_COMPANIES: {
    id: 'CompanyListPage.ALL_COMPANIES',
    defaultMessage: 'Alle Unternehmen',
  },
  CREATE_COMPANY: {
    id: 'CompanyListPage.CREATE_COMPANY',
    defaultMessage: 'Unternehmen anlegen',
  },
  MAINTAIN_USER: {
    id: 'CompanyListPage.MAINTAIN_USER',
    defaultMessage: 'Benutzer verwalten',
  },
});

class CompanyListPageComp extends Component {
    componentWillMount() {
        const { user } = this.props;
        if (!user.is_admin && !user.is_staff && user.company_count === 1) {
            this.props.replace(`/company/${user.company_id}/`);
        } else {
            this.props.companyListLoad();
        }
    }

    render() {
        const { user, error, results, intl: { formatMessage } } = this.props;
        if (error) {
            return <NotFound />;
        }
        if (!results) {
            return <div />;
        }
        return (
            <div>
              <NavBarConfig
                companyList
                currentRouteName={this.props.routes[this.props.routes.length - 1].name}
              />
              <div className="m40">
                  <h3>
                    <Logo floating />
                    {formatMessage(messages.ALL_COMPANIES)}
                  </h3>
                  <Link
                      style={{ marginBottom: 10, marginRight: 10 }}
                      className="lh-btn lh-btn-primary"
                      to="/company/create/"
                  >
                      <span className="fa fa-plus" />
                      {' '}{formatMessage(messages.CREATE_COMPANY)}
                  </Link>

                  {user.is_admin || user.is_staff ? (
                      <Link style={{ marginBottom: 10 }} className="lh-btn lh-btn-primary" to="/users">
                          <span className="fa fa-users" />{' '}{formatMessage(messages.MAINTAIN_USER)}
                      </Link>
                  ) : null}
                <CompanyList items={results} />
              </div>
            </div>
        );
    }
}

export default connect(
    state => state.companyList,
    { companyListLoad: companyList.load, replace }
)(injectIntl(CompanyListPageComp));
