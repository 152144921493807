import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import Tools from '../../../components/video/Tools';

const messages = defineMessages({
  CONNECTION_LOST: {
    id: 'UserVideoStatus.CONNECTION_LOST',
    defaultMessage: 'Die Verbindung zum Kunden wurde unterbrochen. Klicken Sie '
      + 'bitte auf den roten Telefonhörer falls sie das Gespräch beenden möchten.',
  },
  WAIT_FOR_CUSTOMER: {
    id: 'UserVideoStatus.WAIT_FOR_CUSTOMER',
    defaultMessage: 'Das Gespräch beginnt sobald der Kunde sich verbindet.',
  },
});

function UserVideoStatus(props) {
  const { activeCallCreatedAndRunning, call, handleStopCall, intl: { formatMessage } } = props;

  if (!activeCallCreatedAndRunning || call.call_end) {
    return null;
  }

  return (
    <div className="video-status-container">
      <div className="video-status-message">
        {call.call_begin ? (
          formatMessage(messages.CONNECTION_LOST)
        ) : (
          formatMessage(messages.WAIT_FOR_CUSTOMER)
        )}
      </div>

      <Tools
        endCall={() => handleStopCall(call)}
      />
    </div>
  );
}

export default injectIntl(UserVideoStatus);
