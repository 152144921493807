import React from 'react';
import { DragSource } from 'react-dnd';
import { CALL } from '../../../../helpers/dragTypes';
import EventNote from './EventNote';

const callSource = {
  beginDrag(props) {
    return props.event;
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return;
    }

    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();
        // dispatch action
    props.handleDragging(item, dropResult);
  },
  canDrag({ event, canDrag }) {
    return true;
  },
};

@DragSource(CALL, callSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
class NoteWithoutCall extends React.Component {
  render() {
    const {
      event,
      customer,
      connectDragSource,
      canDrag,
      orders,
      addNoteToOrder,
    } = this.props;
    return (
      <div className="note-without-call">
        <EventNote
          evt={event}
          deleteEvent={this.props.deleteEvent}
          updateEvent={this.props.updateEvent}
          customer={customer}
          orders={orders}
          addNoteToOrder={addNoteToOrder}
          connectDragSource={connectDragSource}
        />
      </div>
    );
  }
}

export default NoteWithoutCall;
