import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import classnames from 'classnames';
import Visibility from 'visibilityjs';

/* global document */

// eslint-disable-next-line
import OT from 'opentok';

import { connectionPublishError } from '../../../actions/video';
import {
  startCall,
  endCall,
  mute,
  unmute,
  pauseVideo,
  restartStream,
  resumeVideo,
  unpublish,
  // videoSetFullsize,
  // videoSetReducedsize,
  sendSignal,
} from '../../../actions/ot_signaling';
// import { SelfAudioState } from '../../components/video/AudioState';
import VideoState from '../../../components/video/VideoState';
import VideoTouch from '../../../components/video/VideoTouch';
import Offline from '../../../components/Offline';
import { createPictureCustomer } from '../../../actions/screenshot';
import Tools from '../../../components/video/Tools';
import { detectIOS } from '../../../helpers/browserdetect';
import AudioRequestedModal from '../components/AudioRequestedModal';
import VideoError from '../components/VideoError';

const messages = defineMessages({
  MESSAGE_NO_CONNECTION: {
    id: 'EndkundeVideoPage.MESSAGE_NO_CONNECTION',
    defaultMessage: 'Es besteht keine Internetverbindung.',
  },
  MESSAGE_OPPONENT_SPEAKING: {
    id: 'EndkundeVideoPage.MESSAGE_OPPONENT_SPEAKING',
    defaultMessage: 'Gegenstelle spricht...',
  },
  MESSAGE_NO_CONVERSATION_BUDDY: {
    id: 'EndkundeVideoPage.MESSAGE_NO_CONVERSATION_BUDDY',
    defaultMessage: 'Es ist noch kein Gesprächspartner im Gespräch',
  },
  MESSAGE_NO_ACTIVE_CONVERSATION: {
    id: 'EndkundeVideoPage.MESSAGE_NO_ACTIVE_CONVERSATION',
    defaultMessage: 'Es wurde kein aktives Gespräch zu Ihrer Sitzungs-ID gefunden.',
  },
  MESSAGE_CONNECTING: {
    id: 'EndkundeVideoPage.MESSAGE_CONNECTING',
    defaultMessage: 'Verbindungsaufbau...',
  },
  MESSAGE_MODERATOR_LEAVE_ROOM: {
    id: 'EndkundeVideoPage.MESSAGE_MODERATOR_LEAVE_ROOM',
    defaultMessage: 'Der Moderator hat das Gespräch verlassen.',
  },
  UPLOAD_FAILED: {
    id: 'EndkundeVideoPage.UPLOAD_FAILED',
    defaultMessage: 'Der Upload ist fehlgeschlagen',
  },
  IMAGE_OVERSIZED: {
    id: 'EndkundeVideoPage.IMAGE_OVERSIZED',
    defaultMessage: 'Der Server meldet, dass die Bilddatei zu groß für einen Upload ist.',
  },
  START_UPLOAD_AGAIN: {
    id: 'EndkundeVideoPage.START_UPLOAD_AGAIN',
    defaultMessage: 'Upload erneut starten',
  },
  CANCEL: {
    id: 'EndkundeVideoPage.CANCEL',
    defaultMessage: 'Abbrechen',
  },
});

function getMessage(
  callRunning,
  offline,
  connection,
  remoteAudio,
  remoteConnected,
  formatMessage
) {
  if (offline) {
    return formatMessage(messages.MESSAGE_NO_CONNECTION);
  }
  const connected = connection && connection.connected;
  const remoteSpeaking = remoteAudio && remoteAudio.speaking;
  if (connected) {
    if (remoteConnected) {
      // msg = 'Mit Handwerker verbunden.';
      if (remoteSpeaking) {
        // msg += ' Gegenstelle spricht...';
        return formatMessage(messages.MESSAGE_OPPONENT_SPEAKING);
      }
    } else {
      if (callRunning) {
        return formatMessage(messages.MESSAGE_NO_CONVERSATION_BUDDY);
      }
      return formatMessage(messages.MESSAGE_NO_ACTIVE_CONVERSATION);
    }
  } else {
    return formatMessage(messages.MESSAGE_CONNECTING);
  }
  return null;
}

class EndkundeVideoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { fullscreen: false };
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
  }

  componentWillMount() {
    if (!this.props.mediaDevices || !this.props.mediaDevices.streamURL) {
      this.props.redirect(`/v/${this.props.roomName}/`);
      return;
    }
    this.props.start(this.props.mediaDevices);
  }

  componentDidMount() {
    if (!this.props.mediaDevices || !this.props.mediaDevices.streamURL) {
      this.props.redirect(`/v/${this.props.roomName}/`);
      return;
    }

    this.visibilityListener = Visibility.change(this.handleVisibilityChange);

    /* global window */
    window.scrollTo(0, 1);
  }

  componentWillUnmount() {
    Visibility.unbind(this.visibilityListener);
    this.props.end();
  }

  handleVisibilityChange(e, state) {
    if (state === 'visible') {
      if (this.didPause) {
        this.props.resume();
        this.didPause = false;
      }
    } else if (this.props.video && !this.props.video.paused) {
      this.props.pause();
      this.didPause = true;
    }
  }

  render() {
    const {
      connection,
      video,
      remoteAudio,
      touchPosition,
      callRunning,
      offline,
      videoDimensions,
      mediaDevices,
      roomName,
      remoteConnection,
      intl: { formatMessage },
    } = this.props;

    if (!mediaDevices || !mediaDevices.streamURL) {
      return <div />;
    }

    const { fullscreen } = this.state;
    const remoteConnected = remoteConnection && remoteConnection.connected;
    const msg = getMessage(
      callRunning,
      offline,
      connection,
      remoteAudio,
      remoteConnected,
      formatMessage
    );

    if (connection.publishError) {
      return (
        <div className="container video-chat-error">
          <VideoError error={connection.publishError} room={roomName} />
        </div>
      );
    }

    const hasDimensions = videoDimensions && Object.keys(videoDimensions);

    return (
      <div
        className={classnames(
          'publisher-video-screen',
          hasDimensions && videoDimensions.height > videoDimensions.width
            ? 'portrait-video'
            : 'landscape-video'
        )}
      >
        <Offline />
        <AudioRequestedModal />
        <div className={classnames('video')} ref={ref => (this.video = ref)}>
          {touchPosition &&
          <VideoTouch position={touchPosition} videoRef={this.video} />}
          <div id="opentok-container" />
          {video &&
          <VideoState
            videoActive={video.active}
            videoPaused={video.paused}
          />}
          <Tools
            videoActive={video && video.active}
            paused={video && video.paused}
            pause={this.props.pause}
            resume={this.props.resume}
            chatActive={false}
            toggleChat={null}
            callActive={connection && connection.connected && remoteConnected}
            endCall={this.props.unpublish}
          />
        </div>

        {msg && <div className="video-overlay">{msg}</div>}
      </div>
    );
  }
}

export default connect(
  state => ({
    mediaDevices: state.mediaDevices,
    offline: state.offline,
    audio: state.audio,
    ...state.video,
    remoteConnection: state.video.remoteConnection
  }),
  (dispatch, props) => ({
    redirect: path => dispatch(replace(path)),
    /*    restartStream: () =>
          dispatch(restartStream(getPublisherConfig(props.location.query))),
    */
    unpublish: () =>
      unpublish().then(() => {
        let pathName = `/v/${props.roomName}/end/`;
        if (props.companyId) {
          pathName = `/custom/${props.companyId}/v/${props.roomName}/end/`;
        }
        return dispatch(push(pathName))
      }),
    start: (mediaDevices) =>
      dispatch(
        startCall({
          roomName: props.roomName,
          elementId: 'opentok-container',
          name: 'Endkunde',
          audioDeviceId: mediaDevices.audioDeviceId,
          videoDeviceId: mediaDevices.videoDeviceId,
          stream: mediaDevices.streamURL
        })
      ),
    end: () => dispatch(endCall()),
    mute: () => dispatch(mute()),
    unmute: () => dispatch(unmute()),
    pause: () => dispatch(pauseVideo()),
    resume: () => dispatch(resumeVideo()),
  })
)(injectIntl(EndkundeVideoComponent));
