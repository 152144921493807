import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { loggedOut } from 'actions/auth';
import { push } from 'react-router-redux';

const messages = defineMessages({
  LOGOUT: {
    id: 'LogoutPage.LOGOUT',
    defaultMessage: 'Ausloggen...',
  },
});

@connect(null,
    dispatch => ({
        logout: () => {
            dispatch(loggedOut());
            dispatch(push('/'));
        },
    })
)

class LogoutPage extends React.PureComponent {
    componentDidMount() {
        this.props.logout();
    }

    render() {
      const { intl: { formatMessage } } = this.props;

        return (
            <div className="flex">
                <form className="flex-box home-content">
                    <p>{formatMessage(messages.LOGOUT)}</p>
                </form>
            </div>
        );
    }
}

export default injectIntl(LogoutPage);
