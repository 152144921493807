import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';

import FormGroup2 from 'components/form/FormGroup';
import FormError from 'components/form/FormError';
import FormSubmit from 'components/form/FormSubmit';
import TextField from 'components/form/TextField';

const messages = defineMessages({
  PLACEHOLDER_SESSION_NAME: {
    id: 'JoinForm.PLACEHOLDER_SESSION_NAME',
    defaultMessage: 'Sitzungsname angeben',
  },
  LABEL_BUILD_UP_CONNECTION: {
    id: 'JoinForm.LABEL_BUILD_UP_CONNECTION',
    defaultMessage: 'Verbindung herstellen',
  },
  VALIDATION_SESSION_NAME: {
    id: 'JoinForm.VALIDATION_SESSION_NAME',
    defaultMessage: 'Wir benötigen Ihren Sitzungsnamen um eine Verbindung herstellen zu können.',
  },
});

function JoinForm(props) {
  const {
    intl: { formatMessage },
  } = props;
  return (
    <Form
      onSubmit={props.onSubmit}
      validate={values => {
        const errors = {};
        if (!values.room) {
          errors.room = formatMessage(messages.VALIDATION_SESSION_NAME);
        }
        return errors;
      }
      }
    >
      {({ submitError, handleSubmit, pristine, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field name="room">
            {({ input, meta }) => {
              return (
                <FormGroup2 input={input} meta={meta}>
                  <TextField
                    {...input}
                    tabIndex="4"
                    placeholder={formatMessage(messages.PLACEHOLDER_SESSION_NAME)}
                    autoCapitalize="off"
                    autoCorrect="off"
                    autoComplete="off"
                  />
                </FormGroup2>
              )
            }}
          </Field>
          <FormError error={submitError} />
          <FormSubmit
            blockClassName="text-center"
            btnClass="lh-btn lh-btn-primary lh-btn-block"
            btnProps={{ tabIndex: 5 }}
            submitting={submitting}
            label={formatMessage(messages.LABEL_BUILD_UP_CONNECTION)}
          />
          </form>
      )
      }
    </Form>
  );
}
/*
<FormGroup field={name}>
  <TextField
    tabIndex="4"
    placeholder={formatMessage(messages.PLACEHOLDER_SESSION_NAME)}
    autoCapitalize="off"
    autoCorrect="off"
    autoComplete="off"
    {...name}
  />
</FormGroup>
<FormError error={error} />
<FormSubmit
  blockClassName="text-center"
  btnClass="lh-btn lh-btn-primary lh-btn-block"
  btnProps={{ tabIndex: 5 }}
  submitting={submitting}
  label={formatMessage(messages.LABEL_BUILD_UP_CONNECTION)}
/>

const JoinFormReduxForm = reduxForm({
  form: 'join',
  fields: ['name'],
  validate: (values, { intl: { formatMessage } }) => ({
    name: values.name ? null : formatMessage(messages.VALIDATION_SESSION_NAME),
  }),
  touchOnBlur: false,
})(JoinForm);
*/

export default injectIntl(JoinForm);

