import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { deleteCompanyUser, companyUserDeleted } from 'actions/company';

import { Link } from 'react-router';
import NavBarConfig from 'components/NavBarConfig';

const messages = defineMessages({
  BACK: {
    id: 'CompanyUserDeletePage.BACK',
    defaultMessage: 'Zurück',
  },
  DELETE_USER: {
    id: 'CompanyUserDeletePage.DELETE_USER',
    defaultMessage: 'Benutzer löschen',
  },
  REALLY_WANT_TO_DELETE: {
    id: 'CompanyUserDeletePage.REALLY_WANT_TO_DELETE',
    defaultMessage: 'Sind sie sich sicher, dass sie den Benutzer wirklich löschen wollen?',
  },
  CAN_NOT_REVOKED: {
    id: 'CompanyUserDeletePage.CAN_NOT_REVOKED',
    defaultMessage: 'Diese Aktion kann nicht rückgängig gemacht werden.',
  },
  BUTTON_DELETE_USER: {
    id: 'CompanyUserDeletePage.BUTTON_DELETE_USER',
    defaultMessage: 'Benutzer unwiderruflich löschen',
  },
});

function CompanyUserDeletePage({
  company,
  companyUser,
  onSubmit,
  routes,
  intl: { formatMessage },
}) {
  if (!companyUser) {
    return null;
  }
  return (
    <div>
      <NavBarConfig deletePage currentRouteName={routes[routes.length - 1].name} />
      <div className="m40 w-xs">
        <Link to={`/company/${company.id}/user/${companyUser.user_id}/`}>
          {formatMessage(messages.BACK)}
        </Link>
        <h3>{formatMessage(messages.DELETE_USER)}</h3>
        <p>
          {formatMessage(messages.REALLY_WANT_TO_DELETE)}
          <br />
          {formatMessage(messages.CAN_NOT_REVOKED)}
        </p>
        <button
          className="btn btn-danger btn-block btn-lg"
          onClick={onSubmit}
          style={{ marginTop: '15px' }}
          type="button"
        >
          <span className="fa fa-trash" />
          {' '}{formatMessage(messages.BUTTON_DELETE_USER)}
        </button>
      </div>
    </div>
  );
}

export default connect(null, (dispatch, { params }) => ({
  onSubmit: () =>
    deleteCompanyUser(params.companyId, params.userId).then(() => {
      dispatch(companyUserDeleted({ companyId: params.companyId, userId: params.userId }));
      dispatch(push(`/company/${params.companyId}/user/`));
    }),
}))(injectIntl(CompanyUserDeletePage));
