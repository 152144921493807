import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import _ from 'lodash';

import { availableLocales } from 'actions/intl';

import FormGroup2 from '../../../components/form/FormGroup';
import FormError from '../../../components/form/FormError';
import FormSubmit from '../../../components/form/FormSubmit';
import TextField from '../../../components/form/TextField';


const messages = defineMessages({
  FIELD_REQUIRED: {
    id: 'CustomerUpdateForm.FIELD_REQUIRED',
    defaultMessage: 'Dieses Feld ist erforderlich.',
  },
  PLACEHOLDER_CUSTOMER_NAME: {
    id: 'CustomerUpdateForm.PLACEHOLDER_CUSTOMER_NAME',
    defaultMessage: 'Kundenname',
  },
  INDICATE_PHONE_NUMBER: {
    id: 'CustomerUpdateForm.INDICATE_PHONE_NUMBER',
    defaultMessage: 'Bitte geben sie hier die mobile Telefonnummer des Kunden an.\n' +
      'Standardmäßig wird der Kunde zu einem Videogespräch per SMS eingeladen.',
  },
  COUNTRY_CODE: {
    id: 'CustomerUpdateForm.COUNTRY_CODE',
    defaultMessage: 'Die mobile Telefonnummer wird ohne Ländervorwahl als deutsche Telefonnummer interpretiert.',
  },
  PLACEHOLDER_MOBILE: {
    id: 'CustomerUpdateForm.PLACEHOLDER_MOBILE',
    defaultMessage: 'Mobile Telefonnummer',
  },
  LABEL_SAVE_CHANGES: {
    id: 'CustomerUpdateForm.LABEL_SAVE_CHANGES',
    defaultMessage: 'Änderungen speichern',
  },
  LABEL_LANGUAGE: {
    id: 'CustomerUpdateForm.LABEL_LANGUAGE',
    defaultMessage: 'Sprache des Kunden',
  },
  LOCALE_DE: {
    id: 'CustomerUpdateForm.LOCALE_DE',
    defaultMessage: 'Deutsch',
  },
  LOCALE_EN: {
    id: 'CustomerUpdateForm.LOCALE_EN',
    defaultMessage: 'Englisch',
  },
});

function CustomerUpdateFormComponent(props) {
  const {
    onSubmit,
    submitting,
    intl: { formatMessage },
  } = props;

  const locales = availableLocales.map(locale => ({
    value: locale,
    label: formatMessage(messages[`LOCALE_${locale.toUpperCase()}`]),
  }));

  const onSubmitLocal = (data) => {
    const dataSubmit = {
      name: data.name,
      phone: data.phone,
      locale: data.locale.value
    }
    return props.onSubmit(dataSubmit);
  }
  return (
    <Form
      onSubmit={onSubmitLocal}
      validate={values => {
        const errors = {};
        if (!values.name) {
          errors.name= formatMessage(messages.FIELD_REQUIRED)
        }
        return errors;
      }}
      initialValues={{
        ...props.initialValues,
        locale: _.find(locales, locale => locale.value === props.initialValues.locale)
      }}
    >
      {({ submitError, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="name"
          >
            {({ input, meta }) => (
              <FormGroup2 input={input} meta={meta}>
                <TextField
                  {...input}
                  tabIndex="1"
                  autoFocus
                  autoComplete="off"
                  placeholder={formatMessage(messages.PLACEHOLDER_CUSTOMER_NAME)}
                />
              </FormGroup2>
            )}
          </Field>
          <p className="m40 w-xs">
            {formatMessage(messages.INDICATE_PHONE_NUMBER)}
            <br />
            <br />
            {formatMessage(messages.COUNTRY_CODE)}
          </p>
          <Field
            name="phone"
          >
            {({ input, meta }) => (
              <FormGroup2 input={input} meta={meta}>
                <TextField
                  {...input}
                  tabIndex="2"
                  placeholder={formatMessage(messages.PLACEHOLDER_MOBILE)}
                  autoCapitalize="off"
                  autoCorrect="off"
                  autoComplete="off"
                />
              </FormGroup2>
            )}
          </Field>

          <span>{formatMessage(messages.LABEL_LANGUAGE)}</span>
          <Field
            name="locale"
          >
            {({ input, meta }) => {
              return (
                <FormGroup2 input={input} meta={meta}>
                  <Select
                    {...input}
                    name="form-field-locale"
                    value={input.value}
                    options={locales}
                    searchable={false}
                    clearable={false}
                    tabIndex="3"
                  />
                </FormGroup2>
              )
            } }
          </Field>
          <FormError error={submitError} />
          <FormSubmit
            btnClass="lh-btn lh-btn-primary lh-btn-block btn-lg"
            btnProps={{ tabIndex: 4 }}
            submitting={submitting}
            label={formatMessage(messages.LABEL_SAVE_CHANGES)}
          />
        </form>
      )}
    </Form>
  );
}

/*
const CustomerUpdateForm = reduxForm({
  form: 'customerUpdate',
  fields: ['name', 'phone', 'locale'],
  validate: (values, { intl: { formatMessage } }) => ({
    name: values.name ? null : formatMessage(messages.FIELD_REQUIRED),
    phone: values.phone ? null : formatMessage(messages.FIELD_REQUIRED),
  }),
  touchOnBlur: false,
})(CustomerUpdateFormComponent);
*/

export default injectIntl(CustomerUpdateFormComponent);
