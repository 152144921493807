import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router';

const messages = defineMessages({
  YOU_HAVE_A_MESSAGE: {
    id: 'RequestPasswordResetSuccess.YOU_HAVE_A_MESSAGE',
    defaultMessage: 'Sie haben Post',
  },
  SENDED_LINK: {
    id: 'RequestPasswordResetSuccess.SENDED_LINK',
    defaultMessage: 'Wir haben Ihnen eine Nachricht mit einem Link zugesendet.',
  },
  FOLLOW_LINK: {
    id: 'RequestPasswordResetSuccess.FOLLOW_LINK',
    defaultMessage: 'Bitte folgen Sie diesem Link um Ihr Passwort zurückzusetzen.',
  },
  BACK: {
    id: 'RequestPasswordResetSuccess.BACK',
    defaultMessage: 'Zurück',
  },
});

class RequestPasswordResetSuccess extends React.PureComponent {
    render() {
      const { intl: { formatMessage } } = this.props;

        return (
            <div className="flex">
                <div className="home-content content-block row login-page">
                    <h1>{formatMessage(messages.YOU_HAVE_A_MESSAGE)}</h1>
                    <p className="alert alert-info">
                        {formatMessage(messages.SENDED_LINK)}
                        <br />
                        <br />
                        {formatMessage(messages.FOLLOW_LINK)}
                    </p>
                    <div className="text-center" style={{ marginTop: '15px' }}>
                        <Link to="/">
                            {formatMessage(messages.BACK)}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(RequestPasswordResetSuccess);
