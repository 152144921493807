import React from 'react';
import { Link } from 'react-router';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  OT_HARDWARE_UNAVAILABLE: {
    id: 'VideoError.OT_HARDWARE_UNAVAILABLE',
    defaultMessage: 'Leider konnte tele-LOOK nicht auf ihre Kamera zugreifen. Bitte überprüfen Sie, ob diese gerade von einer anderen Anwendung benutzt wird und versuchen Sie es erneut.',
  },
  OT_USER_MEDIA_ACCESS_DENIED: {
    id: 'VideoError.OT_USER_MEDIA_ACCESS_DENIED',
    defaultMessage: 'Bitte geben Sie Ihre Kamera für tele-LOOK frei.',
  },
  OT_CHROME_MICROPHONE_ACQUISITION_ERROR: {
    id: 'VideoError.OT_CHROME_MICROPHONE_ACQUISITION_ERROR',
    defaultMessage: 'Leider konnte tele-LOOK auf Grund eines Chrome-Bugs keine Audio-Verbindung aufbauen. Um eine Audio-Verbindung aufzubauen, müssen Sie Chrome neustarten.',
  },
  SCREEN_TITLE: {
    id: 'VideoError.SCREEN_TITLE',
    defaultMessage: 'Fehler',
  },
  TRY_AGAIN_BUTTON: {
    id: 'VideoError.TRY_AGAIN_BUTTON',
    defaultMessage: 'Neu verbinden',
  },
  UNKNOWN_ERROR: {
    id: 'VideoError.UNKNOWN_ERROR',
    defaultMessage: 'Beim Aufbau des Video-Dialogs ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  },
  INVALID_ROOM_NAME: {
    id: 'VideoError.INVALID_ROOM_NAME',
    defaultMessage: 'Zu dieser Session-ID konnte kein Videogespräch gefunden werden.',
  },
  STARTPAGE_BUTTON: {
    id: 'VideoError.STARTPAGE_BUTTON',
    defaultMessage: 'Zurück zur Startseite',
  },
});

function VideoError({ error, room, intl: { formatMessage } }) {
  return (
    <div>
      <h3>{formatMessage(messages.SCREEN_TITLE)}</h3>
      <p>
        {messages[error]
          ? formatMessage(messages[error])
          : formatMessage(messages.UNKNOWN_ERROR)}
      </p>

      {error !== 'INVALID_ROOM_NAME' &&
        <Link to={`/v/${room}`} className="lh-btn lh-btn-primary">
          {formatMessage(messages.TRY_AGAIN_BUTTON)}
        </Link>}

      <Link to="/login" className="lh-btn lh-btn-primary">
        {formatMessage(messages.STARTPAGE_BUTTON)}
      </Link>
    </div>
  );
}

export default injectIntl(VideoError);
