import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { Link } from 'react-router';

import Logo from '../../components/Logo';

const messages = defineMessages({
  BACK_TO_STARTPAGE: {
    id: 'TermsPageComponent.BACK_TO_STARTPAGE',
    defaultMessage: 'Zurück zur Startseite',
  },
});

function TermsPageComponent({ intl: { formatMessage } }) {
  return (
    <div className="flex">
      <div className="content-block row login-page">
        <Logo />
        <div className="terms">
          <h1>
            Solutiness – AGB
          </h1>
          <h3>
            1.    Geltung
          </h3>
          <p>
            <strong>(1)</strong>
            {' '}
            Das Angebot auf der Website www.tele-look.de /.com, u.a. ist ein Angebot der Solutiness GmbH, Alte Breisacher Str. 14a, 79112 Freiburg i. Brsg. (im Folgenden: Solutiness). Für alle Verträge gelten ausschließlich die nachstehenden Vertragsbedingungen. Sie werden durch die Registrierung anerkannt.
          </p>
          <p>
            <strong>(2)</strong>
            {' '}
            Das Angebot von Solutiness richtet sich ausschließlich an gewerblich tätige Handwerker und Unternehmen (im Folgenden: Der Betrieb).
          </p>
          <p>
            <strong>(3)</strong>
            {' '}
            Abweichende Geschäftsbedingungen des Betriebs sind, sofern sie nicht ausdrücklich von Solutiness schriftlich akzeptiert wurden, unverbindlich.
          </p>

          <h3>
            2.    Vertragsschluss
          </h3>
          <p>
            Ein Vertrag kommt nach schriftlichem Vertragsabschluss zustande.  Minderjährige sind von der Teilnahme ausgeschlossen.
          </p>

          <h3>
            3.    Anmeldung
          </h3>
          <p>
            <strong>(1)</strong>
            {' '}
            Jeder Betrieb hat sich bei Solutiness durch eine Registrierung zu registrieren. Bei der Anmeldung hat der Betrieb seinen Namen, bei Gesellschaften auch den des Vertretungsberechtigten, sowie Postanschrift (nicht: Postfach), Telefon, Fax und Mailadresse anzugeben. Der Betrieb hat Solutiness bei Änderungen unverzüglich zu informieren.
          </p>
          <p>
            <strong>(2)</strong>
            {' '}
            Jeder Betrieb darf sich nur einmal anmelden und nur einen Account bei Solutiness betreiben. Anmeldungen unterschiedlicher Abteilungen sind auf Antrag möglich. Solutiness behält sich die Entscheidung vor.
          </p>
          <p>
            <strong>(3)</strong> Unrichtige Angaben berechtigen Solutiness zur sofortigen Kündigung.
          </p>

          <h3>
            4.    Betriebe und Kunden
          </h3>
          <p>
            <strong>(1)</strong>
            {' '}
            Solutiness unterstützt Betriebe bei der Analyse/Diagnose oder Anbahnung oder Durchführung von Handwerks- und Unternehmensverträgen durch die Möglichkeit zur Sichtung insbesondere von Maschinen, Anlagen oder Reparaturobjekten und Umgebung, ohne hierfür anwesend sein zu müssen. Die im Vertrag vereinbarten Gebühren werden monatlich oder vorschüssig fällig, es fallen unabhängig der Nutzung keine zusätzlichen Minutengebühren an.
          </p>
          <p>
            <strong>(2)</strong>
            {' '}
            Die Inanspruchnahme der Dienstleistungen von Solutiness ist für die Kunden des Betriebs kostenfrei.
          </p>
          <p>
            <strong>(3)</strong>
            {' '}
            Solutiness ist an den zwischen Betrieb und Kunden geschlossenen Verträgen nicht beteiligt. Es steht für deren Erfüllung nicht ein.
          </p>

          <h3>
            5.    Leistungsgegenstand
          </h3>
          <p>
            <strong>(1)</strong>
            {' '}
            Solutiness bietet ein virtuelles Werkzeug als technisches Hilfsmittel für den Betrieb an, um sich via Fernansicht einen Überblick über die Situation beim Kunden vor Ort machen zu können. Hierbei können Fotos gemacht und auf dem Server von Solutiness für den Betrieb und dessen Kunden zugänglich gespeichert werden.
          </p>
          <p>
            <strong>(2)</strong>
            {' '}
            Der Betrieb kann die Leistungen von Solutiness nur bei gleichzeitiger Nutzung seines Mobilfunkanschlusses und/oder Internetanschlusses in Anspruch nehmen.
          </p>
          <p>
            <strong>(3)</strong>
            {' '}
            Die Leistungen von Solutiness setzen das Vorhandensein von entsprechendem technischem Equipment beim Betrieb wie auch beim Kunden voraus.
          </p>

          <h3>
            6.    Ablauf
          </h3>
          <p>
            <strong>(1)</strong>
            {' '}
            Der Betrieb kann mit Einverständnis des Kundenden Versand einer Einladung per SMS durch Solutiness veranlassen, oder selber mit einer e-Mail o.ä. an den Kunden. Durch einen Klick auf die URL in der Einladung löst der Kunde die Nutzung der Solutiness-Software aus. Eine Automatisierung des Einladungsvorgangs unterliegt den gleichen Bedingungen.
          </p>
          <p>
            <strong>(2)</strong>
            {' '}
            Der Betrieb sichert zu, vor Übertragung der Mobilfunknummer des Kunden an Solutiness dessen Einwilligung hierzu eingeholt zu haben. Er stellt Solutiness bei Fehlen einer solchen Einwilligung von eventuellen Schäden frei.
          </p>

          <h3>
            7.    Persönlichkeitsrechte der Kunden
          </h3>
          <p>
            <strong>(1)</strong>
            {' '}
            Durch die Nutzung von Solutiness gewähren die Kunden dem Betrieb häufig Einblicke in Wohn- oder Geschäftsräume, die der Privatsphäre unterfallen. Fotoaufnahmen, die der Betrieb unter Nutzung von Solutiness anfertigt, sind daher vertraulich zu behandeln und dürfen ausschließlich zur Vertragserfüllung genutzt werden.
          </p>
          <p>
            <strong>(2)</strong>
            {' '}
            Der Betrieb sichert zu, den Kunden über die Speicherung der Aufzeichnungen bei Solutiness informiert und dessen Einwilligung in diese Speicherung eingeholt zu haben.
          </p>
          <p>
            <strong>(3)</strong>
            {' '}
            Eine Weitergabe an Dritte (z.B. Kooperationspartner, andere Betriebe) ist nur mit vorheriger Einwilligung des Kunden gestattet. Es obliegt allein dem Kunden, zu entscheiden, in welche Bereiche seiner Wohn- und Geschäftsräume er via Solutiness Einblick gewährt. Der Betrieb sichert zu, vor der Zugänglichmachung von Aufzeichnungen für Dritte ausdrücklich die Einwilligung hierzu vom Kunden eingeholt zu haben.
          </p>
          <p>
            <strong>(4)</strong>
            {' '}
            Der Betrieb stellt Solutiness von Ansprüchen des Betroffenen frei, soweit dieser wegen des Fehlens einer entsprechenden Einwilligung Ansprüche gegenüber Solutiness geltend macht.
          </p>

          <h3>
            8.    Nutzungsrechte
          </h3>
          <p>
            Die Berechtigung zur Nutzung der Solutiness-Software ist abhängig vom Bestehen dieses Vertrages. Nach dessen Beendigung kann der Betrieb auf Software und hinterlegte Aufzeichnungen nicht mehr zugreifen.
          </p>

          <h3>
            9.    Zugang und Passwort
          </h3>
          <p>
            Der Betrieb erhält für den Zugriff ein Passwort. Dieses ist vertraulich zu behandeln und gegen unbefugten Zugriff zu sichern. Es darf nur an Personen weitergegeben werden, die im Namen des Betriebes Leistungen bei Solutiness beauftragen dürfen. Sollte das Passwort abhanden kommen oder ein Missbrauch des Zugangs bekannt werden, ist Solutiness hiervon unverzüglich zu unterrichten. Dem Betrieb wird dann ein neuer Zugang zugewiesen.
          </p>

          <h3>
            10.    Vergütung (<Link to="/prices" target="_blank">Preise</Link>)
          </h3>
          <p>
            <strong>(1)</strong>
            {' '}
            Die im Vertrag vereinbarten Preise verstehen sich zzgl. Umsatzsteuer. Es fallen unabhängig von der Nutzung keine weiteren Minutengebühren an. Hiervon unberührt bleiben die anfallenden Gebühren des Mobilfunkanbieters und/oder Internetproviders des Betriebs.
          </p>
          <p>
            <strong>(2)</strong>
            {' '}
            Nutzungslizenzen werden von Solutiness, soweit nicht anders vereinbart, monatlich abgerechnet.
          </p>
          <p>
            <strong>(3)</strong>
            {' '}
            Im Falle von Zahlungsverzug werden Verzugszinsen gem. § 288 BGB berechnet. Weitergehende Ansprüche bleiben vorbehalten.
          </p>
          <p>
            <strong>(4)</strong>
            {' '}
            Eine Aufrechnung steht dem Betrieb nur zu, soweit seine Gegenansprüche rechtskräftig festgestellt, unbestritten oder von Solutiness anerkannt sind.
          </p>

          <h3>
            11.    Kündigung
          </h3>
          <p>
            <strong>(1)</strong>
            {' '}
            Verträge sind sind in der Regel auf eine Laufzeit von einem Jahr abgeschlossen. Im Ausnahmefall sind kürzere Laufzeiten zu Testzwecken schriftlich vereinbart. Aufschläge für kürzere Vertragslaufzeiten werden individuell bestimmt. Verträge verlängern sich jeweils automatisch um ein Jahr, sofern nicht eine Vertragspartei drei Monate vor Ablauf schriftlich kündigt.
          </p>
          <p>
            <strong>(2)</strong>
            {' '}
            Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt unberührt.
          </p>
          <p>
            <strong>(3)</strong> Kündigungen können per e-Mail ausgesprochen werden.
          </p>
          <h3>
            12.    Preisanpassungsklausel
          </h3>
          <p>
            Der Anbieter behält sich vor, die Gebühren/Preise für Dauerschuldverhältnisse zu Beginn einer neuen Vertragsperiode angemessen zu erhöhen. Der Kunde wird darüber mindestens sechs Wochen im Voraus informiert. Beträgt die Erhöhung mehr als 5%, steht dem Kunden ein außerordentliches Kündigungsrecht zu.
          </p>

          <h3>
            13.    Löschung von Aufzeichnungen
          </h3>
          <p>
            <strong>(1)</strong>
            {' '}
            Die für den Betrieb gespeicherten Fotos, PDFs und Vorgänge/Protokolle werden nach drei Monaten gelöscht.
          </p>
          <p>
            <strong>(2)</strong>
            {' '}
            Die für den Betrieb gespeicherten Fotos, PDFs und Vorgänge/Protokolle werden gelöscht, wenn der Betrieb bzw. der betroffene Kunde es verlangt.
          </p>
          <p>
            <strong>(3)</strong>
            {' '}
            Nach Beendigung des Vertrages werden die Aufzeichnungen gelöscht.
          </p>
          <h3>
            14.    Technische Mängel
          </h3>
          <p>
            <strong>(1)</strong>
            {' '}
            Solutiness gewährleistet eine Erreichbarkeit des Dienstes von 98,5 % im Monatsmittel.
          </p>
          <p>
            <strong>(2)</strong>
            {' '}
            Solutiness ist für vorsätzliche Angriffe Dritter (Hacker, Computerviren, DoS-Angriffe u.ä.) auf seine Server und auf das Internet nicht verantwortlich.
          </p>
          <p>
            <strong>(3)</strong>
            {' '}
            Solutiness haftet nicht für die telefonische Erreichbarkeit der Betriebe oder Kunden. Die Leistungen sind abhängig von den jeweils verwendeten Telekommuniktionsanbietern.
          </p>

          <h3>
            15.    Unzulässiges Verhalten
          </h3>
          <p>
            <strong>(1)</strong>
            {' '}
            Die Nutzung von Solutiness setzt voraus, dass sich der Betrieb während der Anbahnung und Umsetzung der Verträge mit den Kunden rechtstreu verhält. Alle Aufzeichnungen dürfen nur zu den vorgesehenen Zwecken und im Rahmen der Einwilligung des Kunden verwendet werden.
          </p>
          <p>
            <strong>(2)</strong>
            {' '}
            Die Nutzung von Solutiness ist zur Diagnose/Analyse, Sichtung, Lösung, Auftragsvorplanung, Anbahnung und Durchführung von gewerblichen, zulässigen Leistungen diverser Art gestattet. Der Betrieb sichert zu, die erforderlichen Befähigungen, Zulassungen etc. zu besitzen. Eine Nutzung zu anderen Zwecken ist nicht gestattet.
          </p>
          <p>
            <strong>(3)</strong>
            {' '}
            Solutiness ist bei Verletzung dieser Vertragspflicht zur fristlosen Kündigung des Vertrages berechtigt.
          </p>

          <h3>
            16.    Allgemeine Haftungsbegrenzung
          </h3>
          <p>
            Schadensersatzansprüche gegen Solutiness sind ausgeschlossen, soweit nicht vorsätzliches oder grob fahrlässiges Verhalten oder die Verletzung einer vertragswesentlichen Pflicht vorliegt. Gleiches gilt, soweit der Ersatz von mittelbaren oder Mangelfolgeschäden verlangt wird. Jede Haftung ist auf den bei Vertragsschluss vorhersehbaren und für solche Fälle typischen Schaden begrenzt.
          </p>

          <h3>
            17.    Dienst- und Werkverträge
          </h3>
          <p>
            Soweit bei der Nutzung von Solutiness Verträge zwischen Betrieb und Kunden geschlossen werden, ist Solutiness nicht Vertragspartner. Solutiness haftet insb. nicht für Identität und Solvenz des Kunden oder die Erbringung von in diesen Verträgen eingegangenen Verpflichtungen.
          </p>

          <h3>
            18.    Textform
          </h3>
          <p>
            Erklärungen gegenüber Solutiness sowie Änderungen des Vertrages bedürfen zumindest der Textform (e-Mail, Fax).
          </p>

          <h3>
            19.    Datenschutz
          </h3>
          <p>
            <strong>(1)</strong>
            {' '}
            Die Daten des Betriebs werden von Solutiness zur Vertragsabwicklung und zur Kommunikation gespeichert.
          </p>
          <p>
            <strong>(2)</strong>
            {' '}
            Die Daten der Kunden (Mobilnummer und Fotos) ausschließlich zum Zugriff durch den Betrieb bzw. dessen Kooperationspartner gespeichert.
          </p>
          <p>
            <strong>(3)</strong>
            {' '}
            Die Betriebe werden von Solutiness, solange sie dem nicht widersprechen, gelegentlich per Post oder e-Mail über Aktuelles informiert.
          </p>

          <h3>
            20.    Erfüllungsort, Gerichtsstand, Rechtswahl
          </h3>
          <p>
            Erfüllungsort für alle Verpflichtungen und Gerichtsstand für alle Streitigkeiten aus dem Vertragsverhältnis ist, soweit es sich beim Vertragspartner um einen Kaufmann oder eine juristische Person öffentlichen Rechts handelt, Freiburg/Br. Das Vertragsverhältnis unterliegt deutschem Recht.
          </p>

          <h3>
            21.    Salvatorische Klausel
          </h3>
          <p>
            Sollte eine Klausel dieser Bedingungen unwirksam sein, bleibt die Wirksamkeit der übrigen Bedingungen unberührt.
          </p>
        </div>
        <p>
          Stand: 23.09.2019
        </p>

        <div className="text-center" style={{ marginTop: '15px' }}>
          <Link to="/">
            {formatMessage(messages.BACK_TO_STARTPAGE)}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(TermsPageComponent);
