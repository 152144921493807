import React from 'react';

import UserVideo from './UserVideo';
import UserVideoDrawing from './UserVideoDrawing';
import UserVideoStatus from './UserVideoStatus';
import VideoError from '../../video/components/VideoError';

export default function UserVideoContainer(props) {
  const {
    activeCallCreatedAndRunning,
    call,
    closeUserVideo,
    connected,
    connection,
    handleStopCall,
    room,
  } = props;

  if (!call) {
    return null;
  }

  if (connection.subscribeError) {
    return (
      <div className="video-status-container">
        <div className="video-status-error">
          <VideoError error={connection.subscribeError} room={room} />
        </div>
      </div>
      );
  }

  if (!connected) {
    return (
      <UserVideoStatus
        activeCallCreatedAndRunning={activeCallCreatedAndRunning}
        handleStopCall={handleStopCall}
        {...props}
      />
    );
  } else if (false) {
    return (
      <UserVideoDrawing
        className={activeCallCreatedAndRunning && connected ? 'active' : ''}
        closeUserVideo={closeUserVideo}
        handleStopCall={handleStopCall}
        {...props}
      />);
  }

  return (
    <UserVideo
      className={activeCallCreatedAndRunning && connected ? 'active' : ''}
      closeUserVideo={closeUserVideo}
      handleStopCall={handleStopCall}
      {...props}
    />
  );
}
