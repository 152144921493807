import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  CONTACT_PERSON: {
    id: 'PaymentInfo.CONTACT_PERSON',
    defaultMessage: 'Ansprechpartner',
  },
  MALE: {
    id: 'PaymentInfo.MALE',
    defaultMessage: 'Herr',
  },
  FEMALE: {
    id: 'PaymentInfo.FEMALE',
    defaultMessage: 'Frau',
  },
  BILLING_ADDRESS: {
    id: 'PaymentInfo.BILLING_ADDRESS',
    defaultMessage: 'Rechnungsadresse',
  },
  SEPA: {
    id: 'PaymentInfo.SEPA',
    defaultMessage: 'SEPA-Lastschrift',
  },
  TAX_ID: {
    id: 'PaymentInfo.TAX_ID',
    defaultMessage: 'Steuer-ID',
  },
});

function PaymentInfo(props) {
  const { payment, intl: { formatMessage } } = props;

  return (
    <div>
      <h4>{formatMessage(messages.CONTACT_PERSON)}</h4>
      <p>
        {payment.contactSalutation === 'm' ? `${formatMessage(messages.MALE)} ` : `${formatMessage(messages.FEMALE)} `}
        {payment.contactFirstname} {payment.contactLastname}
      </p>
      <h4>{formatMessage(messages.BILLING_ADDRESS)}</h4>
      <p>
        {payment.addressName}<br />
        {payment.addressStreet} {payment.addressStreetnumber}<br />
        {payment.addressZipcode} {payment.addressCity}<br />
        {payment.addressCountry}
      </p>
      <h4>{formatMessage(messages.SEPA)}</h4>
      <p>
        IBAN: {payment.iban}<br />
        {payment.addressCountry === 'Deutschland' ? null : (
          <span>BIC: {payment.bic}<br /></span>
        )}
        {formatMessage(messages.TAX_ID)}: {payment.taxid}
      </p>
    </div>
  );
}

export default injectIntl(PaymentInfo);
