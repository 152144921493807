import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router';

const messages = defineMessages({
  END_CONVERSATIONEN: {
    id: 'AfterCallPage.END_CONVERSATIONEN',
    defaultMessage: 'Gespräch beendet.',
  },
  RESUME_CONVERSATION: {
    id: 'AfterCallPage.RESUME_CONVERSATION',
    defaultMessage: 'Sollten sie das Gespräch wiederaufnehmen '
      + 'wollen klicken sie bitte hier:',
  },
  BACK_TO_CONVERSATION: {
    id: 'AfterCallPage.BACK_TO_CONVERSATION',
    defaultMessage: 'Zurück zum Gespräch',
  },
  CREDITS: {
    id: 'AfterCallPage.CREDITS',
    defaultMessage: 'Wir danken Ihnen für die Nutzung von tele-LOOK.',
  },
  QUESTIONS: {
    id: 'AfterCallPage.QUESTIONS',
    defaultMessage: 'Sollten Sie weitere Fragen oder Anregungen haben bitte kontaktieren sie uns unter ',
  },
  BACK_TO_STARTPAGE: {
    id: 'AfterCallPage.BACK_TO_STARTPAGE',
    defaultMessage: 'Zur Startseite',
  },
});

function AfterCallPage(props) {
  const { intl: { formatMessage }, params: { roomName, companyId } } = props;

  let pathName = `/v/${roomName}`;
  if (companyId) {
    pathName = `custom/${companyId}/v/${roomName}`;
  }

  return (
    <div className="flex">
      <div className="flex-box home-content">
        <h1>{formatMessage(messages.END_CONVERSATIONEN)}</h1>
        <p style={{ marginBottom: '15px' }}>
          {formatMessage(messages.RESUME_CONVERSATION)}
        </p>
        <p style={{ marginTop: '15px' }}>
          <Link to={pathName}>{formatMessage(messages.BACK_TO_CONVERSATION)}</Link>
        </p>
        <p>
          {formatMessage(messages.CREDITS)}
          <br />
          {formatMessage(messages.QUESTIONS)} <a href="mailto:support@tele-look.com">support@tele-look.com</a>.
        </p>
        <br />
        <div>
          <Link to="/">{formatMessage(messages.BACK_TO_STARTPAGE)}</Link>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(AfterCallPage);
