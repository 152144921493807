import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Link } from 'react-router';
import { FORM_ERROR } from 'final-form';

import { changePassword2 } from '../../actions/auth';
import NavBarConfig from '../../components/NavBarConfig';
import ResetPasswordSettingsForm from '../../components/forms/ResetPasswordSettingsForm';


const messages = defineMessages({
  CHANGE_PASSWORD: {
    id: 'SettingsPasswordPage.CHANGE_PASSWORD',
    defaultMessage: 'Passwort ändern',
  },
  BACK: {
    id: 'SettingsPasswordPage.BACK',
    defaultMessage: 'Zurück',
  },
});

function SettingsPasswordPage({ onSubmit, routes, intl: { formatMessage } }) {
  return (
    <div>
      <NavBarConfig currentRouteName={routes[routes.length - 1].name} />
      <div className="m40 w-xs">
        <Link to="/settings/">{formatMessage(messages.BACK)}</Link>
        <h3>{formatMessage(messages.CHANGE_PASSWORD)}</h3>
        <ResetPasswordSettingsForm onSubmit={onSubmit} />
      </div>
    </div>
  );
}

export default connect(null,
  dispatch => ({
    onSubmit: async formData => {
      const data = await changePassword2(formData);
      if (data.response) {
        dispatch(push('/settings/password/success/'));
      } else {
        return { [FORM_ERROR]: data.error[Object.keys(data.error)[0]] };
      }
    },
  })
)(injectIntl(SettingsPasswordPage));
