import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';

import TextField from 'components/form/TextField';
import FormGroup2 from 'components/form/FormGroup';
import FormError from 'components/form/FormError';
import FormSubmit from 'components/form/FormSubmit';

const messages = defineMessages({
  PLACEHOLDER_EMAIL_OR_PHONE: {
    id: 'RequestPasswordResetForm.PLACEHOLDER_EMAIL_OR_PHONE',
    defaultMessage: 'E-Mail Adresse oder Telefonnummer',
  },
  LABEL_SEND_MESSAGE: {
    id: 'RequestPasswordResetForm.LABEL_SEND_MESSAGE',
    defaultMessage: 'Nachricht versenden',
  },
  VALIDATION_FIELD_REQUIRED: {
    id: 'RequestPasswordResetForm.VALIDATION_FIELD_REQUIRED',
    defaultMessage: 'Dieses Feld ist erforderlich.',
  },
});

function RequestPasswordResetForm(props) {
  const {
    intl: { formatMessage },
  } = props;
  return (
    <Form
      onSubmit={props.onSubmit}
      validate={values => {
        const errors = {};
        if (!values.contact) {
          errors.contact = formatMessage(messages.VALIDATION_FIELD_REQUIRED);
        }
        return errors;
      }
      }
    >
      {({ submitError, handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field name="contact">
            {({ input, meta }) => {
              return (
                <FormGroup2 input={input} meta={meta}>
                  <TextField
                    {...input}
                    tabIndex="1"
                    placeholder={formatMessage(messages.PLACEHOLDER_EMAIL_OR_PHONE)}
                    autoCapitalize="off"
                    autoCorrect="off"
                    autoFocus
                    autoComplete
                  />
                </FormGroup2>
              )
            }}
          </Field>
          <FormError error={submitError} />
          <FormSubmit
            btnClass="btn-lg btn-primary btn-block"
            btnProps={{ tabIndex: 3 }}
            submitting={submitting}
            label={formatMessage(messages.LABEL_SEND_MESSAGE)}
          />
        </form>
      )}
    </Form>

  );
}

/*
const RequestPasswordResetFormReduxForm = reduxForm({
  form: 'requestPasswordReset',
  fields: ['contact'],
  validate: (values, { intl: { formatMessage } }) => ({
    contact: values.contact ? null : formatMessage(messages.VALIDATION_FIELD_REQUIRED),
  }),
  touchOnBlur: false,
})(RequestPasswordResetForm);
*/

export default injectIntl(RequestPasswordResetForm);
