import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';
import api from './api';
import {
  COMPANY_LIST, COMPANY_SINGLE,
  COMPANY_CREATED, COMPANY_UPDATED, COMPANY_DELETED,
  COMPANY_USER_LIST, COMPANY_USER_SINGLE,
  COMPANY_USER_CREATED, COMPANY_USER_UPDATED, COMPANY_USER_DELETED,
  CUSTOMER_LIST, CUSTOMER_SINGLE,
  ORDER_LIST, ORDER_CREATED,
  CUSTOMER_CREATED, CUSTOMER_UPDATED, CUSTOMER_DELETED,
  COMPANY_MEMBERSHIP,
  EVENT_LIST,
  EVENT_CREATED,
  EVENT_DELETED,
  // NOTE_CREATED,
  // NOTE_WITH_CALL_CREATED,
  ORDER_SELECTED, CALL_ADDED_TO_ORDER, NOTE_ADDED_TO_ORDER,
  ORDER_SIDEBAR_TOGGLED,
  END_NOTE_CREATED,
  OPEN_CALL_BEGIN_MODAL, CLOSE_CALL_BEGIN_MODAL,
  OPEN_CALL_END_MODAL, CLOSE_CALL_END_MODAL,
  SET_LAST_CALL,
  UPDATE_CALL,
  EVENT_UPDATED,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  REGISTRATION_CONFIRMATION_SUCCESS,
  REGISTRATION_CONFIRMATION_FAIL,
  CALL_CREATED,
} from './constants';
import { activeCallGet } from './video';

export const registrationConfirmationSuccess = createAction(REGISTRATION_CONFIRMATION_SUCCESS);
export const registrationConfirmationFail = createAction(REGISTRATION_CONFIRMATION_FAIL);

export const orderSelected = createAction(ORDER_SELECTED);
export const toggleOrderSidebar = createAction(ORDER_SIDEBAR_TOGGLED);

export const openCallBeginModal = createAction(OPEN_CALL_BEGIN_MODAL);
export const closeCallBeginModal = createAction(CLOSE_CALL_BEGIN_MODAL);
export const openCallEndModal = createAction(OPEN_CALL_END_MODAL);
export const closeCallEndModal = createAction(CLOSE_CALL_END_MODAL);

export const companyCreated = createAction(COMPANY_CREATED);
export const createCompany = data => api.post({
  url: '/api/company/',
  data,
});

export const companyUpdated = createAction(COMPANY_UPDATED);
export const updateCompany = (companyId, data) => api.put({
  url: `/api/company/${companyId}/`,
  data,
});

export const updateCompany2 = (companyId, data) => api._put2({
  url: `/api/company/${companyId}/`,
  data,
});

export const companyDeleted = createAction(COMPANY_DELETED);
export const deleteCompany = companyId => api.del({
  url: `/api/company/${companyId}/`,
});

export const deleteCompany2 = companyId => api._del2({
  url: `/api/company/${companyId}/`,
});

export const setLastCall = createAction(SET_LAST_CALL);
export const updateCall = createAction(UPDATE_CALL);

export const createNoteWithCall = (companyId, customerId, callId, data) => api.post({
  url: `/api/company/${companyId}/customer/${customerId}/call/${callId}/note`,
  data,
});

export const endNoteCreated = createAction(END_NOTE_CREATED);
export const createEndNote = (companyId, customerId, callId, data) => api.put({
  url: `/api/company/${companyId}/customer/${customerId}/call/${callId}/end_note`,
  data,
});

// TODO --> /api/company/${companyId}/customer/${customerId} ?
export const createNote = (companyId, customerId, orderId, data) => api.post({
  url: `/api/company/${companyId}/customer/${customerId}/order/${Math.max(orderId, 0)}/note`,
  data,
});

export const companyUserCreated = createAction(COMPANY_USER_CREATED);
export const createCompanyUser = (companyId, data) => api.post({
  url: `/api/company/${companyId}/user/`,
  data,
});
export const createCompanyUser2 = (companyId, data) => api._post2({
  url: `/api/company/${companyId}/user/`,
  data,
});

export const orderCreated = createAction(ORDER_CREATED);
export const createOrder = (companyId, customerId, data) => api.post({
  url: `/api/company/${companyId}/customer/${customerId}/order`,
  data,
});


export const callAddedToOrder = createAction(CALL_ADDED_TO_ORDER);
export const addCallToOrder = (companyId, customerId, orderId, data) => api.post({
  url: `/api/company/${companyId}/customer/${customerId}/order/${orderId}/call`,
  data,
});

export const noteAddedToOrder = createAction(NOTE_ADDED_TO_ORDER);
export const addNoteToOrder = (companyId, customerId, orderId, data) => api.put({
  url: `/api/company/${companyId}/customer/${customerId}/order/${orderId}/note/move`,
  data,
});

export const ORDER_UPDATED = 'ORDER_UPDATED';

export const orderUpdated = createAction(ORDER_UPDATED);
export const orderSetActive = (companyId, customerId, orderId, data) => api.put({
  url: `/api/company/${companyId}/customer/${customerId}/order/${orderId}/unfinish`,
  data,
});
export const orderSetInactive = (companyId, customerId, orderId, data) => api.put({
  url: `/api/company/${companyId}/customer/${customerId}/order/${orderId}/finish`,
  data,
});

export const companyUserUpdated = createAction(COMPANY_USER_UPDATED);
export const updateCompanyUser = (companyId, userId, data) => api.put({
  url: `/api/company/${companyId}/user/${userId}/`,
  data,
});

export const updateCompanyUser2 = (companyId, userId, data) => api._put2({
  url: `/api/company/${companyId}/user/${userId}/`,
  data,
});

export const companyUserDeleted = createAction(COMPANY_USER_DELETED);
export const deleteCompanyUser = (companyId, userId) => api.del({
  url: `/api/company/${companyId}/user/${userId}/`,
});

export const customerCreated = createAction(CUSTOMER_CREATED);
export const createCustomer = (companyId, data) => api.post({
  url: `/api/company/${companyId}/customer/`,
  data,
});

export const createCustomer2 = (companyId, data) => api._post2({
  url: `/api/company/${companyId}/customer/`,
  data,
});

export const customerUpdated = createAction(CUSTOMER_UPDATED);
export const updateCustomer = (companyId, customerId, data) => api.put({
  url: `/api/company/${companyId}/customer/${customerId}/`,
  data,
});

export const customerDeleted = createAction(CUSTOMER_DELETED);
export const deleteCustomer = (companyId, customerId) => api.del({
  url: `/api/company/${companyId}/customer/${customerId}/`,
});

export const deleteCustomer2 = (companyId, customerId) => api._del2({
  url: `/api/company/${companyId}/customer/${customerId}/`,
});

export const resetPasswordSendSms = (companyId, userId) => api.post({
  url: `/api/company/${companyId}/user/${userId}/reset_password/sms`,
});
export const resetPasswordSendEmail = (companyId, userId) => api.post({
  url: `/api/company/${companyId}/user/${userId}/reset_password/email`,
});

export const eventCreated = createAction(EVENT_CREATED);
export const eventUpdated = createAction(EVENT_UPDATED);

export const createConversation = createAction(EVENT_CREATED,
    (companyId, customerId, data) => api.post({
      url: `/api/company/${companyId}/customer/${customerId}/conversation/`,
      data,
    })
);

export const eventDeleted = createAction(EVENT_DELETED);
export const deleteEvent = (companyId, customerId, eventId) => api.del({
  url: `/api/company/${companyId}/customer/${customerId}/event/${eventId}`,
});

export const createSMS = createAction(EVENT_CREATED,
    (companyId, customerId, callId) => api.post({
      url: `/api/company/${companyId}/customer/${customerId}/call/${callId}/sms/`,
    })
);

export const checkRoom = data => api.post({ url: '/api/room/', data });
export const checkRoom2 = data => api._post2({ url: '/api/room/', data });


export const resetPassword = (password, userId, token) => api.post({
  url: `/api/reset_password/${userId}/${token}/`, password,
});

export const checkToken = (userId, token) => api.get({
  url: `/api/reset_password/${userId}/${token}/`,
});

export const getActiveCall = (companyId, customerId) => api.get({
  url: `/api/company/${companyId}/customer/${customerId}/call/active`
});

export const confirmRegistration = (userId, token) => api.get({
  url: `/api/confirm_registration/${userId}/${token}/`,
});

export const registerUser = data => api.post({
  url: '/api/register',
  data,
});

export const registerSuccess = createAction(REGISTER_SUCCESS);
export const registerFail = createAction(REGISTER_FAIL);

export function createApiRequestActions(actions) {
  return {
    load: createAction(actions.LOAD),
    request: createAction(actions.REQUEST),
    success: createAction(actions.SUCCESS),
    failure: createAction(actions.FAILURE),
    search: createAction(actions.SEARCH),
  };
}

export const companyList = createApiRequestActions(COMPANY_LIST);
export const companySingle = createApiRequestActions(COMPANY_SINGLE);
export const companyUserList = createApiRequestActions(COMPANY_USER_LIST);
export const companyUserSingle = createApiRequestActions(COMPANY_USER_SINGLE);
export const customerList = createApiRequestActions(CUSTOMER_LIST);
export const customerSingle = createApiRequestActions(CUSTOMER_SINGLE);
export const companyMembership = createApiRequestActions(COMPANY_MEMBERSHIP);
export const eventList = createApiRequestActions(EVENT_LIST);
export const orderList = createApiRequestActions(ORDER_LIST);


const emptyState = {
  isFetching: false,
  filteredResults: [],
  filter: ''
};

function filterList(list, filter) {
  let newList = [...list];
  if (list && filter && filter !== '') {
      const filterToLower = filter.toLowerCase();
      newList = list.filter(item => {
      if (item.name.toLowerCase().indexOf(filterToLower) > -1
          || (item.phone && item.phone.toLowerCase().indexOf(filterToLower) > -1)
          || (item.last_event && item.last_event.text
              && item.last_event.text.toLowerCase().indexOf(filterToLower) > -1)
      ) {
        return true;
      }
      return false;
    });
  }
  return newList;
}

function handleApiRequestActions(actions, extra = {}) {
  return handleActions({
    [actions.LOAD]: (state) => ({
      ...emptyState,
      filter: state.filter
    }),
    [actions.REQUEST]: (state) => ({
      ...emptyState,
      filter: state.filter,
      isFetching: true,
    }),
    [actions.SUCCESS]: (state, action) => {
      const newFilteredResults = filterList(action.payload.results, state.filter);
      return {
      ...emptyState,
      ...action.payload,
      filteredResults: newFilteredResults,
      isFetching: false,
    };
    },
    [actions.FAILURE]: (state, action) => ({
      ...emptyState,
      error: action.payload,
      isFetching: false,
    }),
    [actions.SEARCH]: (state, action) => {
      const newFilteredResults = filterList(state.results, action.payload);
      return {
        ...state,
        filteredResults: newFilteredResults,
        filter: action.payload
      }
    },
    ...extra,
  }, {});
}

export const companyListReducer = handleApiRequestActions(COMPANY_LIST);
export const companyUserListReducer = handleApiRequestActions(COMPANY_USER_LIST);
export const customerListReducer = handleApiRequestActions(CUSTOMER_LIST);

export const eventListReducer = handleApiRequestActions(EVENT_LIST, {
  [EVENT_CREATED]: (state, action) => {
    if (state.results) {
      return {
        ...state,
        results: [...state.results, action.payload],
      };
    }
    return state;
  },
});

function replaceObjectInList(obj, list) {
  const orderIndex = _.findIndex(list, o => (o.id === obj.id));
  return [
    ...list.slice(0, orderIndex),
    obj,
    ...list.slice(orderIndex + 1),
  ];
}

function reduceCallToOrder(order, call) {
  return Object.assign({}, order, {
    calls: [...order.calls, call],
  });
}

function addEventToOrder(order, event) {
  const newKey = {};
  if (event.call_id <= 0) {
    newKey.notes = [...order.notes, event];
  } else if (event.sms || event.call_event_type || event.call_id > 0) {
    const callIndex = _.findIndex(order.calls, call => (call.id === event.call_id));
    const call = order.calls[callIndex];
    newKey.calls = [
      ...order.calls.slice(0, callIndex),
            { ...call, events: [...call.events, event] },
      ...order.calls.slice(callIndex + 1),
    ];
  }
  return Object.assign({}, order, newKey);
}

function addEndNoteToOrder(order, event) {
  const newKey = {};
  const callIndex = _.findIndex(order.calls, call => (call.id === event.call_id));
  const call = order.calls[callIndex];
  const newCall = Object.assign({}, call, {
    end_note: event,
  });
  newKey.calls = [
    ...order.calls.slice(0, callIndex),
    newCall,
    ...order.calls.slice(callIndex + 1),
  ];
  return Object.assign({}, order, newKey);
}

function updateCallInOrder(order, newCall) {
  const callIndex = _.findIndex(order.calls, call => (call.id === newCall.id));

  return Object.assign({}, order, {
    calls: [
      ...order.calls.slice(0, callIndex),
      newCall,
      ...order.calls.slice(callIndex + 1),
    ],
  });
}

// TODO: wording updateEvent is not 100% correct: should be more specific,
// as the implementation only allows notes to be edited

function updateEventFromOrder(order, event) {
  if (event.call_id > 0) {
    const callIndex = _.findIndex(order.calls, c => c.id === event.call_id);
    const call = order.calls[callIndex];
    const eventIndex = _.findIndex(call.events, n => (n.id === event.id));
    return Object.assign({}, order, {
      calls: [
        ...order.calls.slice(0, callIndex),
        Object.assign({}, call, (() => {
          if (event.call_event_type === 'end_note') {
            return {
              end_note: event,
            };
          }

          return {
            events: [
              ...call.events.slice(0, eventIndex),
              event,
              ...call.events.slice(eventIndex + 1),
            ],
          };
        })()),
        ...order.calls.slice(callIndex + 1),
      ],
    });
  }
  const eventIndex = _.findIndex(order.notes, n => (n.id === event.id));
  return {
    ...order,
    notes: [
      ...order.notes.slice(0, eventIndex),
      event,
      ...order.notes.slice(eventIndex + 1),
    ],
  };
}

function deleteEventFromOrder(order, event) {
  if (event.call_id > 0) {
    const callIndex = _.findIndex(order.calls, c => c.id === event.call_id);
    const call = order.calls[callIndex];
    const eventIndex = _.findIndex(call.events, n => (n.id === event.id));

    return Object.assign({}, order, {
      calls: [
        ...order.calls.slice(0, callIndex),
        Object.assign({}, call, {
          events: [
            ...call.events.slice(0, eventIndex),
            ...call.events.slice(eventIndex + 1),
          ],
        }),
        ...order.calls.slice(callIndex + 1),
      ],
    });
  }

  const newKey = {};
  const eventIndex = _.findIndex(order.notes, n => (n.id === event.id));
  newKey.notes = [
    ...order.notes.slice(0, eventIndex),
    ...order.notes.slice(eventIndex + 1),
  ];
  return Object.assign({}, order, newKey);
}

export const orderListReducer = handleApiRequestActions(ORDER_LIST, {
  [ORDER_CREATED]: (state, action) => {
    if (state.results) {
      return {
        ...state,
        results: [...state.results, action.payload],
      };
    }
    return state;
  },
  [CALL_ADDED_TO_ORDER]: (state, action) => ({
    ...state, results: action.payload.orders,
  }),
  [NOTE_ADDED_TO_ORDER]: (state, action) => ({
    ...state, results: action.payload.orders,
  }),
  [ORDER_UPDATED]: (state, action) => ({
    ...state,
    results: replaceObjectInList(action.payload, state.results),
  }),
  [EVENT_CREATED]: (state, action) => {
    const event = action.payload;
    const orderIndex = _.findIndex(state.results, order => (order.id === (event.order_id || -1)));
    const order = state.results[orderIndex];
    return {
      ...state,
      results: [
        ...state.results.slice(0, orderIndex),
        addEventToOrder(order, event),
        ...state.results.slice(orderIndex + 1),
      ],
    };
  },
  [UPDATE_CALL]: (state, action) => {
    const call = action.payload;
    const orderIndex = _.findIndex(state.results, order => (order.id === (call.order_id || -1)));
    const order = state.results[orderIndex];
    return {
      ...state,
      results: [
        ...state.results.slice(0, orderIndex),
        updateCallInOrder(order, call),
        ...state.results.slice(orderIndex + 1),
      ],
    };
  },
  [END_NOTE_CREATED]: (state, action) => {
    const event = action.payload;
    const orderIndex = _.findIndex(state.results, order => (order.id === (event.order_id || -1)));
    const order = state.results[orderIndex];
    return {
      ...state,
      results: [
        ...state.results.slice(0, orderIndex),
        addEndNoteToOrder(order, event),
        ...state.results.slice(orderIndex + 1),
      ],
    };
  },
  [EVENT_DELETED]: (state, action) => {
    const event = action.payload;
    const orderIndex = _.findIndex(state.results, order => (order.id === (event.order_id || -1)));
    const order = state.results[orderIndex];
    return {
      ...state,
      results: [
        ...state.results.slice(0, orderIndex),
        deleteEventFromOrder(order, event),
        ...state.results.slice(orderIndex + 1),
      ],
    };
  },
  [EVENT_UPDATED]: (state, action) => {
    const event = action.payload;
    //console.log(event.order_id);
    const orderIndex = _.findIndex(state.results, order => (order.id === (event.order_id || -1)));
    const order = state.results[orderIndex];
    return {
      ...state,
      results: [
        ...state.results.slice(0, orderIndex),
        updateEventFromOrder(order, event),
        ...state.results.slice(orderIndex + 1),
      ],
    };
  },
  [CALL_CREATED]: (state, action) => {
    const { call } = action.payload;
    const orderIndex = _.findIndex(state.results, order => (order.id == (call.order_id || -1)));
    const order = state.results[orderIndex];
    return {
      ...state,
      results: [
        ...state.results.slice(0, orderIndex),
        reduceCallToOrder(order, call),
        ...state.results.slice(orderIndex + 1),
      ],
    };
  },
});

export const companySingleReducer = handleActions({
  [COMPANY_SINGLE.LOAD]: () => ({
    ...emptyState,
  }),
  [COMPANY_SINGLE.REQUEST]: () => ({
    ...emptyState,
    isFetching: true,
  }),
  [COMPANY_SINGLE.SUCCESS]: (state, action) => ({
    ...state,
    company: action.payload,
    error: null,
    isFetching: false,
  }),
  [COMPANY_SINGLE.FAILURE]: (state, action) => ({
    ...state,
    company: null,
    error: action.payload,
    isFetching: false,
  }),
  [COMPANY_UPDATED]: (state, action) => ({
    ...state,
    company: action.payload,
  }),
  [COMPANY_MEMBERSHIP.REQUEST]: state => ({
    ...state,
    companyMembership: null,
  }),
  [COMPANY_MEMBERSHIP.SUCCESS]: (state, action) => ({
    ...state,
    companyMembership: action.payload,
  }),
  [COMPANY_MEMBERSHIP.FAILURE]: (state, action) => ({
    ...state,
    company: null,
    companyMembership: null,
    error: action.payload,
  }),
}, {});

export const companyUserSingleReducer = handleActions({
  [COMPANY_USER_SINGLE.LOAD]: () => ({
    ...emptyState,
  }),
  [COMPANY_USER_SINGLE.REQUEST]: () => ({
    ...emptyState,
    isFetching: true,
  }),
  [COMPANY_USER_SINGLE.SUCCESS]: (state, action) => ({
    ...emptyState,
    companyUser: action.payload,
    isFetching: false,
  }),
  [COMPANY_USER_SINGLE.FAILURE]: (state, action) => ({
    ...emptyState,
    error: action.payload,
    isFetching: false,
  }),
  [COMPANY_USER_UPDATED]: (state, action) => ({
    ...state,
    companyUser: action.payload,
  }),
}, {});

export const customerSingleReducer = handleActions({
  [CUSTOMER_SINGLE.LOAD]: () => ({
    ...emptyState,
  }),
  [CUSTOMER_SINGLE.REQUEST]: () => ({
    ...emptyState,
    isFetching: true,
  }),
  [CUSTOMER_SINGLE.SUCCESS]: (state, action) => ({
    ...emptyState,
    customer: action.payload,
    isFetching: false,
  }),
  [CUSTOMER_SINGLE.FAILURE]: (state, action) => ({
    ...emptyState,
    error: action.payload,
    isFetching: false,
  }),
  [CUSTOMER_UPDATED]: (state, action) => ({
    ...state,
    customer: action.payload,
  }),
}, {});

export const selectedOrderReducer = handleActions({
  [ORDER_SELECTED]: (state, action) => action.payload,
}, -2);

export const orderSidebarOpenReducer = handleActions({
  [ORDER_SIDEBAR_TOGGLED]: state => !state,
}, false);

export const callBeginModalActiveReducer = handleActions({
  [OPEN_CALL_BEGIN_MODAL]: () => true,
  [CLOSE_CALL_BEGIN_MODAL]: () => false,
}, false);

export const callEndModalActiveReducer = handleActions({
  [OPEN_CALL_END_MODAL]: () => true,
  [CLOSE_CALL_END_MODAL]: () => false,
}, false);

export const lastCallReducer = handleActions({
  [SET_LAST_CALL]: (state, action) => action.payload,
}, null);

export const registrationConfirmationReducer = handleActions({
  [REGISTRATION_CONFIRMATION_SUCCESS]: state => ({
    ...state,
    success: true,
    done: true,
  }),
  [REGISTRATION_CONFIRMATION_FAIL]: (state, action) => ({
    ...state,
    success: false,
    done: true,
    error: action.payload,
  }),
}, {
  success: false,
  done: false,
  error: null,
});

export const registrationReducer = handleActions({
  [REGISTER_SUCCESS]: state => ({
    ...state,
    success: true,
    error: null,
  }),
  [REGISTER_FAIL]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
}, {
  success: false,
  error: null,
});
