import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';
import { DragSource } from 'react-dnd';
import classnames from 'classnames';
import { Link } from 'react-router';
import { DropdownButton, MenuItem } from 'react-bootstrap';

import { DATE_FORMAT } from '../../../../config';
import { CALL } from '../../../../helpers/dragTypes';
import Capitals from '../Capitals';
import EventNote from './EventNote';

require('moment-duration-format');

const callSource = {
  beginDrag(props) {
    return props.call;
  },
  endDrag(props, monitor) {  // , component) {
    if (!monitor.didDrop()) {
      return;
    }
    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();
        // dispatch action
    props.handleDragging(item, dropResult);
  },
  canDrag({ call, canDrag }) {
    return call.can_drag && canDrag;
  },
};

const messages = defineMessages({
  DRAGGING: {
    id: 'Call.DRAGGING',
    defaultMessage: 'Dragging',
  },
  CONVERSATION_CREATED: {
    id: 'Call.CONVERSATION_CREATED',
    defaultMessage: 'Gespräch erstellt',
  },
  ASSIGN_TO_PROJECT: {
    id: 'Call.ASSIGN_TO_PROJECT',
    defaultMessage: 'Zu Projekt zuordnen',
  },
  TITLE_CLICK_AND_DRAG: {
    id: 'Call.TITLE_CLICK_AND_DRAG',
    defaultMessage: 'Klicken und ziehen Sie den Anruf auf ein Projekt, um ihn dem Projekt zuzuordnen.',
  },
  CLASSIFY_PROJECT: {
    id: 'Call.CLASSIFY_PROJECT',
    defaultMessage: 'Eingeordnet im Projekt',
  },
  SMS_SENDED: {
    id: 'Call.SMS_SENDED',
    defaultMessage: 'Eine SMS mit der Einstiegs-URL wurde an den Kunden versendet.',
  },
  CONVERSATION_STARTED: {
    id: 'Call.CONVERSATION_STARTED',
    defaultMessage: 'Gespräch angefangen',
  },
  CONVERSATIONEN_FINISHED: {
    id: 'Call.CONVERSATIONEN_FINISHED',
    defaultMessage: 'Gespräch beendet - Gesprächsdauer',
  },
  CONVERSATION_CANCELED: {
    id: 'Call.CONVERSATION_CANCELED',
    defaultMessage: 'Gespräch vorzeitig abgebrochen',
  },
});

function extractBegin(events) {
  const begin = _.find(events, e => e.call_event_type === 'begin') || null;
  return begin;
}

function extractEnd(events) {
  const end = _.find(events, e => e.call_event_type === 'end') || null;
  return end;
}

function filterBeginAndEnd(events) {
  return events.filter(e => e.call_event_type !== 'end');
}

function renderDate(date) {
  const momObj = typeof date === 'string' ? moment(date, DATE_FORMAT) : moment(date);
  return `${momObj.format('DD.MM.YY HH:mm')} Uhr`;
}

@injectIntl
@DragSource(CALL, callSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))
class Call extends React.Component {
  render() {
    const {
      call,
      customer,
      isDragging,
      connectDragSource,
      connectDragPreview,
      orders,
      intl: { formatMessage },
    } = this.props;

    const events = call.events || [];
    const begin = extractBegin(events);
    const end = extractEnd(events);
    const eventsToRender = filterBeginAndEnd(events);
    connectDragPreview(<h1>{formatMessage(messages.DRAGGING)}</h1>);
    const activeOrders = orders ? orders.filter(order => order.active).filter(order => order.id !== -1) : [];
    return (
      <div>
        <div className={classnames('call show-dots', { dragging: isDragging })}>
          <div className="event-row call-begin">
            <div className="event-row-left-col">
              <div className="event-icon begin">
                <i className="icon-phone" />
              </div>
            </div>
            <div className="event-row-right-col">
              <div className="event-row-header">
                <p className="call-title">{formatMessage(messages.CONVERSATION_CREATED)}</p>
                {call.order_name && (
                <span className="ordered-in">
                  <span className="menu-item-responsive">
                    <span>
                      {formatMessage(messages.CLASSIFY_PROJECT)}
                    </span>
                    <i className="fa fa-bookmark" />
                  </span>
                  <Link
                    title={call.order_name}
                    className="text-overflow max400"
                    to={`/company/${customer.company_id}/customer/${customer.id}/order/${call.order_id}`}
                  >
                    {call.order_name}
                  </Link>
                </span>
                                )}
                {activeOrders && activeOrders.length ? (
                  <DropdownButton
                    pullRight
                    title=""
                    id={`call${call.id}`}
                  >
                    <MenuItem header id="Zu Projekt zuordnen">{formatMessage(messages.ASSIGN_TO_PROJECT)}</MenuItem>
                    {activeOrders.map(order => (
                      <MenuItem
                        key={order.id}
                        eventKey={order.id}
                        onClick={() => this.props.addCallToOrder(call, order)}
                      >
                        {order.name}
                      </MenuItem>
                                        ))}
                  </DropdownButton>
                                ) : null}
                {activeOrders && activeOrders.length ? (
                  connectDragSource(
                    <i
                      className="drag-handle fa fa-th"
                      title={formatMessage(messages.TITLE_CLICK_AND_DRAG)}
                    />
                  )
                ) : null}
                <div className="event-row-header-date-time">
                  {renderDate(call.created)}
                </div>
              </div>
            </div>
          </div>
          {eventsToRender
          .filter(event => (
              !event.call_event_type
              || (event.call_event_type && event.call_event_type !== 'end_note')
          ))
          .map(event => (
          (event.text || event.pictures.length > 0) ? (
            <EventNote
              key={event.id}
              evt={event}
              deleteEvent={this.props.deleteEvent}
              updateEvent={this.props.updateEvent}
              customer={customer}
              call={call}
            />
          ) : (
            <div key={event.id} className="event-row">
              <div className="event-row-left-col">
                {event.text ? (
                  <Capitals customer={{ name: call.user_name }} />
                ) : (
                  event.sms ? (
                    <div className="avatar">
                      <i className="fa fa-envelope-o" />
                    </div>
                  ) : (
                    event.call_event_type ? (
                      <div className="avatar">
                        <i className="fa fa-phone" />
                      </div>
                    ) : null
                  )
                )}
              </div>
              <div className="event-row-right-col">
                <div className="event-row-header">
                  {event.sms ? (
                    <p className="event-row-text">
                      {formatMessage(messages.SMS_SENDED)}
                    </p>
                  ) : null}
                  {event.call_event_type ? (
                      event.call_event_type === 'begin' ? (
                        <p className="event-row-text">{formatMessage(messages.CONVERSATION_STARTED)}</p>
                      ) : null
                  ) : null}
                  <div className="event-row-header-date-time">
                    {renderDate(event.created)}
                  </div>
                </div>
              </div>
            </div>
          )
          ))}
          {call.end_note && (
            <EventNote
              evt={call.end_note}
              deleteEvent={this.props.deleteEvent}
              updateEvent={this.props.updateEvent}
              customer={customer}
              call={call}
              endNote
            />
          )}

          {end && (
            <div className="event-row call-end">
              <div className="event-row-left-col">
                <div className="event-icon end">
                  <i className="icon-phone-down" />
                </div>
              </div>
              <div className="event-row-right-col">
                <div className="event-row-header">
                  <p className="call-title">
                    {begin ? [
                      formatMessage(messages.CONVERSATIONEN_FINISHED),
                      ' ',
                      moment.duration(call.duration, 'seconds').format('mm:ss', { trim: false }),
                      ' min',
                    ] : (
                      formatMessage(messages.CONVERSATION_CANCELED)
                    )}
                  </p>
                  <div className="event-row-header-date-time">
                    {renderDate(end.created)}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Call;
