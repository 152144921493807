import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { updateCompanyUser2, companyUserUpdated, createCompanyUser2, companyUserCreated } from 'actions/company';

import { Link } from 'react-router';
import NavBarConfig from 'components/NavBarConfig';
import CompanyUserUpdate from 'components/companyUser/CompanyUserUpdate';
import { FORM_ERROR } from 'final-form';

const messages = defineMessages({
  BACK: {
    id: 'CompanyUserUpdatePage.BACK',
    defaultMessage: 'Zurück',
  },
  EDIT_USER: {
    id: 'CompanyUserUpdatePage.EDIT_USER',
    defaultMessage: 'Benutzer bearbeiten',
  },
});

function CompanyUserUpdatePage({
  user,
  company,
  companyUser,
  onSubmit,
  routes,
  intl: { formatMessage },
}) {
  if (!companyUser) { return null; }

  //console.log(companyUser);

  return (
    <div>
      <NavBarConfig updatePage currentRouteName={routes[routes.length - 1].name} />
        <div className="m40 w-xs">
          <Link to={`/company/${company.id}/user/${companyUser.id}/`}>
            {formatMessage(messages.BACK)}
          </Link>
          <h3>{formatMessage(messages.EDIT_USER)}</h3>
          <CompanyUserUpdate
            is_admin={user.is_admin || user.is_staff}
            onSubmit={onSubmit}
            initialValues={{
              name: companyUser.user.name,
              email: companyUser.user.email,
              phone: companyUser.user.phone,
              is_admin: companyUser.is_admin,
              is_owner: companyUser.is_owner,
              is_inactive: companyUser.is_inactive,
            }}
          />
        </div>
    </div>
  );
}

export default connect(null,
  (dispatch, { params }) => ({
    onSubmit: async formData => {
      const data = await updateCompanyUser2(params.companyId, params.userId, {
        ...formData,
        email: formData.email || undefined,
        phone: formData.phone || undefined,
      });
      if (data.response) {
        const companyUser = data.response;
        dispatch(companyUserUpdated(companyUser));
        dispatch(push(`/company/${params.companyId}/user/${params.userId}/`));
      } else {
        return { [FORM_ERROR]: data.error[Object.keys(data.error)[0]] };
      }
    }
  })
)(injectIntl(CompanyUserUpdatePage));
