import React from 'react';

const ENTER_KEY_CODE = 13;
const ESC_KEY_CODE = 27;

export default class OrderTextInput extends React.Component {
  state = {
    isEditing: false,
    text: this.props.initialValue || '',
  }

  _commitChanges = () => {
    const newText = this.state.text.trim();
    if (this.props.onDelete && newText === '') {
      this.props.onDelete();
    } else if (this.props.onCancel && newText === this.props.initialValue) {
      this.props.onCancel();
    } else if (newText !== '') {
      this.props.onSave(newText);
      this.setState({ text: '' });
    }
  }

  render() {
    return (
      <input
        className={this.props.className}
        onChange={(e) => {
          this.setState({ text: e.target.value });
        }}
        onKeyDown={(e) => {
          if (this.props.onCancel && e.keyCode === ESC_KEY_CODE) {
            this.props.onCancel();
          } else if (e.keyCode === ENTER_KEY_CODE) {
            this._commitChanges();
          }
        }}
        placeholder={this.props.placeholder}
        value={this.state.text}
      />
    );
  }
}
