import { createAction, handleActions } from 'redux-actions';
import { get } from './api';

export const SET_USERS = 'SET_USERS';

export const getUsers = () => get({ url: '/api/user/' });
export const setUsers = createAction(SET_USERS);


const emptyState = {};


export const userListReducer = handleActions({
  [SET_USERS]: (state, action) => ({ ...action.payload }),
}, emptyState);
