import React, { Component, cloneElement } from 'react';
import { connect } from 'react-redux';
import { companySingle } from 'actions/company';
import NotFound from 'components/NotFound';
import NavBarConfig from 'components/NavBarConfig';
import CompanyExpiredInformation from './components/CompanyExpiredInformation';

function showCompanyExpired({ user, company, pathname, search }) {
  if ((user.is_admin || user.is_staff) && search.indexOf('ignoreexpired') !== -1) {
    return false;
  }
  if (!company.isExpired) {
    return false;
  }
  const locationParts = pathname.split('/');
  if (locationParts[3] === 'payment') {
    return false;
  }
  return true;
}

class CompanyContainerComponent extends Component {
  componentWillMount() {
    this.props.companySingleLoad(this.props.params.companyId);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.params.companyId !== nextProps.params.companyId) {
      nextProps.companySingleLoad(nextProps.params.companyId);
    }
  }
  render() {
    const { user, company, companyMembership, error, children, location: { pathname, search } } = this.props;
    if (error) {
      return <NotFound />;
    }
    if (!company || !companyMembership) {
      return <div />;
    }
    if (this.props.params.companyId !== `${company.id}`) {
      return <div />;
    }
    return (
      <NavBarConfig
        companyList={user.is_admin || user.is_staff || user.company_count > 1}
        company={companyMembership.is_owner || companyMembership.is_admin ? company : false}
        currentRouteName={this.props.routes[this.props.routes.length - 1].name}
      >
        {showCompanyExpired({ user, company, pathname, search }) ? (
          <CompanyExpiredInformation isOwner={companyMembership.is_owner} id={company.id} />
        ) : (
          cloneElement(children, { user, company, companyMembership })
        )}
      </NavBarConfig>
    );
  }
}

export default connect(
  state => state.companySingle, { companySingleLoad: companySingle.load }
)(CompanyContainerComponent);
