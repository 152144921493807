import { createAction, handleActions } from 'redux-actions';
import api from 'actions/api';

export const PASSWORD_RESET_TOKEN_STATUS = 'PASSWORD_RESET_TOKEN_STATUS';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';

export const resetPassword = (userId, token, data) => api.post({
  url: `/api/reset_password/changePassword/${userId}/${token}/`,
  data,
});
export const resetPassword2 = (userId, token, data) => api._post2({
  url: `/api/reset_password/changePassword/${userId}/${token}/`,
  data,
});

export const checkToken = (userId, token) => api.get({
  url: `/api/reset_password/${userId}/${token}/`,
});

export const setTokenStatus = createAction(PASSWORD_RESET_TOKEN_STATUS);
export const setPasswordChanged = createAction(PASSWORD_CHANGED);

const emptyState = {
  tokenStatus: false,
  passwordChanged: false,
};

export const passwordResetReducer = handleActions({
  [PASSWORD_RESET_TOKEN_STATUS]: (state, action) => ({
    ...state,
    tokenStatus: action.payload,
  }),
  [PASSWORD_CHANGED]: state => ({
    ...state,
    tokenAccepted: true,
  }),
}, emptyState);
