import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import _ from 'lodash';

import FormGroup2 from '../../../components/form/FormGroup';
import FormError from '../../../components/form/FormError';
import FormSubmit from '../../../components/form/FormSubmit';
import TextField from '../../../components/form/TextField';


const messages = defineMessages({
  CHOOSE_SALUTATION: {
    id: 'CompanyPaymentFormComponent.CHOOSE_SALUTATION',
    defaultMessage: 'Bitte Anrede auswählen...',
  },
  MALE: {
    id: 'CompanyPaymentFormComponent.MALE',
    defaultMessage: 'Herr',
  },
  FEMALE: {
    id: 'CompanyPaymentFormComponent.FEMALE',
    defaultMessage: 'Frau',
  },
  CONTACT_PERSON: {
    id: 'CompanyPaymentFormComponent.CONTACT_PERSON',
    defaultMessage: 'Ansprechpartner',
  },
  PLACEHOLDER_FIRST_NAME: {
    id: 'CompanyPaymentFormComponent.PLACEHOLDER_FIRST_NAME',
    defaultMessage: 'Vorname',
  },
  PLACEHOLDER_LAST_NAME: {
    id: 'CompanyPaymentFormComponent.PLACEHOLDER_LAST_NAME',
    defaultMessage: 'Nachname',
  },
  BILLINGS_ADDRESS: {
    id: 'CompanyPaymentFormComponent.BILLINGS_ADDRESS',
    defaultMessage: 'Rechnungsadresse',
  },
  COMPANY_NAME: {
    id: 'CompanyPaymentFormComponent.COMPANY_NAME',
    defaultMessage: 'Firmenname',
  },
  STREET: {
    id: 'CompanyPaymentFormComponent.STREET',
    defaultMessage: 'Straße',
  },
  HOUSE_NUMBER: {
    id: 'CompanyPaymentFormComponent.HOUSE_NUMBER',
    defaultMessage: 'Hausnummer',
  },
  ZIPCODE: {
    id: 'CompanyPaymentFormComponent.ZIPCODE',
    defaultMessage: 'Postleitzahl',
  },
  CITY: {
    id: 'CompanyPaymentFormComponent.CITY',
    defaultMessage: 'Stadt',
  },
  COUNTRY: {
    id: 'CompanyPaymentFormComponent.COUNTRY',
    defaultMessage: 'Land',
  },
  SEPA: {
    id: 'CompanyPaymentFormComponent.SEPA',
    defaultMessage: 'SEPA-Lastschrift',
  },
  TAX_ID: {
    id: 'CompanyPaymentFormComponent.TAX_ID',
    defaultMessage: 'Steuer-ID',
  },
  SAVE_DATA: {
    id: 'CompanyPaymentFormComponent.SAVE_DATA',
    defaultMessage: 'Daten speichern',
  },
  VALIDATION_SALUTATION: {
    id: 'CompanyPaymentFormComponent.VALIDATION_SALUTATION',
    defaultMessage: 'Bitte wählen Sie eine Anrede aus.',
  },
  VALIDATION_FIRST_NAME: {
    id: 'CompanyPaymentFormComponent.VALIDATION_FIRST_NAME',
    defaultMessage: 'Bitte tragen Sie den Vorname des Ansprechpartners ein.',
  },
  VALIDATION_LAST_NAME: {
    id: 'CompanyPaymentFormComponent.VALIDATION_LAST_NAME',
    defaultMessage: 'Bitte tragen Sie den Nachnamen des Ansprechpartners ein.',
  },
  VALIDATION_COMPANY_NAME: {
    id: 'CompanyPaymentFormComponent.VALIDATION_COMPANY_NAME',
    defaultMessage: 'Bitte tragen Sie den Firmennamen ein.',
  },
  VALIDATION_STREET: {
    id: 'CompanyPaymentFormComponent.VALIDATION_STREET',
    defaultMessage: 'Bitte tragen Sie die Straße ein.',
  },
  VALIDATION_STREETNUMBER: {
    id: 'CompanyPaymentFormComponent.VALIDATION_STREETNUMBER',
    defaultMessage: 'Bitte tragen Sie die Hausnummer ein.',
  },
  VALIDATION_ZIPCODE: {
    id: 'CompanyPaymentFormComponent.VALIDATION_ZIPCODE',
    defaultMessage: 'Bitte tragen Sie die Postleitzahl ein.',
  },
  VALIDATION_CITY: {
    id: 'CompanyPaymentFormComponent.VALIDATION_CITY',
    defaultMessage: 'Bitte tragen Sie die Stadt ein.',
  },
  VALIDATION_COUNTRY: {
    id: 'CompanyPaymentFormComponent.VALIDATION_COUNTRY',
    defaultMessage: 'Bitte tragen Sie das Land ein.',
  },
  VALIDATION_IBAN: {
    id: 'CompanyPaymentFormComponent.VALIDATION_IBAN',
    defaultMessage: 'Bitte tragen Sie die IBAN ein.',
  },
  VALIDATION_TAX_ID: {
    id: 'CompanyPaymentFormComponent.VALIDATION_TAX_ID',
    defaultMessage: 'Bitte tragen Sie die Steuer-ID ein.',
  },
  VALIDATION_LENGTH: {
    id: 'CompanyPaymentFormComponent.VALIDATION_LENGTH',
    defaultMessage: 'Bitte geben Sie maximal {max_Length} Zeichen ein',
  },
  VALIDATION_BIC: {
    id: 'CompanyPaymentFormComponent.VALIDATION_BIC',
    defaultMessage: 'Bitte tragen Sie die BIC ein.',
  },
});

function validate(value, maxLength, emptyMessage, formatMessage) {
  if (!value) {
    return emptyMessage;
  }

  if (value.length > maxLength) {
    return formatMessage(messages.VALIDATION_LENGTH, { max_Length: maxLength });
  }

  return null;
}

function validateBic(bic, country, formatMessage) {
  if (country === 'Deutschland') {
    return null;
  }
  return validate(bic, 11, formatMessage(messages.VALIDATION_BIC), formatMessage);
}

class CompanyPaymentFormComponent extends React.Component {
  render() {
    const {
/*
      fields: {
        contactSalutation,
        contactFirstname,
        contactLastname,
        addressName,
        addressStreet,
        addressStreetnumber,
        addressZipcode,
        addressCity,
        addressCountry,
        iban,
        bic,
        taxid,
      },
 */
      intl: { formatMessage },
    } = this.props;

    const selectOptions = [
      { value: '', label: formatMessage(messages.CHOOSE_SALUTATION) },
      { value: 'm', label: formatMessage(messages.MALE) },
      { value: 'f', label: formatMessage(messages.FEMALE) },
    ];

    const onSubmitLocal = (data) => {
      const dataSubmit = {
        ...data,
        contactSalutation: data.contactSalutation.value
      }
      return this.props.onSubmit(dataSubmit);
    }

    return (
      <Form
        onSubmit={onSubmitLocal}
        validate={values => {
          const errors = {};
          if (!values.contactSalutation || values.contactSalutation.value === '') {
            errors.contactSalutation = formatMessage(messages.VALIDATION_SALUTATION);
          }
          let error = validate(values.contactFirstname, 30, formatMessage(messages.VALIDATION_FIRST_NAME), formatMessage);
          if (error) errors.contactFirstname = error;

          // errors.contactFirstname = validate(values.contactFirstname, 30, formatMessage(messages.VALIDATION_FIRST_NAME), formatMessage);
          error = validate(values.contactLastname, 30, formatMessage(messages.VALIDATION_LAST_NAME), formatMessage);
          if (error) errors.contactLastname = error;

          error = validate(values.addressName, 100, formatMessage(messages.VALIDATION_COMPANY_NAME), formatMessage);
          if (error) errors.addressName = error;

          error = validate(values.addressStreet, 100, formatMessage(messages.VALIDATION_STREET), formatMessage);
          if (error) errors.addressStreet = error;

          error = validate(values.addressStreetnumber, 10, formatMessage(messages.VALIDATION_STREETNUMBER), formatMessage);
          if (error) errors.addressStreetnumber = error;

          error = validate(values.addressZipcode, 20, formatMessage(messages.VALIDATION_ZIPCODE), formatMessage);
          if (error) errors.addressZipcode = error;

          error = validate(values.addressCity, 100, formatMessage(messages.VALIDATION_CITY), formatMessage);
          if (error) errors.addressCity = error;

          error = validate(values.addressCountry, 50, formatMessage(messages.VALIDATION_COUNTRY), formatMessage);
          if (error) errors.addressCountry = error;

          error = validate(values.iban, 34, formatMessage(messages.VALIDATION_IBAN), formatMessage);
          if (error) errors.iban = error;

          error = validateBic(values.bic, values.addressCountry, formatMessage);
          if (error) errors.bic = error;

          error = validate(values.taxid, 50, formatMessage(messages.VALIDATION_TAX_ID), formatMessage);
          if (error) errors.taxid = error;

          return errors;
        }
        }
        initialValues={{
            ...this.props.initialValues,
            contactSalutation: this.props.initialValues ? _.find(selectOptions, salutation => salutation.value === this.props.initialValues.contactSalutation) : selectOptions[0].value
        }}
      >
        {({ submitError, handleSubmit, pristine, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <h4>{formatMessage(messages.CONTACT_PERSON)}</h4>
            <Field name="contactSalutation">
              {({ input, meta }) => {
                return (
                  <FormGroup2 input={input} meta={meta}>
                    <Select
                      {...input}
                      name="form-field-name"
                      options={selectOptions}
                    />
                  </FormGroup2>
                )
              }}
            </Field>
            <Field name="contactFirstname">
              {({ input, meta }) => {
                return (
                  <FormGroup2 input={input} meta={meta}>
                    <TextField
                      {...input}
                      tabIndex="1"
                      autoComplete="off"
                      placeholder={formatMessage(messages.PLACEHOLDER_FIRST_NAME)}
                    />
                  </FormGroup2>
                )
              }}
            </Field>
            <Field name="contactLastname">
              {({ input, meta }) => {
                return (
                  <FormGroup2 input={input} meta={meta}>
                    <TextField
                      {...input}
                      tabIndex="2"
                      autoComplete="off"
                      placeholder={formatMessage(messages.PLACEHOLDER_LAST_NAME)}
                    />
                  </FormGroup2>
                )
              }}
            </Field>
            <h4>{formatMessage(messages.BILLINGS_ADDRESS)}</h4>
            <Field name="addressName">
              {({ input, meta }) => {
                return (
                  <FormGroup2 input={input} meta={meta}>
                    <TextField
                      {...input}
                      tabIndex="3"
                      autoComplete="off"
                      placeholder={formatMessage(messages.COMPANY_NAME)}
                    />
                  </FormGroup2>
                )
              }}
            </Field>
            <Field name="addressStreet">
              {({ input, meta }) => {
                return (
                  <FormGroup2 input={input} meta={meta}>
                    <TextField
                      {...input}
                      tabIndex="4"
                      autoComplete="off"
                      placeholder={formatMessage(messages.STREET)}
                    />
                  </FormGroup2>
                )
              }}
            </Field>
            <Field name="addressStreetnumber">
              {({ input, meta }) => {
                return (
                  <FormGroup2 input={input} meta={meta}>
                    <TextField
                      {...input}
                      tabIndex="5"
                      autoComplete="off"
                      placeholder={formatMessage(messages.HOUSE_NUMBER)}
                    />
                  </FormGroup2>
                )
              }}
            </Field>
            <Field name="addressZipcode">
              {({ input, meta }) => {
                return (
                  <FormGroup2 input={input} meta={meta}>
                    <TextField
                      {...input}
                      tabIndex="6"
                      autoComplete="off"
                      placeholder={formatMessage(messages.ZIPCODE)}
                    />
                  </FormGroup2>
                )
              }}
            </Field>
            <Field name="addressCity">
              {({ input, meta }) => {
                return (
                  <FormGroup2 input={input} meta={meta}>
                    <TextField
                      {...input}
                      tabIndex="7"
                      autoComplete="off"
                      placeholder={formatMessage(messages.CITY)}
                    />
                  </FormGroup2>
                )
              }}
            </Field>
            <Field name="addressCountry">
              {({ input, meta }) => {
                return (
                  <FormGroup2 input={input} meta={meta}>
                    <TextField
                      {...input}
                      tabIndex="8"
                      autoComplete="off"
                      placeholder={formatMessage(messages.COUNTRY)}
                    />
                  </FormGroup2>
                )
              }}
            </Field>
            <h4>{formatMessage(messages.SEPA)}</h4>
            <Field name="iban">
              {({ input, meta }) => {
                return (
                  <FormGroup2 input={input} meta={meta}>
                    <TextField
                      {...input}
                      tabIndex="9"
                      autoCapitalize="off"
                      autoCorrect="off"
                      autoComplete="off"
                      placeholder="IBAN"
                    />
                  </FormGroup2>
                )
              }}
            </Field>
            <pre>{JSON.stringify(values, 0, 2)}</pre>
            { (!values && !values.addressCountry && values.addressCountry.value === 'Deutschland') ? null : (
                <Field name="bic">
                {({ input, meta }) => {
                  return (
                    <FormGroup2 input={input} meta={meta}>
                      <TextField
                        {...input}
                        tabIndex="10"
                        autoComplete="off"
                        placeholder="BIC"
                      />
                    </FormGroup2>
                  )
                }}
              </Field>
            )}
            <Field name="taxid">
              {({ input, meta }) => {
                return (
                  <FormGroup2 input={input} meta={meta}>
                    <TextField
                      {...input}
                      tabIndex="11"
                      autoComplete="off"
                      placeholder={formatMessage(messages.TAX_ID)}
                    />
                  </FormGroup2>
                )
              }}
            </Field>
            <FormError error={submitError} />

            <FormSubmit
              btnClass="lh-btn lh-btn-primary"
              btnProps={{ tabIndex: 12 }}
              submitting={submitting}
              label={formatMessage(messages.SAVE_DATA)}
            />
          </form>
        )
        }
      </Form>
    );
  }
}

/*
const CompanyPaymentFormComponentReduxForm = reduxForm({
  form: 'companyPayment',
  fields: [
    'contactSalutation',
    'contactFirstname',
    'contactLastname',
    'addressName',
    'addressStreet',
    'addressStreetnumber',
    'addressZipcode',
    'addressCity',
    'addressCountry',
    'iban',
    'bic',
    'taxid',
  ],
  validate: (values, { intl: { formatMessage } }) => ({
    contactSalutation: values.contactSalutation ? null : formatMessage(messages.VALIDATION_SALUTATION),
    contactFirstname: validate(values.contactFirstname, 30, formatMessage(messages.VALIDATION_FIRST_NAME), formatMessage),
    contactLastname: validate(values.contactLastname, 30, formatMessage(messages.VALIDATION_LAST_NAME), formatMessage),
    addressName: validate(values.addressName, 100, formatMessage(messages.VALIDATION_COMPANY_NAME), formatMessage),
    addressStreet: validate(values.addressStreet, 100, formatMessage(messages.VALIDATION_STREET), formatMessage),
    addressStreetnumber: validate(values.addressStreetnumber, 10, formatMessage(messages.VALIDATION_STREETNUMBER), formatMessage),
    addressZipcode: validate(values.addressZipcode, 20, formatMessage(messages.VALIDATION_ZIPCODE), formatMessage),
    addressCity: validate(values.addressCity, 100, formatMessage(messages.VALIDATION_CITY), formatMessage),
    addressCountry: validate(values.addressCountry, 50, formatMessage(messages.VALIDATION_COUNTRY), formatMessage),
    iban: validate(values.iban, 34, formatMessage(messages.VALIDATION_IBAN), formatMessage),
    bic: validateBic(values.bic, values.addressCountry, formatMessage),
    taxid: validate(values.taxid, 50, formatMessage(messages.VALIDATION_TAX_ID), formatMessage),
  }),
  touchOnBlur: false,
})(CompanyPaymentFormComponent);
*/

export default injectIntl(CompanyPaymentFormComponent);
