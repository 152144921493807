/* global localStorage */
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  HEADER: {
    id: 'SlugPage.HEADER',
    defaultMessage: 'Danke dass Sie tele-LOOK installiert haben',
  },
  TEXT: {
    id: 'SlugPage.TEXT',
    defaultMessage: 'Klicken Sie auf den Button unten, um direkt in die tele-LOOK App zu gelangen.',
  },
  BUTTON: {
    id: 'SlugPage.BUTTON',
    defaultMessage: 'tele-LOOK öffnen',
  },
});

function getSlug() {
  try {
    return localStorage.getItem('ios-slug') || '';
  } catch (e) {
    return '';
  }
}

function SlugPageComponent({ intl: { formatMessage } }) {
  return (
    <div className="flex">
      <div style={{ background: '#fff', maxWidth: 480 }} className="content-block home-content">
        <h4 style={{ margin: 0 }}>{formatMessage(messages.HEADER)}</h4>

        <p>{formatMessage(messages.TEXT)}</p>

        <div className="text-center" style={{ margin: '40px 0' }}>
          <a className="lh-btn lh-btn-primary" href={`https://app.tele-look.com/v/${getSlug()}`}>
            {formatMessage(messages.BUTTON)}
          </a>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(SlugPageComponent);
