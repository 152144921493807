import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { login2, loggedIn, checked, checkedLoginNotLoggedIn, check } from 'actions/auth';
import { FORM_ERROR } from 'final-form'

import { removeAuthToken } from 'actions/api';
import { push, replace } from 'react-router-redux';
import { checkRoom2 } from 'actions/company';

import { Link } from 'react-router';
import nutzer from 'styles/images/nutzer.png';
import handw from 'styles/images/handw.png'

import LoginForm from 'components/forms/LoginForm';
import Logo from 'components/Logo';
import JoinForm from 'components/forms/JoinForm';
import SelectLanguageButton from 'components/SelectLanguageButton';


const messages = defineMessages({
  START_SESSION: {
    id: 'LoginPage.START_SESSION',
    defaultMessage: 'Sitzung starten',
  },
  ENTER_SESSION_NAME: {
    id: 'LoginPage.ENTER_SESSION_NAME',
    defaultMessage: 'Bitte geben Sie Ihren Sitzungsnamen an um eine Verbindung aufzubauen.',
  },
  LOGIN_COMPANY: {
    id: 'LoginPage.LOGIN_COMPANY',
    defaultMessage: 'Unternehmenslogin',
  },
  QUESTION_FORGOT_PASSWORD: {
    id: 'LoginPage.QUESTION_FORGOT_PASSWORD',
    defaultMessage: 'Passwort vergessen?',
  },
  STANDART_BUISSINESS_TERMS: {
    id: 'LoginPage.STANDART_BUISSINESS_TERMS',
    defaultMessage: 'AGB',
  },
  DATA_PRIVACY: {
    id: 'LoginPage.DATA_PRIVACY',
    defaultMessage: 'Datenschutz',
  },
  IMPRINT: {
    id: 'LoginPage.IMPRINT',
    defaultMessage: 'Impressum',
  },
  HANDBOOK: {
    id: 'LoginPage.HANDBOOK',
    defaultMessage: 'Handbuch'
  },
});

function checkOrRedirectIfLoggedIn(props) {
    const { checkedLogin, user, redirect } = props;
    if (!checkedLogin) {
        props.check();
    } else {
        if (!user) {
            return;
        }
        if (user.is_admin
            || user.is_staff
            || user.company_count > 1) {
            redirect(props.location.query.next || '/company/');
            return;
        }
        redirect(props.location.query.next || `/company/${user.company_id}/`);
    }
}

class LoginPage extends React.PureComponent {
    componentWillMount() {
        checkOrRedirectIfLoggedIn(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.checkedLogin !== nextProps.checkedLogin) {
            checkOrRedirectIfLoggedIn(nextProps);
        }
    }

    render() {
      const { intl: { formatMessage } } = this.props;

        return (
            <div className="flex">
                <div className="content-block row login-page">
                    <Logo />

                    <div className="login-row">
                        <div className="home-content col-md-6 col-sm-12 col-xs-12">
                            <img
                                role="presentation"
                                className="hidden-xs hidden-sm"
                                src={handw}
                                style={{ height: 80, width: 80, float: 'right' }}
                            />
                            <h3>
                               {formatMessage(messages.LOGIN_COMPANY)}
                            </h3>
                            <LoginForm
                              onSubmit={this.props.login}
                              reason={this.props.location.query.reason}
                            />
                            <div className="text-center" style={{ marginTop: '15px' }}>
                                <Link to="/requestPasswortReset">
                                    {formatMessage(messages.QUESTION_FORGOT_PASSWORD)}
                                </Link>
                            </div>
                        </div>
                        <div className="room-content col-md-6 col-sm-12 col-xs-12">
                            <img
                                role="presentation"
                                className="hidden-xs hidden-sm"
                                src={nutzer}
                                style={{ height: 80, width: 80, float: 'right' }}
                            />
                            <h3>
                                 {formatMessage(messages.START_SESSION)}
                            </h3>
                            <p>{formatMessage(messages.ENTER_SESSION_NAME)}</p>
                            <JoinForm onSubmit={this.props.gotoRoom} />
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="text-center" style={{ marginTop: '15px' }}>
                            <Link to="/terms">{formatMessage(messages.STANDART_BUISSINESS_TERMS)}</Link>
                            <Link
                                style={{ marginLeft: 30 }}
                                to="/datenschutz"
                            >{formatMessage(messages.DATA_PRIVACY)}</Link>
                            <Link
                                style={{ marginLeft: 30 }}
                                to="/impressum"
                            >{formatMessage(messages.IMPRINT)}</Link>
                            <Link
                                style={{ marginLeft: 30 }}
                                to="/handbook"
                            >{formatMessage(messages.HANDBOOK)}</Link>
                        </div>
                        <div className="text-center" style={{ marginTop: '15px' }}>
                          <SelectLanguageButton textVersion />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default connect(
    state => state.auth,
    (dispatch, props) => ({
        redirect: path => dispatch(replace(path)),
        check: () => check().then(
            user => dispatch(checked(user)),
            () => {
                // remove old token data if user login check not successful
                removeAuthToken();
                dispatch(checkedLoginNotLoggedIn());
            },
        ),
        login: async formData => {
          const data = await login2(formData);
          if (data.response) {
            dispatch(loggedIn(data.response));
            dispatch(push(props.location.query.next || '/'));
          } else {
            return { [FORM_ERROR]: data.error[Object.keys(data.error)[0]] };
          }
        },
        gotoRoom: async formData => {
          const data = await checkRoom2(formData);
          if (data.response) {
            dispatch(push(`/v/${data.response.slug}/`))
          } else {
            return { [FORM_ERROR]: data.error[Object.keys(data.error)[0]] };
          }
        }
/*
          checkRoom(formData).then(
            room => dispatch(push(`/v/${room.slug}/`))
        ),
 */
    })
)(injectIntl(LoginPage));
