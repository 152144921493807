import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';

const messages = defineMessages({
  CLONED_URL_INTO_STACK: {
    id: 'CopyPreToClipboard.CLONED_URL_INTO_STACK',
    defaultMessage: 'Url wurde in die Zwischenablage kopiert.',
  },
  CLONE_URL_INTO_STACK: {
    id: 'CopyPreToClipboard.CLONE_URL_INTO_STACK',
    defaultMessage: 'Url in die Zwischenablage kopieren.',
  },
  TITLE_CLONE_URL_INTO_STACK: {
    id: 'CopyPreToClipboard.TITLE_CLONE_URL_INTO_STACK',
    defaultMessage: 'Sitzungs-Url in die Zwischenablage kopieren',
  },
});

class CopyPreToClipboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }
  render() {
    const { content, intl: { formatMessage } } = this.props;
    const tooltiptext = this.state.copied ? formatMessage(messages.CLONED_URL_INTO_STACK) : formatMessage(messages.CLONE_URL_INTO_STACK);
    return (
      <div className="lh-btn-group" title={formatMessage(messages.TITLE_CLONE_URL_INTO_STACK)} >
        <pre className="hidden-sm hidden-xs" style={{ overflow: 'hidden' }}>
          {content}
        </pre>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="copied">{tooltiptext}</Tooltip>}
        >
          <CopyToClipboard
            text={content}
            onCopy={() => {
              this.setState({ copied: true });
              setTimeout(() => {
                this.setState({ copied: false });
              }, 5000);
            }}
          >
            <button
              className="lh-btn lh-btn-default lh-btn-small"
            >
              <i className="fa fa-clipboard" />
            </button>
          </CopyToClipboard>
        </OverlayTrigger>
      </div>
        );
  }
}

export default injectIntl(CopyPreToClipboard);
