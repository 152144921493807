import React from 'react';
import logo from '../styles/images/logo.png';

export default function Logo(props={
  floating: false,
  margin: 40,
}) {
  const { floating, margin } = props;

  if (floating) {
    return <img width="150" style={{ float: 'right' }} src={logo} alt="Willkommen bei tele-LOOK" />;
  }

  return (
    <div style={{ margin, textAlign: 'center' }}>
      <img className="logo" src={logo} alt="Willkommen bei tele-LOOK" />
    </div>
  );
}
