import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { updateCompany2, companyUpdated } from 'actions/company';

import { Link } from 'react-router';
import NotFound from 'components/NotFound';
import NavBarConfig from 'components/NavBarConfig';
import CompanyUpdate from 'components/company/CompanyUpdate';
import { FORM_ERROR } from 'final-form';

const messages = defineMessages({
  BACK: {
    id: 'CompanyUpdatePage.BACK',
    defaultMessage: 'Zurück',
  },
  EDIT_COMPANY: {
    id: 'CompanyUpdatePage.EDIT_COMPANY',
    defaultMessage: 'Unternehmen bearbeiten',
  },
});

function CompanyUpdatePage({
  company,
  companyMembership,
  onSubmit,
  routes,
  intl: { formatMessage },
}) {
  if (!company || !companyMembership) { return null; }
  if (!companyMembership.is_owner && !companyMembership.is_admin) {
    return <NotFound />;
  }

  return (
    <div>
      <NavBarConfig updatePage currentRouteName={routes[routes.length - 1].name} />
      <div className="m40 w-xs">
        <Link to={`/company/${company.id}/`}>{formatMessage(messages.BACK)}</Link>
        <h3>{formatMessage(messages.EDIT_COMPANY)}</h3>
        <CompanyUpdate
          onSubmit={onSubmit}
          initialValues={company}
        />
      </div>
    </div>
  );
}

export default connect(null,
  (dispatch, props) => ({
    onSubmit: async formData => {
      const data = await updateCompany2(props.params.companyId, formData);

      if (data.response) {
        const company = data.response;
        dispatch(companyUpdated(company));
        dispatch(push(`/company/${company.id}/`));
      } else {
        return { [FORM_ERROR]: data.error[Object.keys(data.error)[0]] };
      }
    }
  })
)(injectIntl(CompanyUpdatePage));
