import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { Link } from 'react-router';

import Logo from '../../components/Logo';

import DeHandbook from '../../static/pdf/handbook/de-DE_tele-look.pdf';

const messages = defineMessages({
  HANDBOOK: {
    id: 'HandbookPage.HANDBOOK',
    defaultMessage: 'Handbuch',
  },
  INTRODUCTION: {
    id: 'HandbookPage.INTRODUCTION',
    defaultMessage: 'Hier finden Sie unser Handbuch um einen schnellen Einblick in tele-LOOK zu bekommen.'
  },
  LANGUAGE: {
    id: 'HandbookPage.LANGUAGE',
    defaultMessage: 'Sprache'
  },
  GERMAN: {
    id: 'HandbookPage.GERMAN',
    defaultMessage: 'Deutsch'
  },
  BACK: {
    id: 'HandbookPage.BACK',
    defaultMessage: 'Zurück',
  },
  PDF: {
    id: 'HandbookPage.PDF',
    defaultMessage: 'PDF',
  },
});


class HandbookPage extends React.Component {
    render() {
      const { intl: { formatMessage } } = this.props;

        return (
          <div className="flex">
            <div className="content-block row login-page">
              <Logo />
              <div className="terms">
                <h1>
                  {formatMessage(messages.HANDBOOK)}
                </h1>
                <p>
                  {formatMessage(messages.INTRODUCTION)}
                </p>
                <table className="table table-condensed table-striped">
                    <thead>
                      <tr>
                        <th>{formatMessage(messages.LANGUAGE)}</th>
                        <th>{formatMessage(messages.PDF)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={'GERMAN'}>
                        <td>
                          {formatMessage(messages.GERMAN)}
                        </td>
                        <td>
                          <a href={DeHandbook}>
                            {formatMessage(messages.PDF)}
                          </a>
                        </td>
                      </tr>
                    </tbody>
                </table>
              </div>
              <div className="text-center" style={{ marginTop: '15px' }}>
                <Link to="/">
                  {formatMessage(messages.BACK)}
                </Link>
              </div>
            </div>
          </div>
        );
    }
}

export default injectIntl(HandbookPage);
