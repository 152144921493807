import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  DATA_PRIVACY: {
    id: 'datenschutz.DATA_PRIVACY',
    defaultMessage: 'Sie erhalten diese Einladung über tele-LOOK auf Initiative des Betriebs, der diese SMS ankündigte.'
      + 'Fotos des audiovisuellen Dialogs werden bei Solutiness gespeichert. Auf Anforderung erhalten Sie '
      + 'Auskunft und alle Daten werden gelöscht, sofern der Ersteller des Dialogs nicht begründet widerspricht. Kontakt: Solutiness GmbH, Alte Breisacher Str. 14a,'
      + 'D-79112 Freiburg, Tel.: +49 761-7699970, E-Mail: <a>support@tele-look.com</a> .',
  },
});

const datenschutz = (
  <p>
    <FormattedMessage
      {...messages.DATA_PRIVACY}
      values={{
        a: msg => (
          <a href="mailto:support@tele-look.com">
            {msg}
          </a>
        ),
      }}
    />
  </p>
);

export default datenschutz;
