import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Link } from 'react-router';
import { FORM_ERROR } from 'final-form'

import { companyUpdated } from '../../actions/company';
import { loadPaymentData, savePaymentData2 } from '../../actions/payment';
import NavBarConfig from '../../components/NavBarConfig';
import NotFound from '../../components/NotFound';
import CompanyPaymentForm from './components/CompanyPaymentForm';

const messages = defineMessages({
  BACK: {
    id: 'CompanyPaymentEditPage.BACK',
    defaultMessage: 'Zurück',
  },
  EDIT_ACCOUNT: {
    id: 'CompanyPaymentEditPage.EDIT_ACCOUNT',
    defaultMessage: 'Nutzerkonto bearbeiten',
  },
  CREATE_ACCOUNT: {
    id: 'CompanyPaymentEditPage.CREATE_ACCOUNT',
    defaultMessage: 'Nutzerkonto anlegen',
  },
});

class CompanyPaymentEditPage extends React.Component {
  componentWillMount() {
    const { company } = this.props;
    if (!company.payment && company.hasPaymentData) {
      this.props.loadPaymentData();
    }
  }

  render() {
    const {
      user,
      company,
      companyMembership,
      onSubmit,
      routes,
      intl: { formatMessage },
    } = this.props;

    if (!user.is_admin && !user.is_staff && !companyMembership.is_owner) {
      return <NotFound />;
    }

    return (
      <div>
        <NavBarConfig
          customerList={{ company_id: company.id }}
          currentRouteName={routes[routes.length - 1].name}
        />
        <div className="m40 w-xs">
          <Link to={`/company/${company.id}/payment/`}>{formatMessage(messages.BACK)}</Link>
          <h3>
            {formatMessage(
              company.hasPaymentData ? messages.EDIT_ACCOUNT : messages.CREATE_ACCOUNT
            )}
          </h3>
          <CompanyPaymentForm
            onSubmit={onSubmit}
            initialValues={company.payment}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  (dispatch, props) => ({
    onSubmit: async formData => {
      const data = await savePaymentData2(props.params.companyId, formData);
      if (data.response) {
        const company = data.response;
        dispatch(companyUpdated(company));
        dispatch(push(`/company/${company.id}/payment/`));
      } else {
        return { [FORM_ERROR]: data.error[Object.keys(data.error)[0]] };
      }
    },
    loadPaymentData: () =>
      loadPaymentData(props.company.id).then(response =>
        dispatch(companyUpdated({ ...props.company, payment: response }))
      ),
  }))(injectIntl(CompanyPaymentEditPage));
