import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router';
import classnames from 'classnames';
import { Navbar, Nav } from 'react-bootstrap';
import moment from 'moment';

import SelectLanguageButton from './SelectLanguageButton';
import { trunc } from '../helpers/index';

const messages = defineMessages({
  USER: {
    id: 'Breadcrumb.USER',
    defaultMessage: 'Benutzer',
  },
  CUSTOMER: {
    id: 'Breadcrumb.CUSTOMER',
    defaultMessage: 'Kunde',
  },
  APPLY: {
    id: 'Breadcrumb.APPLY',
    defaultMessage: 'Anlegen',
  },
  UPDATE: {
    id: 'Breadcrumb.UPDATE',
    defaultMessage: 'Bearbeiten',
  },
  DELETE: {
    id: 'Breadcrumb.DELETE',
    defaultMessage: 'Löschen',
  },
  EDIT: {
    id: 'Breadcrumb.EDIT',
    defaultMessage: 'Bearbeiten',
  },
  RESPONSIVE_START: {
    id: 'Breadcrumb.RESPONSIVE_START',
    defaultMessage: 'Start',
  },
  TITLE_START: {
    id: 'Breadcrumb.TITLE_START',
    defaultMessage: 'Start',
  },
  BREADCRUMB_START: {
    id: 'Breadcrumb.BREADCRUMB_START',
    defaultMessage: 'Start',
  },
  PROPERTIES: {
    id: 'Breadcrumb.PROPERTIES',
    defaultMessage: 'Einstellungen',
  },
  TITLE_PROPERTIES: {
    id: 'Breadcrumb.TITLE_PROPERTIES',
    defaultMessage: 'Einstellungen',
  },
  LOG_OUT: {
    id: 'Breadcrumb.LOG_OUT',
    defaultMessage: 'Ausloggen',
  },
  TITLE_LOG_OUT: {
    id: 'Breadcrumb.TITLE_LOG_OUT',
    defaultMessage: 'Ausloggen',
  },
  EXPIRATION_DATE: {
    id: 'Breadcrumb.EXPIRATION_DATE',
    defaultMessage: 'Ihr Test-Nutzerkonto läuft {expirationDate} ab.',
  },
});

function NavBarComponent({ config, intl: { formatMessage } }) {
  const {
    // startPage,
    user,
    companyList,
    company,
    companyUserList,
    companyUser,
    customer,
    createPage,
    updatePage,
    deletePage,
    editPage,
    currentRouteName = '',
  } = config;

  if (!user) {
    return null;
  }

  const breadcrumbItems = [];
  let pathname = '/';

  // if (startPage) {
  //     breadcrumbItems.push(
  //         <Link to="/">
  //             <span className="fa fa-home" />
  //             {' Startseite'}
  //         </Link>
  //     );
  // }
  // if (companyList) {
  //     pathname = '/company/';
  //     breadcrumbItems.push(
  //         <Link to={pathname}>
  //             {'Start'}
  //         </Link>
  //     );
  // }

  if (companyList) {
    pathname = '/company/';
  }

  if (company) {
    pathname = `/company/${company.id}/`;
    breadcrumbItems.push(
      <Link
        to={pathname}
        title={company.name}
        className={classnames('hide-md', {
          bold: currentRouteName === 'customer-list',
        })}
      >
        {trunc(company.name, 40, true)}
      </Link>
    );
  }
  if (companyUserList) {
    pathname = `/company/${company.id}/user/`;
    breadcrumbItems.push(
      <Link to={pathname}>
        {formatMessage(messages.USER)}
      </Link>
      );
  }
  if (companyUser) {
    pathname = `/company/${companyUser.company_id}/user/${companyUser.user_id}/`;
    breadcrumbItems.push(
      <Link to={pathname} className="hide-md">
        {trunc(companyUser.user.name, 40, true)}
      </Link>
      );
  }
  if (customer) {
    pathname = `/company/${customer.company_id}/customer/${customer.id}/`;
    breadcrumbItems.push(
      <Link to={pathname} className={currentRouteName === 'customer-single' ? 'bold' : null}>
        <span className="fa fa-user" />
        {` ${formatMessage(messages.CUSTOMER)} ${trunc(customer.name, 40, true)}`}
      </Link>
      );
  }
  if (createPage) {
    const link = company && !companyUserList ? 'customer/create/' : 'create/';
    breadcrumbItems.push(
      <Link to={`${pathname}${link}`} className={currentRouteName.indexOf('create') !== -1 ? 'bold' : null}>
        <span className="fa fa-plus" />
        {` ${formatMessage(messages.APPLY)}`}
      </Link>
      );
  }
  if (updatePage) {
    breadcrumbItems.push(
      <Link to={`${pathname}update/`} className={currentRouteName.indexOf('update') !== -1 ? 'bold' : null}>
        <span className="fa fa-pencil" />
        {` ${formatMessage(messages.UPDATE)}`}
      </Link>
      );
  }
  if (deletePage) {
    breadcrumbItems.push(
      <Link to={`${pathname}delete/`} className={currentRouteName.indexOf('delete') !== -1 ? 'bold' : null}>
        <span className="fa fa-trash" />
        {` ${formatMessage(messages.DELETE)}`}
      </Link>
      );
  }
  if (editPage) {
    pathname = `/company/${customer.company_id}/customer/${customer.id}/`;
    breadcrumbItems.push(
      <Link to={`${pathname}update/`}>
        <span className="fa fa-pencil" />
        {' '}
        {formatMessage(messages.EDIT)}
      </Link>
      );
  }


  let expirationDate;
  let showTestAccountNotice = false;
  if (company &&
    !company.hasPaymentData &&
    currentRouteName.indexOf('company-payment') === -1) {
    expirationDate = moment(company.created).add(14, 'days');
    showTestAccountNotice = true;
  }

  return (
    <Navbar>
      <Navbar.Header>
        <Navbar.Brand>
          <Link to="/">
            <i className="fa fa-home hidden-lg" title={formatMessage(messages.TITLE_START)} />
            <span className="visible-lg-inline">{formatMessage(messages.RESPONSIVE_START)}</span>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav className="breadcrumb-menu">
          <li className="visible-xs-block" key={-1} role="presentation">
            <Link to="/">
              <i className="fa fa-home" /> <span>{formatMessage(messages.BREADCRUMB_START)}</span>
            </Link>
          </li>
          {breadcrumbItems.map((it, index) => <li key={index} role="presentation">{it}</li>)}
        </Nav>
        <Nav pullRight className="user-menu">
          <li role="presentation" className="visible hidden-xs">
            <SelectLanguageButton />
          </li>
          <li role="presentation">
            <span style={{ fontWeight: 'bold', display: 'inline-block', padding: '10px 15px' }}>
              {user.name}
            </span>
          </li>
          <li role="presentation" className="menu-item-responsive">
            <Link to="/settings/" className={currentRouteName === 'settings' ? 'bold' : null}>
              <i className="fa fa-gear" title={formatMessage(messages.TITLE_PROPERTIES)} />
              {' '}
              <span>{formatMessage(messages.PROPERTIES)}</span>
            </Link>
          </li>
          <li role="presentation">
            <Link to="/logout/" className="menu-item-responsive">
              <i className="fa fa-sign-out" title={formatMessage(messages.TITLE_LOG_OUT)} />
              {' '}
              <span>{formatMessage(messages.LOG_OUT)}</span>
            </Link>
          </li>
          <li role="presentation" className="hidden visible-xs">
            <SelectLanguageButton textVersion />
          </li>
        </Nav>
      </Navbar.Collapse>
      {showTestAccountNotice ? (
        <div style={{ textAlign: 'center', lineHeight: '20px', paddingBottom: '8px' }}>
          <Link
            style={{ color: 'red' }}
            to={`/company/${company.id}/payment`}
          >
            {formatMessage(messages.EXPIRATION_DATE, { expirationDate: expirationDate.fromNow() })}
          </Link>
        </div>
      ) : null}
    </Navbar>
  );
  // <ol  className="breadcrumb ">
  //     {breadcrumbItems.map((item, i) => (
  //     <li
  //       style={{ maxWidth: '200px'}}
  //       key={i}
  //       className={"text-overflow" + (i === breadcrumbLength ? ' active' : '')}>
  //         {item}
  //     </li>
  //     ))}
  // </ol>
}

export default connect(
  state => ({ config: state.navbar }),
)(injectIntl(NavBarComponent));
