import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
// eslint-disable-next-line
import OT from 'opentok';

import NavBarConfig from '../../components/NavBarConfig';
import ChatInput from '../../components/video/ChatInput';
import CustomerHeader from './components/CustomerHeader';
import EndCallModal from './components/EndCallModal';
import EventList from './components/event/EventList';
import Loader from '../../components/Loader';
import NoVideoSupport from './components/NoVideoSupport';
import OrderHeader from './components/OrderHeader';
import OrderSidebar from './components/OrderSidebar';
import StartCallModal from './components/StartCallModal';
import UserVideoContainer from './components/UserVideoContainer';
/* global window */

import {
  createSMS,
  eventCreated,
  createNote, createNoteWithCall,
  openCallBeginModal,
  closeCallBeginModal,
  openCallEndModal,
  closeCallEndModal,
  setLastCall,
  createEndNote, endNoteCreated,
  addCallToOrder, callAddedToOrder,
  updateCall,
  orderList,
  getActiveCall
} from '../../actions/company';

import {
  activeCallGet,
  createCall,
  callCreated,
  createCallEnd,
  callEndCreated,
  callLeave,
} from '../../actions/video';

import {
  unsubscribe,
  endCall, sendSignal,
} from '../../actions/ot_signaling';

class CustomerSinglePageComponent extends React.Component {
  constructor(props) {
    super(props);

    let browserWithoutVideosupportLocal = false;
    if (/* detectSafariOnOSX() || */ OT.checkSystemRequirements() === 0) {
      browserWithoutVideosupportLocal = true;
    }

    this.state = {
      sendSMS: true,
      middleHeight: 0,
      orderSideBarActive: false,
      browserWithoutVideosupport: browserWithoutVideosupportLocal,
    };

    this.setMiddleHeight = this.setMiddleHeight.bind(this);
    this.handleCreateSMS = this.handleCreateSMS.bind(this);

    if (!this.props.activeCallFetching) {
      this.props.load();
    }
  }

  componentDidMount() {
    this.setMiddleHeight();
    window.addEventListener('optimizedResize', this.setMiddleHeight);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.params.companyId !== nextProps.params.companyId ||
            this.props.params.customerId !== nextProps.params.customerId) {
      nextProps.load();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.orderListFetching !== prevProps.orderListFetching) {
      this.scrollUp();
    }

    if (this.props.params.orderId !== prevProps.params.orderId) {
      this.scrollUp();
    }

    if (this.props.call !== prevProps.call || (
      (this.props.call && prevProps.call) && this.props.call.begin !== prevProps.call.begin
    ) || (this.props.connected !== prevProps.connected)) {
      this.setMiddleHeight();
    }
  }

  componentWillUnmount() {
    const { call } = this.props;
    if (call && !call.call_end) {
      this.props.stopCallWithoutNote(call);
    } else {
      this.props.stopCallConnection();
    }

    window.removeEventListener('optimizedResize', this.setMiddleHeight);
  }

  setMiddleHeight() {
    if (this.refs.middle) {
      const { height } = this.refs.middle.getBoundingClientRect();
      this.setState({ middleHeight: height });
    }
  }

  scrollUp() {
    const { innerMiddle } = this.refs;
    if (typeof innerMiddle !== 'undefined') {
      innerMiddle.scrollTop = innerMiddle.scrollHeight;
    }
  }

  handleStopCall = (withEndNote=true) => {
    const { call } = this.props;
    // load reloads the duration of the active call
    if (withEndNote) {
      this.props.stopCall(call);
    } else {
      this.props.stopCallWithoutNote(call);
    }
  }

  handleCallEndModalSuccess = (data, form) => {
    this.props.closeCallEndModal();
    const { lastCall } = this.props;
    if (data.endNote) {
      const postData = { text: data.endNote };
      if (data.order && data.order !== 'false') {
        postData.orderId = data.order;
      }
      this.props.createEndNote(postData, lastCall, form);
    } else if (data.order && data.order !== 'false') {
      this.props.addCallToOrder(lastCall, { id: data.order });
    }
  }

  handleNoteSubmit = (data, form) => {
    const { call } = this.props;
    if (!call || (call && call.call_end)) {
      this.props.createNote(data, form);
    } else {
      this.props.createNoteWithCall(data, call, form);
    }
  }

  handleSMSToggle = () => {
    this.setState({ sendSMS: !this.state.sendSMS });
  }

  handleCreateSMS() {
    const { call } = this.props;
    this.props.createSMS(call);
  }

  handleCallBeginModalSuccess = () => {
    this.props.closeCallBeginModal();
    this.props.createCall().then(() => {
      if (this.state.sendSMS
          && this.props.customer.phone) {
        this.handleCreateSMS();
      } /*else {
        //console.log('Send no SMS');
      }*/
    });
  }

  renderNormalScreen2 = () => {
    const {
      company,
      companyMembership,
      customer,
      connected,
      call,
      activeCallFetching,
      orders,
      callBeginModalActive,
      callEndModalActive,
      lastCall,
      orderListFetching,
      params,
      routes,
    } = this.props;

    const { middleHeight, orderSideBarActive } = this.state;
    if (!company || !companyMembership) { return null; }

    const activeCallCreatedAndRunning = call && call.call_end_id === -1;

    let showCustomerNotifiedEvent = false;
    if (call && !call.call_begin && !call.call_end && !connected) {
      showCustomerNotifiedEvent = true;
    }

    const selectedOrderId = parseInt(this.props.params.orderId || -2, 10);
    const order = _.find(orders, o => o.id === selectedOrderId);

    let middleHeightCalculated = middleHeight;

    if (window.innerWidth < 992) {
      middleHeightCalculated = middleHeight - 50;
    }

    if (window.innerWidth > 992 && selectedOrderId > -2) {
      middleHeightCalculated = middleHeight - 68;
    }

    const { browserWithoutVideosupport: noVideo } = this.state;

    return (
      <div className="customer-single">
        <NavBarConfig editPage currentRouteName={routes[routes.length - 1].name} />
        {connected ? null : (
          <CustomerHeader
            activeCallCreatedAndRunning={activeCallCreatedAndRunning}
            activeCallFetching={activeCallFetching}
            call={call}
            connected={connected}
            customer={customer}
            handleCreateSMS={this.handleCreateSMS}
            handleStopCall={this.handleStopCall}
            openCallBeginModal={this.props.openCallBeginModal}
            selectedOrderId={selectedOrderId}
            couldNotCreateCall={noVideo}
            company={company}
          />
        )}
        {!noVideo &&
        <UserVideoContainer
          activeCallCreatedAndRunning={activeCallCreatedAndRunning}
          call={call}
          closeUserVideo={this.closeUserVideo}
          handleStopCall={this.handleStopCall}
          {...this.props}
        />
        }
        {!noVideo &&
        <div className="middle" ref="middle">
          <OrderSidebar
            params={this.props.params}
            selectedOrder={selectedOrderId}
            middleHeight={middleHeightCalculated}
            close={() => this.setState({ orderSideBarActive: false })}
            active={orderSideBarActive}
          />
          <div className="inner-middle" ref="innerMiddle">
            {order && (order.id !== -1) ? (
              <OrderHeader
                order={order}
                companyId={company.id}
                customerId={customer.id}
              />
            ) : (
              <div className="order-header-mobile">
                <i
                  className="icon-right order-header-mobile-toggle"
                  onClick={() => this.setState({ orderSideBarActive: !this.state.orderSideBarActive })}
                />
              </div>
            )}
            {orderListFetching ? (
              <Loader />
            ) : (
              <EventList
                customer={customer}
                call={call}
                selectedOrder={selectedOrderId}
                stopCallBeforeStarted={this.handleStopCall}
                showCustomerNotifiedEvent={showCustomerNotifiedEvent}
                middleHeight={middleHeightCalculated}
              />
            )}
          </div>
        </div>
        }
        {!noVideo &&
          <ChatInput onSubmit={this.handleNoteSubmit} />
        }
        {!noVideo &&
        <StartCallModal
          open={callBeginModalActive}
          handleModalClose={this.props.closeCallBeginModal}
          toggleSMS={this.handleSMSToggle}
          smsActive={this.state.sendSMS}
          handleSuccess={this.handleCallBeginModalSuccess}
          customer={customer}
        />
        }
        {!noVideo &&
        <EndCallModal
          open={callEndModalActive}
          handleModalClose={this.props.closeCallEndModal}
          toggleSMS={this.handleSMSToggle}
          smsActive={this.state.sendSMS}
          handleModalSuccess={this.handleCallEndModalSuccess}
          customer={customer}
          orders={orders}
          lastCall={lastCall}
        />
        }
        {noVideo &&
        <NoVideoSupport
          browserWithoutVideosupport={noVideo}
          companyId={params.companyId}
          customerId={params.customerId}
        />
        }
      </div>
    );
  }

  render() {
    return this.renderNormalScreen2();
  }
}

export default connect(
  (state) => {
    const {
      customerSingle,
      video: {
        room: {
          remoteConnection: { connected },
          room },
        activeCall: {
          call,
          isFetching: activeCallFetching,
        },
        connection,
        fullsize,
      },
      orderList: {
        results,
        isFetching: orderListFetching,
      },
      callBeginModalActive,
      callEndModalActive,
      lastCall,
    } = state;

    return {
      ...customerSingle,
      connected,
      connection,
      call,
      room,
      activeCallFetching,
      orders: results,
      callBeginModalActive,
      callEndModalActive,
      lastCall,
      orderListFetching,
      fullsize,
    };
  },
  (dispatch, props) => ({
    createSMS: call => dispatch(
      createSMS(
        props.params.companyId, props.params.customerId, call.id)),
    load: () => dispatch(activeCallGet.load({
      companyId: props.params.companyId,
      customerId: props.params.customerId,
    })),
    createCall: () => createCall(props.params.companyId, props.params.customerId)
      .then((call) => {
        dispatch(callCreated({ call }));
        dispatch(push(`/company/${props.params.companyId}/customer/${props.params.customerId}`));
      }),
    stopCallConnection: () => {
      //console.log('stopCallConnection');
      dispatch(endCall());
      unsubscribe();
    },
    stopCallWithoutNote: (theCall) => {
      const isModerator = (props.room.members.filter(subscriber => props.room.self.connectionId !== subscriber.connectionId).length == 0);
      const signal = isModerator ? 'moderator-end-call' : 'subscriber-leave-call';
      sendSignal(signal, props.room.self.connectionId);
      dispatch(endCall());
      unsubscribe();
      if (isModerator) {
        createCallEnd(props.params.companyId, props.params.customerId, theCall.id)
          .then(({ callEnd, call }) => {
            dispatch(callEndCreated(callEnd));
            dispatch(eventCreated(callEnd));
            dispatch(setLastCall(call));
            dispatch(updateCall(call));
          });
      } else {
        dispatch(setLastCall(theCall));
        dispatch(updateCall(theCall));
        dispatch(callLeave());
      }
    },
    stopCall: (theCall) => {
      const isModerator = (props.room.members.filter(subscriber => props.room.self.connectionId !== subscriber.connectionId && subscriber.type === 'subscriber').length == 0);
      const signal = isModerator ? 'moderator-end-call' : 'subscriber-leave-call';
      sendSignal(signal, props.room.self.connectionId);
      dispatch(endCall());
      unsubscribe();
      if (isModerator) {
        createCallEnd(props.params.companyId, props.params.customerId, theCall.id)
        .then(({ callEnd, call }) => {
          dispatch(callEndCreated(callEnd));
          dispatch(eventCreated(callEnd));
          dispatch(setLastCall(call));
          dispatch(updateCall(call));
          dispatch(openCallEndModal());
        });
      } else {
        dispatch(setLastCall(theCall));
        dispatch(updateCall(theCall));
        dispatch(callLeave());
      }
    },
    createNote: (note, form) => {
      createNote(
        props.params.companyId, props.params.customerId, props.params.orderId || -1, note)
        .then((newNote) => {
          dispatch(eventCreated(newNote));
          if (form) {
            form.reset()
          }
        });
    },
    createNoteWithCall: (note, call, form) => createNoteWithCall(
      props.params.companyId, props.params.customerId, call.id, note)
      .then((newNote) => {
        dispatch(eventCreated(newNote));
        if (form) {
          form.reset();
        }
      }),
    createEndNote: (note, call, form) => {
      createEndNote(
        props.params.companyId, props.params.customerId, call.id, note)
        .then((newNote) => {
          dispatch(endNoteCreated(newNote));
          if (form) {
            form.reset();
          }
          dispatch(orderList.load(props.params));
        })
    },
    addCallToOrder: (call, order) => addCallToOrder(
        props.customer.company_id,
        props.customer.id,
        order.id,
        { callId: call.id },
    ).then((orders) => {
      dispatch(callAddedToOrder({ orders }));
    }),
    openCallBeginModal: () => {
      // check if have an active call remote
      getActiveCall(
        props.params.companyId,
        props.params.customerId,
      ).then((call) => {
        if (call) {
          //console.log('openCallBeginModel %o', call);
          dispatch(activeCallGet.success(call));
        } else {
          //console.log('no call found %o', call);
          dispatch(openCallBeginModal());
        }
      });
    },
    ...bindActionCreators({
      closeCallBeginModal,
      openCallEndModal,
      closeCallEndModal,
    }, dispatch),
  })
)(CustomerSinglePageComponent);
