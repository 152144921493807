import React from 'react';

export default function TextField({ type, ...input }) {
    return input.rows ? (
        <textarea
            className="form-control"
            type={type || 'text'}
            id={input.name}
            {...input}
        />
    ) : (
        <input
            className="form-control"
            type={type || 'text'}
            id={input.name}
            {...input}
        />
    );
}
