import React, { Component } from 'react';
// eslint-disable-next-line
import OT from 'opentok';

import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { detectIOS } from '../../helpers/browserdetect';

class OpenTokRequiredPageComponent extends Component {
  componentWillMount() {
    if (detectIOS()) {
      const __getSupportedCodecs = async function build(page) {
        try {
          //console.log('Start check on supported codecs');
          const supportedCodecs = await OT.getSupportedCodecs();
          //console.log('Start check on supported codecs are loaded');
          // The client does not support encoding or decoding H264.
          // Let's recommend using a different browser.

          if (supportedCodecs
            && supportedCodecs.videoEncoders.indexOf('VP8') >= 0
            && supportedCodecs.videoDecoders.indexOf('VP8') >= 0) {
          } else {
            page.props.replace({
              pathname: '/iosapp/',
              query: { url: page.props.location.pathname },
            });
          }
        } catch (err) {
          page.props.replace({
            pathname: '/iosapp/',
            query: { url: page.props.location.pathname },
          });
        }
      };

      __getSupportedCodecs(this);
/*
      this.props.replace({
        pathname: '/iosapp/',
        query: { url: this.props.location.pathname },
      });
 */
    } else
    if (OT.checkSystemRequirements() === 0) {
      this.props.replace({
        pathname: '/nsp/',
        query: { url: this.props.location.pathname },
      });
    }
  }
  render() {
    return this.props.children;
  }
}

export default connect(null, { replace })(OpenTokRequiredPageComponent);

/*
*/
