import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';
import Modal from 'react-modal';

const messages = defineMessages({
  CLASSIY_PROJECT: {
    id: 'EndCallModalForm.CLASSIY_PROJECT',
    defaultMessage: 'In Projekt einordnen',
  },
  SAVE_AND_BACK: {
    id: 'EndCallModalForm.SAVE_AND_BACK',
    defaultMessage: 'Speichern und Zurück',
  },
});

class EndCallModalForm extends React.Component {

  render() {
    const {
      orders,
      intl: { formatMessage },
     } = this.props;

    const _onSubmitLocal = (data, form) => {
      return this.props.onSubmit(data, form);
    }

    Modal.setAppElement('#app');

    return (
      <Form
        onSubmit={_onSubmitLocal}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field name="endNote">
              {({ input, meta }) => {
              return (
                <textarea
                {...input}
                className="form-control call-end-modal-textarea"
                cols="30"
                rows="3"
                />
              )
              }}
            </Field>
            <Field name="order">
              {({ input, meta }) => {
                return (
                <select
                  className="form-control call-end-modal-select"
                  {...input}
                >
                  <option value="false">{formatMessage(messages.CLASSIY_PROJECT)}</option>
                    {orders
                      .filter(o => o.id > -1 && o.active)
                      .map((ord, index) => (
                        <option key={index} value={ord.id}>{ord.name}</option>
                      ))}
                </select>
              )
              }}
            </Field>
            <input
              type="submit"
              className="lh-btn lh-btn-primary btn-lg call-end-modal-submit"
              value={formatMessage(messages.SAVE_AND_BACK)}
            />
          </form>
        )}
      </Form>
    );
  }
}

export default injectIntl(EndCallModalForm);
