import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  NO_VIDEO_SUPPORT: {
    id: 'NoVideoSupport.NO_VIDEO_SUPPORT',
    defaultMessage: 'Ihr Browser unterstützt leider kein Videoübertragung.',
  },
  IMPROVE_VIDEO_SUPPORT: {
    id: 'NoVideoSupport.IMPROVE_VIDEO_SUPPORT',
    defaultMessage: 'Wir arbeiten mit Hochdruck daran, die Browserunterstützung '
      + 'zu verbessern, allerdings wird ihr Browser bisher nicht unterstützt.',
  },
  FIREFOX_OR_CHROME: {
    id: 'NoVideoSupport.FIREFOX_OR_CHROME',
    defaultMessage: 'Sie können diese Web-Anwendung mit aktuellen Firefox oder Chrome verwenden.',
  },
  THANKS_FOR_UNDERSTANDING: {
    id: 'NoVideoSupport.THANKS_FOR_UNDERSTANDING',
    defaultMessage: 'Vielen Dank für Ihr Verständnis.',
  },
});

function NoVideoSupport(props) {
  const { browserWithoutVideosupport, companyId, customerId, intl: { formatMessage } } = props;

  if (!browserWithoutVideosupport) {
    return null;
  }

  return (
    <div style={{ backgroundColor: 'white', padding: '20px', width: 480, margin: '40px auto', border: '1px solid #ccc', borderRadius: '5px' }}>
      <h4 style={{ margin: '0 0 15px 0' }}>
        {formatMessage(messages.NO_VIDEO_SUPPORT)}
      </h4>
      <p style={{ margin: '0 0 15px 0' }}>
        {formatMessage(messages.IMPROVE_VIDEO_SUPPORT)}
      </p>
      <p style={{ margin: '0 0 15px 0' }}>
        {formatMessage(messages.FIREFOX_OR_CHROME)}
      </p>
      <pre style={{ margin: '0 0 15px 0', padding: 15 }}>
        <a href={`https://www.tele-look.com/company/${companyId}/customer/${customerId}/`}>
          https://www.tele-look.com/company/{companyId}/customer/{customerId}/
        </a>
      </pre>
      <p style={{ margin: '0 0 15px 0' }}>
        {formatMessage(messages.THANKS_FOR_UNDERSTANDING)}
      </p>
      <p style={{ margin: 0 }}>
        <a href="mailto:support@tele-look.com">support@tele-look.com</a>.
      </p>
    </div>
  );
}

export default injectIntl(NoVideoSupport);
