import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  OWNER: {
    id: 'CompanyUserSingle.OWNER',
    defaultMessage: 'Besitzer',
  },
  ADMIN: {
    id: 'CompanyUserSingle.ADMIN',
    defaultMessage: 'Administrator',
  },
  DEACTIVATED: {
    id: 'CompanyUserSingle.DEACTIVATED',
    defaultMessage: 'Deaktiviert',
  },
});

function CompanyUserSingle(props) {
  const { companyUser, intl: { formatMessage } } = props;
  const { user } = companyUser;
  const initiales = (user.name || '').split(' ').filter(w => w).map(w => w[0]).join('');
  return (
    <div className="media">
      <div className="media-left">
        <div className="avatar">{initiales}</div>
      </div>
      <div className="media-body">
        <h4 className="media-heading" style={{ wordWrap: 'break-word' }}>{user.name}</h4>
        {user.email || user.phone ? (
          <div>
            {user.email ? user.email : null}
            {user.email && user.phone ? ' ' : null}
            {user.phone ? user.phone : null}
          </div>
        ) : null}
        {companyUser.is_owner || companyUser.is_admin ? (
          <div>
            {companyUser.is_owner ? (
              <span className="label label-primary" style={{ color: '#fff' }}>
                {formatMessage(messages.OWNER)}
              </span>
            ) : null}
            {companyUser.is_admin ? (
              <span className="label label-info" style={{ color: '#fff' }}>
                {formatMessage(messages.ADMIN)}
              </span>
            ) : null}
            {companyUser.is_inactive ? (
              <span className="label label-danger" style={{ color: '#fff' }}>
                {formatMessage(messages.DEACTIVATED)}
              </span>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default injectIntl(CompanyUserSingle);
