import superagent from 'superagent';
import { store } from '../main';

export const host = '';
export const baseUrl = `${host}/api/`;

/* global localStorage */
/* global location */

// Workaround for missing local storage, especially Safari in private mode
let localToken;

function saveAuthToken(receivedToken) {
  try {
    localStorage.setItem('token', receivedToken);
  } catch (e) {
    localToken = receivedToken;
  }
}

export function getAuthToken() {
  try {
    return localStorage.getItem('token');
  } catch (e) {
    return localToken;
  }
}

export function removeAuthToken() {
  localToken = undefined;
  try {
    localStorage.removeItem('token');
  } catch (e) {
    // needed for safari
  }
}


function analyzeResponse(error, response) {
  if (response && response.header['x-authorization-token']) {
    saveAuthToken(response.header['x-authorization-token']);
  }
  if (error) {
    if (error.status === 401) {
      if (location.pathname !== '/login/') {
        location.href = `/login/?reason=401&next=${location.pathname}`;
      }
    }
    if (error.response) {
      if (error.response.body) {
        return { error: (error.response.body), response: null };
      }
      return { error: { _error: error.response.text || error.response.error.message }, response: null };
    }
    return { error: { _error: error.message }, response: null };
  }
  return { error: null, response: response.body };
}

function callback(resolve, reject) {
  return (error, response) => {
    if (response && response.header['x-authorization-token']) {
      saveAuthToken(response.header['x-authorization-token']);
    }
    if (error) {
      if (error.status === 401) {
        if (location.pathname !== '/login/') {
          location.href = `/login/?reason=401&next=${location.pathname}`;
        }
      }
      if (error.response) {
        if (error.response.body) {
          reject(error.response.body);
        } else {
          reject({ _error: error.response.text || error.response.error.message });
        }
      } else {
        reject({ _error: error.message });
      }
      return;
    }
    resolve(response.body);
  };
}

function configRequest(_request, { query = {}, data = {}, fields = {}, attachments = {} }) {
  let request = _request;
  Object.keys(query).forEach(k => {
    if (Array.isArray(query[k])) {
      query[k].forEach(v => {
        request = request.query({ [`${k}[]`]: v });
      });
    } else {
      request = request.query({ [k]: query[k] });
    }
  });

  if (Object.keys(data).length) {
    request = request.send(data);
  }
  Object.keys(fields).filter(fieldName => fields[fieldName]).forEach(fieldName => {
    request = request.field(fieldName, fields[fieldName]);
  });
  Object.keys(attachments)
    .filter(attachmentName => attachments[attachmentName])
    .forEach(attachmentName => {
      request = request.attach(attachmentName, attachments[attachmentName]);
    });

  request = request.set('Accept', 'application/json');
  request.set('X-User-Locale', store.getState().intl.locale);

  const token = getAuthToken();
  request = request.set('Authorization', token ? `Bearer ${token}` : undefined);
  return request;
}

function createRequest(type) {
  return ({ url, ...rest }, resolve, reject, progress) => {
    if (!url) {
      throw new Error('url required!');
    }
    const request = configRequest(superagent[type](host + url), rest);
    if (progress) {
      request.on('progress', progress);
    }
    return request.end(callback(resolve, reject));
  };
}

function createRequestAwait(type) {

  return async ({ url, ...rest }, progress) => {
    if (!url) {
      throw new Error('url required!');
    }
    const request = configRequest(superagent[type](host + url), rest);
    if (progress) {
      request.on('progress', progress);
    }

    try {
      const res = await request;
      return analyzeResponse(null, res);
    } catch (err) {
      return analyzeResponse(err, null);
    }
  };
}

function createPromise(fn) {
  return (params, progress) =>
    new Promise((resolve, reject) => fn(params, resolve, reject, progress));
}

export function debounce(fn, delay) {
  let timer = null;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
}

export const _get = createRequest('get'); // GET
export const _put = createRequest('put'); // PUT
export const _put2 = createRequestAwait('put'); // PUT
export const _del = createRequest('del'); // DELETE
export const _del2 = createRequestAwait('del'); // DELETE
export const _post = createRequest('post'); // POST
export const _post2 = createRequestAwait('post'); // POST
export const _patch = createRequest('patch'); // PATCH

export const get = createPromise(_get);
export const put = createPromise(_put);
export const del = createPromise(_del);
export const post = createPromise(_post);
export const patch = createPromise(_patch);

export default {
  get,
  _get,
  post,
  _post,
  _post2,
  put,
  _put,
  _put2,
  patch,
  _patch,
  del,
  _del,
  _del2,
  debounce,
  baseUrl,
  host,
  removeAuthToken,
};
