import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { trunc } from '../../../helpers';

const messages = defineMessages({
  CALL_BEGIN: {
    id: 'BillingCurrentMonth.CALL_BEGIN',
    defaultMessage: 'Gesprächsbeginn',
  },
  USER_NAME: {
    id: 'BillingCurrentMonth.USER_NAME',
    defaultMessage: 'Benutzer',
  },
  CUSTOMER_NAME: {
    id: 'BillingCurrentMonth.CUSTOMER_NAME',
    defaultMessage: 'Teilnehmername',
  },
  CUSTOMER_PHONE: {
    id: 'BillingCurrentMonth.CUSTOMER_PHONE',
    defaultMessage: 'Teilnehmer-TelNr.',
  },
  PROJECT: {
    id: 'BillingCurrentMonth.PROJECT',
    defaultMessage: 'Projekt',
  },
  DURATION: {
    id: 'BillingCurrentMonth.DURATION',
    defaultMessage: 'Dauer (Sek.)',
  },
  CALL_ACTIVE: {
    id: 'BillingCurrentMonth.CALL_ACTIVE',
    defaultMessage: 'Das Gespräch ist noch aktiv',
  },
  TOTAL_DURATION: {
    id: 'BillingCurrentMonth.TOTAL_DURATION',
    defaultMessage: 'Summe (in Sekunden)',
  },
  EMPTY_MESSAGE: {
    id: 'BillingCurrentMonth.EMPTY_MESSAGE',
    defaultMessage: 'Im aktuellen Monat wurden noch keine Kundengespräche geführt.',
  },
});

function BillingCurrentMonth({ connections, intl: { formatMessage } }) {
  if (connections.length === 0) {
    return <p>{formatMessage(messages.EMPTY_MESSAGE)}</p>;
  }

  let totalDuration = 0;

  return (
    <table className="table table-condensed table-striped">
      <thead>
        <tr>
          <th>{formatMessage(messages.CALL_BEGIN)}</th>
          <th>{formatMessage(messages.USER_NAME)}</th>
          <th>{formatMessage(messages.CUSTOMER_NAME)}</th>
          <th>{formatMessage(messages.CUSTOMER_PHONE)}</th>
          <th>{formatMessage(messages.PROJECT)}</th>
          <th style={{ textAlign: 'right' }}>
            {formatMessage(messages.DURATION)}
          </th>
        </tr>
      </thead>
      <tbody>
        {connections.map(connection => {
          if (connection.duration === 0) {
            return null;
          }

          totalDuration += connection.duration;

          return (
            <tr>
              <td>
                {connection.begin}
                {connection.active &&
                  <i
                    className="icon-phone"
                    style={{ color: '#4db88a', marginLeft: 8 }}
                    title={formatMessage(messages.CALL_ACTIVE)}
                  />}
              </td>
              <td>{trunc(connection.user, 30)}</td>
              <td>{trunc(connection.customerName, 30)}</td>
              <td>{connection.customerPhone}</td>
              <td>{trunc(connection.order, 30)}</td>
              <td className="text-right">{connection.duration}</td>
            </tr>
          );
        })}
      </tbody>

      <tfoot>
        <tr className="text-right">
          <td colSpan="5">
            <strong>{formatMessage(messages.TOTAL_DURATION)}</strong>
          </td>
          <td><strong>{totalDuration}</strong></td>
        </tr>
      </tfoot>
    </table>
  );
}

export default injectIntl(BillingCurrentMonth);
