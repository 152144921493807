import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Button } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';

import FormGroup2 from '../../../components/form/FormGroup';
import TextField from '../../../components/form/TextField';

const messages = defineMessages({
  PLACEHOLDER_NAME: {
    id: 'OrderForm.PLACEHOLDER_NAME',
    defaultMessage: 'Name',
  },
  CANCEL: {
    id: 'OrderForm.CANCEL',
    defaultMessage: 'Abbrechen',
  },
  SAVE: {
    id: 'OrderForm.SAVE',
    defaultMessage: 'Speichern',
  },
});

class OrderForm extends React.Component {
  render() {
    const {
        onSubmit,
        intl: { formatMessage },
        initialValues,
    } = this.props;
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={{ ...initialValues }}
      >
        {({ submitError, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="order-rename-form">
            <Field
              name="name"
            >
              {({ input, meta }) => {
                return (
                  <FormGroup2 field="name">
                    <TextField
                      tabIndex="1"
                      autoComplete="off"
                      placeholder={formatMessage(messages.PLACEHOLDER_NAME)}
                      {...input}
                    />
                  </FormGroup2>
                );
              }}
            </Field>
            <Button onClick={() => this.props.cancelEdit()}>{formatMessage(messages.CANCEL)}</Button>
            <Button bsStyle="primary" type="submit">{formatMessage(messages.SAVE)}</Button>
          </form>
        )}
      </Form>
    );
  }
}

export default injectIntl(OrderForm);
