import Modal from 'react-modal';
import { defineMessages, injectIntl } from 'react-intl';
import React from 'react';
import moment from 'moment';

import EndCallModalForm from './EndCallModalForm';

require('moment-duration-format');

const messages = defineMessages({
  CONVERSATION_ENDED: {
    id: 'EndCallModal.CONVERSATION_ENDED',
    defaultMessage: 'Sie haben das Gespräch beendet.',
  },
  OVERALL_DURATION: {
    id: 'EndCallModal.OVERALL_DURATION',
    defaultMessage: 'Gesamtdauer',
  },
  WRITE_NOTE: {
    id: 'EndCallModal.WRITE_NOTE',
    defaultMessage: 'Wenn Sie möchten, können Sie eine Endnotiz schreiben.',
  },
  NOTE_CALL_IS_ENDED: {
    id: 'EndCallModal.NOTE_CALL_IS_ENDED',
    defaultMessage: 'Die Verbindung ist beendet und vollständig abgeschlossen! Erneute Verbindungen sind ohne Ihre ausdrückliche Zustimmung nicht möglich.',
  },
});

function EndCallModal(props) {
  const {
    open,
    handleModalSuccess,
    handleModalClose,
    orders,
    lastCall,
    intl: { formatMessage },
  } = props;

  return (
    <Modal
      contentLabel="Modal"
      isOpen={open}
      closeTimeoutMS={0}
      shouldCloseOnOverlayClick
      onRequestClose={handleModalClose}
      className="lh-modal"
      style={{ overlay: { zIndex: 20000000 } }}
    >
      <h2 className="call-end-modal-title">{formatMessage(messages.CONVERSATION_ENDED)}</h2>
      <div className="call-end-modal-note-is-ended">
        {formatMessage(messages.NOTE_CALL_IS_ENDED)}
      </div>
      <div className="call-end-modal-duration-title">{formatMessage(messages.OVERALL_DURATION)}:</div>
      <h3 className="call-end-modal-duration-value">{lastCall && moment.duration(lastCall.duration, 'seconds').format('mm:ss', { trim: false })} min</h3>
      <div className="call-end-modal-end-note">
        {formatMessage(messages.WRITE_NOTE)}
      </div>
      <EndCallModalForm orders={orders} onSubmit={handleModalSuccess} />
    </Modal>
  );
}

export default injectIntl(EndCallModal);
