import React from 'react';
import classNames from 'classnames';

export default function FormError(props) {
    const { error, horizontal, className, style } = props;
    const groupClassName = classNames('form-group', className, { 'has-error': error });
    const blockClassName = classNames({ 'col-sm-8': horizontal, 'col-sm-offset-4': horizontal });

    let errors;
    if (error) {
        errors = Array.isArray(error) ? (
            error.map((err, i) => (
                <span key={i} className="help-block">{err}</span>
            ))
        ) : (
            <span className="help-block">{error}</span>
        );
    }

    //console.log(props);

    return errors ? (
        <div className={groupClassName} style={style}>
            <div className={blockClassName}>
                {errors}
            </div>
        </div>
    ) : <div />;
}

FormError.defaultProps = {
    horizontal: false,
};
