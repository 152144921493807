import React from 'react';
import { connect } from 'react-redux';

export const AUDIO_STATUS_REQUESTED = 'Warten bis der Kunde sein Mikrofon freischaltet.';
export const AUDIO_STATUS_ON = 'Ton erfolgreich aktiviert.';
export const AUDIO_STATUS_OFF = 'Der Kunde hat den Vorgang abgebrochen.';


class AudioRequestedState extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.requested && nextProps.requested) {
      this.setState({ status: AUDIO_STATUS_REQUESTED });
    }
    if (this.props.requested && !nextProps.requested) {
      this.setState({ status: nextProps.active ? AUDIO_STATUS_ON : AUDIO_STATUS_OFF });
      setTimeout(() => this.setState({ status: null }), 2500);
    }
  }

  render() {
    const { status } = this.state;
    if (!status) {
      return <div />;
    }
    return (
      <div className="video-overlay">{status}</div>
    );
  }
}

export default connect(
  state => ({
    requested: state.audio.self.requested,
    active: state.audio.remote.active,
  })
)(AudioRequestedState);
