
function createApiRequestActions(base) {
  return {
    LOAD: `${base}_LOAD`,
    REQUEST: `${base}_REQUEST`,
    SUCCESS: `${base}_SUCCESS`,
    FAILURE: `${base}_FAILURE`,
    SEARCH: `${base}_SEARCH`,
  };
}
export const ORDER_SELECTED = 'ORDER_SELECTED';

export const COMPANY_CREATED = 'COMPANY_CREATED';
export const COMPANY_UPDATED = 'COMPANY_UPDATED';
export const COMPANY_DELETED = 'COMPANY_DELETED';

export const COMPANY_USER_CREATED = 'COMPANY_USER_CREATED';
export const COMPANY_USER_UPDATED = 'COMPANY_USER_UPDATED';
export const COMPANY_USER_DELETED = 'COMPANY_USER_DELETED';

export const CUSTOMER_CREATED = 'CUSTOMER_CREATED';
export const CUSTOMER_UPDATED = 'CUSTOMER_UPDATED';
export const CUSTOMER_DELETED = 'CUSTOMER_DELETED';

export const EVENT_CREATED = 'EVENT_CREATED';
export const EVENT_DELETED = 'EVENT_DELETED';
export const EVENT_UPDATED = 'EVENT_UPDATED';
export const ORDER_CREATED = 'ORDER_CREATED';
export const CALL_ADDED_TO_ORDER = 'CALL_ADDED_TO_ORDER';
export const NOTE_ADDED_TO_ORDER = 'NOTE_ADDED_TO_ORDER';

export const OPEN_CALL_BEGIN_MODAL = 'OPEN_CALL_BEGIN_MODAL';
export const CLOSE_CALL_BEGIN_MODAL = 'CLOSE_CALL_BEGIN_MODAL';
export const OPEN_CALL_END_MODAL = 'OPEN_CALL_END_MODAL';
export const CLOSE_CALL_END_MODAL = 'CLOSE_CALL_END_MODAL';
export const OFFLINE_STATUS = 'OFFLINE_STATUS';

export const END_NOTE_CREATED = 'END_NOTE_CREATED';

export const NOTE_CREATED = 'NOTE_CREATED';
export const NOTE_WITH_CALL_CREATED = 'NOTE_WITH_CALL_CREATED';
export const EVENT_LIST_HEIGHT_UPDATED = 'EVENT_LIST_HEIGHT_UPDATED';
export const SET_LAST_CALL = 'SET_LAST_CALL';
export const UPDATE_CALL = 'UPDATE_CALL';

export const COMPANY_LIST = createApiRequestActions('COMPANY_LIST');
export const COMPANY_SINGLE = createApiRequestActions('COMPANY_SINGLE');
export const COMPANY_USER_LIST = createApiRequestActions('COMPANY_USER_LIST');
export const COMPANY_USER_SINGLE = createApiRequestActions('COMPANY_USER_SINGLE');
export const CUSTOMER_LIST = createApiRequestActions('CUSTOMER_LIST');
export const CUSTOMER_SINGLE = createApiRequestActions('CUSTOMER_SINGLE');
export const EVENT_LIST = createApiRequestActions('EVENT_LIST');
export const ORDER_LIST = createApiRequestActions('ORDER_LIST');

export const ACTIVE_CALL = createApiRequestActions('ACTIVE_CALL');
export const ACTIVE_CALL_UPDATED = 'ACTIVE_CALL_UPDATED';

export const COMPANY_MEMBERSHIP = createApiRequestActions('COMPANY_MEMBERSHIP');

export const ORDER_SIDEBAR_TOGGLED = 'ORDER_SIDEBAR_TOGGLED';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTRATION_CONFIRMATION_SUCCESS = 'REGISTRATION_CONFIRMATION_SUCCESS';
export const REGISTRATION_CONFIRMATION_FAIL = 'REGISTRATION_CONFIRMATION_FAIL';

export const HEART_BEAT = 'HEART_BEAT';

export const CALL_CREATED = 'CALL_CREATED';
export const CALL_RECREATED = 'CALL_RECREATED';

export const NAVBAR_CONFIG_SET = 'NAVBAR_CONFIG_SET';

export const CONNECTION_INIT = 'CONNECTION_INIT';
export const CONNECTION_DESTROY = 'CONNECTION_DESTROY';

export const STREAM_CREATED = 'STREAM_CREATED';

export const REMOTE_VIDEO_ACTIVATED = 'REMOTE_VIDEO_ACTIVATED';

export const RESET_VIDEO = 'RESET_VIDEO';

export const REMOTE_AUDIO_ACTIVATED = 'REMOTE_AUDIO_ACTIVATED';
export const REMOTE_AUDIO_DEACTIVATED = 'REMOTE_AUDIO_DEACTIVATED';
export const REMOTE_AUDIO_MUTED = 'REMOTE_AUDIO_MUTED';
export const REMOTE_AUDIO_UNMUTED = 'REMOTE_AUDIO_UNMUTED';
export const REMOTE_AUDIO_VOLUME = 'REMOTE_AUDIO_VOLUME';
export const REMOTE_AUDIO_SPEAKING = 'REMOTE_AUDIO_SPEAKING';
export const REMOTE_AUDIO_UNSPEAKING = 'REMOTE_AUDIO_UNSPEAKING';
export const REMOTE_AUDIO_REQUESTED = 'REMOTE_AUDIO_REQUESTED';
export const REMOTE_AUDIO_ACCEPTED = 'REMOTE_AUDIO_ACCEPTED';
export const REMOTE_AUDIO_DECLINED = 'REMOTE_AUDIO_DECLINED';

export const AUDIO_ACTIVATED = 'AUDIO_ACTIVATED';
export const AUDIO_DEACTIVATED = 'AUDIO_DEACTIVATED';
export const AUDIO_MUTED = 'AUDIO_MUTED';
export const AUDIO_UNMUTED = 'AUDIO_UNMUTED';
export const AUDIO_VOLUME = 'AUDIO_VOLUME';
export const AUDIO_SPEAKING = 'AUDIO_SPEAKING';
export const AUDIO_UNSPEAKING = 'AUDIO_UNSPEAKING';
export const AUDIO_REQUESTED = 'AUDIO_REQUESTED';
export const AUDIO_ACCEPTED = 'AUDIO_ACCEPTED';
export const AUDIO_DECLINED = 'AUDIO_DECLINED';
