import { get, _put2, put } from './api';

export const loadPaymentData = companyId => get({
  url: `/api/company/${companyId}/payment`,
});
export const savePaymentData = (companyId, data) => put({
  url: `/api/company/${companyId}/payment`,
  data,
});

export const savePaymentData2 = (companyId, data) => _put2({
  url: `/api/company/${companyId}/payment`,
  data,
});
