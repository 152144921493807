import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';

import FormGroup2 from 'components/form/FormGroup';
import TextField from 'components/form/TextField';
import FormError from 'components/form/FormError';
import FormSubmit from 'components/form/FormSubmit';

import { Link } from 'react-router';

const messages = defineMessages({
  INDICATE_PASSWORD: {
    id: 'PasswordResetForm.INDICATE_PASSWORD',
    defaultMessage: 'Bitte geben Sie zur Überprüfung ihr aktuelles Passwort erneut an.',
  },
  FOLLOW_LINK: {
    id: 'PasswordResetForm.FOLLOW_LINK',
    defaultMessage: 'Sollten Sie dies nicht zur Hand haben können Sie auch ihr',
  },
  LINK_RESET_PASSWORD: {
    id: 'PasswordResetForm.LINK_RESET_PASSWORD',
    defaultMessage: 'Passwort zurücksetzen',
  },
  PLACEHOLDER_CURRENT_PASSWORD: {
    id: 'PasswordResetForm.PLACEHOLDER_CURRENT_PASSWORD',
    defaultMessage: 'Aktuelles Passwort',
  },
  CHOOSE_NEW_PASSWORD: {
    id: 'PasswordResetForm.CHOOSE_NEW_PASSWORD',
    defaultMessage: 'Hier können Sie nun ein neues Passwort wählen',
  },
  PLACEHOLDER_NEW_PASSWORD: {
    id: 'PasswordResetForm.PLACEHOLDER_NEW_PASSWORD',
    defaultMessage: 'Neues Passwort',
  },
  PLACEHOLDER_REPEAT_NEW_PASSWORD: {
    id: 'PasswordResetForm.PLACEHOLDER_REPEAT_NEW_PASSWORD',
    defaultMessage: 'Neues Passwort wiederholen',
  },
  PLACEHOLDER_CHANGE_PASSWORD: {
    id: 'PasswordResetForm.PLACEHOLDER_CHANGE_PASSWORD',
    defaultMessage: 'Passwort ändern',
  },
  VALIDATION_CURRENT_PASSWORD: {
    id: 'PasswordResetForm.VALIDATION_CURRENT_PASSWORD',
    defaultMessage: 'Bitte geben Sie ihr aktuelles Passwort an',
  },
  VALIDATION_NEW_PWASSWORD: {
    id: 'PasswordResetForm.VALIDATION_NEW_PWASSWORD',
    defaultMessage: 'Bitte geben Sie ein neues Passwort an',
  },
  VALIDATION_PASSWORDS_DISSENT: {
    id: 'PasswordResetForm.VALIDATION_PASSWORDS_DISSENT',
    defaultMessage: 'Die beiden neuen Passwörter stimmen nicht überein',
  },
});

function PasswordResetForm(props) {
  const {
    intl: { formatMessage },
  } = props;
  return (
    <Form
      onSubmit={props.onSubmit}
      validate={values => {
        const errors = {};
        if (!values.oldpassword) {
          errors.oldpassword = formatMessage(messages.VALIDATION_CURRENT_PASSWORD);
        }
        if (!values.newpassword) {
          errors.newpassword = formatMessage(messages.VALIDATION_NEW_PWASSWORD);
        } else if (values.newpassword !== values.repassword) {
          // hack: use "oldpassword"
          errors.repassword = formatMessage(messages.VALIDATION_PASSWORDS_DISSENT);
        }
        return errors;
        }
      }
    >
      {({ handleSubmit, submitError, submitting }) => (
        <form onSubmit={handleSubmit}>
          <p>
            {formatMessage(messages.INDICATE_PASSWORD)} <br />
            {formatMessage(messages.FOLLOW_LINK)}{' '}
            <Link to="/requestPasswortReset" target="_blank">{formatMessage(messages.LINK_RESET_PASSWORD)}</Link>.
          </p>
          <Field
            name="oldpassword"
          >
            {({ input, meta }) => (
              <FormGroup2 style={{ marginTop: 20 }} input={input} meta={meta}>
                <TextField
                  {...input}
                  tabIndex="1"
                  type="password"
                  placeholder={formatMessage(messages.PLACEHOLDER_CURRENT_PASSWORD)}
                />
              </FormGroup2>
            )}
          </Field>
          <p>
            {formatMessage(messages.CHOOSE_NEW_PASSWORD)}
          </p>
          <Field
            name="newpassword"
          >
            {({ input, meta }) => (
              <FormGroup2 style={{ marginTop: 20 }} input={input} meta={meta}>
                <TextField
                  {...input}
                  tabIndex="2"
                  type="password"
                  placeholder={formatMessage(messages.PLACEHOLDER_NEW_PASSWORD)}
                />
              </FormGroup2>
            )}
          </Field>
          <Field name="repassword">
            {({ input, meta }) => (
              <FormGroup2 input={input} meta={meta}>
                <TextField
                  {...input}
                  tabIndex="3"
                  type="password"
                  placeholder={formatMessage(messages.PLACEHOLDER_REPEAT_NEW_PASSWORD)}
                />
              </FormGroup2>
            )}
          </Field>
          <FormError error={submitError} />
          <FormSubmit
            btnClass="lh-btn lh-btn-primary lh-btn-block"
            btnProps={{ tabIndex: 4 }}
            submitting={submitting}
            label={formatMessage(messages.PLACEHOLDER_CHANGE_PASSWORD)}
          />
        </form>
      )}
    </Form>
  );
}
/*
const PasswordResetFormReduxForm = reduxForm({
  form: 'resetPasswordForm',
  fields: ['newpassword', 'repassword', 'oldpassword'],
  validate: (values, { intl: { formatMessage } }) => {
    const errors = {};
    if (!values.oldpassword) {
      errors.oldpassword = formatMessage(messages.VALIDATION_CURRENT_PASSWORD);
    }
    if (!values.newpassword) {
      errors.newpassword = formatMessage(messages.VALIDATION_NEW_PWASSWORD);
    } else if (values.newpassword !== values.repassword) {
      // hack: use "oldpassword"
      errors.repassword = formatMessage(messages.VALIDATION_PASSWORDS_DISSENT);
    }
    return errors;
  },
  touchOnBlur: false,
})(PasswordResetForm);
*/

export default injectIntl(PasswordResetForm);
