import React from 'react';

export default ({ customer }) => {
  const initialen = (customer.name || '')
  .split(' ')
  .slice(0, 2)
  .filter(w => w)
  .map(w => w[0])
  .join('');
  return (
    <div className="avatar">
      {initialen}
    </div>
  );
};
