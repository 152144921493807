import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form } from 'react-final-form';

import FormError from '../form/FormError';
import FormSubmit from '../form/FormSubmit';

const messages = defineMessages({
  DELETE_COMPANY: {
    id: 'CompanyDeleteComponent.DELETE_COMPANY',
    defaultMessage: 'Unternehmen unwiderruflich löschen',
  },
});

function CompanyDeleteComponent(props) {
  const {
    style,
    intl: { formatMessage },
  } = props;
  return (
    <Form
      onSubmit={props.onSubmit}
    >
      {({ submitError, handleSubmit, submitting }) => (
        <form style={style} onSubmit={handleSubmit}>
          <FormError error={submitError} />
          <FormSubmit
            btnClass="lh-btn lh-btn-warning btn-lg btn-block"
            btnProps={{ tabIndex: 1 }}
            submitting={submitting}
            icon="fa fa-trash"
            label={formatMessage(messages.DELETE_COMPANY)}
          />
        </form>
      )}
    </Form>
  );
}

export default injectIntl(CompanyDeleteComponent);
