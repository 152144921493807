import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import { Link } from 'react-router';
import { updateCustomer, customerUpdated } from '../../actions/company';
import NavBarConfig from '../../components/NavBarConfig';
import CustomerUpdate from './components/CustomerUpdate';

const messages = defineMessages({
  BACK: {
    id: 'AllUsers.BACK',
    defaultMessage: 'Zurück',
  },
  EDIT_CUSTOMER: {
    id: 'CustomerUpdatePage.EDIT_CUSTOMER',
    defaultMessage: 'Kunde bearbeiten',
  },
  DELETE_MESSAGE: {
    id: 'CustomerUpdatePage.DELETE_MESSAGE',
    defaultMessage: 'Kunde löschen',
  },
});

function CustomerUpdatePage({
  company,
  customer,
  routes,
  onSubmit,
  intl: { formatMessage },
}) {
  if (!customer) { return null; }
  return (
    <div>
      <NavBarConfig updatePage currentRouteName={routes[routes.length - 1].name} />
      <div className="m40 w-xs">
        <Link to={`/company/${company.id}/customer/${customer.id}/`}>
          {formatMessage(messages.BACK)}
        </Link>
        <h3>{formatMessage(messages.EDIT_CUSTOMER)}</h3>
        <CustomerUpdate
          onSubmit={onSubmit}
          initialValues={customer}
        />

        <Link
          className="lh-btn lh-btn-warning lh-btn-block btn-lg"
          to={`/company/${company.id}/customer/${customer.id}/delete/`}
          style={{ marginTop: '30px' }}
        >
          <span className="fa fa-trash" />
          {' '}{formatMessage(messages.DELETE_MESSAGE)}
        </Link>
      </div>
    </div>
  );
}

export default connect(null,
  (dispatch, { params }) => ({
    onSubmit: formData => updateCustomer(params.companyId, params.customerId, formData).then(
      (customer) => {
        dispatch(customerUpdated(customer));
        dispatch(push(`/company/${params.companyId}/customer/${params.customerId}/`));
      }
    ),
  }),
)(injectIntl(CustomerUpdatePage));
