import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import { trunc } from '../../../helpers/index';
import {
    orderUpdated,
    orderSetActive,
    orderSetInactive,
} from '../../../actions/company';
import { orderEditStart, orderEditStop } from '../../../actions/orderEdit';
import api from '../../../actions/api';
import OrderForm from './OrderForm';

const messages = defineMessages({
  BUTTON_COMPLETE_PROJECT: {
    id: 'OrderHeader.BUTTON_COMPLETE_PROJECT',
    defaultMessage: 'Projekt abschließen',
  },
  BUTTON_COMPLETE: {
    id: 'OrderHeader.BUTTON_COMPLETE',
    defaultMessage: 'Abschließen',
  },
  BUTTON_REACTIVATE_PROJECT: {
    id: 'OrderHeader.BUTTON_REACTIVATE_PROJECT',
    defaultMessage: 'Projekt reaktivieren',
  },
  ALERT_SAVING: {
    id: 'OrderHeader.ALERT_SAVING',
    defaultMessage: 'Ein Fehler ist aufgetreten beim speichern: {error}',
  },
});

class OrderHeader extends React.Component {
  render() {
    const {
        order,
        companyId,
        customerId,
        orderFinish,
        edit,
        intl: { formatMessage },
    } = this.props;
    if (!order) {
      return null;
    }
    if (!order.name) {
      return null;
    }
    return (
      <div className="order-header">
        <h1 className="order-header-title">
          {!edit && (
            <span className="text-overflow valign">
              <Link
                className="icon-left order-header-back"
                to={`/company/${companyId}/customer/${customerId}/order/-2`}
              />
              <span>
                {trunc(order.name, 30)}
              </span>
            </span>
                    )}
          {edit ? (
            <OrderForm
              order={order}
              onSubmit={formData => this.props.updateOrder(
                              companyId, customerId, order, formData
                            )}
              cancelEdit={this.props.stopEdit}
              initialValues={{ name: order.name }}
            />
          ) : (
            <span>
              <button
                className="lh-btn lh-btn-default order-header-button hidden-sm hidden-xs"
                onClick={this.props.startEdit}
              >
                <span className="fa fa-pencil" />
              </button>
              <i
                className="fa fa-pencil visible-sm visible-xs"
                onClick={this.props.startEdit}
              />
            </span>
          )}
          {!edit && (
            order.active ? (
              <span>
                <button
                  className="lh-btn lh-btn-primary lh-btn-small lh-btn-extra-small order-header-button hidden-sm hidden-xs"
                  onClick={() => orderFinish(companyId, customerId, order.id)}
                >
                  {formatMessage(messages.BUTTON_COMPLETE_PROJECT)}
                </button>
                <button
                  className="lh-btn lh-btn-primary lh-btn-small lh-btn-extra-small order-header-button visible-sm visible-xs"
                  onClick={() => orderFinish(companyId, customerId, order.id)}
                >
                  {formatMessage(messages.BUTTON_COMPLETE)}
                </button>
              </span>
            ) : (
              <button
                className="lh-btn lh-btn-primary lh-btn-small lh-btn-extra-small order-header-button"
                onClick={() => this.props.orderSetActive(
                        companyId, customerId, order.id
                    )}
              >
                <span className="fa fa-check active" /> {formatMessage(messages.BUTTON_REACTIVATE_PROJECT)}
              </button>
            )
        )}
        </h1>
        <Link
          to={`/company/${companyId}/customer/${customerId}`}
        >
          <i className="icon-fail order-header-close" />
        </Link>
      </div>
    );
  }
}

/* global alert */

const OrderHeaderConnect = connect(
  (state, props) => ({
    edit: props.order ? state.orderEdit[props.order.id] || null : null,
  }),
  (dispatch, props) => ({
    orderFinish: (companyId, customerId, orderId) => orderSetInactive(
        companyId, customerId, orderId).then(
          newO => dispatch(orderUpdated(newO))),
    orderSetActive: (companyId, customerId, orderId) => orderSetActive(
        companyId, customerId, orderId).then(
          newO => dispatch(orderUpdated(newO))),
    startEdit: () => dispatch(orderEditStart(props.order)),
    stopEdit: () => dispatch(orderEditStop(props.order.id)),
    updateOrder: (companyId, customerId, order, formData) => {
          // console.info('updateOrder', evt, formData);
      api.put({ url: `/api/company/${companyId}/customer/${customerId}/order/${order.id}/name`, data: formData })
      .then(
          (newOrder) => {
            dispatch(orderUpdated(newOrder));
            dispatch(orderEditStop(newOrder.id));
          },
          // eslint-disable-next-line no-alert
          (e) => { alert(props.intl.formatMessage(messages.ALERT_SAVING, { error: e })); }
      );
    },
  })
)(OrderHeader);

export default injectIntl(OrderHeaderConnect);
