import React from 'react';

import Application from './pages/Application';
import IndexPage from './pages/IndexPage';
import NotFoundPage from './pages/NotFoundPage';

import LoginPage from './pages/anonymous/LoginPage';
import RequestPasswordReset from './pages/anonymous/RequestPasswordReset';
import RequestPasswordResetSuccess from './pages/anonymous/RequestPasswordResetSuccess';
import LogoutPage from './pages/anonymous/LogoutPage';
import ResetPassword from './pages/anonymous/ResetPassword';
import ResetPasswordSuccess from './pages/anonymous/ResetPasswordSuccess';
import ResetPasswordTokenError from './pages/anonymous/ResetPasswordTokenError';
import TermsPage from './pages/anonymous/TermsPage';
import OpenTokRequiredPage from './pages/anonymous/OpenTokRequiredPage';
import HandbookPage from './pages/anonymous/HandbookPage'
import LoginRequiredPage from './pages/anonymous/LoginRequiredPage';
import SlugPage from './pages/anonymous/SlugPage';
import MaintenanceModePage from './pages/anonymous/MaintenanceModePage';

import IOSAppPage from './pages/anonymous/IOSAppPage';
import ImpressumPage from './pages/anonymous/ImpressumPage';
import DatenschutzPage from './pages/anonymous/DatenschutzPage';
import NotSupportedPage from './pages/anonymous/NotSupportedPage';
import SamsungBrowserPage from './pages/anonymous/SamsungBrowserPage';

import Navigation from './pages/Navigation';

import SettingsPage from './pages/user/SettingsPage';
import SettingsPasswordPage from './pages/user/SettingsPasswordPage';
import SettingsPasswordSuccess from './pages/user/SettingsPasswordSuccess';

import CompanyListPage from './pages/company/CompanyListPage';
import CompanyContainer from './pages/company/CompanyContainer';
import CompanyCreatePage from './pages/company/CompanyCreatePage';
import CompanySinglePage from './pages/company/CompanySinglePage';
import CompanyUpdatePage from './pages/company/CompanyUpdatePage';
import CompanyDeletePage from './pages/company/CompanyDeletePage';
import CompanyPaymentPage from './pages/company/CompanyPaymentPage';
import CompanyPaymentEditPage from './pages/company/CompanyPaymentEditPage';
import CompanyBillingPage from './pages/company/CompanyBillingPage';
import CompanyBillingRedirectPage from './pages/company/CompanyBillingRedirectPage';

import CompanyUserListPage from './pages/companyUser/CompanyUserListPage';

import CompanyUserContainer from './pages/companyUser/CompanyUserContainer';
import CompanyUserCreatePage from './pages/companyUser/CompanyUserCreatePage';
import CompanyUserSinglePage from './pages/companyUser/CompanyUserSinglePage';
import CompanyUserUpdatePage from './pages/companyUser/CompanyUserUpdatePage';
import CompanyUserDeletePage from './pages/companyUser/CompanyUserDeletePage';
import PasswordRequestMessageSuccess from './pages/companyUser/PasswordRequestMessageSuccess';

import CustomerListPage from './pages/customer/CustomerListPage';
import CustomerContainer from './pages/customer/CustomerContainer';
import CustomerCreatePage from './pages/customer/CustomerCreatePage';
import CustomerSinglePage from './pages/customer/CustomerSinglePage';
import CustomerUpdatePage from './pages/customer/CustomerUpdatePage';
import CustomerDeletePage from './pages/customer/CustomerDeletePage';

import AllUserPage from './pages/user/AllUserPage';

import EndkundeVideoPage from './pages/video/EndkundeVideoPage2';
import AfterCallPage from './pages/video/AfterCallPage';

//import VideoExpressPage from './pages/VideoExpressPage'
//import VideoExpressPageHandy from './pages/VideoExpressPageHandy'
// import OpenTokPage from 'pages/OpenTokPage';
// import SimpleWebRTCPage from 'pages/SimpleWebRTCPage';
export default {
  path: '/',
  component: Application,
  indexRoute: { component: IndexPage },
  childRoutes: [
    { path: 'login', component: LoginPage },
    { path: 'register', component: LoginPage },
    { path: 'register/affid/:affId', component: LoginPage },
    { path: 'registerSuccess', component: LoginPage },
    { path: 'terms', component: TermsPage },
    { path: 'requestPasswortReset', component: RequestPasswordReset },
    { path: 'requestPasswortReset/success', component: RequestPasswordResetSuccess },
    { path: 'logout', component: LogoutPage },
    { path: 'confirmRegistration/:userId/:token', component: LoginPage },
    { path: 'resetPassword/:userId/:token', component: ResetPassword },
    { path: 'resetPassword/success', component: ResetPasswordSuccess },
    { path: 'resetPassword/tokenError', component: ResetPasswordTokenError },
    { path: 'impressum', component: ImpressumPage },
    { path: 'datenschutz', component: DatenschutzPage },
    { path: 'handbook', component: HandbookPage },
    { path: 'slug', component: SlugPage },
    { path: 'maintenance', component: MaintenanceModePage },
//    { path: 've', component: VideoExpressPage },
//    { path: 'veh', component: VideoExpressPageHandy },
    {
      component: OpenTokRequiredPage,
      childRoutes: [
        {
          component: LoginRequiredPage,
          childRoutes: [
            {
              component: Navigation,
              childRoutes: [
                { path: 'company', component: CompanyListPage, name: 'company-list' },
                { path: 'company/create', component: CompanyCreatePage, name: 'company-create' },
                {
                  path: 'company/:companyId',
                  component: CompanyContainer,
                  indexRoute: { component: CompanySinglePage, name: 'company-single' },
                  childRoutes: [
                    { path: 'billing', component: CompanyBillingPage, name: 'company-billing' },
                    { path: 'update', component: CompanyUpdatePage, name: 'company-update' },
                    { path: 'delete', component: CompanyDeletePage, name: 'company-delete' },
                    {
                      path: 'payment',
                      indexRoute: { component: CompanyPaymentPage, name: 'company-payment' },
                      childRoutes: [
                        { path: 'edit', component: CompanyPaymentEditPage, name: 'company-payment-edit' },
                      ],
                    },
                    { path: 'user', component: CompanyUserListPage },
                    { path: 'user/create', component: CompanyUserCreatePage },
                    {
                      path: 'user/:userId',
                      component: CompanyUserContainer,
                      indexRoute: { component: CompanyUserSinglePage },
                      childRoutes: [
                        { path: 'update', component: CompanyUserUpdatePage },
                        { path: 'delete', component: CompanyUserDeletePage },
                        { path: 'success', component: PasswordRequestMessageSuccess },
                      ],
                    },
                    { path: 'customer', component: CustomerListPage, name: 'customer-list' },
                    { path: 'customer/create', component: CustomerCreatePage, name: 'customer-create' },
                    {
                      path: 'customer/:customerId',
                      component: CustomerContainer,
                      indexRoute: { component: CustomerSinglePage },
                      childRoutes: [
                        { path: 'order/:orderId', component: CustomerSinglePage, name: 'customer-single' },
                        { path: 'update', component: CustomerUpdatePage, name: 'customer-update' },
                        { path: 'delete', component: CustomerDeletePage, name: 'customer-delete' },
                      ]
                    },
                  ]
                },
                { path: 'users', component: AllUserPage },
                { path: 'settings', component: SettingsPage, name: 'settings' },
                { path: 'settings/password', component: SettingsPasswordPage },
                { path: 'settings/password/success', component: SettingsPasswordSuccess },
                { path: 'billing', component: CompanyBillingRedirectPage, name: 'company-billing-redirect' },
              ],
            },
          ],
        },
      ],
    },
//    { path: '/v/:room', component: SelectDevicePage },
    { path: '/v/:roomName', component: EndkundeVideoPage },
    { path: '/v/:roomName/ios', component: IOSAppPage },
    { path: '/v/:roomName/nsp', component: NotSupportedPage },
    { path: '/v/:roomName/samsung', component: SamsungBrowserPage },
    { path: '/v/:roomName/video', component: EndkundeVideoPage },
    { path: '/v/:roomName/end', component: AfterCallPage },
    { path: '/nsp', component: NotSupportedPage },
    { path: '/iosapp', component: IOSAppPage },
// Customer pages
    { path: '/custom/:companyId/v/:roomName', component: EndkundeVideoPage },
    { path: '/custom/:companyId/v/:roomName/video', component: EndkundeVideoPage },
    { path: '/custom/:companyId/v/:roomName/end', component: AfterCallPage },
    { path: '/custom/:companyId/v/:roomName/samsung', component: SamsungBrowserPage },
// Rest notfoundpage
    { path: '*', component: NotFoundPage },
  ],
};
