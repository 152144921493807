import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router';

const messages = defineMessages({
  REQUEST_RECIEVED: {
    id: 'PasswordRequestMessageSuccess.REQUEST_RECIEVED',
    defaultMessage: 'Ihre Anfrage ist eingegangen',
  },
  MAIL_RESET_PASSWORD: {
    id: 'PasswordRequestMessageSuccess.MAIL_RESET_PASSWORD',
    defaultMessage: 'Der Benutzer hat eine Nachricht mit Instruktionen zur Rücksetzung seines Passwortes erhalten.',
  },
  BACK: {
    id: 'PasswordRequestMessageSuccess.BACK',
    defaultMessage: 'Zurück',
  },
});

function PasswordRequestMessageSuccess({ params: { companyId, userId }, intl: { formatMessage } }) {
  return (
    <div>
      <div className="m40 w-xs">
        <Link to={`/company/${companyId}/user/${userId}/`}>{formatMessage(messages.BACK)}</Link>
        <h3>{formatMessage(messages.REQUEST_RECIEVED)}</h3>
        <p className="alert alert-info">
          {formatMessage(messages.MAIL_RESET_PASSWORD)}
        </p>
      </div>
   </div>
  );
}

export default injectIntl(PasswordRequestMessageSuccess);
