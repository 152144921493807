import React from 'react';
import Highlight from 'react-highlighter';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router';
import moment from 'moment';
import classnames from 'classnames';

import { DATE_FORMAT } from '../../../config';
import CustomerSingle from './CustomerEntry';


const messages = defineMessages({
  CONVERSATION_FINISHED: {
    id: 'CustomerList.CONVERSATION_FINISHED',
    defaultMessage: 'Gespräch beendet',
  },
  CONVERSATION_STARTED: {
    id: 'CustomerList.CONVERSATION_STARTED',
    defaultMessage: 'Gespräch angefangen',
  },
  TOOK_SCREENSHOT: {
    id: 'CustomerList.TOOK_SCREENSHOT',
    defaultMessage: 'Screenshot aufgenommen',
  },
  APPLY_CUSTOMER: {
    id: 'CustomerList.APPLY_CUSTOMER',
    defaultMessage: 'Bisher wurden keine Kunden angelegt. Bitte verwenden Sie "Kunde anlegen" rechts oben um einen Kunden anzulegen.',
  },
  NOT_FOUND_CUSTOMER: {
    id: 'CustomerList.NOT_FOUND_CUSTOMER',
    defaultMessage: 'Es konnte kein Kunde gefunden werden.',
  },
  CLOCK: {
    id: 'CustomerList.CLOCK',
    defaultMessage: 'Uhr',
  },
  NO_EVENTS: {
    id: 'CustomerList.NO_EVENTS',
    defaultMessage: 'Zu diesem Kunden gibt es noch keine Ereignisse.',
  },
});

function renderLastEvent(event, formatMessage, filter) {
  if (event.text) {
    return <Highlight search={filter}>{event.text}</Highlight>;
  }
  if (event.call_event_type === 'end') {
    return formatMessage(messages.CONVERSATION_FINISHED);
  }
  if (event.call_event_type === 'begin') {
    return formatMessage(messages.CONVERSATION_STARTED);
  }
  if (event.pictures && event.pictures.length > 0) {
    return formatMessage(messages.TOOK_SCREENSHOT);
  }
  return null;
}

function CustomerList(props) {
  const { items,
          filter,
          allItems,
          intl: { formatMessage }
  } = props;

  if (!(items && items.length)) {
    let message = formatMessage(messages.APPLY_CUSTOMER);

    if (allItems && allItems.length > 0) {
      message = formatMessage(messages.NOT_FOUND_CUSTOMER);
    }

    return (
      <p style={{ padding: '30px 20px' }}>
        {message}
      </p>
      );
  }
  const orderedItems = items.sort((l, r) => { return l.name > r.name ? 1 : (r.name > l.name ? -1 : 0) });
  return (
    <div className="customer-list" style={{ marginTop: 0 }}>
      {orderedItems.map(customer => (
        <Link
          key={customer.id}
          className={classnames('customer-list-row', { 'customer-list-row-deleted': customer.deleted })}
          to={`/company/${customer.company_id}/customer/${customer.id}/`}
        >
          <CustomerSingle customer={customer} filter={filter} />
          {customer.last_event ? (
            <div className="customer-list-row-last-event">
              <div className="customer-list-row-last-event-time">
                <span className="customer-list-row-text-small">
                  {moment(customer.last_event.created, DATE_FORMAT).format('DD.MM.YY')}
                </span>
                {' '}
                <span className="customer-list-row-text-small">
                  {moment(customer.last_event.created, DATE_FORMAT).format('HH:mm')} {formatMessage(messages.CLOCK)}
                </span>
              </div>
              <div className="customer-list-row-text">
                {renderLastEvent(customer.last_event, formatMessage, filter)}
              </div>
            </div>
          ) : (
            <div className="customer-list-row-last-event">
              <span className="customer-list-row-last-event-empty">
                {formatMessage(messages.NO_EVENTS)}
              </span>
            </div>
          )}
        </Link>
      ))}
    </div>
  );
}

export default injectIntl(CustomerList);
