import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import { changeLanguage } from '../actions/intl';

const messages = defineMessages({
  SWITCH_LOCALE: {
    id: 'SelectLanguageButton.SWITCH_LOCALE',
    defaultMessage: 'Switch to english',
  },
});

function SelectLanguageButton(props) {
  const { locale, textVersion = false, intl: { formatMessage } } = props;
  const otherLocale = locale === 'de' ? 'en' : 'de';
  const updateLocale = () => props.changeLanguage(otherLocale);

  if (textVersion) {
    return (
      <a onClick={updateLocale}>
        {formatMessage(messages.SWITCH_LOCALE)}
      </a>
    );
  }

  return (
    <button
      onClick={updateLocale}
      className="lh-btn lh-btn-small lh-btn-primary"
    >
      {otherLocale}
    </button>
  );
}

export default connect(
  state => ({ locale: state.intl.locale }),
  { changeLanguage },
)(injectIntl(SelectLanguageButton));
