import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
// eslint-disable-next-line
import OT from 'opentok';
import moment from 'moment';
import { connect } from 'react-redux';
import _ from 'lodash';

import { DATE_FORMAT } from '../../../../config';
import api from '../../../../actions/api';
import {
    eventList,
    callAddedToOrder,
    addCallToOrder,
    addNoteToOrder,
    noteAddedToOrder,
    deleteEvent,
    eventDeleted,
    eventUpdated,
} from '../../../../actions/company';
import { eventEditStop } from '../../../../actions/eventEdit';
import Loader from '../../../../components/Loader';
import NoteWithoutCall from './NoteWithoutCall';
import Call from './Call';

const messages = defineMessages({
  ERROR_OCCURRED: {
    id: 'EventList.ERROR_OCCURRED',
    defaultMessage: 'Ein Fehler ist aufgetreten beim speichern: {error}',
  },
  STARTING_CONVERSATION: {
    id: 'EventList.STARTING_CONVERSATION',
    defaultMessage: 'Das Gespräch beginnt sobald sich der Kunde verbindet...',
  },
  NO_ENTRIES_AVAILABLE: {
    id: 'EventList.NO_ENTRIES_AVAILABLE',
    defaultMessage: 'Hier existieren noch keine Einträge. Fangen Sie ein neues '
      + 'Gespräch an oder erstellen Sie eine Notiz um Einträge zu erzeugen.',
  },
});

const getEventTime = e => moment(e.created, DATE_FORMAT).valueOf();
function groupEvents(order) {
  let results = [];
    // only ONE call is allowed to have no call_begin and call_end: the one with the highest id
    // all others have to be filtered out
  if (order.calls.length > 0) {
        // const maxCallId = _.maxBy(order.calls, o => o.id).id;
    results = results.concat(
      order.calls
      .map((c) => {
          // needed for the ui to differentiate between notes and calls
        c.is_call = true; // eslint-disable-line no-param-reassign
        c.can_drag = !!order.active; // eslint-disable-line no-param-reassign
        return c;
      })
    );
  }
  if (order.notes.length > 0) {
    results = results.concat(order.notes.map((n) => {
      n.can_drag = !!order.active; // eslint-disable-line no-param-reassign
      return n;
    }));
  }
  if (results.length > 0) {
    results.sort((a, b) => getEventTime(a) - getEventTime(b));
  }
  return results;
}

function addOrderReferenceToEvents(order, orders) {
  if (!order) return null;
  function mapEvent(event) {
    if (event.order_id) {
      const eventOrder = _.find(orders, o => o.id == event.order_id);
      if (eventOrder) {
        event.order_name = eventOrder.name; // eslint-disable-line no-param-reassign
      }
    }
    return event;
  }
  return {
    ...order,
    calls: order.calls.map(mapEvent),
    notes: order.notes.map(mapEvent),
  };
}


function eventNNoteCount(orders) {
  // inkorrekte Gesamtzahl aber mit vorher vergleichbar
  let count = 0;
  orders.forEach((order) => {
    count += order.notes.length;
    order.calls.forEach((call) => {
      count += call.events.length;
    });
  });
  return count;
}


function somethingWasDeleted(previousOrders, currentOrders) {
  return eventNNoteCount(previousOrders) > eventNNoteCount(currentOrders);
}

class EventList extends React.PureComponent {
  // componentWillMount() {
  //     // this.props.load();
  // }
  // componentWillReceiveProps(nextProps) {
  //     // if (this.props.customer.id !== nextProps.customer.id) {
  //     //     nextProps.load();
  //     // }
  // }
  componentDidUpdate(prevProps) {
    const { container } = this.refs;
    if (typeof container !== 'undefined') {
      if (somethingWasDeleted(prevProps.orders, this.props.orders)) {
        return;
      }
      setTimeout(() => {
        container.scrollTop = container.scrollHeight;
      }, 50);
    }
  }

  render() {
    const {
      middleHeight,
      error,
      orders,
      className,
      customer,
      showCustomerNotifiedEvent,
      call,
      selectedOrder,
      connection,
      remoteConnection,
      intl: { formatMessage },
    } = this.props;

    let classNameLocal = className !== undefined ? className : '';

    let order = null;
    // console.log(selectedOrder);
    if (selectedOrder === -2 && orders) {
      order = {
        id: -2,
        calls: _.flatten(orders.map(o => o.calls)),
        notes: _.flatten(orders.map(o => o.notes)),
        active: true,
      };
    } else {
      order = _.find(orders, o => o.id === selectedOrder);
    }
    order = addOrderReferenceToEvents(order, orders);
    if (error) {
      return (
        <pre className={classNameLocal}>{JSON.stringify(error)}</pre>
      );
    }
    if (!order) {
      return null;
    }
    let resultsToRender = order;
    resultsToRender = groupEvents(resultsToRender);
    if (showCustomerNotifiedEvent) {
      resultsToRender = resultsToRender.concat({
        customer_notified: true,
        id: Infinity,
        created: call.created,
      });
    }

    const connected = connection && connection.connected && remoteConnection && remoteConnection.connected;

    return resultsToRender.length ? (
      <div
        ref="container"
        className={`event-items ${classNameLocal}`}
        style={{ height: middleHeight }}
      >
        {resultsToRender
          .filter(item => (!item.call_event_type))
          .map((item, index) => {
            if (item.customer_notified) {
              if (OT.checkSystemRequirements() === 0) {
                return null;
              }
              return (
                <div key={item.id} className="event-row">
                  <div className="event-row-left-col" style={{ paddingTop: 11 }}>
                    <Loader flexCenter />
                  </div>
                  <div className="event-row-right-col event-row-right-col-waiting">
                    <div className="event-row-text event-row-text-waiting">
                      {formatMessage(messages.STARTING_CONVERSATION)}
                    </div>
                    <button
                      className="lh-btn lh-btn-warning lh-btn-small lh-btn-small-noblock lh-button-event-list-call-end"
                      onClick={() => this.props.stopCallBeforeStarted()}
                      style={{ height: 30 }}
                    >
                      <i className="icon-phone-down" />
                    </button>
                  </div>
                </div>
              );
            }
            if (item.is_call) {
              return (
                <Call
                  canDrag
                  handleDragging={(call, order) => this.props.addCallToOrder(call, order)}
                  key={index}
                  call={item}
                  customer={customer}
                  deleteEvent={this.props.deleteEvent}
                  updateEvent={this.props.updateEvent}
                  addCallToOrder={this.props.addCallToOrder}
                  orders={orders}
                  connected={connected}
                />
              );
            }
            return (
              <NoteWithoutCall
                handleDragging={(note, order) => this.props.addNoteToOrder(note, order)}
                key={index}
                event={item}
                customer={customer}
                deleteEvent={this.props.deleteEvent}
                updateEvent={this.props.updateEvent}
                canDrag
                addNoteToOrder={this.props.addNoteToOrder}
                orders={orders}
              />
            );
          })}
      </div>
    ) : (
      <span className="no-entries">
        {formatMessage(messages.NO_ENTRIES_AVAILABLE)}
      </span>
    );
  }
}

export default connect(
    (state) => {
      const {
          orderList: { results },
          orderSidebarOpen,
          video,
        } = state;
        // let order = null;
        // if (results && results.length > 0) {
        //     order = _.find(results, r => r.id == selectedOrder);
        // }
      return {
        eventList: state.eventList,
        orders: results,
        activePicturesEvent: state.video.activePicturesEvent,
        orderSidebarOpen,
        ...video,
      };
    },
    (dispatch, props) => ({
      load: () => dispatch(eventList.load({
        companyId: props.customer.company_id,
        customerId: props.customer.id,
      })),
      addCallToOrder: (call, order) => addCallToOrder(
            props.customer.company_id,
            props.customer.id,
            order.id,
            { callId: call.id },
        ).then((orders) => {
          dispatch(callAddedToOrder({ orders }));
        }),
      addNoteToOrder: (note, order) => addNoteToOrder(
            props.customer.company_id,
            props.customer.id,
            order.id,
            { noteId: note.id },
        ).then((orders) => {
          dispatch(noteAddedToOrder({ orders }));
        }),
      deleteEvent: event => deleteEvent(props.customer.company_id, props.customer.id, event.id)
        .then(() => {
          dispatch(eventDeleted(event));
        }),
      updateEvent: (evt, formData) => {
        api.put({ url: `/api/event/${evt.id}`, data: formData })
            .then(
                (newEvent) => {
                  dispatch(eventUpdated(Object.assign({}, newEvent, { order_id: evt.order_id })));
                  dispatch(eventEditStop(newEvent.id));
                },
                /* eslint-disable no-undef */
                /* eslint-disable no-alert */
                (e) => { alert(props.intl.formatMessage(messages.ERROR_OCCURRED, { error: e })); }
            );
      },
    })
)(injectIntl(EventList));

