import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { authReducer as auth } from './actions/auth';
import {
    companyListReducer as companyList,
    companySingleReducer as companySingle,
    companyUserListReducer as companyUserList,
    companyUserSingleReducer as companyUserSingle,
    customerListReducer as customerList,
    customerSingleReducer as customerSingle,
    eventListReducer as eventList,
    orderListReducer as orderList,
    selectedOrderReducer as selectedOrder,
    orderSidebarOpenReducer as orderSidebarOpen,
    callBeginModalActiveReducer as callBeginModalActive,
    callEndModalActiveReducer as callEndModalActive,
    lastCallReducer as lastCall,
    registrationConfirmationReducer as registrationConfirmation,
    registrationReducer as registration,
} from './actions/company';
import navbar from './actions/navbar';
import offline from './actions/offline';
import { passwordResetReducer as passwordReset } from './actions/resetPassword';
import { userListReducer as userList } from './actions/user';
import video from './actions/video';
import audio from './actions/audio';
import mediaDevices from './actions/mediaDevices';
import eventEdit from './actions/eventEdit';
import orderEdit from './actions/orderEdit';
import intl from './actions/intl';

function lastAction(state = null, action) { return action; }

const rootReducer = combineReducers({
  auth,
  companyList,
  companySingle,
  companyUserList,
  companyUserSingle,
  customerList,
  customerSingle,
  eventList,
  orderList,
  orderSidebarOpen,
  lastCall,
  selectedOrder,
  navbar,
  offline,
  registrationConfirmation,
  registration,
  callBeginModalActive,
  callEndModalActive,
  passwordReset,
  userList,
  mediaDevices,
  routing,
  lastAction,
  eventEdit,
  orderEdit,

  audio,
  video,
  intl,
});

export default rootReducer;
