import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { FORM_ERROR } from 'final-form';
import { Link } from 'react-router';


import { createCompanyUser2, companyUserCreated } from 'actions/company';
import NotFound from 'components/NotFound';
import NavBarConfig from 'components/NavBarConfig';
import CompanyUserCreate from 'components/companyUser/CompanyUserCreate';
import {unsubscribe} from "actions/ot_signaling";

const messages = defineMessages({
  BACK: {
    id: 'CompanyUserCreatePage.BACK',
    defaultMessage: 'Zurück',
  },
  CREATE_NEW_USER: {
    id: 'CompanyUserCreatePage.CREATE_NEW_USER',
    defaultMessage: 'Neuen Benutzer anlegen',
  },
});

function CompanyUserCreatePage({
  user,
  company,
  companyMembership,
  onSubmit,
  routes,
  intl: { formatMessage },
}) {
  if (!company || !companyMembership) { return null; }
  if (!user.is_admin && !user.is_staff && !companyMembership.is_owner && !companyMembership.is_admin) {
    return <NotFound />;
  }
  return (
    <div>
      <NavBarConfig
          companyUserListunsubscribe
          createPage
          currentRouteName={routes[routes.length - 1].name}
      />
      <div className="m40 w-xs">
        <Link to={`/company/${company.id}/user/`}>{formatMessage(messages.BACK)}</Link>
        <h3>{formatMessage(messages.CREATE_NEW_USER)}</h3>
        <CompanyUserCreate
          onSubmit={onSubmit}
          is_admin={user.is_admin || user.is_staff}
        />
      </div>
    </div>
  );
}

export default connect(
  state => state.companySingle,
  (dispatch, props) => ({
    onSubmit: async formData => {
      const data = await createCompanyUser2(props.params.companyId, {
        ...formData,
        email: formData.email || undefined,
        phone: formData.phone || undefined,
      });
      if (data.response) {
        const companyUser = data.response;
        dispatch(companyUserCreated(companyUser));
        dispatch(push(`/company/${companyUser.company_id}/user/`));
      } else {
        return { [FORM_ERROR]: data.error[Object.keys(data.error)[0]] };
      }
   }
  }),
)(injectIntl(CompanyUserCreatePage));
