import React from 'react';
import { connect } from 'react-redux';
import { customerSingle, orderList } from '../../actions/company';
import NavBarConfig from '../../components/NavBarConfig';
import NotFound from '../../components/NotFound';


class CustomerContainer extends React.Component {
  componentWillMount() {
    this.props.load();
  }
  componentWillReceiveProps(nextProps) {
    const { companyId, customerId } = this.props.params;
    if (companyId !== nextProps.params.companyId || customerId !== nextProps.params.customerId) {
      nextProps.load();
    }
  }
  render() {
    const {
      user,
      company,
      customer,
      companyMembership,
      room,
      error,
      params: {
        companyId,
        customerId
      },
    } = this.props;
    if (!company || !companyMembership) {
      return <div />;
    }
    if (error) {
      return <NotFound />;
    }
    if (!customer) {
      return <div />;
    }
    if (companyId !== `${customer.company_id}` || customerId !== `${customer.id}`) {
      return <div />;
    }
    return (
      <NavBarConfig customerList={{ company_id: customer.company_id }} customer={customer}>
        {React.cloneElement(this.props.children, { user, company, companyMembership, customer, room })}
      </NavBarConfig>
    );
  }
}

export default connect(
  state => {
    return {
      ...state.customerSingle,
      room: state.video.room
    }
  },
  (dispatch, props) => ({
    load: () => {
      dispatch(customerSingle.load(props.params));
      dispatch(orderList.load(props.params));
    },
  })
)(CustomerContainer);
