import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { Link } from 'react-router';

import Logo from '../../components/Logo';

const messages = defineMessages({
  IMPRINT: {
    id: 'ImprintPage.IMPRINT',
    defaultMessage: 'Impressum',
  },
  BACK_TO_STARTPAGE: {
    id: 'ImprintPage.BACK_TO_STARTPAGE',
    defaultMessage: 'Zurück zur Startseite',
  },
  INFORMATION: {
    id: 'ImprintPage.INFORMATION',
    defaultMessage: 'Angaben gemäß § 5 TMG',
  },
  MANAGER: {
    id: 'ImprintPage.MANAGER',
    defaultMessage: 'Geschäftsführer',
  },
  CONTACT: {
    id: 'ImprintPage.CONTACT',
    defaultMessage: 'Kontakt',
  },
  PHONE: {
    id: 'ImprintPage.PHONE',
    defaultMessage: 'Telefon',
  },
  E_MAIL: {
    id: 'ImprintPage.E_MAIL',
    defaultMessage: 'E-Mail',
  },
  REGISTRATION: {
    id: 'ImprintPage.REGISTRATION',
    defaultMessage: 'Registrierung',
  },
  LISTING: {
    id: 'ImprintPage.LISTING',
    defaultMessage: 'Eintragung im Handelsregister',
  },
  CHANCERY: {
    id: 'ImprintPage.CHANCERY',
    defaultMessage: 'Registergericht',
  },
  REGISTRATION_NUMBER: {
    id: 'ImprintPage.REGISTRATION_NUMBER',
    defaultMessage: 'Registernummer',
  },
  TURNOVER_TAX: {
    id: 'ImprintPage.TURNOVER_TAX',
    defaultMessage: 'Umsatzsteuer-ID',
  },
  VALUE_ADDED_TAX_ACT: {
    id: 'ImprintPage.VALUE_ADDED_TAX_ACT',
    defaultMessage: 'Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz',
  },
});

class ImprintPage extends React.Component {
    render() {
      const { intl: { formatMessage } } = this.props;

        return (
          <div className="flex">
            <div className="content-block row login-page">
              <Logo />
              <div className="terms">
                <h1>
                  {formatMessage(messages.IMPRINT)}
                </h1>
                <p>
                  <strong>{formatMessage(messages.INFORMATION)}:</strong>
                  <br />
                  Solutiness GmbH
                  <br />
                  Alte Breisacher Straße 14a
                  <br />
                  79112 Freiburg
                  <br />
                  <br />
                  <strong>{formatMessage(messages.MANAGER)}:</strong>
                  <br />
                  Klaus Plüher
                  <br />
                  <br />
                  <strong>{formatMessage(messages.CONTACT)}:</strong>
                  <br />
                  {formatMessage(messages.PHONE)}: +49 761-76 99 97-0
                  <br />
                  {formatMessage(messages.E_MAIL)}: <a href="mailto:info@Solutiness.de">info@Solutiness.de</a>
                  <br />
                  <br />
                  <strong>{formatMessage(messages.REGISTRATION)}:</strong>
                  <br />
                  {formatMessage(messages.LISTING)}.
                  <br />
                  {formatMessage(messages.CHANCERY)}: Amtsgericht Freiburg i. Br.
                  <br />
                  {formatMessage(messages.REGISTRATION_NUMBER)}: 714335
                  <br />
                  <br />
                  <strong>{formatMessage(messages.TURNOVER_TAX)}:</strong>
                  <br />
                  {formatMessage(messages.VALUE_ADDED_TAX_ACT)}: DE305720076
                </p>
              </div>
              <div className="text-center" style={{ marginTop: '15px' }}>
                <Link to="/">
                  {formatMessage(messages.BACK_TO_STARTPAGE)}
                </Link>
              </div>
            </div>
          </div>
        );
    }
}

export default injectIntl(ImprintPage);
