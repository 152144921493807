import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { getUsers, setUsers } from '../../actions/user';
import AllUsers from './components/AllUsers';

const messages = defineMessages({
  BACK: {
    id: 'AllUserPage.BACK',
    defaultMessage: 'Zurück',
  },
  ALL_USERS: {
    id: 'AllUserPage.ALL_USERS',
    defaultMessage: 'Alle Benutzer',
  },
});

@connect(
    state => state.userList,
    dispatch => ({
      getUsers: () => getUsers().then(resp => dispatch(setUsers(resp))),
    })
)

class AllUserPage extends React.PureComponent {
  componentWillMount() {
    this.props.getUsers();
  }
  render() {
    const { intl: { formatMessage } } = this.props;
    return (
      <div>
        <div className="m40">
          <Link to="/">{formatMessage(messages.BACK)}</Link>
          <h3>{formatMessage(messages.ALL_USERS)}</h3>
          <AllUsers items={this.props.results} />
        </div>
      </div>
    );
  }
}

export default injectIntl(AllUserPage);
