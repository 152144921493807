import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { ButtonToolbar, Button } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';

import FormGroup2 from '../../../../components/form/FormGroup';
import FormError from '../../../../components/form/FormError';
import TextField from '../../../../components/form/TextField';

const messages = defineMessages({
  PLACEHOLDER_NOTE_CONTENT: {
    id: 'EventNoteForm.PLACEHOLDER_NOTE_CONTENT',
    defaultMessage: 'Bitte Notizinhalt hier angeben',
  },
  CANCEL: {
    id: 'EventNoteForm.CANCEL',
    defaultMessage: 'Abbrechen',
  },
  SAVE: {
    id: 'EventNoteForm.SAVE',
    defaultMessage: 'Speichern',
  },
});

function EventNoteForm(props) {
  const {
    onSubmit,
    initialValues,
    cancelEdit,
    intl: { formatMessage },
  } = props;
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ ...initialValues }}
    >
      {({ submitError, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="text"
          >
            {({ input, meta }) => {
              return (
                <FormGroup2 input={input} meta={meta}>
                  <TextField
                    autoComplete="off"
                    placeholder={formatMessage(messages.PLACEHOLDER_NOTE_CONTENT)}
                    rows={10}
                    {...input}
                  />
                </FormGroup2>
            )
            }}
          </Field>
          <FormError error={submitError} />
          <ButtonToolbar>
            <Button onClick={() => cancelEdit()}>{formatMessage(messages.CANCEL)}</Button>
            <Button bsStyle="primary" type="submit">{formatMessage(messages.SAVE)}</Button>
          </ButtonToolbar>
        </form>
      )}
    </Form>
  );
}

export default injectIntl(EventNoteForm);
