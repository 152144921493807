import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  BROWSER_NOT_SUPPORTET: {
    id: 'SamsungBrowserPage.BROWSER_NOT_SUPPORTET',
    defaultMessage: 'Dieser Browser wird nicht unterstützt.',
  },
  CHROME_OR_FIREFOX: {
    id: 'SamsungBrowserPage.CHROME_OR_FIREFOX',
    defaultMessage: 'Sie können tele-LOOK mit dem Standard Chrome-Browser verwenden.',
  },
  FOLLOW_LINK: {
    id: 'SamsungBrowserPage.FOLLOW_LINK',
    defaultMessage: 'Bitte klicken Sie den folgenden Button um tele-LOOK in Chrome zu öffnen.',
  },
  CHROME_BROWSER_OPEN_BUTTON:
  {
    id: 'SamsungBrowserPage.CHROME_BROWSER_OPEN_BUTTON',
    defaultMessage: 'In Chrome öffnen',
  }
});

class SamsungBrowserPage extends React.Component {

  render() {
    const { intl: { formatMessage }, params: { roomName, companyId }, location } = this.props;

    let pathName = `/v/${roomName}`;
    if (companyId) {
      pathName = `/custom/${this.props.params.companyId}/v/${roomName}`;
    }

    const url = roomName ? `googlechrome://navigate?url=https://www.tele-look.com${pathName}/` : `googlechrome://navigate?url=https://www.tele-look.com${location.query.url}`;

    return (
      <div className="flex">
        <div style={{ background: '#fff', maxWidth: 480 }} className="content-block home-content">
          <h4 style={{ margin: '0 0 15px 0' }}>
            {formatMessage(messages.BROWSER_NOT_SUPPORTET)}
          </h4>
          <p style={{ margin: '0 0 15px 0' }}>
              {formatMessage(messages.CHROME_OR_FIREFOX)}
              <br />
              {formatMessage(messages.FOLLOW_LINK)}
          </p>
          <div className="text-center" style={{ margin: '40px 0' }}>
              <a className="lh-btn lh-btn-primary" href={url} >{formatMessage(messages.CHROME_BROWSER_OPEN_BUTTON)}</a>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(SamsungBrowserPage);
