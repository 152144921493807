import { combineReducers } from 'redux';
import { createAction, handleActions } from 'redux-actions';

import {
        CONNECTION_INIT,
        CONNECTION_DESTROY,
        REMOTE_AUDIO_ACTIVATED,
        REMOTE_AUDIO_DEACTIVATED,
        REMOTE_AUDIO_MUTED,
        REMOTE_AUDIO_UNMUTED,
        REMOTE_AUDIO_VOLUME,
        REMOTE_AUDIO_SPEAKING,
        REMOTE_AUDIO_UNSPEAKING,
        REMOTE_AUDIO_REQUESTED,
        REMOTE_AUDIO_ACCEPTED,
        REMOTE_AUDIO_DECLINED,
        AUDIO_ACTIVATED,
        AUDIO_DEACTIVATED,
        AUDIO_MUTED,
        AUDIO_UNMUTED,
        AUDIO_VOLUME,
        AUDIO_SPEAKING,
        AUDIO_UNSPEAKING,
        AUDIO_REQUESTED,
        AUDIO_ACCEPTED,
        AUDIO_DECLINED,
} from './constants';


/*
const AUDIO_ACTIVATED = 'AUDIO_ACTIVATED';
const AUDIO_DEACTIVATED = 'AUDIO_DEACTIVATED';
const AUDIO_MUTED = 'AUDIO_MUTED';
const AUDIO_UNMUTED = 'AUDIO_UNMUTED';
const AUDIO_VOLUME = 'AUDIO_VOLUME';
const AUDIO_SPEAKING = 'AUDIO_SPEAKING';
const AUDIO_UNSPEAKING = 'AUDIO_UNSPEAKING';
const AUDIO_REQUESTED = 'AUDIO_REQUESTED';
const AUDIO_ACCEPTED = 'AUDIO_ACCEPTED';
const AUDIO_DECLINED = 'AUDIO_DECLINED';

const REMOTE_AUDIO_ACTIVATED = 'REMOTE_AUDIO_ACTIVATED';
const REMOTE_AUDIO_DEACTIVATED = 'REMOTE_AUDIO_DEACTIVATED';
const REMOTE_AUDIO_MUTED = 'REMOTE_AUDIO_MUTED';
const REMOTE_AUDIO_UNMUTED = 'REMOTE_AUDIO_UNMUTED';
const REMOTE_AUDIO_VOLUME = 'REMOTE_AUDIO_VOLUME';
const REMOTE_AUDIO_SPEAKING = 'REMOTE_AUDIO_SPEAKING';
const REMOTE_AUDIO_UNSPEAKING = 'REMOTE_AUDIO_UNSPEAKING';
const REMOTE_AUDIO_REQUESTED = 'REMOTE_AUDIO_REQUESTED';
const REMOTE_AUDIO_ACCEPTED = 'REMOTE_AUDIO_ACCEPTED';
const REMOTE_AUDIO_DECLINED = 'REMOTE_AUDIO_DECLINED';
*/

export const audioActivated = createAction(AUDIO_ACTIVATED);
export const audioDeactivated = createAction(AUDIO_DEACTIVATED);
export const audioMuted = createAction(AUDIO_MUTED);
export const audioUnmuted = createAction(AUDIO_UNMUTED);
export const audioVolume = createAction(AUDIO_VOLUME);
export const audioSpeaking = createAction(AUDIO_SPEAKING);
export const audioUnspeaking = createAction(AUDIO_UNSPEAKING);
export const audioRequested = createAction(AUDIO_REQUESTED);
export const audioAccepted = createAction(AUDIO_ACCEPTED);
export const audioDeclined = createAction(AUDIO_DECLINED);

export const remoteAudioActivated = createAction(REMOTE_AUDIO_ACTIVATED);
export const remoteAudioDeactivated = createAction(REMOTE_AUDIO_DEACTIVATED);
export const remoteAudioMuted = createAction(REMOTE_AUDIO_MUTED);
export const remoteAudioUnmuted = createAction(REMOTE_AUDIO_UNMUTED);
export const remoteAudioVolume = createAction(REMOTE_AUDIO_VOLUME);
export const remoteAudioSpeaking = createAction(REMOTE_AUDIO_SPEAKING);
export const remoteAudioUnspeaking = createAction(REMOTE_AUDIO_UNSPEAKING);
export const remoteAudioRequested = createAction(REMOTE_AUDIO_REQUESTED);
export const remoteAudioAccepted = createAction(REMOTE_AUDIO_ACCEPTED);
export const remoteAudioDeclined = createAction(REMOTE_AUDIO_DECLINED);

const volumeReducer = handleActions({
  [CONNECTION_INIT]: () => 0,
  [CONNECTION_DESTROY]: () => 0,
  [AUDIO_VOLUME]: (state, action) => action.payload,
}, 0);

const remoteVolumeReducer = handleActions({
  [CONNECTION_INIT]: () => 0,
  [CONNECTION_DESTROY]: () => 0,
  [REMOTE_AUDIO_VOLUME]: (state, action) => action.payload,
}, 0);

const activeReducer = handleActions({
  [CONNECTION_INIT]: () => false,
  [CONNECTION_DESTROY]: () => false,
  [AUDIO_ACTIVATED]: () => true,
  [AUDIO_DEACTIVATED]: () => false,
  [AUDIO_ACCEPTED]: () => true,
  [AUDIO_DECLINED]: () => false,
}, false);

const remoteActiveReducer = handleActions({
  [CONNECTION_INIT]: () => false,
  [CONNECTION_DESTROY]: () => false,
  [REMOTE_AUDIO_ACTIVATED]: () => true,
  [REMOTE_AUDIO_DEACTIVATED]: () => false,
  [REMOTE_AUDIO_ACCEPTED]: () => true,
  [REMOTE_AUDIO_DECLINED]: () => false,
}, false);

const mutedReducer = handleActions({
  [CONNECTION_INIT]: () => false,
  [CONNECTION_DESTROY]: () => false,
  [AUDIO_MUTED]: () => true,
  [AUDIO_UNMUTED]: () => false,
}, false);

const remoteMutedReducer = handleActions({
  [CONNECTION_INIT]: () => false,
  [CONNECTION_DESTROY]: () => false,
  [REMOTE_AUDIO_MUTED]: () => true,
  [REMOTE_AUDIO_UNMUTED]: () => false,
}, false);

const speakingReducer = handleActions({
  [CONNECTION_INIT]: () => false,
  [CONNECTION_DESTROY]: () => false,
  [AUDIO_SPEAKING]: () => true,
  [AUDIO_UNSPEAKING]: () => false,
}, false);

const remoteSpeakingReducer = handleActions({
  [CONNECTION_INIT]: () => false,
  [CONNECTION_DESTROY]: () => false,
  [REMOTE_AUDIO_SPEAKING]: () => true,
  [REMOTE_AUDIO_UNSPEAKING]: () => false,
}, false);

const audioRequestedReducer = handleActions({
  [CONNECTION_INIT]: () => false,
  [CONNECTION_DESTROY]: () => false,
  [AUDIO_REQUESTED]: () => true,
  [REMOTE_AUDIO_ACCEPTED]: () => false,
  [REMOTE_AUDIO_DECLINED]: () => false,
}, false);

const remoteAudioRequestedReducer = handleActions({
  [CONNECTION_INIT]: () => false,
  [CONNECTION_DESTROY]: () => false,
  [REMOTE_AUDIO_REQUESTED]: () => true,
  [AUDIO_ACCEPTED]: () => false,
  [AUDIO_DECLINED]: () => false,
}, false);

export default combineReducers({
  self: combineReducers({
    active: activeReducer,
    muted: mutedReducer,
    volume: volumeReducer,
    speaking: speakingReducer,
    requested: audioRequestedReducer,
  }),
  remote: combineReducers({
    active: remoteActiveReducer,
    muted: remoteMutedReducer,
    volume: remoteVolumeReducer,
    speaking: remoteSpeakingReducer,
    requested: remoteAudioRequestedReducer,
  }),
});
