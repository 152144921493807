import React from 'react';
import classNames from 'classnames';

export default function FormSubmit(props) {
    const { icon, label, submitting, horizontal, className, style, btnClass, btnProps } = props;

    const groupClassName = classNames('form-group', className);
    const blockClassName = classNames({ 'col-sm-8': horizontal, 'col-sm-offset-4': horizontal });
    const btnClassName = classNames('btn', btnClass);

    return (
        <div className={groupClassName} style={style}>
            <div className={blockClassName}>
                <button
                    className={btnClassName}
                    type="submit"
                    disabled={submitting}
                    {...btnProps}
                >
                    {icon ? <span className={icon} /> : null}
                    {icon ? ` ${label}` : label}
                </button>
            </div>
        </div>
    );
}

FormSubmit.defaultProps = {
    horizontal: false,
};
