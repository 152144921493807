import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { check, checked, loggedOut } from '../../actions/auth';
import { removeAuthToken } from '../../actions/api';

export class LoginRequiredComponent extends React.Component {
  componentWillMount() {
    if (!this.props.user) {
      this.props.check();
    }
  }

  render() {
    const { user, children } = this.props;
    if (!children || !user) {
      return <div />; // Always return an element in a Component that is used as a route
    }
    return React.cloneElement(children, { user });
  }
}

export const LoginRequiredContainer = connect(
  state => ({ user: state.auth.user }),
  (dispatch, { location, router }) => ({
    check: () => check().then(
      user => dispatch(checked(user)),
      () => {
        // remove old token data if user login check not successful
        removeAuthToken();
        dispatch(loggedOut());
        router.push({
          pathname: '/login/',
          query: { next: location.pathname },
        });
      },
    ),
  }),
)(LoginRequiredComponent);

export default withRouter(LoginRequiredContainer);
