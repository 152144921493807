import moment from 'moment';
import _ from 'lodash';

export function getDuration(end, events) {
  if (end.call_event_type !== 'end') {
    throw new Error('Can only get duratino for event with call_event_type == "end"');
        // return null;
  }
  const begin = _.find(events, evt => end.call_id === evt.call_id && evt.call_event_type === 'begin');
  if (!begin) {
    return null;
  }
  const seconds = Math.floor(
    (new Date(end.created).getTime() - new Date(begin.created).getTime()) / 1000);
  return moment.duration(seconds, 'seconds').humanize();
}

export function trunc(text, n, useWordBoundary) {
  const tooLong = text.length > n;
  let st = tooLong ? text.substr(0, n - 1) : text;
  const indexOfLastSpace = st.lastIndexOf(' ');
  if (useWordBoundary && tooLong && indexOfLastSpace > -1) {
    st = st.substr(0, indexOfLastSpace);
  }
    // unicode for ellipsis
  return tooLong ? `${st} \u2026` : st;
}

let movingAvg = null;
export function calcAudioLevel(audioLevel) {
  if (movingAvg === null || movingAvg <= audioLevel) {
    movingAvg = audioLevel;
  } else {
    movingAvg = (0.7 * movingAvg) + (0.3 * audioLevel);
  }

  // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
  let logLevel = 1 + ((Math.log(movingAvg) / Math.LN10) / 1.5);
  logLevel = Math.min(Math.max(logLevel, 0), 1);
  return logLevel;
}
