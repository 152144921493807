import React from 'react';
import { Link } from 'react-router';
import classnames from 'classnames';
import { DropTarget } from 'react-dnd';

import { CALL } from '../../../helpers/dragTypes';

const orderTarget = {
  canDrop() {  // props, monitor) {
    return true;
  },
  // hover(props, monitor, component) {
  //   //  console.log('hover');
  // },
  drop(props, monitor) {  // , component) {
    if (monitor.didDrop()) {
      return;
    }
    const item = monitor.getItem();
    return props.order;
  },
};

@DropTarget(CALL, orderTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  isOverCurrent: monitor.isOver({ shallow: true }),
  canDrop: monitor.canDrop(),
  itemType: monitor.getItemType(),
}))
export default class OrderSidebarElement extends React.Component {
  render() {
    const { order, connectDropTarget, isOver, canDrop, className, to, onClick } = this.props;
    return connectDropTarget(
      <div
        className={classnames('order', {
          over: isOver && canDrop,
          finished: !order.active,
        }, className)}
      >
        <Link
          to={to}
          onClick={onClick}
        >
          <div className="text text-overflow" title={order.name}>
            {order.name} <span className={classnames('fa ', { 'fa-check': !order.active, active: !order.active })} />
          </div>
        </Link>
      </div>
        );
  }
}
