import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import moment from 'moment';

import TextOverflow from '../../../components/TextOverflow';

const messages = defineMessages({
  NO_USERS_FOUND: {
    id: 'AllUsers.NO_USERS_FOUND',
    defaultMessage: 'Es wurden keine Benutzer gefunden.',
  },
  NAME: {
    id: 'AllUsers.NAME',
    defaultMessage: 'Name',
  },
  EMAIL: {
    id: 'AllUsers.EMAIL',
    defaultMessage: 'E-Mail-Adresse',
  },
  PHONE: {
    id: 'AllUsers.PHONE',
    defaultMessage: 'Telefonnummer',
  },
  CREATED: {
    id: 'AllUsers.CREATED',
    defaultMessage: 'Erstellt',
  },
  LAST_LOGIN: {
    id: 'AllUsers.LAST_LOGIN',
    defaultMessage: 'Letze Anmeldung',
  },
  COUNT_COMPANIES: {
    id: 'AllUsers.COUNT_COMPANIES',
    defaultMessage: 'Anzahl der Firmen',
  },
  OWNER: {
    id: 'AllUsers.OWNER',
    defaultMessage: 'Besitzer',
  },
  ADMIN: {
    id: 'AllUsers.ADMIN',
    defaultMessage: 'Administrator',
  },
  DISABLED: {
    id: 'AllUsers.DISABLED',
    defaultMessage: 'Deaktiviert',
  },
  DELETED: {
    id: 'AllUsers.DELETED',
    defaultMessage: 'Gelöscht',
  },
});

class AllUsers extends React.PureComponent {
  render() {
    const { items, intl: { formatMessage } } = this.props;
    if (!(items && items.length)) {
      return <p>{formatMessage(messages.NO_USERS_FOUND)}</p>;
    }
    return (
      <div className="table-responsive">
        <table className="table table-condensed table-striped">
          <thead>
            <tr>
              <th>{formatMessage(messages.NAME)}</th>
              <th>{formatMessage(messages.EMAIL)}</th>
              <th>{formatMessage(messages.PHONE)}</th>
              <th>{formatMessage(messages.CREATED)}</th>
              <th>{formatMessage(messages.LAST_LOGIN)}</th>
              <th>{formatMessage(messages.COUNT_COMPANIES)}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {items.map(user => (
              <tr key={user.id}>
                <td><TextOverflow title={user.name}>{user.name}</TextOverflow></td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>{user.created ? moment(user.created, 'YYYY-MM-Do h:mm:ss').fromNow() : ''}</td>
                <td>{user.last_login ? moment(user.last_login, 'YYYY-MM-Do h:mm:ss').fromNow() : ''}</td>
                <td>{user.company_count}</td>
                <td>
                  {user.is_owner ? (
                    <span className="label label-primary" style={{ color: '#fff' }}>
                      {formatMessage(messages.OWNER)}
                    </span>
                  ) : null}
                  {user.is_admin ? (
                    <span className="label label-info" style={{ color: '#fff' }}>
                      {formatMessage(messages.ADMIN)}
                    </span>
                  ) : null}
                  {user.is_inactive ? (
                    <span className="label label-danger" style={{ color: '#fff' }}>
                      {formatMessage(messages.DISABLED)}
                    </span>
                  ) : null}
                  {user.is_deleted && (
                    <span className="label label-danger" style={{ color: '#fff' }}>
                      {formatMessage(messages.DELETED)}
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
        );
  }
}

export default injectIntl(AllUsers);
