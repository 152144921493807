import { createAction, handleActions } from 'redux-actions';
import { take, put, call, fork, select } from 'redux-saga/effects';

// eslint-disable-next-line
import OT from 'opentok';

/* global window */
/* global navigator */

export const MEDIA_DEVICES_INIT = 'MEDIA_DEVICES_INIT';
export const MEDIA_DEVICES_DESTROY = 'MEDIA_DEVICES_DESTROY';
export const MEDIA_DEVICES_LOAD = 'MEDIA_DEVICES_LOAD';
export const MEDIA_DEVICES_START_STREAM = 'MEDIA_DEVICES_START_STREAM';
export const MEDIA_DEVICES_ERROR_STREAM = 'MEDIA_DEVICES_ERROR_STREAM';
export const MEDIA_DEVICES_AUDIO_SELECT = 'MEDIA_DEVICES_AUDIO_SELECT';
export const MEDIA_DEVICES_VIDEO_SELECT = 'MEDIA_DEVICES_VIDEO_SELECT';


export const SPEAKING = 'SPEAKING';
export const STOPPED_SPEAKING = 'STOPPED_SPEAKING';


export const initMediaDevices = createAction(MEDIA_DEVICES_INIT);
export const destroyMediaDevices = createAction(MEDIA_DEVICES_DESTROY);
export const loadMediaDevices = createAction(MEDIA_DEVICES_LOAD);

export const startStream = createAction(MEDIA_DEVICES_START_STREAM);
export const errorStream = createAction(MEDIA_DEVICES_ERROR_STREAM);

export const selectAudioDevice = createAction(MEDIA_DEVICES_AUDIO_SELECT);
export const selectVideoDevice = createAction(MEDIA_DEVICES_VIDEO_SELECT);

export const speaking = createAction(SPEAKING);
export const stoppedSpeaking = createAction(STOPPED_SPEAKING);


const emptyState = {
  streamURL: null,
  audioDevices: [],
  videoDevices: [],
  audioDeviceId: null,
  videoDeviceId: null,

  audio: null,
  video: null,
};

const mediaDevices = handleActions({
  [MEDIA_DEVICES_LOAD]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [MEDIA_DEVICES_AUDIO_SELECT]: (state, action) => ({
    ...state,
    audioDeviceId: action.payload,
  }),
  [MEDIA_DEVICES_VIDEO_SELECT]: (state, action) => ({
    ...state,
    videoDeviceId: action.payload,
  }),
  [MEDIA_DEVICES_START_STREAM]: (state, action) => ({
    ...state,
    streamURL: action.payload,
    video: {
      videoActive: true,
      paused: false,
    },
    audio: state.audioDeviceId ? {
      micActive: true,
      muted: false,
      speaking: false,
    } : null,
    error: null,
  }),
  [MEDIA_DEVICES_ERROR_STREAM]: (state, action) => ({
    ...state,
    streamURL: null,
    error: action.payload,
  }),

  [SPEAKING]: state => ({
    ...state,
    audio: state.audio ? {
      ...state.audio,
      speaking: true,
    } : null,
  }),
  [STOPPED_SPEAKING]: state => ({
    ...state,
    audio: state.audio ? {
      ...state.audio,
      speaking: false,
    } : null,
  }),
}, emptyState);

export default mediaDevices;


let localStream = null;
let speechEvents = null;

export function stop() {
  if (localStream) {
    if (localStream.getTracks()) {
      localStream.getTracks().forEach(track => track.stop());
    } else {
      if (localStream.getAudioTracks()) {
        localStream.getAudioTracks().forEach(track => track.stop());
      }
      if (localStream.getVideoTracks()) {
        localStream.getVideoTracks().forEach(track => track.stop());
      }
    }
    localStream = null;
  }
  if (speechEvents) {
    speechEvents.stop();
    speechEvents = null;
  }
}

function GgetUserMedia(constraints) {
  return OT.getUserMedia(constraints)
    .then(stream => { return { stream, error: null }; }, error => { return { stream: null, error }; });
}

function createReadableStreamOfSpeechEvents(eventSource, eventName, actionCreator) {
  let deferred;

  eventSource.on(eventName, (...args) => {
    if (deferred) {
      deferred.resolve(actionCreator(...args));
      deferred = null;
    }
  });

  return {
    read() {
      if (!deferred) {
        deferred = {};
        deferred.promise = new Promise((resolve) => {
          deferred.resolve = resolve;
        });
      }
      return deferred.promise;
    },
  };
}

function* watchReadableStream(readableStream) {
  let action = yield call(readableStream.read);
  while (action) {
    yield put(action);
    action = yield call(readableStream.read);
  }
}

function* letStartStream(constraints = { audioSource: false, videoSource: true, facingMode: 'environment', resolution: '1280x720' }) {
  stop();

  //console.log(constraints);

  const { stream, error } = yield call(GgetUserMedia, constraints);

  if (stream) {
    localStream = stream;

    yield put(startStream(localStream));
  } else {
    yield put(errorStream(error));
  }
}


function push(arr, item) {
  if (!arr.find(i => i.id === item.id)) {
    arr.push(item);
  }
}

function selectBestVideoDevice(devices) {
  let dev = devices.find(vd => {
    return (vd.label.indexOf('back') !== -1 // english
      || vd.label.indexOf('Back') !== -1 // english
      || vd.label.indexOf('Rück') !== -1 // deutsch
      || vd.label.indexOf('arrière') !== -1 // french
      || vd.label.indexOf('achterzijde') !== -1 // netherland
      || vd.label.indexOf('posteriore') !== -1 // italian
      || vd.label.indexOf('trasera') !== -1 // spanish
      || vd.label.indexOf('задняя') !== -1 // russian
      || vd.label.indexOf('Trás') !== -1 // portugal brasil
      || vd.label.indexOf('traseira') !== -1 // portugal
      || vd.label.indexOf('后置镜头') !== -1 // chinese simplefied
      || vd.label.indexOf('後置相機') !== -1 // chinese traditional
      || vd.label.indexOf('後置鏡頭') !== -1 // chinese traditional hongkong
      || vd.label.indexOf('背面側カメラ') !== -1 // japan
      || vd.label.indexOf('후면 카메라') !== -1 // korean
      || vd.label.indexOf('Arka Kamera') !== -1 // korean
      || vd.label.indexOf('الكاميرا الخلفية') !== -1 // arabisch
      || vd.label.indexOf('กล้องด้านหลัง') !== -1 // thailändisch
      || vd.label.indexOf('Kamera på baksidan') !== -1 // schwedisch
      || vd.label.indexOf('Kamera, bagside') !== -1 // dänisch
      || vd.label.indexOf('Kamera bak') !== -1 // norwegisch
      || vd.label.indexOf('Taktkamera') !== -1 // finisch
      || vd.label.indexOf('Camera mặt sau') !== -1 // vietnameschisch
      || vd.label.indexOf('Tylny aparat') !== -1 // polnisch
      || vd.label.indexOf('Kamera Belakang') !== -1 // indonesisch
      || vd.label.indexOf('מצלמה אחורית') !== -1 // hebräisch
      || vd.label.indexOf('Πίσω κάμερα') !== -1 // griechisch
      || vd.label.indexOf('Camera din spate') !== -1 // rumänisch
      || vd.label.indexOf('Hátsó kamera') !== -1 // ungarisch
      || vd.label.indexOf('Zadní fotoaparát') !== -1 // tschechisch
      || vd.label.indexOf('Càmera del darrere') !== -1 // katalanisch
      || vd.label.indexOf('Zadná kamera') !== -1 // slowakisch
      || vd.label.indexOf('Задня камера') !== -1 // ukrainisch
      || vd.label.indexOf('Stražnja kamera') !== -1 // kroatisch
      || vd.label.indexOf('Kamera Belakang') !== -1 // mailisisch
      || vd.label.indexOf('बैक कैमरा') !== -1 // hindi
    );
  });
  if (!dev) {
    dev = devices.find(vd => vd.label.indexOf('2') !== -1);
  }
  if (!dev) {
    const len = devices.length;
    dev = len > 1 ? devices[len - 1] : devices[0];
  }
  return dev.id;
}

function EenumerateDevices() {
  return new Promise((resolve) => {
    OT.getDevices((error, devices) => {
      resolve({ error, devices })
    });
  });
}

function GgetSupportConstraints() {
  return navigator.mediaDevices.getSupportedConstraints();
}

function* onInitMediaDevices(enableAudio = true) {
  const constraints = {
    audioSource: enableAudio,
    videoSource: true,
    resolution: '1280x720'
  }

  const audioDevices = [];
  const videoDevices = [];
  let audioDeviceId = 'default';
  let videoDeviceId = 'default';
  let streamIsStarted = false;

  const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
  if (supportedConstraints.facingMode === true) {
    constraints.facingMode = 'environment';

    yield call(letStartStream, constraints);
    streamIsStarted = true;
  }

  const { devices } = yield call(EenumerateDevices);

  if (devices) {
    const size = devices.length;
    for (let i = 0; i < size; ++i) {
      const sourceInfo = devices[i];
      if (sourceInfo.kind === 'audioInput') {
        push(audioDevices, {
          id: sourceInfo.deviceId,
          label: sourceInfo.label || `microphone ${audioDevices.length + 1}`,
        });
      } else if (sourceInfo.kind === 'videoInput') {
        push(videoDevices, {
          id: sourceInfo.deviceId,
          label: sourceInfo.label || `camera ${videoDevices.length + 1}`,
        });
      }
    }
    audioDeviceId = audioDevices[0].id;
    videoDeviceId = selectBestVideoDevice(videoDevices);
  } else {
    push(audioDevices, {
      id: 'default',
      label: 'Default',
    });
    push(videoDevices, {
      id: 'default',
      label: 'Default',
    });
  }

  yield put(loadMediaDevices({
    audioDevices: enableAudio ? audioDevices : null,
    audioDeviceId: enableAudio ? audioDeviceId : null,
    videoDevices,
    videoDeviceId,
  }));

  if (!streamIsStarted) {
    constraints.audioSource = enableAudio ? audioDeviceId : null;
    constraints.videoSource = videoDeviceId;

    yield call(letStartStream, constraints);
  }
}

function* onDestroyMediaDevices() {
  yield call(stop);
}

const getAudioDeviceId = state => state.mediaDevices.audioDeviceId;
const getVideoDeviceId = state => state.mediaDevices.videoDeviceId;

function* onSelectAudioDevice(audioDeviceId) {
  const videoDeviceId = yield select(getVideoDeviceId);
  const constraints = {
    audioSource: audioDeviceId,
    videoSource: videoDeviceId,
    resolution: '1280x720'
  }
  yield call(letStartStream, constraints);
}
function* onSelectVideoDevice(videoDeviceId) {
  const audioDeviceId = yield select(getAudioDeviceId);
  const constraints = {
    audioSource: audioDeviceId,
    videoSource: videoDeviceId,
    resolution: '1280x720'
  }
  yield call(letStartStream, constraints);
}


/** ************************************************************************ * */
/** ***************************** WATCHERS ********************************* * */
/** ************************************************************************ * */
const TRUE = true;

export function* watchInitMediaDevices() {
  while (TRUE) {
    const { payload: enableAudio } = yield take(MEDIA_DEVICES_INIT);
    yield fork(onInitMediaDevices, enableAudio);
  }
}

export function* watchSelectAudioDevice() {
  while (TRUE) {
    const { payload: audioDeviceId } = yield take(MEDIA_DEVICES_AUDIO_SELECT);
    yield fork(onSelectAudioDevice, audioDeviceId);
  }
}

export function* watchSelectVideoDevice() {
  while (TRUE) {
    const { payload: videoDeviceId } = yield take(MEDIA_DEVICES_VIDEO_SELECT);
    yield fork(onSelectVideoDevice, videoDeviceId);
  }
}

export function* watchDestroyMediaDevices() {
  while (TRUE) {
    yield take(MEDIA_DEVICES_DESTROY);
    yield fork(onDestroyMediaDevices);
  }
}
