/* global window */
/* global navigator */

import OT from '../opentok';

export function detectIOS() {
  return navigator.userAgent.match(/(iPad|iPhone|iPod)/g) || false;
}

export function detectIOSAndSafariNotSupportWebrtc() {
  const ua = navigator.userAgent;
  return ((ua.match(/(iPad|iPhone|iPod)/g) && OT.checkSystemRequirements() === 0) || false);
}

export function detectSafariOnOSX() {
  const ua = navigator.userAgent;
  return ((navigator.userAgent.indexOf('Macintosh') > -1 && navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') < 0) || false);
}

export function detectIE() {
  const ua = window.navigator.userAgent;

  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}

export function detectFirefox() {
  return /* navigator.userAgent.indexOf('Android') > -1 &&*/ navigator.userAgent.indexOf('Firefox') > -1;
}

export function detectFirefoxOnAndroid() {
  return navigator.userAgent.indexOf('Android') > -1 && navigator.userAgent.indexOf('Firefox') > -1;
}

/**
 * detect Samsung Browser on Mobile Phones
 * returns true if user agent has SamsungBrowser as string in his useragent
 */
export function detectSamsungBrowser() {
  return navigator.userAgent.indexOf('SamsungBrowser') > -1;
}

/**
 * detect Opera Mini on Android on Mobile Phones
 * returns true if user agent has SamsungBrowser as string in his useragent
 */
export function detectOperaBrowserOnAndroid() {
  return navigator.userAgent.indexOf('Android') > -1 && (navigator.userAgent.indexOf('OPR/') > -1 || navigator.userAgent.indexOf('Opera') > -1);
}

/**
 * detect Andoird Browser on Mobile Phones
 * returns true if user agent has AndroidBrowser (i.e. Samsung S3 Mini) as string in his useragent
 */
export function detectAndroidBrowser() {
  return navigator.userAgent.indexOf('Android') > -1 && navigator.userAgent.indexOf('AppleWebKit') > -1;
}
