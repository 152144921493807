/* global window */
import React from 'react';
import { Link } from 'react-router';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  TITLE: {
    id: 'CompanyBillingRedirectPage.TITLE',
    defaultMessage: 'Sie haben Zugriff auf mehr als eine Firma',
  },
  COMPANY_LIST: {
    id: 'CompanyBillingRedirectPage.COMPANY_LIST',
    defaultMessage: 'Firmenliste',
  },
});

function CompanyBillingRedirectPage({ user: { company_count, company_id }, intl: { formatMessage } }) {
  const companyListLink = <Link to="company">{formatMessage(messages.COMPANY_LIST)}</Link>;

  if (company_count !== 1) { // eslint-disable-line camelcase
    return (
      <div style={{ padding: '30px 30px 30px 30px' }} className="flex">
        <div className="content-block row">
          <h2>{formatMessage(messages.TITLE)}</h2>
          <p>
            <FormattedMessage
              id="CompanyBillingRedirectPage.MESSAGE"
              defaultMessage="Sie haben Zugriff auf mehr als eine Firma. Deswegen können wir Sie nicht direkt zu den Einzelverbindungsnachweisen einer bestimmten Firma weiterleiten. Bitte wählen Sie eine Firma in der {companyListLink} aus. In der Kundenliste erreichen Sie dann über das Menü die Einzelverbindungsnachweise."
              values={{ companyListLink }}
            />
          </p>
        </div>
      </div>
    );
  }

  window.location = `/company/${company_id}/billing`; // eslint-disable-line camelcase
}

export default injectIntl(CompanyBillingRedirectPage);
