import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router';

const messages = defineMessages({
  PASSWORD_CHANGED: {
    id: 'SettingsPasswordSuccess.PASSWORD_CHANGED',
    defaultMessage: 'Ihr Passwort wurde geändert',
  },
  USE_NEW_PASSWORD: {
    id: 'SettingsPasswordSuccess.USE_NEW_PASSWORD',
    defaultMessage: 'Bitte verwenden Sie in Zukunft ihr neues Passwort.',
  },
  BACK: {
    id: 'SettingsPasswordSuccess.BACK',
    defaultMessage: 'Zurück',
  },
});

function SettingsPasswordSuccess({ intl: { formatMessage } }) {
  return (
    <div>
      <div className="m40 w-xs">
        <Link to="/settings/">{formatMessage(messages.BACK)}</Link>
        <h3>{formatMessage(messages.PASSWORD_CHANGED)}</h3>
        <p className="alert alert-info">
          {formatMessage(messages.USE_NEW_PASSWORD)}
        </p>
      </div>
    </div>
  );
}

export default injectIntl(SettingsPasswordSuccess);
