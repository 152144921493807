import React from 'react';
import Offline from '../components/Offline';
import NavBar from '../components/NavBar';
import NavBarConfig from '../components/NavBarConfig';

export default function Navigation({ children, user }) {
  return (
    <div className="navigation">
      <Offline />
      <NavBar />
      <NavBarConfig user={user} />
      <div className="container content-bg">
        {React.cloneElement(children, { user })}
      </div>
    </div>
  );
}
