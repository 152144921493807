import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router';

const messages = defineMessages({
  UNKOWN_PAGE: {
    id: 'NotFound.UNKOWN_PAGE',
    defaultMessage: 'Seite unbekannt',
  },
  PAGE_NOT_FOUND: {
    id: 'NotFound.PAGE_NOT_FOUND',
    defaultMessage: 'Die von Ihnen angewählte Seite konnte leider nicht gefunden werden.',
  },
  BACK_TO_STARTPAGE: {
    id: 'NotFound.BACK_TO_STARTPAGE',
    defaultMessage: 'Zurück zur Startseite',
  },
});

function NotFound({ intl: { formatMessage } }) {
  return (
    <div className="flex">
      <div className="content-block row login-page">
        <h2>{formatMessage(messages.UNKOWN_PAGE)}</h2>
        <p style={{ paddingBottom: 20 }}>
          {formatMessage(messages.PAGE_NOT_FOUND)}
        </p>
        <Link to="/">
          {formatMessage(messages.BACK_TO_STARTPAGE)}
        </Link>
      </div>
    </div>
  );
}

export default injectIntl(NotFound);
