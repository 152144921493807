import React from 'react';


export default function TextOverflow(props) {
  const { title, maxWidth, children, inline } = props;
  return (
    <span
      style={{ maxWidth: maxWidth || '450px', display: inline ? 'inline-block' : 'block' }}
      className="text-overflow"
      title={title}
    >
      {children}
    </span>
  );
}
