import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { FORM_ERROR } from 'final-form';
import { loggedIn, requestPasswordReset2 } from 'actions/auth';

import RequestPasswordResetForm from 'components/forms/RequestPasswordResetForm';
import { Link } from 'react-router';


const messages = defineMessages({
  RESET_PASSWORD: {
    id: 'RequestPasswordReset.RESET_PASSWORD',
    defaultMessage: 'Passwort zurücksetzen',
  },
  INDICATE_MAIL_OR_PHONE: {
    id: 'RequestPasswordReset.INDICATE_MAIL_OR_PHONE',
    defaultMessage: 'Bitte geben Sie Ihre E-Mail Adresse oder Ihre Telefonnummer an.',
  },
  MAIL_RESET_PASSWORD: {
    id: 'RequestPasswordReset.MAIL_RESET_PASSWORD',
    defaultMessage: 'Wir werden dann einen Link an diese Adresse senden um Ihnen ein neues Passwort zu erstellen.',
  },
  BACK: {
    id: 'RequestPasswordReset.BACK',
    defaultMessage: 'Zurück',
  },
});

class RequestPasswordReset extends React.PureComponent {
    render() {
      const { intl: { formatMessage } } = this.props;

        return (
            <div className="flex">
                <div className="home-content content-block row login-page">
                    <h1>{formatMessage(messages.RESET_PASSWORD)}</h1>
                    <p>
                        {formatMessage(messages.INDICATE_MAIL_OR_PHONE)}
                        {formatMessage(messages.MAIL_RESET_PASSWORD)}
                    </p>
                    <RequestPasswordResetForm onSubmit={this.props.action} />
                    <div className="text-center" style={{ marginTop: '15px' }}>
                        <Link to="/">
                            {formatMessage(messages.BACK)}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null,
  dispatch => ({
    action: async formData => {
      const data = await requestPasswordReset2(formData);
      if (data.response) {
        dispatch(push('/requestPasswortReset/success/'))
      } else {
        return { [FORM_ERROR]: data.error[Object.keys(data.error)[0]] };
      }
    },
  })
)(injectIntl(RequestPasswordReset));
