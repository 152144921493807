/* global import */
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import classnames from 'classnames';
import { connect } from 'react-redux';

const messages = defineMessages({
  TEST_MESSAGE: {
    id: 'TESTMESSAGE',
    defaultMessage: 'Testmessage...'
  }
});

class UserVideoDrawing extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      fullsize,
      remoteConnection,
      intl: { formatMessage },
    } = this.props;

    return (
      <div
        className={classnames('videomode video-chat-embedded', {
          active: remoteConnection.connected,
          fullscreen: fullsize,
        })}

        style={{ flexDirection: 'column' }}

        ref={c => {
          this.container_el = c;
        }}
      >
        Test
      </div>
    );
  }
}

export default connect(state => {
    const {
      video,
      video: {
        fullsize,
      },
    } = state;
    return {
      ...video,
      fullsize
    };
  },
  {
  })(injectIntl(UserVideoDrawing));
