import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router';
import datenschutz from 'helpers/datasecurity';

import Logo from '../../components/Logo';


const messages = defineMessages({
  DATA_PRIVACY: {
    id: 'DataPrivacyPage.DATA_PRIVACY',
    defaultMessage: 'Datenschutzerklärung',
  },
  DATA_PRIVACY_CUSTOMER: {
    id: 'DataPrivacyPage.DATA_PRIVACY_CUSTOMER',
    defaultMessage: 'Datenschutz auf dem Mobile Gerät',
  },
  DATA_PRIVACY_GENERAL: {
    id: 'DataPrivacyPage.DATA_PRIVACY_GENERAL',
    defaultMessage: 'Datenschutzerklärung als tele-LOOK',
  },
  BACK_TO_STARTPAGE: {
    id: 'DataPrivacyPage.BACK_TO_STARTPAGE',
    defaultMessage: 'Zurück zur Startseite',
  },
});

class DataPrivacyPage extends React.Component {
    render() {
      const { intl: { formatMessage } } = this.props;

        return (
          <div className="flex">
            <div className="content-block row login-page">
              <Logo />
              <div className="terms">
                <h1>
                  {formatMessage(messages.DATA_PRIVACY)}
                </h1>
                <h2>
                  {formatMessage(messages.DATA_PRIVACY_CUSTOMER)}
                </h2>
                {datenschutz}
                <h2>
                  {formatMessage(messages.DATA_PRIVACY_GENERAL)}
                </h2>
                <p>
                  Wir freuen uns, dass Sie sich für unsere Angebote interessieren. Im Folgenden erklären wir, wie wir Ihre personenbezogenen Daten insbesondere im Rahmen einer Bestellung verarbeiten und halten weitere in diesem Zusammenhang relevante Informationen bereit.
                </p>
                <h3>
                  1. Wer ist verantwortlich für die Verarbeitung Ihrer personenbezogenen Daten?
                </h3>
                <p>
                  Solutiness GmbH, Alte Breisacher Straße 14a, 79112 Freiburg, Tel., 0761 769997-0 Fax 0761 769997-0, E-Mail datenschutz@tele-look.de,  www.tele-look.com ist Verantwortlicher im Sinne der Datenschutz-Grundverordnung (DSGVO).
                </p>
                <h3>
                  2. Ansprechpartner zum Datenschutz
                </h3>
                <p>
                  Zu allen mit der Verarbeitung ihrer personenbezogenen Daten und mit der Wahrnehmung ihrer Rechte gemäß der DSGVO im Zusammenhang stehenden Fragen erreichen Sie unseren Ansprechpartner zum Datenschutz unter datenschutz@tele-look.de.
                </p>
                <h3>
                  3. Für welche Zwecke und auf welcher Rechtsgrundlage verarbeiten wir personenbezogene Daten?
                </h3>
                <p>
                  Wir verarbeiten personenbezogene Daten über Sie allein zum Zweck der Abwicklung Ihrer Anfrage bzw. eines Vertrages, und nur soweit dies hierfür erforderlich ist. Rechtsgrundlage ist dabei. Art. 6 Abs. 1 Buchstabe b DSGVO.
                </p>
                <h3>
                  4. Welche Kategorien personenbezogener Daten verarbeiten wir?
                </h3>
                <p>
                  Wir verarbeiten Daten zu Ihrer Person, Anschrift. Kontaktdaten, Bestelldaten und Bankdaten, die Sie uns im Zusammenhang mit Ihrer Bestellung oder sonstigen Kontaktaufnahme übermitteln. Wir verarbeiten nur diejenigen Daten, die Sie uns selbst zur Verfügung stellen.
                </p>
                <h3>
                  5. Aus welchen Quellen stammen personenbezogene Daten, wenn wir sie nicht bei Ihnen erheben?
                </h3>
                <p>
                  Wir verarbeiten nur diejenigen Daten, die Sie uns selbst zur Verfügung stellen.
                </p>
                <h3>
                  6. Welche Kategorien von Empfängern Daten gibt es?
                </h3>
                <p>
                  Wir übermitteln Ihre personenbezogenen Daten nur an Stellen, die zur Abwicklung der Bestellung erforderlich sind (Bank, Buchhaltung).
                </p>
                <h3>
                  7. Ist die Übermittlung in ein Drittland beabsichtigt?
                </h3>
                <p>
                  Eine Übermittlung in ein Drittland ist nicht beabsichtigt.
                </p>
                <h3>
                  8. Wie lange werden Ihre Daten gespeichert?
                </h3>
                <p>
                  Wir speichern Ihre personenbezogenen Daten, solange dies aus steuerlichen Gründen erforderlich ist.
                </p>
                <h3>
                  9. Werden bei der Internetnutzung Zugriffsdaten erhoben?
                </h3>
                <p>
                  Wir bzw. unser Hostprovider erheben auf Grundlage berechtigter Interessen (Art. 6 Abs. 1 Buchstabe f DSGVO) Daten über jeden Zugriff auf den Server (Logfiles). Zu den Zugriffsdaten gehören Name der abgerufenen Website, Datei, Datum, Uhrzeit, übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp und -version, Betriebssystem des Nutzers, zuvor besuchte Website, IP-Adresse und der anfragende Provider. Logfiles werden aus Sicherheitsgründen (z.B. Aufklärung von Angriffen) sowie zur Fehlersuche für maximal sieben Tage gespeichert und danach gelöscht. Eine anderweitige Verwendung (zur Beweissicherung) findet nur im Falle von Sicherheitsvorfällen (z.B. Angriffen auf die Website) statt.
                </p>
                <h3>
                  10. Benutzt diese Website Cookies?
                </h3>
                <p>
                  Unsere Webseite verwendet Cookies auf Grundlage berechtigter Interessen (Art. 6 Abs. 1 Buchstabe f DSGVO). Bei Cookies handelt es sich um Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglicht.<br/>
                  Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites für die Nutzer zu vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.<br/>
                  Für folgende Anwendungen benötigen wir Cookies: www.tele-look.com.<br/>
                  In den Cookies werden dabei folgende Daten gespeichert und übermittelt: SessionToken, der zur autmatischen Anmeldung verwendet wird.<br/>
                  Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen verwendet.<br/>
                </p>
                <h3>
                  11. Welche Rechte haben Sie?
                </h3>
                <p>
                  Sie haben je nach der Situation im Einzelfall folgende Datenschutzrechte, zu deren Ausübung Sie uns jederzeit unter der in Ziff. 1 und 2 genannten Daten kontaktieren können:
                </p>
                <p>
                  a) Ein Auskunftsrecht in Bezug auf Ihre bei uns verarbeiteten personenbezogenen Daten, den Zweck der Nutzung, die Kategorie der genutzten Daten, deren Empfänger und Zugriffsberechtigte sowie die geplante Dauer der Datenspeicherung bzw. die Kriterien für die Festlegung dieser Dauer.
                </p>
                <p>
                  b) Ein Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung oder ggf. Vervollständigung Sie betreffender unrichtiger Daten.
                </p>
                <p>
                  c) Ein Widerspruchsrecht, soweit die Verarbeitung Sie betreffender personenbezogener Daten aufgrund von Art. 6 Abs 1 Buchstabe f DSGVO erfolgt. Wir verarbeiten diese Daten dann nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                </p>
                <p>
                  d) Ein jederzeitiges Widerrufsrecht, wenn die Verarbeitung auf einer Einwilligung beruht, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.
                </p>
                <p>
                  e) Ein Recht auf unverzügliche Löschung Sie betreffender personenbezogene Daten,  sofern:<br/>
                  -  diese für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind<br/>
                  -  Sie gem. Ziff. 8c Widerspruch einlegen und keine vorrangigen berechtigten Gründe für die Verarbeitung vorliegen.<br/>
                  -  die personenbezogenen Daten unrechtmäßig verarbeitet wurden.<br/>
                  -  die Löschung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist.<br/><br/>
                  Dies gilt nicht, soweit die Verarbeitung erforderlich ist:<br/>
                  -  zur Erfüllung einer rechtlichen Verpflichtung.<br/>
                  -  zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.<br/>
                </p>
                <p>
                  f) Ein Recht auf Einschränkung der Verarbeitung, sofern<br/>
                  -  die Richtigkeit der personenbezogenen Daten von Ihnen bestritten wird, und zwar für eine Dauer, die es uns ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen,<br/>
                  -  die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;<br/>
                  -  wir die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigen, Sie sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder<br/>
                  -  Sie Widerspruch gegen die Verarbeitung gemäß Ziff. 8c eingelegt haben, solange noch nicht feststeht, ob unsere berechtigten Gründe gegenüber den Ihrigen überwiegen.<br/>
                  Wurde die Verarbeitung eingeschränkt, so dürfen diese personenbezogenen Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.<br/>
                  Haben Sie eine Einschränkung der Verarbeitung erwirkt, werden wir Sie unterrichten, bevor die Einschränkung aufgehoben wird.<br/>
                </p>
                <p>
                  g) Ein Beschwerderecht zu einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
                </p>
              </div>

              <div className="text-center" style={{ marginTop: '15px' }}>
                <Link to="/">
                  {formatMessage(messages.BACK_TO_STARTPAGE)}
                </Link>
              </div>
            </div>
          </div>
        );
    }
}

export default injectIntl(DataPrivacyPage);
