import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  BROWSER_NOT_SUPPORTET: {
    id: 'NotSupportedPage.BROWSER_NOT_SUPPORTET',
    defaultMessage: 'Ihr Browser wird leider nicht unterstützt.',
  },
  CHROME_OR_FIREFOX: {
    id: 'NotSupportedPage.CHROME_OR_FIREFOX',
    defaultMessage: 'Sie können diese Web-Anwendung mit aktuellen Chrome oder Firefox-Browser verwenden. '
      + 'Wir arbeiten mit Hochdruck daran, die Browserunterstützung zu verbessern, allerdings wird ihr '
      + 'Browser bisher nicht unterstützt.',
  },
  FOLLOW_LINK: {
    id: 'NotSupportedPage.FOLLOW_LINK',
    defaultMessage: 'Bitte öffnen Sie folgenden Link in einem aktuellen Chrome oder Firefox-Browser:',
  },
  THANKS_FOR_YOUR_UNDERSTANDING: {
    id: 'NotSupportedPage.THANKS_FOR_YOUR_UNDERSTANDING',
    defaultMessage: 'Vielen Dank für Ihr Verständnis.',
  },
  QUESTIONS: {
    id: 'NotSupportedPage.QUESTIONS',
    defaultMessage: 'Bei Fragen kontaktieren Sie bitte',
  },
});

function NotSupportedPage({ intl: { formatMessage }, params: { roomName }, location }) {
  const url = roomName ? `https://www.tele-look.com/v/${roomName}/` : `https://www.tele-look.com${location.query.url}`;
  return (
    <div className="flex">
      <div style={{ background: '#fff', maxWidth: 480 }} className="content-block home-content">
        <h4 style={{ margin: '0 0 15px 0' }}>
          {formatMessage(messages.BROWSER_NOT_SUPPORTET)}
        </h4>
        <p style={{ margin: '0 0 15px 0' }}>
          {formatMessage(messages.CHROME_OR_FIREFOX)}
          <br />
          {formatMessage(messages.FOLLOW_LINK)}
        </p>
        <pre style={{ margin: '0 0 15px 0', padding: 15 }}>
          <a href={url}>
            {url}
          </a>
        </pre>
        <p style={{ margin: '0 0 15px 0' }}>
          {formatMessage(messages.THANKS_FOR_YOUR_UNDERSTANDING)}
        </p>
        <p style={{ margin: 0 }}>
          {formatMessage(messages.QUESTIONS)} <a href="mailto:support@tele-look.com">mailto:support@tele-look.com</a>.
        </p>
      </div>
    </div>
    );
}

export default injectIntl(NotSupportedPage);
