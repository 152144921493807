import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

// eslint-disable-next-line
import OT from 'opentok';

import {
  initMediaDevices,
  destroyMediaDevices,
} from '../../actions/mediaDevices';

import {
  detectSamsungBrowser,
  detectAndroidBrowser,
  detectOperaBrowserOnAndroid,
  detectFirefoxOnAndroid,
  detectIOS,
  detectSafariOnOSX
} from '../../helpers/browserdetect';

import EndkundeVideo from './components/EndkundeVideo';

class EndkundeVideoPage2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      devicesAreNotSelected: true,
      SelectDevicePage: null,
      supportedCodecs: {
          isLoaded: false,
          hasVP: false,
          codecsList: null
      }
    };
  }
  componentWillMount() {
    let pathName = '/v/';
    if (this.props.params.companyId) {
      pathName = `/custom/${this.props.params.companyId}/v/`
    }
    if (detectSafariOnOSX() && OT.checkSystemRequirements() === 0) {
      this.props.redirect(`/v/${this.props.params.roomName}/nsp/`);
    } else if (/*detectSamsungBrowser()|| */ detectOperaBrowserOnAndroid() || detectFirefoxOnAndroid()) {
      this.props.redirect(`${pathName}${this.props.params.roomName}/samsung/`);
    } else if (detectIOS() && OT.checkSystemRequirements() === 0) {
        this.props.redirect(`/v/${this.props.params.roomName}/ios/`);
    } else if (OT.checkSystemRequirements() === 0) {
      if (detectAndroidBrowser()) {
        this.props.redirect(`${pathName}${this.props.params.roomName}/samsung/`);
      } else {
        this.props.redirect(`/v/${this.props.params.roomName}/nsp/`);
      }
    } else {
      const __getSupportedCodecs = async function build(endkundeVideo) {
        try {
          const supportedCodecs = await OT.getSupportedCodecs();
          // The client does not support encoding or decoding H264.
          // Let's recommend using a different browser.
          // console.log(supportedCodecs);
          // console.log(supportedCodecs.videoEncoders);
          // console.log(supportedCodecs.videoDecoders);

          if (supportedCodecs
            && supportedCodecs.videoEncoders.indexOf('VP8') >= 0
            && supportedCodecs.videoDecoders.indexOf('VP8') >= 0) {
            const supportedState = {
              isLoaded: true,
              hasVP: true,
              codecsList: supportedCodecs
            };

            const defaultPath = 'components/SelectDevicePage2';
            const companyId = endkundeVideo.props.params.companyId;

            if (companyId) {
              import(`../../_customerpages/${companyId}/pages/video/${defaultPath}`).then(
                // Success
                SelectDevicePage => {
                  endkundeVideo.setState({
                    SelectDevicePage: SelectDevicePage.default,
                    supportedCodecs: supportedState
                  });
                  // Init OT
                  endkundeVideo.props.init();
                },
                error => {
                  import(`./${defaultPath}`).then(
                    SelectDevicePage => {
                      endkundeVideo.setState({
                        SelectDevicePage: SelectDevicePage.default,
                        supportedCodecs: supportedState
                      });
                      // Init OT
                      endkundeVideo.props.init();
                    }
                  )
                }
              );
            } else {
              import(`./${defaultPath}`).then(
                SelectDevicePage => {
                  endkundeVideo.setState({
                    SelectDevicePage: SelectDevicePage.default,
                    supportedCodecs: supportedState
                  });
                  // Init OT
                  endkundeVideo.props.init();
                }
              )
            }
          } else {
            endkundeVideo.props.redirect(`/v/${endkundeVideo.props.params.roomName}/nsp/`);
          }
        } catch (err) {
          console.log(err);
          endkundeVideo.props.redirect(`/v/${endkundeVideo.props.params.roomName}/nsp/`);
        }
      };

      __getSupportedCodecs(this);
    }
  }

  componentDidMount() {
    //console.log('init');
    /*
    if ((detectSamsungBrowser()||detectOperaBrowserOnAndroid() || detectFirefoxOnAndroid()) === false) {
      if (OT.checkSystemRequirements() !== 0) {
        this.props.init();
      }
    }
    */
  }
  componentWillUnmount() {
    if ((/* detectSamsungBrowser()|| */ detectOperaBrowserOnAndroid() || detectFirefoxOnAndroid() /* || detectIOS() */) === false) {
      if (OT.checkSystemRequirements() !== 0) {
        this.props.destroy();
      }
    }
  }

  render() {
    const {
      roomName,
      companyId
    } = this.props.params;

    let deviceIsSelected = false;
    if (this.props.location.query.deviceSelected) {
      deviceIsSelected = true;
    }
    const {
      SelectDevicePage,
      supportedCodecs
    } = this.state;

    return (
      <div>
      { deviceIsSelected ? (
          <EndkundeVideo
            roomName={roomName}
            companyId={companyId}
          />
        ) : (SelectDevicePage && supportedCodecs.isLoaded ? (
            <SelectDevicePage
              roomName={roomName}
              companyId={companyId}
            />
          ) :(
            <div>Loading </div>
          )
        )
      }
      </div>
    );
  }
}

export default connect(
  state => state.mediaDevices,
  (dispatch, props) => ({
    redirect: path => dispatch(replace(path)),
    init: () => dispatch(initMediaDevices()),
    destroy: () => dispatch(destroyMediaDevices()),
  })
)(injectIntl(EndkundeVideoPage2));
