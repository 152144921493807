import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import moment from 'moment';
import { companyUpdated } from '../../actions/company';
import { loadPaymentData } from '../../actions/payment';
import NavBarConfig from '../../components/NavBarConfig';
import NotFound from '../../components/NotFound';
import PaymentInfo from './components/PaymentInfo';

const messages = defineMessages({
  BACK: {
    id: 'CompanyPaymentPage.BACK',
    defaultMessage: 'Zurück',
  },
  MAINTAIN_ACCOUNT: {
    id: 'CompanyPaymentPage.MAINTAIN_ACCOUNT',
    defaultMessage: 'Nutzerkonto verwalten',
  },
  TEXT_1: {
    id: 'CompanyPaymentPage.TEXT_1',
    defaultMessage: 'Sie haben noch kein Nutzerkonto angelegt. Ihr kostenloses Test-Nutzerkonto ist abgelaufen.',
  },
  TEXT_2: {
    id: 'CompanyPaymentPage.TEXT_2',
    defaultMessage: 'Um tele-LOOK auch nach der kostenlosen Testphase nutzen zu können, kontaktieren Sie uns bitte unter +49 761 76 99 97-50 oder per E-Mail ',
  },
  /*TEXT_3: {
    id: 'CompanyPaymentPage.TEXT_3',
    defaultMessage: 't ',
  },*/
  TEXT_4: {
    id: 'CompanyPaymentPage.TEXT_4',
    defaultMessage: 'Sie haben noch kein Nutzerkonto angelegt. Ihr kostenloses Test-Nutzerkonto '
      + 'läuft {expirationDate} ab.',
  },
  TEXT_5: {
    id: 'CompanyPaymentPage.TEXT_5',
    defaultMessage: 'Das Anlegen eines Nutzerkontos und der Zugriff auf bestehende Videodialoge und dessen gespeicherten Bilder und Notizen sind kostenlos. '
      + 'Während des tele-LOOK Testzeitraum bleibt auch beim Anlegen eines Nutzerkontos alles kostenlos.',
  },
  TEXT_6: {
    id: 'CompanyPaymentPage.TEXT_6',
    defaultMessage: 'Um tele-LOOK auch nach der kostenlosen Testphase nutzen zu können, kontaktieren Sie uns bitte unter +49 761 76 99 97-50 '
      + 'oder per E-Mail `<a href=\"mailto:sales@tele-look.com\"`>sales@tele-look.com`</a`>.',
  },
  TEXT_7: {
    id: 'CompanyPaymentPage.TEXT_7',
    defaultMessage: 'Handwerksunternehmen kontaktieren bitte ihre Innung oder ihren Verband.',
  },
  EDIT_DATA: {
    id: 'CompanyPaymentPage.EDIT_DATA',
    defaultMessage: 'Daten bearbeiten',
  },
  CREATE_ACCOUNT: {
    id: 'CompanyPaymentPage.CREATE_ACCOUNT',
    defaultMessage: 'Nutzerkonto anlegen',
  },
});

class CompanyPaymentPage extends React.Component {
  componentWillMount() {
    const { company } = this.props;
    if (company.hasPaymentData && !company.payment) {
      this.props.loadPaymentData();
    }
  }

  render() {
    const { user, company, companyMembership, routes, intl: { formatMessage } } = this.props;
    const expirationDate = moment(company.created).add(14, 'days');
    if (!user.is_admin && !user.is_staff && !companyMembership.is_owner) {
      return <NotFound />;
    }
    return (
      <div>
        <NavBarConfig
          customerList={{ company_id: company.id }}
          currentRouteName={routes[routes.length - 1].name}
        />
        <div className="m40 w-xs">
          <Link to={`/company/${company.id}/customer/`}>{formatMessage(messages.BACK)}</Link>
          <h3>{formatMessage(messages.MAINTAIN_ACCOUNT)}</h3>

          {!company.hasPaymentData && company.isExpired ? (
            <p>
              {formatMessage(messages.TEXT_1)}
              <br /> <br />
              {formatMessage(messages.TEXT_2)} <a href="mailto:sales@tele-look.com">sales@tele-look.com</a>.
              <br /> <br />
            </p>
          ) : null
          }
          {!company.hasPaymentData && !company.isExpired ? (
            <p>
              {formatMessage(messages.TEXT_4, { expirationDate: expirationDate.fromNow() })}
              <br /> <br />
              {formatMessage(messages.TEXT_5)}
              <br /> <br />
              {formatMessage(messages.TEXT_6)} <a href="mailto:sales@tele-look.com">sales@tele-look.com</a>.
              <br /> <br />
              {formatMessage(messages.TEXT_7)}
            </p>
          ) : null
          }
          {company.hasPaymentData && company.payment !== undefined ? (
            <PaymentInfo payment={company.payment} />
          ) : (
            null
          )}
          <center>
            <Link style={{ marginTop: '20px' }} to={`/company/${company.id}/payment/edit`} className="lh-btn lh-btn-primary">
              {company.hasPaymentData ? formatMessage(messages.EDIT_DATA) : formatMessage(messages.CREATE_ACCOUNT)}
            </Link>
          </center>
        </div>
      </div>
    );
  }
}

export default connect(null,
  (dispatch, props) => ({
    loadPaymentData: () => loadPaymentData(props.company.id)
      .then(response => dispatch(companyUpdated({ ...props.company, payment: response }))),
  })
)(injectIntl(CompanyPaymentPage));
