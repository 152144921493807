import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import { Link } from 'react-router';

import { deleteCustomer2, customerDeleted } from '../../actions/company';
import NavBarConfig from '../../components/NavBarConfig';
import CustomerDelete from './components/CustomerDelete';
import { FORM_ERROR } from 'final-form';

const messages = defineMessages({
  BACK: {
    id: 'CustomerDeletePage.BACK',
    defaultMessage: 'Zurück',
  },
  DELETE_CUSTOMER: {
    id: 'CustomerDeletePage.DELETE_CUSTOMER',
    defaultMessage: 'Kunde löschen',
  },
  QUESTION_DELETE_USER: {
    id: 'CustomerDeletePage.QUESTION_DELETE_USER',
    defaultMessage: 'Sind sie sich sicher, dass sie diese Kunde wirklich löschen wollen?',
  },
  ACTION_CAN_NOT_REVOKED: {
    id: 'CustomerDeletePage.ACTION_CAN_NOT_REVOKED',
    defaultMessage: 'Diese Aktion kann nicht rückgängig gemacht werden.',
  },
});

function CustomerDeletePage({ company, customer, onSubmit, routes, intl: { formatMessage } }) {
  if (!customer) { return null; }
  return (
    <div>
      <NavBarConfig deletePage currentRouteName={routes[routes.length - 1].name} />
      <div className="m40 w-xs">
        <Link to={`/company/${company.id}/customer/${customer.id}/update/`}>
          {formatMessage(messages.BACK)}
        </Link>
        <h3>{formatMessage(messages.DELETE_CUSTOMER)}</h3>
        <p>
          {formatMessage(messages.QUESTION_DELETE_USER)}
          <br />
          {formatMessage(messages.ACTION_CAN_NOT_REVOKED)}
        </p>
        <CustomerDelete
          style={{ marginTop: '15px' }}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
}

export default connect(null,
  (dispatch, { params: { companyId, customerId } }) => ({
    onSubmit: async () => {
      const data = await deleteCustomer2(companyId, customerId);
      if (data.response === null || (data.response && data.response.error === null)) {
        dispatch(customerDeleted({ companyId, customerId }));
        dispatch(push(`/company/${companyId}/customer/`));
        /* global window */
        window.location.reload();
      } else {
        return { [FORM_ERROR]: data.error[Object.keys(data.error)[0]] };
      }
    }
  })
)(injectIntl(CustomerDeletePage));
