import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

/* global window */

// eslint-disable-next-line
import OT from 'opentok';

import CopyPreToClipboard from './CopyPreToClipboard';
import PdfExportButton from './PdfExportButton';

const messages = defineMessages({
  START_CONVERSATION: {
    id: 'CallControl.START_CONVERSATION',
    defaultMessage: 'Gespräch anfangen',
  },
});

function CallControl(props) {
  const {
    activeCallCreatedAndRunning,
    call,
    customer,
    customerSlug,
    handleStopCall,
    handleCreateSMS,
    openCallBeginModal,
    selectedOrderId,
    company,
    intl: { formatMessage },
  } = props;

  let customPages = '';
  if (company.hasCustomPages) {
    customPages = `/custom/${company.id}`;
  }

  const callUrl = `${window.location.origin}${customPages}/v/${customerSlug}/`;
  if (activeCallCreatedAndRunning && !call.call_end) {
    return (
      <div className="video-chat-header-right-col flex-end">
        {call && !call.call_end && (
          <button
            style={{ marginLeft: 15 }}
            className="lh-btn lh-btn-warning lh-btn-small"
            onClick={() => handleStopCall()}
          >
            <i className="icon-phone-down" style={{ fontSize: 15 }} />
          </button>
        )}
        { customer.phone &&
          <button
            style={{ marginLeft: 15 }}
            className="lh-btn lh-btn-default lh-btn-small"
            onClick={() => handleCreateSMS()}
          >
            <i className="fa fa-envelope-o" /> SMS
          </button>
        }

        <CopyPreToClipboard content={callUrl} />
        <PdfExportButton customer={customer} selectedOrderId={selectedOrderId} />
      </div>
    );
  }
  if (OT.checkSystemRequirements() === 0) {
    return <div />;
  }
  return (
    <div className="video-chat-header-right-col flex-end">
      <button
        className="lh-btn lh-btn-primary start-call lh-btn-small lh-btn-extra-small"
        onClick={openCallBeginModal}
      >
        {formatMessage(messages.START_CONVERSATION)}
      </button>
      <PdfExportButton customer={customer} selectedOrderId={selectedOrderId} />
    </div>
  );
}

export default injectIntl(CallControl);
