import request from 'superagent';
import { createAction, handleActions } from 'redux-actions';

import { OFFLINE_STATUS } from './constants';

export const setOffline = createAction(OFFLINE_STATUS);

export function fetchOfflineFn(cb) {
  return function fetchOffline() {
    request
    .get('/api/online')
    .end((err) => {
      if (err) {
        cb(true);
      } else {
        cb(false);
      }
      setTimeout(fetchOffline, 5000);
    });
  }
}


const offline = handleActions({
  [OFFLINE_STATUS]: (state, action) => action.payload,
}, true);

export default offline;
