import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { push } from 'react-router-redux';

import { customerList } from '../../actions/company';
import NotFound from '../../components/NotFound';
import NavBarConfig from '../../components/NavBarConfig';
import CustomerList from './components/CustomerList';

const messages = defineMessages({
  CREATE_CUSTOMER: {
    id: 'CustomerListPage.CREATE_CUSTOMER',
    defaultMessage: 'Kunde anlegen',
  },
  ADJUST_PROFILE: {
    id: 'CustomerListPage.ADJUST_PROFILE',
    defaultMessage: 'Profil anpassen',
  },
  MAINTAIN_USER: {
    id: 'CustomerListPage.MAINTAIN_USER',
    defaultMessage: 'Benutzer verwalten',
  },
  MAINTAIN_ACCOUNT: {
    id: 'CustomerListPage.MAINTAIN_ACCOUNT',
    defaultMessage: 'Nutzerkonto verwalten',
  },
  DELETE_COMPANY: {
    id: 'CustomerListPage.DELETE_COMPANY',
    defaultMessage: 'Unternehmen löschen',
  },
  APPLY_CUSTOMER: {
    id: 'CustomerListPage.APPLY_CUSTOMER',
    defaultMessage: 'Oben rechts können Sie einen ersten Kunden anlegen.',
  },
  BILLINGS: {
    id: 'CustomerListPage.BILLINGS',
    defaultMessage: 'Einzelverbindungsnachweise',
  },
  MORE_OPTIONS: {
    id: 'CustomerListPage.MORE_OPTIONS',
    defaultMessage: 'Weitere Optionen',
  },
});

class CustomerListPageComponent extends React.Component {
  componentWillMount() {
    this.props.load();

    let initFilter = '';
    if (this.props.filter){
      initFilter = this.props.filter;
    }


    this.setState({
      results: null,
      filter: initFilter
    });
    this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.params.companyId !== nextProps.params.companyId) {
      nextProps.load();
    }
  }

  handleOnChangeSearch(event) {
    this.setState({
      filter: event.target.value
    });
    this.props.search(event.target.value);
  }

  render() {
    const {
      user,
      company,
      companyMembership,
      error,
      results,
      filteredResults,
      intl: { formatMessage },
    } = this.props;

    if (!company || !companyMembership) {
      return null;
    }
    if (error) {
      return <NotFound />;
    }
    const canAdmin = user.is_admin || companyMembership.is_admin;
    const isOwner = companyMembership.is_owner;
    return (
      <div>
        <NavBarConfig
          customerList={{ company_id: company.id }}
          currentRouteName={
            this.props.routes[this.props.routes.length - 1].name
          }
        />
        <div className="customer-list-header">
          <div className="customer-list-header-left-col">
            <h3
              style={{ margin: 0 }}
              className="customer-list-header-title company-name"
            >
              {company.name}
            </h3>
          </div>
          <div className="customer-list-header-middle-col">
            <input
              className="form-control"
              type={'text'}
              id={'customerListsearch'}
              value={this.state.filter}
              onChange={this.handleOnChangeSearch}
            />
          </div>
          <div className="customer-list-header-right-col">
            <Link
              className="lh-btn lh-btn-small lh-btn-extra-small lh-btn-primary lh-btn-small-noblock"
              to={`/company/${company.id}/customer/create/`}
            >
              {` ${formatMessage(messages.CREATE_CUSTOMER)}`}
            </Link>
            {canAdmin
              ? <Dropdown pullRight id={1}>
                  {/* eslint-disable jsx-a11y/href-no-hash */}
                  <a
                    href="#"
                    bsRole="toggle"
                    onClick={e => e.preventDefault()}
                    title={formatMessage(messages.MORE_OPTIONS)}
                  >
                    <span className="fa fa-ellipsis-v customer-list-header-extra" />
                  </a>
                  <Dropdown.Menu style={{ width: 300 }}>
                    <MenuItem
                      onClick={() =>
                        this.props.navigate(`/company/${company.id}/update/`)}
                    >
                      {formatMessage(messages.ADJUST_PROFILE)}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        this.props.navigate(`/company/${company.id}/user/`)}
                    >
                      {formatMessage(messages.MAINTAIN_USER)}
                    </MenuItem>
                    {isOwner &&
                      <MenuItem
                        onClick={() =>
                          this.props.navigate(`/company/${company.id}/payment`)}
                      >
                        {formatMessage(messages.MAINTAIN_ACCOUNT)}
                      </MenuItem>}
                    <MenuItem
                      onClick={() =>
                        this.props.navigate(`/company/${company.id}/billing`)}
                    >
                      {formatMessage(messages.BILLINGS)}
                    </MenuItem>
                    {user.is_admin || user.is_staff
                      ? <MenuItem divider />
                      : null}
                    {user.is_admin || user.is_staff
                      ? <MenuItem
                          onClick={() =>
                            this.props.navigate(
                              `/company/${company.id}/delete/`
                            )}
                        >
                          <span className="fa fa-trash" />
                          {' '}
                          {formatMessage(messages.DELETE_COMPANY)}
                        </MenuItem>
                      : null}
                  </Dropdown.Menu>
                </Dropdown>
              : null}
            {/*
            <i ></i>
            */}
          </div>
        </div>
        {filteredResults
          ? <CustomerList items={filteredResults} filter={this.state.filter} allItems={results} />
          : <p>{formatMessage(messages.APPLY_CUSTOMER)}</p>}
      </div>
    );
  }
}

export default connect(
  state => state.customerList,
  (dispatch, props) => ({
    load: () => dispatch(customerList.load(props.params.companyId)),
    navigate: url => dispatch(push(url)),
    search: (search) => dispatch(customerList.search(search))
  })
)(injectIntl(CustomerListPageComponent));
