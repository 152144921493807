import React from 'react';
import classnames from 'classnames';

import Capitals from './Capitals';
import CallControl from './CallControl';

import { trunc } from '../../../helpers/index';


export default function CustomerHeader(props) {
  const {
    activeCallCreatedAndRunning,
    activeCallFetching,
    call,
    connected,
    customer,
    handleCreateSMS,
    handleStopCall,
    openCallBeginModal,
    selectedOrderId,
    couldNotCreateCall,
    company
  } = props;

  return (
    <div
      className={classnames('video-chat-header', {
        row: !activeCallCreatedAndRunning && !call,
      })}
    >
      <div className="video-chat-header-left-col" style={{ marginTop: 0 }}>
        <div className="customer-single-element">
          <div className="customer-single-element-capitals hidden-xs hidden-sm">
            <Capitals customer={customer} />
          </div>
          <div className="customer-single-element-right">
            {customer.name ? (
              <h4 className="customer-sub-title">{trunc(customer.name, 30, true)}</h4>
            ) : null}
            <div className="customer-list-row-text-small" title={customer.deleted || customer.phone}>{customer.deleted || customer.phone}</div>
          </div>
        </div>
      </div>
      {(activeCallFetching || couldNotCreateCall) ? null : (
        <CallControl
          activeCallCreatedAndRunning={activeCallCreatedAndRunning}
          call={call}
          handleStopCall={handleStopCall}
          handleCreateSMS={handleCreateSMS}
          customerSlug={customer.slug}
          openCallBeginModal={openCallBeginModal}
          customer={customer}
          selectedOrderId={selectedOrderId}
          company={company}
        />
      )}

    </div>
  );
}
