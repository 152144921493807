import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import classnames from 'classnames';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
    createOrder,
    orderCreated,
    orderSelected,
    toggleOrderSidebar,
} from '../../../actions/company';
import OrderTextInput from './OrderTextInput';
import OrderSidebarElement from './OrderSidebarElement';

const messages = defineMessages({
  ALL_EVENTS: {
    id: 'OrderSidebar.ALL_EVENTS',
    defaultMessage: 'Alle Ereignisse',
  },
  PROJECTS: {
    id: 'OrderSidebar.PROJECTS',
    defaultMessage: 'Projekte',
  },
  SHOW_ACTIVE: {
    id: 'OrderSidebar.SHOW_ACTIVE',
    defaultMessage: 'Nur Aktive',
  },
  SHOW_ALL: {
    id: 'OrderSidebar.SHOW_ALL',
    defaultMessage: 'Alle anzeigen',
  },
  NEW_PROJECT: {
    id: 'OrderSidebar.NEW_PROJECT',
    defaultMessage: 'Neues Projekt',
  },
});

@connect(
  (state) => {
    const { orderList, customerSingle: { customer }, orderSidebarOpen } = state;
    const customerId = customer && customer.id;
    const companyId = customer && customer.company_id;
    return { orderList, customerId, companyId, orderSidebarOpen };
  },
  dispatch => ({
    createOrder: (companyId, customerId, name) => {
      return createOrder(companyId, customerId, { name })
          .then((order) => {
            dispatch(orderCreated(order));
          });
    },
    selectOrder: (id) => {
      dispatch(orderSelected(id));
    },
    toggleOrderSidebar: () => dispatch(toggleOrderSidebar()),
  })
)

class OrderSidebar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sidebarActive: false,
      showOnlyActive: false,
    };
  }

  _toggleActive() {
    this.setState({ sidebarActive: !this.state.sidebarActive });
  }

  render() {
    const {
      params,
      selectedOrder,
      middleHeight,
      active,
      orderList,
      close,
      customerId,
      companyId,
      intl: { formatMessage },
    } = this.props;
    if (orderList.isFetching) {
      return null;
    }
    let orders = (this.props.orderList.results || []).filter(order => order.id !== -1);
    const { showOnlyActive } = this.state;
    if (showOnlyActive) {
      orders = orders.filter(o => o.active);
    }
    orders = _.sortBy(orders, 'name');
    // (
    //     <div className={classnames("order", {
    //         active: result.id === selectedOrder
    //     })}>
    //         <div className="text">
    //             Ereignisse ohne Projekt
    //         </div>
    //     </div>
    // )
    // console.log(params.orderId);
    return (
      <div
        className={classnames('order-sidebar', { active })}
      >
        <div className="relative order-sidebar-wrapper">
          <i
            className="icon-left order-sidebar-toggle"
            onClick={close}
          />
          <div className="orders">
            <Link
              to={`/company/${params.companyId}/customer/${params.customerId}`}
              className="text-center"
              onClick={close}
            >
              <div
                className={classnames('order', {
                  active: !params.orderId || params.orderId == -2, // params.orderId == "-2" !!!
                })}
              >
                <div className="text">
                  {formatMessage(messages.ALL_EVENTS)}
                </div>
              </div>
            </Link>
            <h4 style={{ paddingTop: 20 }}>
              {formatMessage(messages.PROJECTS)}
              {' '}
              {/* eslint-disable jsx-a11y/href-no-hash */}
              <a href="#" onClick={() => this.setState({ showOnlyActive: !showOnlyActive })}>
                <small>{showOnlyActive ? formatMessage(messages.SHOW_ALL) : formatMessage(messages.SHOW_ACTIVE)}</small>
              </a>
            </h4>
            {orders
            .map(result => (
              <OrderSidebarElement
                key={result.id}
                to={`/company/${params.companyId}/customer/${params.customerId}/order/${result.id}`}
                order={result}
                onClick={close}
                className={classnames('without-order', {
                  active: result.id === selectedOrder,
                })}
              />
            ))}
          </div>
          <OrderTextInput
            placeholder={formatMessage(messages.NEW_PROJECT)}
            className="new-order"
            onSave={(name) => this.props.createOrder(companyId, customerId, name)}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(OrderSidebar);
