import React from 'react';

import { connect } from 'react-redux';
import { companyUserSingle } from 'actions/company';

import NavBarConfig from 'components/NavBarConfig';
import NotFound from 'components/NotFound';


class CompanyUserContainer extends React.Component {
  componentWillMount() {
    this.props.load();
  }
  componentWillReceiveProps(nextProps) {
    const { companyId, userId } = this.props.params;
    if (companyId !== nextProps.params.companyId || userId !== nextProps.params.userId) {
      nextProps.load();
    }
  }
  render() {
    const {
      user, company, companyMembership, companyUser, error, children,
      params: { companyId, userId },
    } = this.props;
    if (!company || !companyMembership) { return null; }
    if (error) {
      return <NotFound />;
    }
    if (!companyUser) {
      return <div />;
    }
    if (companyId !== `${companyUser.company_id}` || userId !== `${companyUser.user_id}`) {
      return <div />;
    }
    if (!children) {
      return <div />;
    }
    return (
      <NavBarConfig companyUserList companyUser={companyUser}>
        {React.cloneElement(children, { user, company, companyMembership, companyUser })}
      </NavBarConfig>
    );
  }
}

export default connect(
  state => state.companyUserSingle,
  (dispatch, props) => ({
    load: () => dispatch(companyUserSingle.load(props.params)),
  })
)(CompanyUserContainer);
