import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';

import TextField from 'components/form/TextField';
import FormGroup2 from 'components/form/FormGroup';
import FormError from 'components/form/FormError';
import FormSubmit from 'components/form/FormSubmit';

const messages = defineMessages({
  LABEL_NAME: {
    id: 'SettingsForm.LABEL_NAME',
    defaultMessage: 'Name',
  },
  LABEL_EMAIL: {
    id: 'SettingsForm.LABEL_EMAIL',
    defaultMessage: 'E-Mail Adresse',
  },
  LABEL_PHONE: {
    id: 'SettingsForm.LABEL_PHONE',
    defaultMessage: 'Telefonnummer',
  },
  LABEL_SAVE: {
    id: 'SettingsForm.LABEL_SAVE',
    defaultMessage: 'Speichern',
  },
  VALIDATION_FIELD_REQUIRED: {
    id: 'SettingsForm.VALIDATION_FIELD_REQUIRED',
    defaultMessage: 'Dieses Feld ist erforderlich.',
  },
  VALIDATION_EMAIL_OR_PHONE: {
    id: 'SettingsForm.VALIDATION_EMAIL_OR_PHONE',
    defaultMessage: 'E-Mail oder Telefonnummer ist erforderlich.',
  },
});

function SettingsForm(props) {
  const {
    intl: { formatMessage },
  } = props;
  //console.log(props);
  //console.log(props.initialValues);
  return (
    <Form
      onSubmit={props.onSubmit}
      validate={values => {
        const errors = {};
        if (!values.name) {
          errors.name= formatMessage(messages.VALIDATION_FIELD_REQUIRED)
        }
        if (!values.name && values.phone) {
          errors.phone = formatMessage(messages.VALIDATION_EMAIL_OR_PHONE)
        }

        return errors;
      }}
      initialValues={props.initialValues}
    >
      {({ handleSubmit, submitError, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="name"
          >
            {({ input, meta }) => {
              return (
                <FormGroup2 label={formatMessage(messages.LABEL_NAME)} input={input} meta={meta}>
                  <TextField
                    {...input}
                    tabIndex="1"
                    autoFocus
                    autoComplete="off"
                  />
                </FormGroup2>
              );
            }}
          </Field>
          <Field
            name="email"
          >
            {({ input, meta }) => {
              return (
                <FormGroup2 label={formatMessage(messages.LABEL_EMAIL)} input={input} meta={meta}>
                  <TextField
                    {...input}
                    tabIndex="2"
                    autoCapitalize="off"
                    autoCorrect="off"
                    autoComplete="off"
                  />
                </FormGroup2>
              );
            }}
          </Field>
          <Field
            name="phone"
          >
            {({ input, meta }) => {
              return (
                <FormGroup2 label={formatMessage(messages.LABEL_PHONE)} input={input} meta={meta}>
                  <TextField
                    {...input}
                    tabIndex="3"
                    autoCapitalize="off"
                    autoCorrect="off"
                    autoComplete="off"
                  />
                </FormGroup2>
              )
            }}
          </Field>
          <FormError error={submitError} />
          <FormSubmit
            btnClass="lh-btn lh-btn-primary lh-btn-block"
            btnProps={{ tabIndex: 4 }}
            submitting={submitting}
            label={formatMessage(messages.LABEL_SAVE)}
          />
        </form>
      )}
    </Form>
  );
}
/*
const SettingsFormReduxForm = reduxForm({
  form: 'settings',
  fields: ['name', 'email', 'phone'],
  validate: (values, { intl: { formatMessage } }) => ({
    name: values.name ? null : formatMessage(messages.VALIDATION_FIELD_REQUIRED),
    _error: values.email || values.phone ? null : formatMessage(messages.VALIDATION_EMAIL_OR_PHONE),
  }),
  touchOnBlur: false,
})(SettingsForm);
*/
export default injectIntl(SettingsForm);
