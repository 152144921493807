import React from 'react';
import classnames from 'classnames';

export default function Loader(props) {
  const { inline, flex, flexCenter } = props;
  const spinnerClass = classnames('spinner', {
    inline,
    flex,
    flexCenter,
  });
  return (
    <div className={spinnerClass}>
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  );
}
