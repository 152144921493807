import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { check, checked, checkedLoginNotLoggedIn } from 'actions/auth';
import { removeAuthToken } from 'actions/api';


function checkOrRedirect(props) {
  const { checkedLogin, user, redirect } = props;
  if (!checkedLogin) {
    props.check();
  } else {
    if (!user) {
      redirect('/login/');
      return;
    }
    if (user.is_admin || user.is_staff || user.company_count > 1) {
      redirect('/company/');
      return;
    }
    redirect(`/company/${user.company_id}/`);
  }
}


class IndexPageComponent extends PureComponent {
  componentWillMount() {
    checkOrRedirect(this.props);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.checkedLogin !== nextProps.checkedLogin) {
      checkOrRedirect(nextProps);
    }
  }
  render() {
    return null;
  }
}

export default connect(
  state => state.auth,
  dispatch => ({
    redirect: path => dispatch(replace(path)),
    check: () => check().then(
      user => dispatch(checked(user)),
      () => {
        // remove old token data if user login check not successful
        removeAuthToken();
        dispatch(checkedLoginNotLoggedIn());
      },
    ),
  }),
)(IndexPageComponent);
