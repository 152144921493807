import Modal from 'react-modal';
import { defineMessages, injectIntl } from 'react-intl';
import React from 'react';

const messages = defineMessages({
  STARTING_CONVERSATION: {
    id: 'StartCallModal.STARTING_CONVERSATION',
    defaultMessage: 'Sie starten jetzt ein Gespräch mit Ihrem Kunden.',
  },
  NAME_CUSTOMER: {
    id: 'StartCallModal.NAME_CUSTOMER',
    defaultMessage: 'Kundenname',
  },
  SEND_NAME: {
    id: 'StartCallModal.SEND_NAME',
    defaultMessage: 'SMS Nachricht an {phone}<br /> mit dem Sitzungsnamen versenden.',
  },
  SMS: {
    id: 'StartCallModal.SMS',
    defaultMessage: 'Sollten Sie dem Kunden also bereits eine SMS gesendet haben\n' +
      'kann er den bestehenden Raum wieder verwenden.',
  },
  SMS_NO_NUMBER: {
    id: 'StartCallModal.SMS_NO_NUMBER',
    defaultMessage: 'Für den Kunden ist keine Telefonnummer angegeben. Geben Sie den Link bitte auf anderem Weg weiter.',
  },
  BUTTON_START_CONVERSATION: {
    id: 'StartCallModal.BUTTON_START_CONVERSATION',
    defaultMessage: 'Gespräch anfangen',
  },
});

const StartCallModalComponent = ({
  open,
  handleModalClose,
  toggleSMS,
  smsActive,
  handleSuccess,
  customer,
  intl: { formatMessage },
}) => {
  let textSMSNumber = formatMessage(messages.SMS);
  let sendSMS = true;

  if (!customer.deleted && !customer.phone) {
    textSMSNumber = formatMessage(messages.SMS_NO_NUMBER);
    sendSMS = false;
  }

  Modal.setAppElement('#app');

  return (
  <Modal
    contentLabel="Modal"
    isOpen={open}
    onRequestClose={handleModalClose}
    closeTimeoutMS={0}
    shouldCloseOnOverlayClick
    className="lh-modal modal-start-call"
    style={{
      overlay: {
        zIndex: 20000000,
      },
    }}
  >
    <i className="fa fa-close close-icon" onClick={handleModalClose} />
    <h3>{formatMessage(messages.STARTING_CONVERSATION)}</h3>
    <p>{formatMessage(messages.NAME_CUSTOMER)}: {customer.name}</p>
    {sendSMS &&
      <div className="checkbox">
        <label>
          <input type="checkbox" onChange={toggleSMS} checked={smsActive} />
          {formatMessage(messages.SEND_NAME, { phone: customer.deleted || customer.phone })}
        </label>
      </div>
    }
    <p>
      <br />{textSMSNumber}
    </p>
    <button
      style={{ marginTop: 20 }}
      type="submit"
      className="lh-btn lh-btn-primary btn-lg lh-btn-block start-call"
      onClick={handleSuccess}
    >
      {formatMessage(messages.BUTTON_START_CONVERSATION)}
    </button>
  </Modal>);
};

export default injectIntl(StartCallModalComponent);
