import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { resetPassword2, checkToken, setTokenStatus, setPasswordChanged } from 'actions/resetPassword';
import { push } from 'react-router-redux';
import { FORM_ERROR } from 'final-form';

import ResetPasswordForm from 'components/forms/ResetPasswordForm';

const messages = defineMessages({
  NEW_PASSWORD: {
    id: 'ResetPassword.NEW_PASSWORD',
    defaultMessage: 'Neus Passwort',
  },
});

class ResetPassword extends React.PureComponent {
    componentWillMount() {
        this.props.checkToken(this.props.params.userId, this.props.params.token);
    }

    render() {
      const { intl: { formatMessage } } = this.props;
      return (
          <div className="flex">
              <div className="flex-box">
                  <h2>{formatMessage(messages.NEW_PASSWORD)}</h2>
                  <ResetPasswordForm onSubmit={this.props.resetPassword} />
              </div>
          </div>
      );
    }
}

export default connect(null,
  (dispatch, props) => ({
    resetPassword: async formData => {
      const { userId, token } = props.params;
      const data = await resetPassword2(
        userId,
        token,
        formData,
      );
      if (data.response) {
        dispatch(setPasswordChanged(true));
        dispatch(push('/resetPassword/success'));
      } else {
        return { [FORM_ERROR]: data.error[Object.keys(data.error)[0]] };
      }
    },
    checkToken: (userId, token) => checkToken(userId, token).then(
      () => {
        dispatch(setTokenStatus(true));
      },
      () => {
        dispatch(setTokenStatus(false));
        dispatch(push('/resetPassword/tokenError'));
      }
    ),
  })
)(injectIntl(ResetPassword));
