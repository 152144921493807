/* global window */
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { deleteCompany2, companyDeleted } from 'actions/company';

import { Link } from 'react-router';
import NotFound from 'components/NotFound';
import NavBarConfig from 'components/NavBarConfig';
import CompanyDelete from 'components/company/CompanyDelete';
import { FORM_ERROR } from 'final-form';

const messages = defineMessages({
  BACK: {
    id: 'CompanyDeletePage.BACK',
    defaultMessage: 'Zurück',
  },
  DELETE_COMPANY: {
    id: 'CompanyDeletePage.DELETE_COMPANY',
    defaultMessage: 'Unternehmen löschen',
  },
  QUESTION_DELETE_COMPANY: {
    id: 'CompanyDeletePage.QUESTION_DELETE_COMPANY',
    defaultMessage: 'Sind sie sich sicher, dass sie dieses Unternehmen wirklich löschen wollen?',
  },
  CAN_NOT_REVOKED: {
    id: 'CompanyDeletePage.CAN_NOT_REVOKED',
    defaultMessage: 'Diese Aktion kann nicht rückgängig gemacht werden.',
  },
});

function CompanyDeletePage({ user, company, onSubmit, routes, intl: { formatMessage } }) {
  if (!company) { return null; }
  if (!user.is_admin && !user.is_staff) {
    return <NotFound />;
  }
  return (
    <div>
      <NavBarConfig deletePage currentRouteName={routes[routes.length - 1].name} />
      <div className="m40 w-xs">
        <Link to={`/company/${company.id}/`}>{formatMessage(messages.BACK)}</Link>
        <h3>{formatMessage(messages.DELETE_COMPANY)}</h3>
        <p style={{ marginBottom: '15px' }}>
          {formatMessage(messages.QUESTION_DELETE_COMPANY)}
          <br />
          {formatMessage(messages.CAN_NOT_REVOKED)}
        </p>
        <CompanyDelete onSubmit={onSubmit} />
      </div>
    </div>
  );
}

export default connect(null,
  (dispatch, { params }) => ({
    onSubmit: async () => {
      const data = await deleteCompany2(params.companyId);
      if (data.response) {
        dispatch(companyDeleted({ companyId: params.companyId }));
        dispatch(push('/company/'));
        window.location.reload();
      } else {
        return { [FORM_ERROR]: data.error[Object.keys(data.error)[0]] };
      }
    }
  })
)(injectIntl(CompanyDeletePage));
