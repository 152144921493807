import React, { PureComponent } from 'react';

/**

  Calculate real video width and height

*/
export function getRealVideoDimensions(video) {
  const [videoWidth, videoHeight] = [video.videoWidth, video.videoHeight];
  const { width, height } = video.getBoundingClientRect();

    // calculate scale
  const [scaleWidth, scaleHeight] = [width / videoWidth, height / videoHeight];
  const scale = scaleWidth < scaleHeight ? scaleWidth : scaleHeight;

    // calculate real video dimensions
  const [realWidth, realHeight] = [scale * videoWidth, scale * videoHeight];

  return {
    scale,
    width: realWidth,
    height: realHeight,
  };
}


/**
  Affine Transformation from the affine window space to the video space
*/
export function windowToVideo(x, y, video) {
  const { top, left, width, height } = video.getBoundingClientRect();

    // the x,y translation to the top left corner of the bounding rect
  const translation = [left, top];

    // 1. translate the vector to the center of the video
  const center = [translation[0] + (width / 2), translation[1] + (height / 2)];
  let vec = [x - center[0], y - center[1]];

  const real = getRealVideoDimensions(video);

    // divide by width and height as we want the position relative to the
    // video dimensions
  vec = [vec[0] / real.width, vec[1] / real.height];

  return {
    x: vec[0],
    y: vec[1],
  };
}

export default class VideoTouch extends PureComponent {
  getTouchPosition(position) {
    const video = this.props.videoRef.getElementsByTagName('video')[0];

    const { width: cWidth, height: cHeight } = this.props.videoRef.getBoundingClientRect();

        // calculate real video dimensions
    const real = getRealVideoDimensions(video);

    const { x, y } = position;

        // calculate real x, y position
    const [offsetX, offsetY] = [real.width * x, real.height * y];

    const r = 20 * real.scale;

    const left = offsetX - r + (cWidth / 2);
    const top = offsetY - r + (cHeight / 2);

    return {
      position: 'absolute',
      width: 2 * r,
      height: 2 * r,
      borderRadius: r,
      left,
      top,
    };
  }

  render() {
    const { position } = this.props;
    if (!position) {
      return null;
    }
    return (
      <span
        className="touch"
        style={this.getTouchPosition(position)}
      />
    );
  }
}
