import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import { getAuthToken } from 'actions/api';

const messages = defineMessages({
  PDF_EXPORT: {
    id: 'PdfExportButton.PDF_EXPORT',
    defaultMessage: 'PDF-Export',
  },
});

/* global document */
/* global window */
function PdfExportButton({
  customer,
  locale,
  selectedOrderId,
  intl: { formatMessage },
}) {
  const orderPart = selectedOrderId > 0 ? `order/${selectedOrderId}/` : '';
  const pdfLocation = `/api/company/${customer.company_id}/customer/${customer.id}/${orderPart}pdf`;
  const token = `Bearer ${getAuthToken()}`;

  return (
    <button
      onClick={() => {
        const cookieOptions = '; path=/api/; max-age=600';
        document.cookie = `Authorization=${token}${cookieOptions}`;
        document.cookie = `User-Locale=${locale}${cookieOptions}`;
        window.location = pdfLocation;
      }}
      style={{ marginRight: 15 }}
      title={formatMessage(messages.PDF_EXPORT)}
      className="lh-btn lh-btn-default lh-btn-small"
    >
      <i className="fa fa-file-pdf-o" style={{ fontSize: 15 }} /> PDF
    </button>
  );
}

export default connect(state => ({ locale: state.intl.locale }))(
  injectIntl(PdfExportButton)
);
