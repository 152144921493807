import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { setOffline, fetchOfflineFn } from '../actions/offline';

class Offline extends React.Component {
  componentDidMount() {
    fetchOfflineFn(oState => this.props.setOffline(oState))();
  }
  render() {
    const {
      offline,
      room
    } = this.props;

    const member = room.members
      .filter(element => room.self.connectionId !== element.connectionId);
/*
    const emptyAudio = {
      active: false,
      muted: false,
      volume: 0,
      speaking: false,
      requested: false,
    };
*/
    return (
      <div
        className="offline-ui"
      >
        <div
          className={classnames({
            'offline-ui-up': !offline,
            'offline-ui-down': offline,
          })}

        >
          <div className="offline-ui-content" />
        </div>
        {member.map(el => {
          return (
            <div>
              <div
                className={
                  classnames('glyphicon',
                    {
                      'glyphicon-volume-off': !el.audio.active,
                      'glyphicon-volume-up': el.audio.active && !el.audio.muted,
                      'glyphicon-volume-down': el.audio.active && el.audio.muted,
                      //'glyphicon-mic': el.audio.active && el.audio.muted,
                      //'glyphicon-mic_off': el.audio.active && !el.audio.muted
                    }
                    )
                }
              >
                <span className="member-ui-content text-overflow">{el.name}</span>
              </div>
            </div>
         )
        }
        )
        }
      </div>
    );
  }
}

export default connect(state => ({
    offline: state.offline,
    room: state.video.room
  }),
  {
    setOffline
  })(Offline);
