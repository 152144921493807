import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';

//import FormGroup from 'components/form/FormGroup';
//import FormError from 'components/form/FormError';
//import FormSubmit from 'components/form/FormSubmit';
//import TextField from 'components/form/TextField';
import classNames from 'classnames';

const messages = defineMessages({
  PLACEHOLDER_EMAIL_OR_PHONE: {
    id: 'LoginForm.PLACEHOLDER_EMAIL_OR_PHONE',
    defaultMessage: 'E-Mail Adresse oder Telefonnummer',
  },
  PLACEHOLDER_PASSWORD: {
    id: 'LoginForm.PLACEHOLDER_PASSWORD',
    defaultMessage: 'Passwort',
  },
  ERROR_SESSION_EXPIRED: {
    id: 'LoginForm.ERROR_SESSION_EXPIRED',
    defaultMessage: 'Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein.',
  },
  LABEL_LOGIN: {
    id: 'LoginForm.LABEL_LOGIN',
    defaultMessage: 'Einloggen',
  },
  VALIDATION_EMAIL_OR_PHONE: {
    id: 'LoginForm.VALIDATION_EMAIL_OR_PHONE',
    defaultMessage: 'Bitte geben Sie E-Mail Adresse oder Telefonnummer an.',
  },
  VALIDATION_PASSWORD: {
    id: 'LoginForm.VALIDATION_PASSWORD',
    defaultMessage: 'Bitte geben Sie ihr Passwort an.',
  },
});

function LoginForm(props) {
  const {
    reason,
    intl: { formatMessage },
  } = props;

  return (
    <Form
      onSubmit={props.onSubmit}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = formatMessage(messages.VALIDATION_EMAIL_OR_PHONE);
        }

        if (!values.password) {
          errors.password = formatMessage(messages.VALIDATION_PASSWORD);
        }
        return errors;
      }
      }
    >
      {({ submitError, handleSubmit, pristine, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field name="email">
            {({ input, meta }) => {
              return (
                <div className={classNames('form-group', { 'has-error': meta.error && meta.touched })}>
                  <input
                    {...input}
                    id={props.name}
                    className="form-control"
                    tabIndex="1"
                    placeholder={formatMessage(messages.PLACEHOLDER_EMAIL_OR_PHONE)}
                    autoCapitalize="off"
                    autoCorrect="off"
                    autoComplete="true"
                  />
                  {meta.error && meta.touched && <span className="help-block">{meta.error}</span>}
                </div>
              )
            }}
          </Field>
          <Field name="password">
            {({ input, meta }) => {
              return (
                <div className={classNames('form-group', { 'has-error': meta.error && meta.touched })}>
                  <input
                    {...input}
                    type="password"
                    id={props.name}
                    className="form-control"
                    tabIndex="2"
                    placeholder={formatMessage(messages.PLACEHOLDER_PASSWORD)}
                  />
                  {meta.error && meta.touched && <span className="help-block">{meta.error}</span>}
                </div>
              )
            }}
          </Field>
          {(submitError || reason === '401') &&
            <div className={classNames('form-group', { 'has-error': (submitError || reason === '401') })}>
              <div className={classNames({ 'col-sm-8': false, 'col-sm-offset-4': false })}>
                <span className="help-block">{submitError || (
                  reason === '401' ? formatMessage(messages.ERROR_SESSION_EXPIRED) : null
                )}</span>
              </div>
            </div>
          }
          <div
            className={'form-group'}
            style={{ display: 'block' }}
          >
              <button
                type="submit"
                className={classNames('btn', 'lh-btn', 'lh-btn-primary', 'lh-btn-block')}
                disabled={submitting}
                tabIndex="3"
              >
                {formatMessage(messages.LABEL_LOGIN)}
              </button>
          </div>
        </form>
        )
      }
    </Form>
  );
}
/*
          <FormGroup field={email}>
            <TextField
              tabIndex="1"
              placeholder={formatMessage(messages.PLACEHOLDER_EMAIL_OR_PHONE)}
              autoCapitalize="off"
              autoCorrect="off"
              autoComplete
              {...email}
            />
          </FormGroup>
          <FormGroup field={password}>
            <TextField
              tabIndex="2"
              type="password"
              placeholder={formatMessage(messages.PLACEHOLDER_PASSWORD)}
              {...password}
            />
          </FormGroup>
          <FormError
            error={error || (
              reason === '401' ? formatMessage(messages.ERROR_SESSION_EXPIRED) : null
            )}
          />
          <FormSubmit
            btnClass="lh-btn lh-btn-primary lh-btn-block"
            style={{ display: 'block' }}
            btnProps={{ tabIndex: 3 }}
            submitting={submitting}
            tabIndex="3"
            label={formatMessage(messages.LABEL_LOGIN)}
          />

 */
/*
const LoginFormReduxForm = reduxForm({
  form: 'login',
  fields: ['email', 'password'],
  validate: (values, { intl: { formatMessage } }) => ({
    email: values.email ? null : formatMessage(messages.VALIDATION_EMAIL_OR_PHONE),
    password: values.password ? null : formatMessage(messages.VALIDATION_PASSWORD),
  }),
   touchOnBlur: false,
})(LoginForm);
*/

export default injectIntl(LoginForm);
