import React from 'react';
import Highlight from 'react-highlighter';
import { trunc } from '../../../helpers/index';
import Capitals from './Capitals';

export default function CustomerEntry({ customer, filter }) {
  return (
    <div className="customer-single-element">
      <div className="customer-single-element-capitals">
        <Capitals customer={customer} />
      </div>
      <div className="customer-single-element-right">
        {customer.name ? (
          <h4 className="customer-sub-title">
            <Highlight search={filter}>{trunc(customer.name, 20, true)}</Highlight>
          </h4>
        ) : null}
        <div className="customer-list-row-text-small" title={customer.deleted || customer.phone}><Highlight search={filter}>{customer.deleted || customer.phone}</Highlight></div>
      </div>
    </div>
  );
}
