import React from 'react';

export default function CheckboxFiled({ label, bold, ...field }) {
    return (
        <div className="checkbox">
            <label style={{ fontWeight: bold ? 'bold' : undefined }}>
                <input type="checkbox" {...field} />
                {label}
            </label>
        </div>
    );
}
