import { take, put, call, fork, select } from 'redux-saga/effects';

// eslint-disable-next-line
import OT from 'opentok';

import api from './actions/api';
import {
  COMPANY_LIST,
  COMPANY_SINGLE,
  COMPANY_USER_LIST,
  COMPANY_USER_SINGLE,
  CUSTOMER_LIST,
  CUSTOMER_SINGLE,
  EVENT_LIST,
  ACTIVE_CALL,
  ORDER_LIST,
  HEART_BEAT,
  CALL_CREATED,
  CALL_RECREATED,
  REMOTE_VIDEO_ACTIVATED
} from './actions/constants';
import {
  companyList,
  companySingle,
  companyMembership,
  companyUserList,
  companyUserSingle,
  customerList,
  customerSingle,
  orderList,
  eventList,
  eventCreated,
  // openCallEndModal,
} from './actions/company';

import {
  watchInitMediaDevices,
  watchSelectAudioDevice,
  watchSelectVideoDevice,
  watchDestroyMediaDevices,
} from './actions/mediaDevices';

import {
  createCallBegin,
  // createCallEnd,
  callBeginCreated,
  // callCreated,
  activeCallGet,
} from './actions/video';

import {
  startCall,
  reConnectStartCall,
} from './actions/ot_signaling';

export const beatHeart = (companyId, customerId, callId) => api.put({
  url: `/api/company/${companyId}/customer/${customerId}/call/${callId}/heartbeat`,
});

function apiCall(method, options) {
  return () => api[method](options)
    .then(response => ({ response }), error => ({ error }));
}


function* fetchEntity(entity, method, options) {
  yield put(entity.request());
  const { response, error } = yield call(apiCall(method, options));
  if (response) {
    yield put(entity.success(response));
  } else {
    yield put(entity.failure(error));
  }
}

const fetchCompanyList = () => fetchEntity.bind(null,
    companyList,
    'get',
    { url: '/api/company/' }
);
const fetchCompanySingle = companyId => fetchEntity.bind(null,
    companySingle,
    'get',
    { url: `/api/company/${companyId}/` }
);
const fetchCompanyMembership = companyId => fetchEntity.bind(null,
    companyMembership,
    'get',
    { url: `/api/company/${companyId}/membership/` }
);
const fetchCompanyUserList = companyId => fetchEntity.bind(null,
    companyUserList,
    'get',
    { url: `/api/company/${companyId}/user/` }
);
const fetchCompanyUserSingle = (companyId, userId) => fetchEntity.bind(null,
    companyUserSingle,
    'get',
    { url: `/api/company/${companyId}/user/${userId}/` }
);
const fetchCustomerList = companyId => fetchEntity.bind(null,
    customerList,
    'get',
    { url: `/api/company/${companyId}/customer/` }
);
const fetchCustomerSingle = (companyId, customerId) => fetchEntity.bind(null,
    customerSingle,
    'get',
    { url: `/api/company/${companyId}/customer/${customerId}/` }
);
const fetchOrderList = (companyId, customerId) => fetchEntity.bind(null,
    orderList,
    'get',
    { url: `/api/company/${companyId}/customer/${customerId}/order` }
);
const fetchEventList = (companyId, customerId) => fetchEntity.bind(null,
    eventList,
    'get',
    { url: `/api/company/${companyId}/customer/${customerId}/event/` }
);
const fetchActiveCall = (companyId, customerId) => fetchEntity.bind(null,
    activeCallGet,
    'get',
    { url: `/api/company/${companyId}/customer/${customerId}/call/active` }
);

function* loadCompanyList() {
  yield call(fetchCompanyList());
}

function* loadCompanySingle(companyId) {
  yield call(fetchCompanySingle(companyId));
}
function* loadCompanyMembership(companyId) {
  yield call(fetchCompanyMembership(companyId));
}

function* loadCompanyUserList(companyId) {
  yield call(fetchCompanyUserList(companyId));
}

function* loadCompanyUserSingle(companyId, userId) {
  yield call(fetchCompanyUserSingle(companyId, userId));
}

function* loadCustomerList(companyId) {
  yield call(fetchCustomerList(companyId));
}

function* loadCustomerSingle(companyId, customerId) {
  yield call(fetchCustomerSingle(companyId, customerId));
}

function* loadOrderList(companyId, customerId) {
  yield call(fetchOrderList(companyId, customerId));
}

function* loadActiveCall(companyId, customerId) {
  yield call(fetchActiveCall(companyId, customerId));
}

function* loadEventList(companyId, customerId) {
  yield call(fetchEventList(companyId, customerId));
}


function getCallData(state) {
  const { video: { connection: { connected } } } = state;
  const callId = state.video.activeCall.call && state.video.activeCall.call.id;
  return { connected, id: callId };
}

function getVideoSessionData(state) {
  const {
    customerSingle: { customer },
    auth: { user },
    companySingle: { company },
  } = state;
  return {
    roomName: customer.slug,
    nick: user.name,
    companyId: company.id,
    customerId: customer.id,
  };
}

function getCall(state) {
  const { video } = state;
  return { activeCall: video.activeCall.call };
}

/** ************************************************************************ **/
/** ***************************** WATCHERS ********************************* **/
/** ************************************************************************ **/
const TRUE = true;

function* watchLoadCompanyList() {
  while (TRUE) {
    yield take(COMPANY_LIST.LOAD);
    yield fork(loadCompanyList);
  }
}
function* watchLoadCompanySingle() {
  while (TRUE) {
    const { payload: companyId } = yield take(COMPANY_SINGLE.LOAD);
    yield fork(loadCompanySingle, companyId);
    yield fork(loadCompanyMembership, companyId);
  }
}
function* watchLoadCompanyUserList() {
  while (TRUE) {
    const { payload: companyId } = yield take(COMPANY_USER_LIST.LOAD);
    yield fork(loadCompanyUserList, companyId);
  }
}
function* watchLoadCompanyUserSingle() {
  while (TRUE) {
    const { payload: { companyId, userId } } = yield take(COMPANY_USER_SINGLE.LOAD);
    yield fork(loadCompanyUserSingle, companyId, userId);
  }
}
function* watchLoadCustomerList() {
  while (TRUE) {
    const { payload: companyId } = yield take(CUSTOMER_LIST.LOAD);
    yield fork(loadCustomerList, companyId);
  }
}
function* watchLoadCustomerSingle() {
  while (TRUE) {
    const { payload: { companyId, customerId } } = yield take(CUSTOMER_SINGLE.LOAD);
    yield fork(loadCustomerSingle, companyId, customerId);
  }
}
function* watchLoadOrderList() {
  while (TRUE) {
    const { payload: { companyId, customerId } } = yield take(ORDER_LIST.LOAD);
    yield fork(loadOrderList, companyId, customerId);
  }
}
function* watchActiveCall() {
  while (TRUE) {
    const { payload: { companyId, customerId } } = yield take(ACTIVE_CALL.LOAD);
    yield fork(loadActiveCall, companyId, customerId);
  }
}
function* watchLoadEventList() {
  while (TRUE) {
    const { payload: { companyId, customerId } } = yield take(EVENT_LIST.LOAD);
    yield fork(loadEventList, companyId, customerId);
  }
}

function* watchConnectionConnected() {
  while (TRUE) {
    const { payload: { companyId, customerId } } = yield take(REMOTE_VIDEO_ACTIVATED);
    const ret = yield select(getCall);
    if (ret.activeCall && !ret.activeCall.call_begin) {
      const res = yield call(createCallBegin, companyId, customerId, ret.activeCall.id);
      yield put(eventCreated(res));
      yield put(callBeginCreated(res));
    }
  }
}

function* watchHeartBeat() {
  while (TRUE) {
    const { payload: { companyId, customerId } } = yield take(HEART_BEAT);
    const { connected, id } = yield select(getCallData);
    if (connected && id && companyId && customerId) {
      yield call(beatHeart, companyId, customerId, id);
    }
  }
}
function* watchCallCreated() {
  if (OT.checkSystemRequirements() !== 0) {
    while (TRUE) {
      yield take(CALL_CREATED);
      const videoSessionData = yield select(getVideoSessionData);
      //console.log('Sarga watchCallCreated %o', videoSessionData);
      yield put(startCall(videoSessionData));
    }
  }
}
function* watchCallLoaded() {
  if (OT.checkSystemRequirements() !== 0) {
    while (TRUE) {
      yield take(ACTIVE_CALL.SUCCESS);
      const videoSessionData = yield select(getVideoSessionData);
      //console.log('Sarga watchCallLoaded %o', videoSessionData);
      yield put(startCall(videoSessionData));
    }
  }
}

function* watchCallReCreated() {
  if (OT.checkSystemRequirements() !== 0) {
    while (TRUE) {
      yield take(CALL_RECREATED);
      const videoSessionData = yield select(getVideoSessionData);
      yield put(reConnectStartCall(videoSessionData));
    }
  }
}

export default function* root() {
  yield [
    fork(watchLoadCompanyList),
    fork(watchLoadCompanySingle),
    fork(watchLoadCompanyUserList),
    fork(watchLoadCompanyUserSingle),
    fork(watchLoadCustomerList),
    fork(watchLoadCustomerSingle),
    fork(watchLoadEventList),
    fork(watchLoadOrderList),

    fork(watchActiveCall),

    fork(watchInitMediaDevices),
    fork(watchSelectAudioDevice),
    fork(watchSelectVideoDevice),
    fork(watchDestroyMediaDevices),

    fork(watchConnectionConnected),
    fork(watchHeartBeat),

    fork(watchCallCreated),
    fork(watchCallLoaded),
    fork(watchCallReCreated),
  ];
}
