// eslint-disable-next-line
import OT from 'opentok';

import { createAction } from 'redux-actions';
import Raven from 'raven-js';

import { calcAudioLevel } from '../helpers/index';
import log from '../helpers/log';
import { post } from './api';
import { HEART_BEAT } from './constants';

import { orderList } from './company';

import {
  connectionConnected,
  connectionDisconnected,
  connectionPublishError,
  connectionSubscribeError,
  streamCreated,
  remoteConnectionConnected,
  remoteConnectionDisconnected,
  connectionInit,
  connectionDestroy,
  videoActivated,
  videoDeactivated,
  videoPaused,
  videoResumed,
  remoteVideoActivated,
  remoteVideoDeactivated,
  remoteVideoPaused,
  remoteVideoResumed,
  videoDimensionsSet,
  touched,
  remoteTouched,
  setCallRunning,
  callReCreated
} from './video';

import {
  audioActivated,
  audioDeactivated,
  audioMuted,
  audioUnmuted,
  audioVolume,
  audioRequested,
  audioAccepted,
  audioDeclined,
  remoteAudioActivated,
  remoteAudioDeactivated,
  remoteAudioMuted,
  remoteAudioUnmuted,
  remoteAudioVolume,
  remoteAudioRequested,
  remoteAudioAccepted,
  remoteAudioDeclined,
} from './audio';

import {
  screenshotRequested,
  screenshotTaken,
  screenshotUploaded,
  screenshotFailed,
  screenshotResetWithTimeout,
  remoteScreenshotRequested,
  remoteScreenshotTaken,
  remoteScreenshotUploaded,
  remoteScreenshotFailed,
  takePublisherScreenshot,
} from './screenshot';
import { act } from 'react-dom/test-utils';

OT.setLogLevel(OT.LOG);
OT.setLogLevel(OT.DEBUG);

const heartBeat = createAction(HEART_BEAT);

let publisher = null;
// let subscription = null;
const subscriptions = [];
let heartbeatInterval = null;
// const session = null;
let activeSession = null;

/* export function getSubscription() {
  return subscription;
} */

export function getSubscriptions() {
  return subscriptions;
}


export function getPublisher() {
  return publisher;
}

export function endCall() {
  return () => {
    log('end call activeSession %o', activeSession);
    //log('end call session %o, activeSession %o', session, activeSession);
    /*
    if (session && session.currentState !== 'disconnected') {
      log('session disconnected ');
      session.disconnect();
    }*/
    if (activeSession && activeSession.currentState !== 'disconnected') {
      log('active session disconnected. subscriptions %o', subscriptions);
      subscriptions.forEach(sub => {
        activeSession.unsubscribe(sub.subscription);
        sub.subscription.off();
      });

      while (subscriptions.length) {
        subscriptions.pop();
      }
      // activeSession.off();
      activeSession.disconnect();
      // activeSession = null;
      log('active session disconnected. subscriptions %o', subscriptions);
    }
  };
}
export function mute() {
  return () => publisher.publishAudio(false);
}
export function unmute() {
  return () => publisher.publishAudio(true);
}
export function pauseVideo() {
  return () => publisher.publishVideo(false);
}
export function resumeVideo() {
  return () => publisher.publishVideo(true);
}

export function sendSignal(type, data = '') {
  if (activeSession) {
    // const cb
    return activeSession.signal({ type, data });
  }
}

export function touch(data) {
  return () => sendSignal('touch', data);
}

export function requestAudio() {
  return () => sendSignal('audio-requested');
}

export function disableAudio() {
  return () => sendSignal('audio-disabled');
}

export function unsubscribe() {
  return new Promise(resolve => {
    if (!activeSession || !subscriptions) {
      log('unsubscribe %o subscription %o', activeSession, subscriptions);
      resolve();
      return;
    }
    requestAnimationFrame(() => {
      log('requestAnimationFrame %o subscription %o', activeSession, subscriptions);
      subscriptions.forEach(sub => {
        activeSession.unsubscribe(sub.subscription);
      })

      resolve();
    });
  });
}

/* global requestAnimationFrame */
let timeout = null;
function touchedTimeout(touchPosition) {
  return dispatch => {
    dispatch(touched(touchPosition));
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => {
      dispatch(touched(null));
    }, 2000);
  };
}

function remoteTouchedTimeout(touchPosition) {
  return dispatch => {
    dispatch(remoteTouched(touchPosition));
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => {
      dispatch(remoteTouched(null));
    }, 2000);
  };
}

function getPublisherOTConfig({
  name,
  publishAudio = false,
  publishVideo = false,
  audioDeviceId = 'default',
  videoDeviceId = 'default',
}) {
  const config = {
    name,
    publishAudio,
    publishVideo,
    insertDefaultUI: name !== 'subscriber-voice-stream',
    insertMode: 'append',
    fitMode: 'contain',
    showControls: false,
    height: '100%',
    width: '100%',
    mirror: false,
    frameRate: 15,
   };

  config.audioSource = audioDeviceId;
  config.resolution = '1280x720';
  config.videoSource = videoDeviceId;

  return config;
}

function initializePublisher(localSession, stream, constraints, dispatch) {
  let audio = null;
  let video = null;
  try {
    // eslint-disable-next-line prefer-destructuring
    audio = stream.getAudioTracks()[0];
    // eslint-disable-next-line prefer-destructuring
    video = stream.getVideoTracks()[0];
  } catch (e) {
    log('Could not getting Track from stream %o', e);
  }

  const config = {
    name: constraints.name,
    publishAudio: constraints.publishAudio,
    publishVideo: constraints.publishVideo,
    insertDefaultUI: constraints.insertDefaultUI,
    insertMode: constraints.insertMode,
    fitMode: constraints.fitMode,
    showControls: constraints.showControls,
    height: constraints.height,
    width: constraints.width,
    mirror: constraints.mirror,
    disableAudioProcessing: false,
    enableStereo: true
  }

  if (audio) {
    config.audioSource = audio;
    log('Using audio %o', audio);
  } else if (constraints.audioDeviceId) {
    log('Using DeviceID instead of MediaTrack for audio %o', constraints.audioDeviceId);
    config.audioSource = constraints.audioDeviceId;
  } else {
    config.audioSource = false;
  }

  if (video) {
    //Apple Hack for Bug in Safari 17.4
    if(video.muted){
      log('REENABLE MUTE %o', video);
      config.videoSource = constraints.videoSource;
    }else{
      log('Using video %o', video);
      config.videoSource = video;
    }
  } else if (constraints.videoDeviceId) {
    log('Using DeviceID instead of MediaTrack for video %o', constraints.videoDeviceId);
    config.videoSource = constraints.videoDeviceId;
  } else {
    config.videoSource = false;
  }

  const publisher = OT.initPublisher(
      config.name !== 'subscriber-voice-stream' ? 'opentok-container' : undefined,
      config,
      publishError => {
        if (publishError) {
          log(publishError.name);
          Raven.captureMessage(JSON.stringify(publishError), { level: 'info' });
          dispatch(connectionPublishError(publishError.name));
        }
      }
    );

    publisher.on('streamCreated', streamEvent => {
      log('publisherStreamCreated %o', streamEvent);

      dispatch(streamCreated(
        {
          fromConnectionId: streamEvent.stream.connection.connectionId,
          stream: {
            streamId: streamEvent.stream.streamId,
            hasVideo: streamEvent.stream.hasVideo,
            hasAudio: streamEvent.stream.hasAudio,
          }
        }
        ));

      if (streamEvent.stream && streamEvent.stream.videoDimensions) {
        dispatch(videoDimensionsSet(streamEvent.stream.videoDimensions));
      }
      if (streamEvent.stream.hasAudio) {
        dispatch(audioActivated(
          {
            fromConnectionId: streamEvent.stream.connection.connectionId,
            stream: {
              streamId: streamEvent.stream.streamId,
              hasVideo: streamEvent.stream.hasVideo,
              hasAudio: streamEvent.stream.hasAudio,
            }
          }
        ));
      }
      if (streamEvent.stream.hasVideo) {
        /*
        let videoSource = streamEvent.stream.publisher.getVideoSource();
        log('TEST Video Source %o', videoSource);
        if(videoSource.track.muted){
          log('TEST REENABLE MUTE %o', videoSource);
          videoSource.track.enabled = false;
          videoSource.track.enabled = true;
          log('TEST REENABLE MUTE %o', videoSource);          
        }*/
    
        dispatch(videoActivated());
      }
    });

    publisher.on('streamDestroyed', streamEvent => {
      log('publisherStreamDestroyed %o', streamEvent);
      if (streamEvent.stream && streamEvent.stream.hasAudio) {
        dispatch(audioDeactivated(
          {
            fromConnectionId: streamEvent.stream.connection.connectionId,
            stream: {
              streamId: streamEvent.stream.streamId,
              hasVideo: streamEvent.stream.hasVideo,
              hasAudio: streamEvent.stream.hasAudio,
            }
          }
        ));
        dispatch(remoteAudioDeactivated(
          {
            fromConnectionId: streamEvent.stream.connection.connectionId,
            stream: {
              streamId: streamEvent.stream.streamId,
              hasVideo: streamEvent.stream.hasVideo,
              hasAudio: streamEvent.stream.hasAudio,
            }
          }
        ));
      }
      if (streamEvent.stream && streamEvent.stream.hasVideo) {
        dispatch(videoDeactivated());
      }
    });

    publisher.on('destroyed', streamEvent => {
      log('publisherDestroyed %o', streamEvent);
      dispatch(audioDeactivated(
        {
          stream: {
            streamId: streamEvent.target.streamId,
            hasVideo: false,
            hasAudio: false,
          }
        }
      ));
      dispatch(remoteAudioDeactivated(
        {
          stream: {
            streamId: streamEvent.target.streamId,
            hasVideo: false,
            hasAudio: false,
          }
        }
      ));
      dispatch(videoDeactivated());
    });
    publisher.on('mediaStopped', streamEvent => {
      log('publisherMediaStopped %o', streamEvent);
    });
    publisher.on('videoDimensionsChanged', streamEvent => {
      log('publisherVideoDimensionsChanged %o', streamEvent);
      if (streamEvent.stream && streamEvent.stream.videoDimensions) {
        dispatch(videoDimensionsSet(streamEvent.stream.videoDimensions));
      }
    });
    publisher.on('videoElementCreated', streamEvent => {
      log('publisherVideoElementCreated %o', streamEvent);
    });    
    publisher.on('audioLevelUpdated', event => {
      // log('audioLevelUpdated %o', event);
      // dispatch(audioVolume(calcAudioLevel(event.audioLevel)));
    });

    localSession.publish(publisher, publishError => {
    if (publishError) {
        log(`Error publishing: ${publishError}`);
        Raven.captureMessage(JSON.stringify(publishError), { level: 'info' });
        dispatch(connectionPublishError(publishError.name));
      } else {
        log('Publishing a stream.');
      }
    });

    return publisher;
}

export function publish(config) {
  const { name, stream } = config;

  return dispatch => {
    const constraints = getPublisherOTConfig(config);
    
    // Initialize streams
    if (!stream) {
      const configGetUserMedia = {
        audioSource: constraints.audioSource,
        videoSource: constraints.videoSource,
        resolution: constraints.resolution
      }
      OT.getUserMedia(configGetUserMedia)
        .then(stream => {
            if (stream) {
              publisher = initializePublisher(activeSession, stream, constraints, dispatch);
            } else {
              return { stream: null, error: 'Stream could not generated' };
            }
            return { stream, error: null };
          },
          error => {
            log(error);
            return { stream: null, error };
          })
    } else {

      publisher = initializePublisher(activeSession, stream, constraints, dispatch);
      return { stream, error: null };
    }
  };
}

export function unpublish() {
  return new Promise(resolve => {
    if (!activeSession ||!publisher) {
      resolve();
      return;
    }
    publisher.once('destroyed', () => requestAnimationFrame(() => {
        publisher.off();
        publisher = null;
        resolve();
      }));
    requestAnimationFrame(() => activeSession.unpublish(publisher));
  });
}

export function restartStream(publisherConfig) {
  return (dispatch, getState) => {
    const audioActive = getState().audio.self.active;

    unpublish().then(() => {
      dispatch(publish(publisherConfig));
      if (audioActive) {
        sendSignal('audio-accepted'); // eslint-disable-line no-use-before-define
      }
    });
  };
}

function subscribe(stream) {
  return dispatch => {
    const conf = {
      insertDefaultUI: stream.name !== 'subscriber-voice-stream',
      fitMode: 'contain',
      showControls: false,
      height: '100%',
      width: '100%',
      insertMode: 'append',
    };

    let sub = activeSession.subscribe(
      stream,
      stream.name !== 'subscriber-voice-stream'
        ? 'opentok-container'
        : undefined,
      conf,
      subscribeError => {
        if (subscribeError) {
          log(`Error subscribing: ${subscribeError}`);
          Raven.captureMessage(JSON.stringify(subscribeError), {
            level: 'info',
          });
          dispatch(connectionSubscribeError(subscribeError.name));
        }
      }
    );
    sub.on('destroyed', event => {
      log('subscriberDestroyed %o', event);
      if (sub) {
        log(sub);
/*
        const index = subscriptions.findIndex(sub => sub.connectionId === connectionId);
        if (index > -1) {
          log('delete subscription %o ', subscriptions[index]);
          const localSubscription = subscriptions[index];
          if (localSubscription.sub) {
            activeSession.unsubscribe(localSubscription.subscription);
            localSubscription.subscription.off();
          }
          subscriptions.splice(index, 1);
        }
*/
        sub.off();
        sub = null;
        dispatch(videoDimensionsSet({}));
      }
    });
    sub.on('connected', event => {
      log('subscriberConnected %o', event);
    });
    sub.on('disconnected', event => {
      log('subscriberDisconnected %o', event);
      /*
      dispatch(remoteConnectionDisconnected({
        name: data.name,
        connectionId: event.connection.connectionId,
        type: data.name !== 'Endkunde' ? 'subscriber' : 'publisher'
      }))
       */
      // dispatch(remoteConnectionDisconnected());
    });
    sub.on('audioLevelUpdated', event => {
      // log('subscription audioLevelUpdated %o', event);
      // dispatch(remoteAudioVolume(calcAudioLevel(event.audioLevel)));
    });
    sub.on('videoEnabled', event => {
      log('subscriberVideoEnabled %o', event);
    });
    sub.on('videoDisabled', event => {
      log('subscriberVideoDisabled %o', event);
    });

    subscriptions.push({
      streamId: stream.streamId,
      connectionId: stream.connection.connectionId,
      subscription: sub
    });
  };
}

function createSession(dispatch, sessionConfig, settings, usingIOS = false) {
  log('1. createSession started. usingiOS is : %o, %o, %o, %o, %o', usingIOS, /*session,*/ activeSession, sessionConfig);

  const {
    audioDeviceId,
    videoDeviceId,
    companyId,
    customerId, // <-- companyId, customerId only used by Servicegeber
    nick, // <-- only used by Servicegeber
    roomName, // <-- roomName only used by Endkunde
  } = sessionConfig;

  const apiKey = settings.api_key;
  const sessionId = settings.session_id;

  const { token } = settings;
  const tokenToUse = token;

  if (activeSession) {
    log('Session will be off %o', activeSession);
    activeSession.off();
    activeSession = null;
  }

  activeSession = OT.initSession(apiKey, sessionId);

  log('2. new active session : %o', activeSession);

  activeSession.on('connectionCreated', connectionEvent => {
    log('connectionCreated: %o activeSession %o', connectionEvent, activeSession);
    if (
      connectionEvent.connection.connectionId
      === activeSession.connection.connectionId
    ) {
      dispatch(connectionConnected({
        name: nick || 'Endkunde',
        connectionId: activeSession.connection.connectionId,
        type: nick ? 'subscriber' : 'publisher'
      }));
    } else {
      log(connectionEvent.connection.data);
      const data = JSON.parse(connectionEvent.connection.data);
      dispatch(remoteConnectionConnected({
        name: data.name,
        connectionId: connectionEvent.connection.connectionId,
        type: data.name !== 'Endkunde' ? 'subscriber' : 'publisher'
      }));
    }
  });
  activeSession.on('connectionDestroyed', connectionEvent => {
    log('connectionDestroyed: %o', connectionEvent);
    log(activeSession);
    if (
      connectionEvent.connection.connectionId
      === activeSession.connection.connectionId
    ) {
      log('Connection is will be disconnected');
      dispatch(connectionDisconnected());
    } else {
      const data = JSON.parse(connectionEvent.connection.data);
      log('Remote Connection is will be disconnected %o', {
        name: data.name,
        connectionId: connectionEvent.connection.connectionId,
        type: data.name !== 'Endkunde' ? 'subscriber' : 'publisher'
      });
      dispatch(remoteConnectionDisconnected({
        name: data.name,
        connectionId: connectionEvent.connection.connectionId,
        type: data.name !== 'Endkunde' ? 'subscriber' : 'publisher'
      }));
    }
  });
  activeSession.on('sessionConnected', sessionConnectEvent => {
    log('sessionConnected: %o', sessionConnectEvent);
    dispatch(connectionInit());
  });
  activeSession.on('sessionDisconnected', sessionDisconnectEvent => {
    log('sessionDisconnected: %o activeSession %o', sessionDisconnectEvent, activeSession);
    if (activeSession
        && activeSession.currentState !== 'disconnected') {
      activeSession.off();
    }

    if (heartbeatInterval) {
      clearInterval(heartbeatInterval);
    }
    dispatch(connectionDestroy({ companyId, customerId }));
  });
  activeSession.on('sessionReconnected', sessionReconnectedEvent => {
    log('sessionReconnected: %o', sessionReconnectedEvent);
  });
  activeSession.on('sessionReconnecting', sessionReconnectingEvent => {
    log('sessionReconnecting: %o', sessionReconnectingEvent);
  });
  activeSession.on('streamCreated', streamEvent => {
    // A new stream, published by another client, has been created on this session. For streams
    // published by your own client, the Publisher object dispatches a streamCreated event.
    log('streamCreated %o', streamEvent);

    const connectionId = streamEvent.stream.connection.connectionId;
    const index = subscriptions.findIndex(sub => sub.connectionId === connectionId);
    if (index > -1) {
      log('delete subscription %o ', subscriptions[index]);
      const localSubscription = subscriptions[index];
      if (localSubscription.sub) {
        activeSession.unsubscribe(localSubscription.sub);
        localSubscription.sub.off();
      }
      subscriptions.splice(index, 1);
    }
/*
    if (subscription) {
      activeSession.unsubscribe(subscription);
      subscription.off();
    }
*/
    dispatch(streamCreated(
      {
        fromConnectionId: streamEvent.stream.connection.connectionId,
        stream: {
          streamId: streamEvent.stream.streamId,
          hasVideo: streamEvent.stream.hasVideo,
          hasAudio: streamEvent.stream.hasAudio,
        }
      }
    ));

    if (streamEvent.stream.hasAudio) {
      dispatch(remoteAudioActivated(
        {
          fromConnectionId: streamEvent.stream.connection.connectionId,
        }
      ));
      dispatch(remoteAudioUnmuted(
        {
          fromConnectionId: streamEvent.stream.connection.connectionId,
        }
      ));
    }
    if (streamEvent.stream.hasVideo) {
      dispatch(remoteVideoActivated(
        {
          companyId,
          customerId
        }
      ));
    }
    if (streamEvent.stream.videoDimensions) {
      dispatch(videoDimensionsSet(streamEvent.stream.videoDimensions));
    }
    dispatch(subscribe(streamEvent.stream));
  });
  activeSession.on('streamDestroyed', streamEvent => {
    log('subscriberStreamDestroyed %o', streamEvent);
    if (streamEvent.stream.hasAudio) {
      dispatch(remoteAudioDeactivated(
        {
          fromConnectionId: streamEvent.stream.connection.connectionId,
        }
      ));
      dispatch(audioDeactivated());

      const connectionId = streamEvent.stream.connection.connectionId;
      const index = subscriptions.findIndex(sub => sub.connectionId === connectionId);
      if (index > -1) {
        log('delete subscription %o ', subscriptions[index]);
        const localSubscription = subscriptions[index];
        if (localSubscription.sub) {
          activeSession.unsubscribe(localSubscription.sub);
          localSubscription.sub.off();
        }
        subscriptions.splice(index, 1);
      }
    }
    if (streamEvent.stream.hasVideo) {
      dispatch(remoteVideoDeactivated(
        {
          fromConnectionId: streamEvent.stream.connection.connectionId,
        }
      ));
    }
  });
  activeSession.on('streamPropertyChanged', e => {
    log('streamPropertyChanged %o', e);
    if (e.stream.connection.connectionId === e.target.connection.connectionId) {
      if (e.changedProperty === 'hasVideo') {
        if (e.oldValue === true && e.newValue === false) {
          dispatch(videoPaused());
        }
        if (e.oldValue === false && e.newValue === true) {
          dispatch(videoResumed());
        }
      }
      if (e.changedProperty === 'hasAudio') {
        if (e.oldValue === true && e.newValue === false) {
          dispatch(audioMuted(
            {
              fromConnectionId: e.stream.connection.connectionId,
              stream: {
                streamId: e.stream.streamId,
                hasVideo: e.stream.hasVideo,
                hasAudio: e.stream.hasAudio,
              }
            }
          ));
        }
        if (e.oldValue === false && e.newValue === true) {
          dispatch(audioUnmuted(
            {
              fromConnectionId: e.stream.connection.connectionId,
              stream: {
                streamId: e.stream.streamId,
                hasVideo: e.stream.hasVideo,
                hasAudio: e.stream.hasAudio,
              }
            }
          ));
        }
      }
    } else {
      if (e.changedProperty === 'videoDimensions') {
        dispatch(
          videoDimensionsSet({
            width: e.newValue.width,
            height: e.newValue.height,
          })
        );
      }
      if (e.changedProperty === 'hasVideo') {
        if (e.oldValue === true && e.newValue === false) {
          dispatch(remoteVideoPaused());
        }
        if (e.oldValue === false && e.newValue === true) {
          dispatch(remoteVideoResumed());
          dispatch(remoteVideoActivated({ companyId, customerId }));
        }
      }
      if (e.changedProperty === 'hasAudio') {
        if (e.oldValue === true && e.newValue === false) {
          dispatch(remoteAudioMuted(
            {
              fromConnectionId: e.stream.connection.connectionId,
              stream: {
                streamId: e.stream.streamId,
                hasVideo: e.stream.hasVideo,
                hasAudio: e.stream.hasAudio,
              }
            }
          ));
        }
        if (e.oldValue === false && e.newValue === true) {
          dispatch(remoteAudioUnmuted(
            {
              fromConnectionId: e.stream.connection.connectionId,
              stream: {
                streamId: e.stream.streamId,
                hasVideo: e.stream.hasVideo,
                hasAudio: e.stream.hasAudio,
              }
            }
          ));
        }
      }
    }
  });
  activeSession.on('signal:touch', e => {
    log('signal:touch %o', e);
    try {
      const data = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;
      if (e.from.connectionId === e.target.connection.connectionId) {
        dispatch(touchedTimeout(data));
      } else {
        dispatch(remoteTouchedTimeout(data));
      }
    } catch (error) {
      log(error); // eslint-disable-line no-console
    }
  });
  activeSession.on('signal:audio-requested', e => {
    log('signal:audio-requested %o', e);
    if (e.from.connectionId === e.target.connection.connectionId) {
      dispatch(audioRequested(
        {
          fromConnectionId: e.from.connectionId,
          toConnectionId: e.target.connection.connectionId
        }
      ));
    } else {
      dispatch(remoteAudioRequested(
        {
          fromConnectionId: e.from.connectionId,
          toConnectionId: e.target.connection.connectionId
        }
      ));
    }
  });
  activeSession.on('signal:audio-accepted', e => {
    log('signal:audio-accepted %o', e);
    if (e.from.connectionId === e.target.connection.connectionId) {
      log('publischer audio on');
      dispatch(audioAccepted({
        fromConnectionId: e.from.connectionId,
        toConnectionId: e.target.connection.connectionId
      }));
      publisher.publishAudio(true);
    } else {
      const config = {
        name: 'subscriber-voice-stream',
        audioSource: true,
        videoSource: false,
        publishAudio: true,
        publishVideo: false,
        videoDeviceId: null,
        audioDeviceId: 'default'
      };

      OT.getUserMedia(config)
        .then(stream => {
          const constraints = getPublisherOTConfig(config);
          publisher = initializePublisher(activeSession, stream, constraints, dispatch);

          dispatch(remoteAudioAccepted(
            {
              fromConnectionId: e.from.connectionId,
              toConnectionId: e.target.connection.connectionId
            }
            ));
          log(' Check signal:audio-accepted .. GetUserMedia');
          log(stream);
          }, error => {
            log(' Error :audio-accepted .. GetUserMedia');
            log(error);
        });
    }
  });
  activeSession.on('signal:audio-declined', e => {
    log('signal:audio-declined %o', e);
    if (e.from.connectionId === e.target.connection.connectionId) {
      dispatch(audioDeclined({
          fromConnectionId: e.from.connectionId,
          toConnectionId: e.target.connection.connectionId
      }));
    } else {
      dispatch(remoteAudioDeclined(
        {
          fromConnectionId: e.from.connectionId,
          toConnectionId: e.target.connection.connectionId
        }
      ));
    }
  });
  activeSession.on('signal:audio-disabled', e => {
    log('signal:audio-disabled %o', e);
    if (e.from.connectionId === e.target.connection.connectionId // I'm self??
        || publisher.element === undefined
    ) {
      log('unpublish %o', e);
      unpublish().then(
        () => dispatch(remoteAudioDeclined(
        {
          fromConnectionId: e.from.connectionId,
          toConnectionId: e.target.connection.connectionId
        }
      )));
    } else {
      publisher.publishAudio(false);
      dispatch(audioDeclined(
        {
          fromConnectionId: e.from.connectionId,
          toConnectionId: e.target.connection.connectionId
        }
      ));
    }
  });
  activeSession.on('signal:screenshot-request', e => {
    log('signal:screenshot-request %o', e);
    if (e.from.connectionId === e.target.connection.connectionId) {
      dispatch(screenshotRequested());
    } else {
      dispatch(remoteScreenshotRequested());
      takePublisherScreenshot(roomName);
    }
  });
  activeSession.on('signal:screenshot-taken', e => {
    log('signal:screenshot-taken %o', e);
    if (e.from.connectionId === e.target.connection.connectionId) {
      dispatch(screenshotTaken());
    } else {
      dispatch(remoteScreenshotTaken());
    }
  });
  activeSession.on('signal:screenshot-uploaded', e => {
    log('signal:screenshot-uploaded %o', e);
    if (e.from.connectionId === e.target.connection.connectionId) {
      dispatch(screenshotUploaded());
    } else {
      dispatch(remoteScreenshotUploaded());
      dispatch(screenshotResetWithTimeout());
      dispatch(orderList.load({ companyId, customerId }));
    }
  });
  activeSession.on('signal:screenshot-failed', e => {
    log('signal:screenshot-failed %o', e);
    if (e.from.connectionId === e.target.connection.connectionId) {
      dispatch(screenshotFailed());
    } else {
      dispatch(remoteScreenshotFailed());
    }
  });
  activeSession.on('signal:switch-session-request', e => {
    log('signal:switch-session-request %o', e);
    if (e.from.connectionId === e.target.connection.connectionId) {
      log('signal:switch-session-request-> connection_id is the same');
    } else {
      // ToDo: Switch session
      log('signal:switch-session-request-> connection_id is NOT the same');
      log('signal:switch-session-request Event : ');
      log(e);
      dispatch(callReCreated());
    }
  });
  activeSession.on('signal:moderator-end-call', e => {
    log('signal:moderator-end-call %o', e);
    if (e.from.connectionId === e.target.connection.connectionId) {
      log('signal:moderator-end-call-> connection_id is the same');
    } else {
      // ToDo: Switch session
      log('signal:moderator-end-call-> connection_id is NOT the same');
      log('signal:moderator-end-call Event : %o ', e);
      log(e);
      // dispatch(callReCreated());
    }
  });
  activeSession.on('signal:subscriber-leave-call', e => {
    log('signal:subscriber-leave-call %o', e);
    if (e.from.connectionId === e.target.connection.connectionId) {
      log('signal:subscriber-leave-call-> connection_id is the same');
    } else {
      // ToDo: Switch session
      log('signal:subscriber-leave-call-> connection_id is NOT the same');
      log('signal:subscriber-leave-call Event : %o ', e);
      // dispatch(callReCreated());
    }
  });
  activeSession.connect(tokenToUse, connectError => {
    if (connectError) {
      log(`Error connecting: ${connectError.message}`);
      Raven.captureException(JSON.stringify(connectError), { level: 'info' });
      return;
    }
    if (videoDeviceId) {
      log('publish video on sessionConfig %o', sessionConfig);
      dispatch(
        publish({
          ...sessionConfig,
          publishVideo: true,
        })
      );
    }
  });

  // this `if` prevents the customer side from trying to beat the heart
  if (companyId && customerId) {
    activeSession.on('streamCreated', () => {
      dispatch(heartBeat({ companyId, customerId }));
      heartbeatInterval = setInterval(
        () => dispatch(heartBeat({ companyId, customerId })),
        4000
      );
    });
    activeSession.on('sessionDisconnected', () => clearInterval(heartbeatInterval));
    activeSession.on('streamDestroyed', () => clearInterval(heartbeatInterval));
  }
}

// const pollRoom = once((dispatch, roomName) => {
//     // cheap polling
//     setInterval(() => {
//         api.post({ url: '/api/room/', data: { name: roomName } })
//         .then(settings => {
//             dispatch(setCallRunning(settings.call_running));
//         });
//     }, 5000);
// });

export function startCall(config) {
  log('Starting call: %o', config);
  const { roomName, nick, name } = config;
  const callName = nick || name;

  return dispatch => {
    post({ url: '/api/room/',
                  data: {
                    room: roomName,
                    name: callName
                }
    }
    )
      .then(settings => {
        dispatch(setCallRunning(settings.call_running));
        // TODO reactivate
        // pollRoom(dispatch, roomName);
        log('OpenTOK Settings: %o', settings);
        createSession(dispatch, config, settings);
      })
      .catch(error => dispatch(connectionPublishError('INVALID_ROOM_NAME')));
  };
}

export function reConnectStartCall(config) {
  log('Re Connect Starting call: %o', config);
  const { roomName } = config;

  return dispatch => {
    post({ url: '/api/room/', data: { name: roomName } })
      .then(settings => {
        dispatch(setCallRunning(settings.call_running));
        // TODO reactivate
        // pollRoom(dispatch, roomName);
        log('Re Connect OpenTOK Settings: %o', settings);
        createSession(dispatch, config, settings, true);
      })
      .catch(error => dispatch(connectionPublishError('INVALID_ROOM_NAME')));
  };
}
