import React, { PureComponent } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';

import FormGroup2 from 'components/form/FormGroup';
import FormError from 'components/form/FormError';
import FormSubmit from 'components/form/FormSubmit';
import TextField from 'components/form/TextField';
import CheckBoxField from 'components/form/CheckBoxField';


// import PasswordStrengthMeter from 'react-password-strength-meter';
// <PasswordStrengthMeter passwordText={fields.password.value} onChange={(e) => console.info(e)} />

const messages = defineMessages({
  PLACEHOLDER_NAME: {
    id: 'CompanyUserCreateForm.PLACEHOLDER_NAME',
    defaultMessage: 'Name',
  },
  PLACEHOLDER_EMAIL: {
    id: 'CompanyUserCreateForm.PLACEHOLDER_EMAIL',
    defaultMessage: 'E-Mail-Adresse',
  },
  PLACEHOLDER_PHONE: {
    id: 'CompanyUserCreateForm.PLACEHOLDER_PHONE',
    defaultMessage: 'Telefonnummer',
  },
  PLACEHOLDER_PASSWORD: {
    id: 'CompanyUserCreateForm.PLACEHOLDER_PASSWORD',
    defaultMessage: 'Passwort',
  },
  LABEL_COMPANY_OWNER: {
    id: 'CompanyUserCreateForm.LABEL_COMPANY_OWNER',
    defaultMessage: 'Dieser Benutzer ist Besitzer des Unternehmens',
  },
  LABEL_ADMIN: {
    id: 'CompanyUserCreateForm.LABEL_ADMIN',
    defaultMessage: 'Dieser Benutzer ist Administrator des Unternehmens',
  },
  LABEL_APPLY_USER: {
    id: 'CompanyUserCreateForm.LABEL_APPLY_USER',
    defaultMessage: 'Benutzer anlegen',
  },
  VALIDATION_FIELD_REQUIRED: {
    id: 'CompanyUserCreateForm.VALIDATION_FIELD_REQUIRED',
    defaultMessage: 'Dieses Feld ist erforderlich.',
  },
  VALIDATION_PASSWORD: {
    id: 'CompanyUserCreateForm.VALIDATION_PASSWORD',
    defaultMessage: 'Bitte vergeben Sie ein Passwort.',
  },
  VALIDATION_EMAIL_OR_PHONE: {
    id: 'CompanyUserCreateForm.VALIDATION_EMAIL_OR_PHONE',
    defaultMessage: 'Ein neuer Benutzer muss E-Mail oder Telefonnummer haben.',
  },
});
/*
@injectIntl
@connect(state => state.auth.user)
@reduxForm({
    form: 'companyUserCreate',
    fields: [
        'name',
        'email',
        'phone',
        'password',
        'is_owner',
        'is_admin',
    ],
    validate: (values, { intl: { formatMessage } }) => ({
        name: values.name ? null : formatMessage(messages.VALIDATION_FIELD_REQUIRED),
        password: values.password ? null : formatMessage(messages.VALIDATION_PASSWORD),
        _error: values.email || values.phone ? null : formatMessage(messages.VALIDATION_EMAIL_OR_PHONE),
    }),
    touchOnBlur: false,
})
 */

class CompanyUserCreateForm extends PureComponent {
    render() {
        const {
            is_admin: showIsOwnerField,  // global admin can set is_owner field
            intl: { formatMessage },
        } = this.props;
        // autoComplete="off"
        return (
            <Form
              onSubmit={this.props.onSubmit}
              validate={values => {
                const errors = {};
                if (!values.name) {
                  errors.name = formatMessage(messages.VALIDATION_FIELD_REQUIRED);
                }
                if (!values.password) {
                  errors.password = formatMessage(messages.VALIDATION_PASSWORD);
                }

                if (!values.email && !values.phone) {
                  errors.phone = formatMessage(messages.VALIDATION_EMAIL_OR_PHONE);
                }

                return errors;
              }}
            >
              {({ handleSubmit, submitError, submitting }) => (
                <form
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <Field
                    name="name"
                  >
                    {({ input, meta }) => {
                      return (
                        <FormGroup2 input={input} meta={meta}>
                          <TextField
                            {...input}
                            tabIndex="1"
                            autoFocus
                            placeholder={formatMessage(messages.PLACEHOLDER_NAME)}
                          />
                        </FormGroup2>
                        )
                    }}
                  </Field>
                  <Field
                    name="email"
                  >
                    {({ input, meta }) => {
                      return (
                        <FormGroup2 input={input} meta={meta}>
                          <TextField
                            {...input}
                            tabIndex="2"
                            autoCapitalize="off"
                            autoCorrect="off"
                            placeholder={formatMessage(messages.PLACEHOLDER_EMAIL)}
                          />
                        </FormGroup2>
                        )
                    }}
                  </Field>
                  <Field
                    name="phone"
                  >
                    {({ input, meta }) => {
                      return (
                        <FormGroup2 input={input} meta={meta}>
                          <TextField
                            {...input}
                            tabIndex="3"
                            placeholder={formatMessage(messages.PLACEHOLDER_PHONE)}
                            autoCapitalize="off"
                            autoCorrect="off"
                            name={null}
                            id={null}
                          />
                        </FormGroup2>
                      )
                    }}
                  </Field>
                  <Field
                    name="password"
                  >
                    {({ input, meta }) => {
                      return (
                        <FormGroup2 input={input} meta={meta}>
                          <TextField
                            {...input}
                            tabIndex="4"
                            type="password"
                            autoCapitalize="off"
                            autoCorrect="off"
                            autoComplete="off"
                            placeholder={formatMessage(messages.PLACEHOLDER_PASSWORD)}
                            name={null}
                            id={null}
                          />
                        </FormGroup2>
                      )
                    }}
                  </Field>
                  {showIsOwnerField ? (
                    <Field
                      name="is_owner"
                      type="checkbox"
                    >
                      {({ input, meta }) => {
                        return (
                          <FormGroup2 input={input} meta={meta}>
                            <CheckBoxField
                              {...input}
                              tabIndex="5"
                              label={formatMessage(messages.LABEL_COMPANY_OWNER)}
                            />
                          </FormGroup2>
                        )
                      }}
                    </Field>
                  ) : null }
                  <Field
                    name="is_admin"
                    type="checkbox"
                  >
                    {({ input, meta }) => {
                      return (
                        <FormGroup2 input={input} meta={meta}>
                          <CheckBoxField
                            {...input}
                            tabIndex="6"
                            label={formatMessage(messages.LABEL_ADMIN)}
                          />
                        </FormGroup2>
                      )
                    }}
                  </Field>

                  <FormError error={submitError} />
                  <FormSubmit
                    btnClass="lh-btn-primary lh-btn"
                    btnProps={{ tabIndex: 7 }}
                    submitting={submitting}
                    icon="fa fa-user-plus"
                    label={formatMessage(messages.LABEL_APPLY_USER)}
                  />
                </form>
              )}
            </Form>
        );
    }
}
export default connect(
  state => state.auth.user
)(injectIntl(CompanyUserCreateForm));
