// import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import Raven from 'raven-js';

import './styles/main.less';
import './helpers/listener';

import IntlProvider from './pages/IntlProvider';

import createStore from './store';
import routes from './routes';
import reducer from './reducers';

import './helpers';

export const store = createStore(reducer);
export const history = syncHistoryWithStore(browserHistory, store);

if (typeof __SENTRY_DSN__ !== 'undefined') {
  Raven.config(__SENTRY_DSN__, { release: __SENTRY_VERSION_STRING__ }).install();
}

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider>
      <Router history={history} routes={routes} />
    </IntlProvider>
  </Provider>,
  document.getElementById('app')
);
