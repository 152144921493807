import { combineReducers } from 'redux';
import { remoteScreenshotReducer, screenshotReducer } from 'actions/screenshot';
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';

import {
  ACTIVE_CALL,
  CALL_CREATED,
  CALL_RECREATED,
  CONNECTION_INIT,
  CONNECTION_DESTROY,
  STREAM_CREATED,
  REMOTE_VIDEO_ACTIVATED,
  REMOTE_AUDIO_REQUESTED,
  REMOTE_AUDIO_ACCEPTED,
  REMOTE_AUDIO_DECLINED,
  REMOTE_AUDIO_DEACTIVATED,
  AUDIO_ACTIVATED,
  AUDIO_DEACTIVATED,
  AUDIO_MUTED,
  AUDIO_UNMUTED,
  AUDIO_VOLUME,
  AUDIO_SPEAKING,
  AUDIO_UNSPEAKING,
  AUDIO_REQUESTED,
  AUDIO_ACCEPTED,
  AUDIO_DECLINED,
  REMOTE_AUDIO_MUTED,
  REMOTE_AUDIO_UNMUTED,
  REMOTE_AUDIO_VOLUME,
  REMOTE_AUDIO_SPEAKING,
  REMOTE_AUDIO_UNSPEAKING, REMOTE_AUDIO_ACTIVATED,
} from './constants';
import {
  createApiRequestActions,
 } from './company';

import { post, put } from './api';

const CONNECTION_CONNECTED = 'CONNECTION_CONNECTED';
const CONNECTION_DISCONNECTED = 'CONNECTION_DISCONNECTED';
const CONNECTION_PUBLISH_ERROR = 'CONNECTION_PUBLISH_ERROR';
const CONNECTION_SUBSCRIBE_ERROR = 'CONNECTION_SUBSCRIBE_ERROR';
const CALL_BEGIN_CREATED = 'CALL_BEGIN_CREATED';
const CALL_END_CREATED = 'CALL_END_CREATED';
const CALL_LEAVE = 'CALL_LEAVE';
const END_NOTE_CREATED = 'END_NOTE_CREATED';


const VIDEO_ACTIVATED = 'VIDEO_ACTIVATED';
const VIDEO_DEACTIVATED = 'VIDEO_DEACTIVATED';
const VIDEO_PAUSED = 'VIDEO_PAUSED';
const VIDEO_RESUMED = 'VIDEO_RESUMED';

const TOUCHED = 'TOUCHED';

const REMOTE_CONNECTION_CONNECTED = 'REMOTE_CONNECTION_CONNECTED';
const REMOTE_CONNECTION_DISCONNECTED = 'REMOTE_CONNECTION_DISCONNECTED';

const REMOTE_VIDEO_DEACTIVATED = 'REMOTE_VIDEO_DEACTIVATED';
const REMOTE_VIDEO_PAUSED = 'REMOTE_VIDEO_PAUSED';
const REMOTE_VIDEO_RESUMED = 'REMOTE_VIDEO_RESUMED';

const REMOTE_TOUCHED = 'REMOTE_TOUCHED';

const VIDEO_SET_FULLSIZE = 'VIDEO_SET_FULLSIZE';
const VIDEO_SET_REDUCEDSIZE = 'VIDEO_SET_REDUCEDSIZE';

const USER_VIDEO_OPENED = 'USER_VIDEO_OPENED';
const SET_CALL_RUNNING = 'SET_CALL_RUNNING';

const SET_VIDEO_DIMENSIONS = 'SET_VIDEO_DIMENSIONS';

export const setCallRunning = createAction(SET_CALL_RUNNING);

export const activeCallGet = createApiRequestActions(ACTIVE_CALL);

export const connectionInit = createAction(CONNECTION_INIT);
export const connectionDestroy = createAction(CONNECTION_DESTROY);
export const connectionConnected = createAction(CONNECTION_CONNECTED);
export const connectionDisconnected = createAction(CONNECTION_DISCONNECTED);
export const connectionPublishError = createAction(CONNECTION_PUBLISH_ERROR);
export const connectionSubscribeError = createAction(CONNECTION_SUBSCRIBE_ERROR);

export const streamCreated = createAction(STREAM_CREATED);

export const videoActivated = createAction(VIDEO_ACTIVATED);
export const videoDeactivated = createAction(VIDEO_DEACTIVATED);
export const videoPaused = createAction(VIDEO_PAUSED);
export const videoResumed = createAction(VIDEO_RESUMED);

export const touched = createAction(TOUCHED);

export const remoteConnectionConnected = createAction(REMOTE_CONNECTION_CONNECTED);
export const remoteConnectionDisconnected = createAction(REMOTE_CONNECTION_DISCONNECTED);

export const remoteVideoActivated = createAction(REMOTE_VIDEO_ACTIVATED);
export const remoteVideoDeactivated = createAction(REMOTE_VIDEO_DEACTIVATED);
export const remoteVideoPaused = createAction(REMOTE_VIDEO_PAUSED);
export const remoteVideoResumed = createAction(REMOTE_VIDEO_RESUMED);

export const remoteTouched = createAction(REMOTE_TOUCHED);

export const userVideoOpened = createAction(USER_VIDEO_OPENED);

export const videoSetFullsize = createAction(VIDEO_SET_FULLSIZE);
export const videoSetReducedsize = createAction(VIDEO_SET_REDUCEDSIZE);

export const callCreated = createAction(CALL_CREATED);
export const callReCreated = createAction(CALL_RECREATED);
export const callBeginCreated = createAction(CALL_BEGIN_CREATED);
export const callEndCreated = createAction(CALL_END_CREATED);
export const endNoteCreated = createAction(END_NOTE_CREATED);
export const callLeave = createAction(CALL_LEAVE);

export const videoDimensionsSet = createAction(SET_VIDEO_DIMENSIONS);

export const createCall = (companyId, customerId) => post({
  url: `/api/company/${companyId}/customer/${customerId}/call/create/`,
});

export const createCallBegin = (companyId, customerId) => post({
  url: `/api/company/${companyId}/customer/${customerId}/call/begin/`,
});

export const createCallEnd = (companyId, customerId, callId) => put({
  url: `/api/company/${companyId}/customer/${customerId}/call/${callId}/end`,
});

export const createEndNote = (companyId, customerId, callId) => put({
  url: `/api/company/${companyId}/customer/${customerId}/call/${callId}/end_note`,
});

const emptyConnection = {
  connected: false,
  publishError: undefined,
  subscribeError: undefined,
};

const connection = handleActions({
  [CONNECTION_INIT]: () => ({ ...emptyConnection }),
  [CONNECTION_DESTROY]: () => ({}),
  [CONNECTION_CONNECTED]: state => ({
    ...state, connected: true,
  }),
  [CONNECTION_DISCONNECTED]: state => ({
    ...state, connected: false,
  }),
  [CONNECTION_PUBLISH_ERROR]: (state, action) => ({ ...state, publishError: action.payload }),
  [CONNECTION_SUBSCRIBE_ERROR]: (state, action) => ({ ...state, subscribeError: action.payload }),
}, {});

const emptyVideo = {
  active: false,
  paused: false,
};

const video = handleActions({
  [CONNECTION_INIT]: () => ({ ...emptyVideo }),
  [CONNECTION_DESTROY]: () => ({}),
  [VIDEO_ACTIVATED]: () => ({
    ...emptyVideo,
    active: true,
  }),
  [VIDEO_PAUSED]: state => ({
    ...state,
    paused: true,
  }),
  [VIDEO_RESUMED]: state => ({
    ...state,
    paused: false,
  }),
}, {});

const remoteConnection = handleActions({
  [CONNECTION_INIT]: () => ({ ...emptyConnection }),
  [CONNECTION_DESTROY]: () => ({}),
  [REMOTE_CONNECTION_CONNECTED]: state => ({
    ...state,
    connected: true,
  }),
  [REMOTE_CONNECTION_DISCONNECTED]: state => ({
    ...state,
    connected: false,
  }),
}, {});

const remoteVideo = handleActions({
  [CONNECTION_INIT]: () => ({ ...emptyVideo }),
  [CONNECTION_DESTROY]: () => ({}),
  [REMOTE_VIDEO_ACTIVATED]: () => ({
    ...emptyVideo,
    active: true,
  }),
  [REMOTE_VIDEO_PAUSED]: state => ({
    ...state,
    paused: true,
  }),
  [REMOTE_VIDEO_RESUMED]: state => ({
    ...state,
    paused: false,
  }),
}, {});


const emptyAudio = {
  active: false,
  muted: false,
  volume: 0,
  speaking: false,
  requested: false,
};

const emptyRoom = {
  self: {
    audio: { ...emptyAudio }
  },
  moderator: {
    audio: { ...emptyAudio }
  },
  subscribers: [],
  publishers: [],
  remoteConnection: { ...emptyConnection },
  remoteVideo: { ...emptyVideo }
};

const emptyUser = {
  name: null,
  connectionId: null,
  type: null,
  stream: null,
  audio: {
    ...emptyAudio
  }
}

const emptyMembers = [];

const roomSelfReducer = handleActions({
    [CONNECTION_INIT]: () => ({ ...emptyUser }),
    [CONNECTION_DESTROY]: () => ({ ...emptyUser }),
    [CONNECTION_CONNECTED]: (state, action) => (
      {
        ...state,
        ...action.payload,
        audio: {
          ...emptyAudio
        }
      }),
    [STREAM_CREATED]: (state, action) => {
        if (action
              && action.payload
              && action.payload.fromConnectionId === state.connectionId) {
          return {
            ...state,
            stream: { ...action.payload.stream }
          }
        }

        return { ...state };
        },
    [CALL_LEAVE]: () => ({ ...emptyUser }),
    [REMOTE_AUDIO_REQUESTED]: (state, action) => ({
      ...state,
      audio: action.payload.toConnectionId !== state.connectionId ?
        { ...state.audio } : {
          ...state.audio,
          requested: true
      }
    }),
    [AUDIO_REQUESTED]: (state, action) => ({
      ...state,
      audio: {
        ...state.audio,
        requested: true
      }
    }),
    [AUDIO_ACCEPTED]: (state) => ({
        ...state,
        audio: {
          ...state.audio,
          active: true,
          requested: false
        }
    }),
    [AUDIO_DECLINED]: (state) => ({
      ...state,
      audio: {
        ...emptyAudio,
      }
    }),
    [AUDIO_ACTIVATED]: (state) => ({
      ...state,
      audio: {
        ...state.audio,
        active: true,
        requested: false,
      }
    }),
    [AUDIO_DEACTIVATED]: (state) => ({
      ...state,
      stream: null,
      audio: {
        ...emptyAudio,
      }
    }),
    [AUDIO_MUTED]: (state, action) => ({
      ...state,
      audio: {
        ...state.audio,
        muted: true,
      }
    }),
    [AUDIO_UNMUTED]: (state) => ({
      ...state,
      audio: {
        ...state.audio,
        muted: false,
      }
    }),
    [AUDIO_VOLUME]: (state, action) => ({
      ...state,
      audio: {
        ...state.audio,
        volume: action.payload,
      }
    }),
    [AUDIO_SPEAKING]: (state) => ({
      ...state,
      audio: {
        ...state.audio,
        speaking: true,
      }
    }),
    [AUDIO_UNSPEAKING]: (state) => ({
      ...state,
      audio: {
        ...state.audio,
        speaking: false,
      }
    }),
}, { ...emptyUser });

const roomMembersReducer = handleActions({
  [CONNECTION_INIT]: () => ([...emptyMembers]),
  [CONNECTION_DESTROY]: () => ([...emptyMembers]),
  [CALL_LEAVE]: () => ([...emptyMembers]),
  [CONNECTION_CONNECTED]: (state, action) => {
    const members = [...state];
    if (members.findIndex(members => members.connectionId === action.payload.connectionId) === -1) {
      members.push({
        ...emptyUser,
        ...action.payload,
        audio: {
          ...emptyAudio
        }
      });
    }
    return members;
  },
  [STREAM_CREATED]: (state, action) => {
    const members = state.map(member => ({
      ...member,
      stream: action.payload.fromConnectionId !== member.connectionId ?
          member.stream : {
          ...action.payload.stream
        }
    }));
    return members;
  },
  [REMOTE_CONNECTION_CONNECTED]: (state, action) => {
    // Check if should be moderator
    const members = [...state];
    if (members.findIndex(members => members.connectionId === action.payload.connectionId) === -1) {
      members.push({
        ...emptyUser,
        ...action.payload,
        audio: {
          ...emptyAudio
        }
      });
    }

    return members;
  },
  [REMOTE_CONNECTION_DISCONNECTED]: (state, action) => {
    // Check if should be moderator
    if (action.payload) {
      const members = [...state];
      return _.filter(members, (member) => (member.connectionId !== action.payload.connectionId));
    }

    return { ...state };
  },
  [REMOTE_AUDIO_REQUESTED]: (state, action) => {
    const members = state.map(member => ({
      ...member,
      audio: action.payload.fromConnectionId !== member.connectionId ?
        { ...member.audio } : {
          ...member.audio,
          active: false,
          requested: true
        }
    }));
    return members;
  },
  [REMOTE_AUDIO_ACCEPTED]: (state, action) => {
    const members = state.map(member => ({
      ...member,
      audio: action.payload.fromConnectionId !== member.connectionId ?
        { ...member.audio } : {
          ...member.audio,
          active: true,
          requested: false
        }
    }));
    return members;
  },
  [REMOTE_AUDIO_ACTIVATED]: (state, action) => {
    const members = state.map(member => ({
      ...member,
      audio: action.payload.fromConnectionId !== member.connectionId ?
        { ...member.audio } : {
          ...member.audio,
          active: true,
          requested: false
        }
    }));
    return members;
  },
  [REMOTE_AUDIO_DECLINED]: (state, action) => {
    const members = state.map(member => ({
      ...member,
      audio: action.payload.fromConnectionId !== member.connectionId ?
        { ...member.audio } : {
          ...emptyAudio,
        }
    }));
    return members;
  },
  [REMOTE_AUDIO_DEACTIVATED]: (state, action) => {
    const members = state.map(member => ({
      ...member,
      stream: null,
      audio: { ...emptyAudio }
    }));
    return members;
  },
  [REMOTE_AUDIO_MUTED]: (state, action) => {
    const members = state.map(member => ({
      ...member,
      stream: action.payload.fromConnectionId !== member.connectionId ?
        member.stream : {
        ...action.payload.stream
        },
      audio: action.payload.fromConnectionId !== member.connectionId ?
        { ...member.audio } : {
          ...member.audio,
          muted: true,
        }
    }));

    return members;
  },
  [REMOTE_AUDIO_UNMUTED]: (state, action) => {
    const members = state.map(member => ({
      ...member,
      stream: action.payload.fromConnectionId !== member.connectionId ?
        member.stream : {
          ...action.payload.stream
        },
      audio: action.payload.fromConnectionId !== member.connectionId ?
        { ...member.audio } : {
          ...member.audio,
          muted: false,
        }
    }));

    return members;
  },
  [REMOTE_AUDIO_VOLUME]: (state, action) => {
    const members = state.map(member => ({
      ...member,
      audio: action.payload.fromConnectionId !== member.connectionId ?
        { ...member.audio } : {
          ...member.audio,
          volume: action.payload.volume,
        }
    }));

    return members;
  },
  [REMOTE_AUDIO_SPEAKING]: (state, action) => {
    const members = state.map(member => ({
      ...member,
      audio: action.payload.fromConnectionId !== member.connectionId ?
        { ...member.audio } : {
          ...member.audio,
          speaking: true,
        }
    }));

    return members;
  },
  [REMOTE_AUDIO_UNSPEAKING]: (state, action) => {
    const members = state.map(member => ({
      ...member,
      audio: action.payload.fromConnectionId !== member.connectionId ?
        { ...member.audio } : {
          ...member.audio,
          speaking: false,
        }
    }));

    return members;
  },
  [AUDIO_REQUESTED]: (state, action) => {
    //console.log(state);
    const members = state.map(member => ({
      ...member,
      audio: {
          ...member.audio,
          requested: true,
        }
    }));

    //console.log(members);

    return members;
  },
  [AUDIO_ACCEPTED]: (state, action) => {
    //console.log(state);
    const members = state.map(member => ({
      ...member,
      audio: action.payload.fromConnectionId !== member.connectionId ?
        { ...member.audio } : {
          ...member.audio,
          active: true,
          requested: false
      }
    }));
    //console.log(members);
    return members;
  },
  [AUDIO_DECLINED]: (state, action) => {
    const members = state.map(member => ({
      ...member,
      audio: action.payload.fromConnectionId !== member.connectionId ?
        { ...member.audio } : {
          ...emptyAudio,
      }
    }));

    return members;
  },
  [AUDIO_ACTIVATED]: (state, action) => {
    const members = state.map(member => ({
      ...member,
      audio: action.payload.fromConnectionId !== member.connectionId ?
        { ...member.audio } : {
          ...member.audio,
          active: true,
          requested: false,
        }
    }));

    return members;
  },
  [AUDIO_DEACTIVATED]: (state, action) => {
    if (action.payload) {
      const members = state.map(member => ({
        ...member,
        stream: action.payload.fromConnectionId !== member.connectionId ?
          { ...member.stream } : null,
        audio: action.payload.fromConnectionId !== member.connectionId ?
          { ...member.audio } : {
            ...emptyAudio,
          }
      }));

      return members;
    }
    return [...state];
  },
  [AUDIO_MUTED]: (state, action) => {
    const members = state.map(member => ({
      ...member,
      audio: action.payload.fromConnectionId !== member.connectionId ?
        { ...member.audio } : {
          ...member.audio,
          muted: true,
        }
    }));

    return members;
  },
  [AUDIO_UNMUTED]: (state, action) => {
    const members = state.map(member => ({
      ...member,
      audio: action.payload.fromConnectionId !== member.connectionId ?
        { ...member.audio } : {
          ...member.audio,
          muted: false,
        }
    }));

    return members;
  },
  [AUDIO_VOLUME]: (state, action) => {
    const members = state.map(member => ({
      ...member,
      audio: action.payload.fromConnectionId !== member.connectionId ?
        { ...member.audio } : {
          ...member.audio,
          volume: action.payload.volume,
        }
    }));

    return members;
  },
  [AUDIO_SPEAKING]: (state, action) => {
    const members = state.map(member => ({
      ...member,
      audio: action.payload.fromConnectionId !== member.connectionId ?
        { ...member.audio } : {
          ...member.audio,
          speaking: true,
        }
    }));

    return members;
  },
  [AUDIO_UNSPEAKING]: (state, action) => {
    const members = state.map(member => ({
      ...member,
      audio: action.payload.fromConnectionId !== member.connectionId ?
        { ...member.audio } : {
          ...member.audio,
          speaking: false,
        }
    }));

    return members;
  },
}, [...emptyMembers]);

const remoteConnectionReducer = handleActions({
  [CONNECTION_DESTROY]: () => ({ ...emptyConnection }),
  [CALL_LEAVE]: () => ({ ...emptyConnection }),
  [REMOTE_CONNECTION_CONNECTED]: (state, action) => {
    // Check if should be moderator
    const remoteConnection = { ...state };
    if (action.payload.type === 'publisher') {
      remoteConnection.connected=true;
    }
    return remoteConnection;
  },
  [REMOTE_CONNECTION_DISCONNECTED]: (state, action) => {
    // Check if should be moderator
    const remoteConnection = { ...state };
    if (action.payload.type === 'publisher') {
      remoteConnection.connected=false;
    }
    return remoteConnection;
  },
}, { ...emptyConnection });

const remoteVideoReducer = handleActions({
  [CONNECTION_DESTROY]: () => ({ ...emptyVideo }),
  [CALL_LEAVE]: () => ({ ...emptyVideo }),
  [CONNECTION_DESTROY]: () => ({ ...emptyVideo }),
  [REMOTE_CONNECTION_DISCONNECTED]: (state, action) => {
    if (action.payload && action.payload.type === 'publisher') {
      return { ...emptyVideo }
    }

    return { ...state };
  },
  [REMOTE_VIDEO_ACTIVATED]: state => ({
    ...state,
    active: true
  }),
  [REMOTE_VIDEO_DEACTIVATED]: state => ({
    ...state,
    active: false
  }),
  [REMOTE_VIDEO_PAUSED]: state => ({
    ...state,
    paused: true
  }),
  [REMOTE_VIDEO_RESUMED]: state => ({
    ...state,
    paused: false
  }),
}, { ...emptyVideo });

const room = combineReducers({
  self: roomSelfReducer,
  members: roomMembersReducer,
  remoteConnection: remoteConnectionReducer,
  remoteVideo: remoteVideoReducer
});

const touchPosition = handleActions({
  [CONNECTION_INIT]: () => null,
  [CONNECTION_DESTROY]: () => null,
  [TOUCHED]: (state, action) => action.payload || null,
  [REMOTE_TOUCHED]: (state, action) => action.payload || null,
}, null);

const fullsize = handleActions({
  [VIDEO_SET_FULLSIZE]: () => true,
  [VIDEO_SET_REDUCEDSIZE]: () => false,
}, false);

const emptyActiveCall = {
  isFetching: false,
  // call: null,
  // error: null,
  // opened: false,
};

const activeCall = handleActions({
  [ACTIVE_CALL.LOAD]: () => ({
    ...emptyActiveCall,
  }),
  [ACTIVE_CALL.REQUEST]: () => ({
    ...emptyActiveCall,
    isFetching: true,
  }),
  [ACTIVE_CALL.SUCCESS]: (state, action) => ({
    ...emptyActiveCall,
    call: action.payload,
    isFetching: false,
  }),
  [ACTIVE_CALL.FAILURE]: (state, action) => ({
    ...emptyActiveCall,
    error: action.payload,
    isFetching: false,
  }),
  [CALL_CREATED]: (state, action) => ({
    ...state,
    call: action.payload.call,
  }),
  [USER_VIDEO_OPENED]: state => ({
    ...state,
    opened: true,
  }),
  [CALL_END_CREATED]: (state, action) => ({
    ...state, call: { ...state.call, call_end: action.payload },
  }),
  [CALL_BEGIN_CREATED]: (state, action) => ({
    ...state, call: { ...state.call, call_begin: action.payload },
  }),
  [CALL_LEAVE]: () => ({
    ...emptyActiveCall,
  }),
}, { ...emptyActiveCall });

const callRunning = handleActions({
  [SET_CALL_RUNNING]: (state, action) => action.payload,
}, false);

const videoDimensions = handleActions({
  [SET_VIDEO_DIMENSIONS]: (state, action) => action.payload,
}, {});

// Scribbled Screenshot
const SCRIBBLED_SCREENSHOT_INIT = 'SCRIBBLE_SCREENSHOT_INIT'
const SCRIBBLED_SCREENSHOT_CONNECTED = 'SCRIBBLE_SCREENSHOT_CONNECTED';
const SCRIBBLED_SCREENSHOT_DESTROY = 'SCRIBBLE_SCREENSHOT_DESTROY';
const SCRIBBLED_SCREENSHOT_DISCONNECTED = 'SCRIBBLE_SCREENSHOT_DISCONNECTED';
const SCRIBBLED_SCREENSHOT_PUBLISH_ERROR = 'SCRIBBLE_SCREENSHOT_PUBLISH_ERROR';
const SCRIBBLED_SCREENSHOT_SUBSCRIBE_ERROR = 'SCRIBBLE_SCREENSHOT_SUBSCRIBE_ERROR';

export const scribbledScreenshotInit = createAction(SCRIBBLED_SCREENSHOT_INIT);
export const scribbledScreenshotConnected = createAction(SCRIBBLED_SCREENSHOT_CONNECTED);
export const scribbledScreenshotDestroy = createAction(SCRIBBLED_SCREENSHOT_DESTROY);
export const scribbledScreenshotDisconnected = createAction(SCRIBBLED_SCREENSHOT_DISCONNECTED);
export const scribbledScreenshotPublishError = createAction(SCRIBBLED_SCREENSHOT_PUBLISH_ERROR);
export const scribbledScreenshotSubscribeError = createAction(SCRIBBLED_SCREENSHOT_SUBSCRIBE_ERROR);

const emptyScribbedScreenshot = {
  connected: false, // used to identify if is connected
  publishError: undefined,
  subscribeError: undefined,
  active: false, // used if active
  paused: false
};

const scribbledScreenshot = handleActions({
  [SCRIBBLED_SCREENSHOT_INIT]: () => ({ ...emptyConnection }),
  [SCRIBBLED_SCREENSHOT_DESTROY]: () => ({}),
  [SCRIBBLED_SCREENSHOT_CONNECTED]: state => ({
    ...state, connected: true,
  }),
  [SCRIBBLED_SCREENSHOT_DISCONNECTED]: state => ({
    ...state, connected: false,
  }),
  [SCRIBBLED_SCREENSHOT_PUBLISH_ERROR]: (state, action) => ({ ...state, publishError: action.payload }),
  [SCRIBBLED_SCREENSHOT_SUBSCRIBE_ERROR]: (state, action) => ({ ...state, subscribeError: action.payload }),
}, {});

export default combineReducers({
  // "connection:" is the connection to the open tok media server
  connection,
  // "remoteConnection:" is the concrete connection to the other peer
  // so this is the indicator if the call is active or not.
  remoteConnection,
  room,
  video,
  remoteVideo,
  touchPosition,
  fullsize,
  activeCall,
  callRunning,
  videoDimensions,
//  scribbledScreenshot,
  screenshot: screenshotReducer,
  remoteScreenshot: remoteScreenshotReducer,
});
