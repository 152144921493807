import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import TextOverflow from 'components/TextOverflow';
import { Link } from 'react-router';

const messages = defineMessages({
  NO_COMPANY_FOUND: {
    id: 'CompanyList.NO_COMPANY_FOUND',
    defaultMessage: 'Es wurden keine Unternehmen gefunden.',
  },
  USER: {
    id: 'CompanyList.USER',
    defaultMessage: 'Benutzer',
  },
  CUSTOMER: {
    id: 'CompanyList.CUSTOMER',
    defaultMessage: 'Kunden',
  },
});

function CompanyList(props) {
  const { items, intl: { formatMessage } } = props;

  if (!(items && items.length)) {
    return <p>{formatMessage(messages.NO_COMPANY_FOUND)}</p>;
  }
  return (
    <div className="company-list">
      <div className="row">
        {items.map(company => (
          <div key={company.id} className="col-sm-6 col-lg-4">
            <Link className="company-item" to={`/company/${company.id}/`}>
              <h4>
                <TextOverflow title={company.name}>
                  {company.name}
                </TextOverflow>
              </h4>
              <div className="clearfix" style={{ lineHeight: '16px' }}>
                <div className="pull-left" style={{ marginRight: '15px' }}>
                  <div style={{ color: 'orange', fontWeight: 'bold' }}>
                    {company.user_count}
                  </div>
                  <div style={{ fontSize: '13px' }}>{formatMessage(messages.USER)}</div>
                </div>
                <div className="pull-left">
                  <div style={{ color: 'orange', fontWeight: 'bold' }}>
                    {company.customer_count}
                  </div>
                  <div style={{ fontSize: '13px' }}>{formatMessage(messages.CUSTOMER)}</div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default injectIntl(CompanyList);
