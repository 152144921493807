import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router';

const messages = defineMessages({
  TEST_ACCOUNT_EXPIRED: {
    id: 'CompanyExpiredInformation.TEST_ACCOUNT_EXPIRED',
    defaultMessage: 'Kostenloser Testzeitraum abgelaufen',
  },
  APPLY_ACCOUNT: {
    id: 'CompanyExpiredInformation.APPLY_ACCOUNT',
    defaultMessage: 'Ihr kostenloser Testzeitraum ist abgelaufen. Um tele-LOOK weiterhin '
      + 'nutzen zu können, müssen Sie einen Nutzerkonto anlegen.',
  },
  INFORM_OWNER: {
    id: 'CompanyExpiredInformation.INFORM_OWNER',
    defaultMessage: 'Bitte informieren Sie den Besitzer der Firma.',
  },
  LINK_APPLY_ACCOUNT: {
    id: 'CompanyExpiredInformation.LINK_APPLY_ACCOUNT',
    defaultMessage: 'Nutzerkonto anlegen',
  },
});

function CompanyExpiredInformation(props) {
  const { isOwner, id, intl: { formatMessage } } = props;
  return (
    <div style={{ padding: '30px 30px 30px 30px' }} className="flex">
      <div className="content-block row login-page">
        <h2>{formatMessage(messages.TEST_ACCOUNT_EXPIRED)}</h2>
        <p>
          {formatMessage(messages.APPLY_ACCOUNT)}
          {isOwner ? null : (
            <span> {formatMessage(messages.INFORM_OWNER)}</span>
          )}
        </p>
        {isOwner ? (
          <center>
            <Link
              style={{ marginTop: '10px' }}
              to={`/company/${id}/payment`}
              className="lh-btn lh-btn-primary"
            >
              {formatMessage(messages.LINK_APPLY_ACCOUNT)}
            </Link>
          </center>
        ) : null}
      </div>
    </div>
  );
}

export default injectIntl(CompanyExpiredInformation);
