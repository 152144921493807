import React from 'react';
import { Link } from 'react-router';
import { defineMessages, injectIntl } from 'react-intl';

import BillingCurrentMonth from './components/BillingCurrentMonth';
import BillingDownloadList from './components/BillingDownloadList';
import NavBarConfig from '../../components/NavBarConfig';
import nfetch from '../../actions/nfetch';

const messages = defineMessages({
  BILLINGS: {
    id: 'CompanyBillingPage.BILLINGS',
    defaultMessage: 'Einzelverbindungsnachweise',
  },
  BACK: {
    id: 'CompanyBillingPage.BACK',
    defaultMessage: 'Zurück',
  },
  CURRENT_MONTH: {
    id: 'CompanyBillingPage.CURRENT_MONTH',
    defaultMessage: 'Einzelverbindungsnachweis des laufenden Monats',
  },
});

export function CompanyBillingPageComponent({
  billing: { connections, downloads },
  company,
  routes,
  intl: { formatMessage },
}) {
  return (
    <div>
      <NavBarConfig currentRouteName={routes[routes.length - 1].name} />
      <div className="m40">
        <Link to={`/company/${company.id}/`}>{formatMessage(messages.BACK)}</Link>
        <h3>{formatMessage(messages.BILLINGS)}</h3>
        <div className="w-xs" style={{ marginLeft: 0, marginBottom: 40 }}>
          <BillingDownloadList downloads={downloads} companyId={company.id} />
        </div>

        <h3>{formatMessage(messages.CURRENT_MONTH)}</h3>
        <BillingCurrentMonth connections={connections} />
      </div>
    </div>
  );
}

const CompanyBillingPageNfetch = nfetch(
  ({ params }) => ({ billing: { url: `/api/company/${params.companyId}/billing` } }),
)(CompanyBillingPageComponent);

export default injectIntl(CompanyBillingPageNfetch);
