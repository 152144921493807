/* global document */

import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import classnames from 'classnames';

import {
  Stage,
  Layer,
  Line,
} from 'react-konva';


import { videoSetFullsize, videoSetReducedsize } from '../../../actions/video';
import { requestScreenshot } from '../../../actions/screenshot';
import { touch } from '../../../actions/ot_signaling';

import Tools from '../../../components/video/Tools';
import VideoState from '../../../components/video/VideoState';
import AudioRequestedState from './AudioRequestedState';

import VideoTouch, { windowToVideo } from '../../../components/video/VideoTouch';

const messages = defineMessages({
  CONNECTION_BUILDUP: {
    id: 'UserVideoComponent.CONNECTION_BUILDUP',
    defaultMessage: 'Verbindungsaufbau...',
  },
  TRANSMISSION_PAUSED: {
    id: 'UserVideoComponent.TRANSMISSION_PAUSED',
    defaultMessage: 'Der Gesprächspartner hat die Videoübertragung pausiert.',
  },
  TRANSMISSION_DISABLED: {
    id: 'UserVideoComponent.TRANSMISSION_DISABLED',
    defaultMessage: 'Die Videoübertragung wurde deaktiviert.',
  },
  NO_VIDEO_SAHRED: {
    id: 'UserVideoComponent.NO_VIDEO_SAHRED',
    defaultMessage: 'Ihr Kunde hat noch kein Video freigegeben. Sollte dies länger ' +
      'dauern empfehlen wir Ihnen, ihn erneut anzurufen.',
  },
  TEXT_SCREENSHOT_UPLOADING: {
    id: 'SubscriberVideo.TEXT_SCREENSHOT_UPLOADING',
    defaultMessage: 'Screenshot wird hochgeladen.',
  },
  TEXT_SCREENSHOT_DONE: {
    id: 'SubscriberVideo.TEXT_SCREENSHOT_DONE',
    defaultMessage: 'Screenshot erfolgreich hochgeladen.',
  },
  TEXT_SCREENSHOT_ERROR: {
    id: 'SubscriberVideo.TEXT_SCREENSHOT_ERROR',
    defaultMessage: 'Der Screenshot konnte nicht hochgeladen werden, bitte versuchen Sie es erneut.',
  },
  TEXT_SCREENSHOT_NOT_SUPPORTED: {
    id: 'SubscriberVideo.TEXT_SCREENSHOT_NOT_SUPPORTED',
    defaultMessage: 'Die App ihres Kunden unterstützt dieses Feature noch nicht.',
  },
  TEXT_SCREENSHOT_REQUESTED: {
    id: 'SubscriberVideo.TEXT_SCREENSHOT_REQUESTED',
    defaultMessage: 'Screenshot wird aufgenommen.',
  },
});

function getMessage(connected, remoteConnected, remoteVideoActive, remoteVideo, formatMessage) {
  if (!connected) {
    return formatMessage(messages.CONNECTION_BUILDUP);
  }
  if (remoteConnected) {
    if (remoteVideoActive) {
      if (remoteVideo.paused) {
        return formatMessage(messages.TRANSMISSION_PAUSED);
      }
    } else {
      return formatMessage(messages.TRANSMISSION_DISABLED);
    }
  } else {
    return formatMessage(messages.NO_VIDEO_SAHRED);
  }
  return null;
}

function getScreenshotMessage(screenshot, remoteScreenshot) {
  if (remoteScreenshot !== '') {
    switch (remoteScreenshot) {
      case 'taken':
        return messages.TEXT_SCREENSHOT_UPLOADING;
      case 'uploaded':
        return messages.TEXT_SCREENSHOT_DONE;
      case 'failed':
        return messages.TEXT_SCREENSHOT_ERROR;
      case 'not_supported':
        return messages.TEXT_SCREENSHOT_NOT_SUPPORTED;
      default:
        break;
    }
  }

  if (screenshot === 'requested') {
    return messages.TEXT_SCREENSHOT_REQUESTED;
  }
}

class UserVideoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.handleFullscreenChange = this.handleFullscreenChange.bind(this);
  }

  onClick(e) {
    const { pageX, pageY } = e;
    const video = this.refs.video.getElementsByTagName('video')[0];
    if (!video) {
      return;
    }
    const { x, y } = windowToVideo(pageX, pageY, video);
    this.props.touch({ x, y });
/*
    const canvas = this.refs.canvas;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = `rgb(${255}, ${255}, ${255})`;
    context.fillRect(0, 0, canvas.width, canvas.height);
 */
  }

  handleScreenShare(fullscreen) {
    if (fullscreen) {
      this.props.videoSetFullsize();
    } else {
      this.props.videoSetReducedsize();
    }
  }

  handleFullscreenChange(fullscreen) {
    if (fullscreen) {
      this.props.videoSetFullsize();
    } else {
      this.props.videoSetReducedsize();
    }
  }

  render() {
    const {
      call,
      connection,
      fullsize,
      handleStopCall,
      remoteScreenshot,
      screenshot,
      touchPosition,
      videoDimensions,
      room,
      intl: { formatMessage },
    } = this.props;

    const connected = connection && connection.connected;
    const remoteConnected = room && room.remoteConnection && room.remoteConnection.connected;
    const remoteVideoActive = room && room.remoteVideo && room.remoteVideo.active;


    // let callBegin = null;
    // if (call && call.call_begin && call.call_begin.created) {
    //   callBegin = new Date(call.call_begin.created);
    // }
    const message = getMessage(
      connected,
      remoteConnected,
      remoteVideoActive,
      room.remoteVideo,
      formatMessage
    );

    //console.log('connected %o, remoteConnected %o, remoteVideoActive %o, remoteVideo %o, message %o', connected, remoteConnected, remoteVideoActive, room.remoteVideo, message);

    const screenshotMessage = getScreenshotMessage(screenshot, remoteScreenshot);
    const hasMessage = message || screenshotMessage;
    const isVertical =
      videoDimensions &&
      Object.keys(videoDimensions) &&
      videoDimensions.height >= videoDimensions.width;

     return (
      <div
        className={classnames('videomode video-chat-embedded', {
          active: room.remoteConnection.connected,
          fullscreen: fullsize,
        })}
        style={{ flexDirection: 'column' }}
        ref={c => {
          this.container_el = c;
        }}
      >
        <div className="video-wrapper">
          <div className={'video-box embedded'} ref="video" onClick={this.onClick}>
            {touchPosition
              ? <VideoTouch position={touchPosition} videoRef={this.refs.video} />
              : null}
            <div id="opentok-container" className={isVertical ? 'vertical' : 'horizontal'} />
            <AudioRequestedState />
            {/* <RemoteAudioState /> */}

            {room.remoteVideo && <VideoState videoActive={room.remoteVideo.active} videoPaused={room.remoteVideo.paused} />}
          </div>
        </div>
        { hasMessage &&
          <div className="video-overlay">
            {message}
            {message && screenshotMessage && <br />}
            {screenshotMessage && formatMessage(screenshotMessage)}
          </div>
        }
        <Tools
          isSubscriber
          videoActive={false}
          screenshotActive={remoteConnected && remoteVideoActive}
          captureScreenshot={this.props.requestScreenshot}
          chatActive={false}
          toggleChat={null}
          callActive={connection.connected}
          endCall={() => handleStopCall(call)}
          fullsize={fullsize}
          //fullsize={true}
          videoSetFullsize={() => {
            return this.handleFullscreenChange(true);
          }}
          videoSetReducedsize={() => {
            return this.handleFullscreenChange(false);
          }}
        />
      </div>
    );
  }
}

/*
        <Stage container={'scribbled'} className={'video-overlay-scribbled'}>
          <Layer>
            <Line
              x={20}
              y={50}
              points={[0, 0, 100, 0, 100, 100]}
              tension={0.5}
              closed
              stroke="black"
              fillLinearGradientStartPoint={{ x: -50, y: -50 }}
              fillLinearGradientEndPoint={{ x: 50, y: 50 }}
              fillLinearGradientColorStops={[0, 'red', 1, 'yellow']}
            />
          </Layer>
        </Stage>
*/

export default connect(state => {
  const {
    video,
    video: {
      fullsize,
    },
  } = state;
  return {
    ...video,
    fullsize
  };
  },
  {
  touch,
  videoSetFullsize,
  videoSetReducedsize,
  requestScreenshot,
})(injectIntl(UserVideoComponent));
