import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';

import FormGroup2 from 'components/form/FormGroup';
import TextField from 'components/form/TextField';
import FormError from 'components/form/FormError';
import FormSubmit from 'components/form/FormSubmit';

const messages = defineMessages({
  CHOOSE_NEW_PASSWORD: {
    id: 'ResetPasswordForm.CHOOSE_NEW_PASSWORD',
    defaultMessage: 'Sie können hier nun ein neues Passwort für Ihren Benutzerkonto wählen.',
  },
  PLACEHOLDER_NEW_PASSWORD: {
    id: 'ResetPasswordForm.PLACEHOLDER_NEW_PASSWORD',
    defaultMessage: 'Neues Passwort',
  },
  PLACEHOLDER_REPEAT_NEW_PASSWORD: {
    id: 'ResetPasswordForm.PLACEHOLDER_REPEAT_NEW_PASSWORD',
    defaultMessage: 'Neues Passwort wiederholen',
  },
  PLACEHOLDER_CHANGE_PASSWORD: {
    id: 'ResetPasswordForm.PLACEHOLDER_CHANGE_PASSWORD',
    defaultMessage: 'Passwort ändern',
  },
  VALIDATION_NEW_PWASSWORD: {
    id: 'ResetPasswordForm.VALIDATION_NEW_PWASSWORD',
    defaultMessage: 'Bitte geben Sie ein neues Passwort an',
  },
  VALIDATION_PASSWORDS_DISSENT: {
    id: 'ResetPasswordForm.VALIDATION_PASSWORDS_DISSENT',
    defaultMessage: 'Die beiden neuen Passwörter stimmen nicht überein',
  },
});

function ResetPasswordForm(props) {
  const {
    intl: { formatMessage },
  } = props;
  return (
    <Form
      onSubmit={props.onSubmit}
      validate={values => {
        const errors = {};
        if (!values.password) {
          errors.password = formatMessage(messages.VALIDATION_NEW_PWASSWORD);
        }
        if (values.password !== values.passwordRe) {
          errors.passwordRe = formatMessage(messages.VALIDATION_PASSWORDS_DISSENT);
        }
        return errors;
      }
      }
    >
      { ({ handleSubmit, submitError, submitting }) => (
        <form onSubmit={handleSubmit}>
          <p>
            {formatMessage(messages.CHOOSE_NEW_PASSWORD)}
          </p>
          <Field
            name="password"
          >
            { ({ input, meta }) => {
              return (
                <FormGroup2 input={input} mete={meta}>
                  <TextField
                    {...input}
                    tabIndex="1"
                    type="password"
                    placeholder={formatMessage(messages.PLACEHOLDER_NEW_PASSWORD)}
                  />
                </FormGroup2>
                )
            }}
          </Field>
          <Field
            name="passwordRe"
          >
            { ({ input, meta }) => {
              return (
                <FormGroup2 input={input} mete={meta}>
                  <TextField
                    {...input}
                    tabIndex="2"
                    type="password"
                    placeholder={formatMessage(messages.PLACEHOLDER_REPEAT_NEW_PASSWORD)}
                  />
                </FormGroup2>
            )
            }}
          </Field>
          <FormError error={submitError} />
          <FormSubmit
            btnClass="btn-lg btn-primary btn-block"
            btnProps={{ tabIndex: 3 }}
            submitting={submitting}
            label={formatMessage(messages.PLACEHOLDER_CHANGE_PASSWORD)}
          />
          </form>
      )}
    </Form>
  );
}

export default injectIntl(ResetPasswordForm);
