import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

import { connect } from 'react-redux';

import { Link } from 'react-router';
import nutzer from 'styles/images/nutzer.png';
import handw from 'styles/images/handw.png'

import Logo from 'components/Logo';


// <div className="flex">
//     <div className="flex-box home-content">

const messages = defineMessages({
  MAINTENANCE_MODE_TITLE: {
    id: 'MaintenanceModePage.MAINTENANCE_MODE_TITLE',
    defaultMessage: 'tele-LOOK wird gewartet',
  },
  MAINTENANCE_MODE_DESCRIPTION: {
    id: 'MaintenanceModePage.MAINTENANCE_MODE_DESCRIPTION',
    defaultMessage: 'Momentan befindet sich tele-LOOK im Wartungsmodus. Bitte haben Sie ein wenig gedult, wir sind gleich wieder für Sie da!',
  },
});

// .container {
//   display: flex;
// }
//
// .col1 {
//   flex: 0 0 320px;
//   position: relative;
//   overflow: auto;
//   padding-bottom: 20px;
// }
//
// .col2 {
//   flex: 1;
// }


class MaintenanceModePage extends React.PureComponent {
    render() {
      const { intl: { formatMessage } } = this.props;

        return (
            <div className="flex">
                <div className="content-block row login-page">
                    <Logo />
                        <div className="home-content col-md-12 col-sm-12 col-xs-12">
                            <img
                                role="presentation"
                                className="hidden-xs hidden-sm"
                                src={handw}
                                style={{ height: 80, width: 80, float: 'right' }}
                            />
                            <h3>
                               {formatMessage(messages.MAINTENANCE_MODE_TITLE)}
                            </h3>
                            <p>{formatMessage(messages.MAINTENANCE_MODE_DESCRIPTION)}</p>
                        </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(MaintenanceModePage);
