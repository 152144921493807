/* eslint-disable */
(function () {
  const throttle = function (type, name, obj) {
    var obj = obj || window;
    let running = false;
    const func = function () {
      if (running) { return; }
      running = true;
      requestAnimationFrame(() => {
        obj.dispatchEvent(new CustomEvent(name));
        running = false;
      });
    };
    obj.addEventListener(type, func);
  };

    /* init - you can init any event */
  throttle('resize', 'optimizedResize');
  throttle('scroll', 'optimizedScroll');
}());
