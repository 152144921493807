import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';

import FormGroup2 from 'components/form/FormGroup';
import FormError from 'components/form/FormError';
import FormSubmit from 'components/form/FormSubmit';
import TextField from 'components/form/TextField';

import { availableLocales } from 'actions/intl';
import _ from 'lodash';

const messages = defineMessages({
  PLACEHOLDER_COMPANY_NAME: {
    id: 'CompanyUpdateComponent.PLACEHOLDER_COMPANY_NAME',
    defaultMessage: 'Unternehmensname',
  },
  LABEL_SAVE_CHANGES: {
    id: 'CompanyUpdateComponent.LABEL_SAVE_CHANGES',
    defaultMessage: 'Änderungen speichern',
  },
  VALIDATION_FIELD_REQUIRED: {
    id: 'CompanyUpdateComponent.VALIDATION_FIELD_REQUIRED',
    defaultMessage: 'Dieses Feld ist erforderlich.',
  },
  LABEL_LANGUAGE: {
    id: 'CompanyUpdateComponent.LABEL_LANGUAGE',
    defaultMessage: 'Sprache zur Erzeugung von Dokumenten',
  },
  LOCALE_DE: {
    id: 'CompanyUpdateComponent.LOCALE_DE',
    defaultMessage: 'Deutsch',
  },
  LOCALE_EN: {
    id: 'CompanyUpdateComponent.LOCALE_EN',
    defaultMessage: 'Englisch',
  },
});

function CompanyUpdateComponent(props) {
  const {
    intl: { formatMessage },
  } = props;

  const locales = availableLocales.map(locale => ({
    value: locale,
    label: formatMessage(messages[`LOCALE_${locale.toUpperCase()}`]),
  }));

  const onSubmitLocal = (data) => {
    const dataSubmit = {
      name: data.name,
      locale: data.locale.value
    }
    return props.onSubmit(dataSubmit);
  }

  return (
    <Form
      onSubmit={onSubmitLocal}
      validate={values => {
        const errors = {};
        if (!values.name) {
          errors.name = formatMessage(messages.VALIDATION_FIELD_REQUIRED);
        }
        return errors;
      }}
      initialValues={{
        ...props.initialValues,
        locale: _.find(locales, locale => locale.value === props.initialValues.locale)
      }}
    >
      {({ submitError, handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="name"
          >
            { ({ input, meta }) => {
              return (
                <FormGroup2 input={input} meta={meta}>
                  <TextField
                    {...input}
                    autoFocus
                    autoComplete="off"
                    placeholder={formatMessage(messages.PLACEHOLDER_COMPANY_NAME)}
                  />
                </FormGroup2>
              );
            }}
          </Field>
          <span>{formatMessage(messages.LABEL_LANGUAGE)}</span>
          <Field
            name="locale"
          >
          { ({ input, meta }) => {
            return (
              <FormGroup2 input={input} meta={meta}>
                <Select
                  {...input}
                  name="form-field-locale"
                  value={input.value}
                  options={locales}
                  searchable={false}
                  clearable={false}
                  placeholder="Sprache"
                />
              </FormGroup2>
            );
          }}
          </Field>
          <FormError error={submitError} />
          <FormSubmit
            btnClass="lh-btn lh-btn-primary"
            submitting={submitting}
            label={formatMessage(messages.LABEL_SAVE_CHANGES)}
          />
        </form>
      )}
    </Form>
  );
}

/*
const CompanyUpdateComponentReduxForm = reduxForm({
  form: 'companyUpdate',
  fields: ['name', 'locale'],
  validate: (values, { intl: { formatMessage } }) => ({
    name: values.name ? null : formatMessage(messages.VALIDATION_FIELD_REQUIRED),
  }),
  touchOnBlur: false,
})(CompanyUpdateComponent);
*/
export default injectIntl(CompanyUpdateComponent);
