import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import {
  mute,
  unmute,
  touch,
  requestAudio,
  disableAudio,
} from '../../actions/ot_signaling';

const messages = defineMessages({
  DEACTIVATE_AUDIO: {
    id: 'Tools.DEACTIVATE_AUDIO',
    defaultMessage: 'Audio-Übertragung deaktivieren',
  },
  REQUEST_AUDIO: {
    id: 'Tools.REQUEST_AUDIO',
    defaultMessage: 'Audio-Übertragung beim Kunden anfragen',
  },
  REMOTE_MUTED: {
    id: 'Tools.REMOTE_MUTED',
    defaultMessage: 'Audio-Übertragung der Gegenseite stummgeschaltet',
  },
  REQUEST_UNMUTED: {
    id: 'Tools.REQUEST_UNMUTED',
    defaultMessage: 'Gegenseite überträgt Audio',
  },
  MUTE_AUDIO: {
    id: 'Tools.MUTE_AUDIO',
    defaultMessage: 'Audio stumm schalten',
  },
  AUDIO_SELF_MUTED: {
    id: 'Tools.AUDIO_SELF_MUTED',
    defaultMessage: 'Audio ist stumm geschaltet',
  },
  RESUME_VIDEO: {
    id: 'Tools.RESUME_VIDEO',
    defaultMessage: 'Videoübertragung reaktivieren',
  },
  PAUSE_VIDEO: {
    id: 'Tools.PAUSE_VIDEO',
    defaultMessage: 'Videoübertragung pausieren',
  },
  CAPTURE_SCREENSHOT: {
    id: 'Tools.CAPTURE_SCREENSHOT',
    defaultMessage: 'Screenshot aufnehmen',
  },
  SCREENSHOT_SCRIBBLE: {
    id: 'Tools.SCREENSHOT_SCRIBBLE',
    defaultMessage: 'Screenshot zeichnen',
  },
  CLOSE_FULLSCREEN: {
    id: 'Tools.CLOSE_FULLSCREEN',
    defaultMessage: 'Vollbild schließen',
  },
  OPEN_FULLSCREEN: {
    id: 'Tools.OPEN_FULLSCREEN',
    defaultMessage: 'Vollbild öffnen',
  },
  END_CALL: {
    id: 'Tools.END_CALL',
    defaultMessage: 'Gespräch beenden',
  },
});

function Tool(props) {
  const { name, clickHandler, active, className } = props;
  return (
    <span
      className={classnames('button', `button-${name}`, { active }, className)}
      onClick={clickHandler}
      title={props.title}
    >
      <i className={classnames('icon', `icon-${name}`)} />
    </span>
  );
}

function RemoteAudioRequestTool(props) {
  return (
    <span
      className="button active"
      onClick={props.active ? props.disableAudio : props.requestAudio}
      title={props.intl.formatMessage(
        props.active ? messages.DEACTIVATE_AUDIO : messages.REQUEST_AUDIO
      )}
    >
      {!props.active && <i className="glyphicon glyphicon-volume-off" />}
      {props.active && props.muted && <i className="fa fa-volume-off" />}
      {props.active && !props.muted && <i className="fa fa-volume-up" />}
    </span>
  );
}

const RemoteAudioRequestControl = connect(state => state.audio.remote, {
  requestAudio,
  disableAudio,
})(injectIntl(RemoteAudioRequestTool));

function RemoteAudioTool(props) {
  if (!props.active) {
    return null;
  }

  // TODO rename REQUEST_UNMUTED after translations
  return (
    <span
      className="button active"
      title={props.intl.formatMessage(
        props.muted ? messages.REMOTE_MUTED : messages.REQUEST_UNMUTED
      )}
    >
      <i
        className={classnames(
          'fa',
          props.muted ? 'fa-volume-off' : 'fa-volume-up'
        )}
      />
    </span>
  );
}

const RemoteAudioControl = connect(state => state.audio.remote)(
  injectIntl(RemoteAudioTool)
);

function SelfAudioTool(props) {
  if (!props.active) {
    return null;
  }
  return (
    <span
      className="button active"
      onClick={props.muted ? props.unmute : props.mute}
      title={props.intl.formatMessage(
        props.muted ? messages.AUDIO_SELF_MUTED : messages.MUTE_AUDIO
      )}
    >
      <i
        className={classnames(
          'fa',
          props.muted ? 'fa-microphone-slash' : 'fa-microphone'
        )}
      />
    </span>
  );
}

const SelfAudioControl = connect(state => state.video.room.self.audio, { mute, unmute })(
  injectIntl(SelfAudioTool)
);

function Tools(props) {
  const {
    isSubscriber,

    videoActive,
    paused,
    pause,
    resume,

    screenshotActive,
    captureScreenshot = null, // will be used as clickHandler

    chatActive,
    toggleChat = null, // will be used as clickHandler

    callActive,
    endCall,

    fullsize,
    videoSetFullsize,
    videoSetReducedsize,
    intl: { formatMessage },
  } = props;
  const cantToggleFullscreen = (videoActive || screenshotActive) && fullsize;
  const scribbleActive = false;

  return (
    <div className="tools">
      <div className="middle">
        {isSubscriber ? <RemoteAudioRequestControl /> : <RemoteAudioControl />}

        <SelfAudioControl />

        {videoActive &&
          <span
            className={classnames('button', { active: !paused })}
            onClick={paused ? resume : pause}
            title={formatMessage(
              paused ? messages.RESUME_VIDEO : messages.PAUSE_VIDEO
            )}
          >
            <i className={classnames('fa', 'fa-pause-circle-o')} />
          </span>}

        {screenshotActive &&
          <Tool
            name="camera"
            active
            clickHandler={captureScreenshot}
            title={formatMessage(messages.CAPTURE_SCREENSHOT)}
          />}

        {scribbleActive &&
        <Tool
          name="notiz"
          active
          clickHandler={captureScreenshot}
          title={formatMessage(messages.SCREENSHOT_SCRIBBLE)}
        />}

        {chatActive && <Tool name="bubble" active clickHandler={toggleChat} />}

        {cantToggleFullscreen &&
          <span
            className={classnames('button', 'active')}
            onClick={fullsize ? videoSetReducedsize : videoSetFullsize}
            title={formatMessage(
              fullsize ? messages.CLOSE_FULLSCREEN : messages.OPEN_FULLSCREEN
            )}
          >
            <i className={classnames('fa', 'fa-arrows-alt')} />
          </span>}

        <Tool
          name="phone-down"
          active={callActive}
          clickHandler={endCall}
          title={formatMessage(messages.END_CALL)}
        />
      </div>
    </div>
  );
}

export default injectIntl(Tools);
